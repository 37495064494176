import { FormBase } from '../FormBase'

export class DeclarationClauses extends FormBase {
  // shared.name
  // shared.nric
  // shared.repCode
  type = 'declaration-clauses'
  data = {
    agree: false
  }

  signatures = {
    applicant: '',
    applicantDate: undefined
  }

  get error () {
    return !this.data.agree ? [{ key: 'agree', msg: 'Please read and indicate terms agreement' }] : []
  }
}
