/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdHocDto } from '../models/AdHocDto';
import type { CalculationExceptionCommissionRecordUpdateDTO } from '../models/CalculationExceptionCommissionRecordUpdateDTO';
import type { CommissionBatchDTO } from '../models/CommissionBatchDTO';
import type { CommissionData } from '../models/CommissionData';
import type { CommissionRecordDTO } from '../models/CommissionRecordDTO';
import type { CommissionRecordResponseDto } from '../models/CommissionRecordResponseDto';
import type { CommissionRecordUpdateDTO } from '../models/CommissionRecordUpdateDTO';
import type { CommissionStatementDTO } from '../models/CommissionStatementDTO';
import type { DownloadCsvDto } from '../models/DownloadCsvDto';
import type { IncomeDeductiblesDTO } from '../models/IncomeDeductiblesDTO';
import type { ListTransactionResponseDto } from '../models/ListTransactionResponseDto';
import type { NewCommissionDataDTO } from '../models/NewCommissionDataDTO';
import type { NewIncomeDeductiblesDTO } from '../models/NewIncomeDeductiblesDTO';
import type { Object } from '../models/Object';
import type { ReportGeneratorDto } from '../models/ReportGeneratorDto';
import type { ReportLogDTO } from '../models/ReportLogDTO';
import type { ScheduleTimeDto } from '../models/ScheduleTimeDto';
import type { UpdateCommissionBatchDTO } from '../models/UpdateCommissionBatchDTO';
import type { ValidateTaxInvoiceDTO } from '../models/ValidateTaxInvoiceDTO';
import { request as __request } from '@openapi/request';

export class CommissionRecordsService {

    /**
     * @param searchField
     * @param searchFieldValue
     * @param limit
     * @param page
     * @param status
     * @param retrieveAll
     * @returns CommissionBatchDTO
     * @throws ApiError
     */
    public static async commissionRecords(
limit?: number,
page?: number,
status?: string,
retrieveAll?: boolean,
searchField?: string,
searchFieldValue?:string
): Promise<Array<CommissionBatchDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/all`,
            query: {
                'limit': limit,
                'page': page,
                'status': status,
                'retrieveAll': retrieveAll,
                'searchField': searchField,
                'searchFieldValue': searchFieldValue
            },
        });
        return result.body;
    }

    /**
     * @param searchField
     * @param searchFieldValue
     * @param limit
     * @param page
     * @param status
     * @param retrieveAll
     * @returns any
     * @throws ApiError
     */
    public static async exportBatchDataCsv(
searchField?: string,
searchFieldValue?: string,
limit?: number,
page?: number,
status?: string,
retrieveAll?: boolean,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/batch/export-csv`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'limit': limit,
                'page': page,
                'status': status,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param searchField
     * @param searchFieldValue
     * @param limit
     * @param page
     * @param isValidated
     * @returns ListTransactionResponseDto
     * @throws ApiError
     */
    public static async getTansactionList(
searchField?: string,
searchFieldValue?: string,
limit?: number,
page?: number,
isValidated?: boolean,
): Promise<ListTransactionResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/transaction/list`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'limit': limit,
                'page': page,
                'isValidated': isValidated,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async validateNewCommissionData(
requestBody: Array<CommissionData>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/validate/new-commission-data`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async validateNewCommissionDataWithRemuneration(
requestBody: NewCommissionDataDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/validate/new-commission-data-remuneration`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async importNewCommissionData(
requestBody: NewCommissionDataDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/import/new-commission-data`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async importNewCommissionDataViaHierarchySetup(
requestBody: NewCommissionDataDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/import/new-commission-data-hierarchy-setup`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async getReport(
oid: any,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/report/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async setScheduleJobTime(
requestBody: ScheduleTimeDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/setScheduleJobTime`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async generateAdHocStatement(
requestBody: AdHocDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/generateAdHocStatement`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getScheduleJobTime(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/getScheduleJobTime`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async downloadCsv(
requestBody: DownloadCsvDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/downloadCsv`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async getCommissionBatchCsv(
oid: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/commission-batch-csv/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async reportGenerator(
requestBody: ReportGeneratorDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/reportGenerator`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns CommissionStatementDTO
     * @throws ApiError
     */
    public static async exportCsvReportList(): Promise<Array<CommissionStatementDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/reportList/export-csv`,
        });
        return result.body;
    }

    /**
     * @returns CommissionStatementDTO
     * @throws ApiError
     */
    public static async csvExportReportListByBatchId(
        batchId: number
    ): Promise<Array<CommissionStatementDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/reportList/export-report-csv`,
            query: {
                'batchId': batchId
            }
        });
        return result.body;
    }

    /**
     * @param limit
     * @param page
     * @returns CommissionStatementDTO
     * @throws ApiError
     */
    public static async reportList(
limit: number,
page: number,
searchField?: string,
searchFieldValue?: string
): Promise<Array<CommissionStatementDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/reportList/all`,
            query: {
                'limit': limit,
                'page': page,
                'searchField': searchField,
                'searchFieldValue': searchFieldValue
            },
        });
        return result.body;
    }

    /**
     * @param userOid
     * @returns CommissionStatementDTO
     * @throws ApiError
     */
    public static async reportListForUser(
userOid: string,
limit?: number,
page?: number,
searchField?: string,
searchFieldValue?: string
): Promise<Array<CommissionStatementDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/reportList/${userOid}`,
            query: {
                'limit': limit,
                'page': page,
                'searchField': searchField,
                'searchFieldValue': searchFieldValue
            },
        });
        return result.body;
    }

    /**
     * @param published
     * @returns any
     * @throws ApiError
     */
    public static async exportCsvStatementList(
published?: boolean,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/statementList/export-csv`,
            query: {
                'published': published,
            },
        });
        return result.body;
    }

    /**
     * @param limit
     * @param page
     * @returns CommissionStatementDTO
     * @throws ApiError
     */
    public static async getReportList(
limit: number,
page: number,
): Promise<Array<CommissionStatementDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/statementList/all`,
            query: {
                'limit': limit,
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * @param batchId
     * @param limit
     * @param page
     * @returns CommissionStatementDTO
     * @throws ApiError
     */
    public static async getReportListByBatchId(
batchId: number,
limit: number,
page: number,
): Promise<Array<CommissionStatementDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/statementList/${batchId}`,
            query: {
                'limit': limit,
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * @param batchId
     * @param valueDate
     * @returns any
     * @throws ApiError
     */
    public static async getGiroReport(
batchId: number,
valueDate: number,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/giro-report`,
            query: {
                'batchId': batchId,
                'valueDate': valueDate,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updatePublishedStatus(
requestBody: Object,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/commission_records/publishStatus`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async listOfPrincipalsAndUsers(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/listOfPrincipalsAndUsers`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getDeductiblesDescription(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/deductibles/description`,
        });
        return result.body;
    }

    /**
     * @returns IncomeDeductiblesDTO
     * @throws ApiError
     */
    public static async allIncomeDeductibles(requestBody:any): Promise<any> {
      const result = await __request({
          method: 'POST',
          path: `/web/api/commission_records/deductibles/all`,
          body: requestBody,
      });
      return result.body;
  }
    /**
     * @returns IncomeDeductiblesDTO
     * @throws ApiError
     */
    public static async getDeductiblesCsv(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/deductibles/export-csv`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addDeductibles(
requestBody: NewIncomeDeductiblesDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/deductibles`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateDeductibles(
requestBody: IncomeDeductiblesDTO,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/commission_records/deductibles`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async deleteDeductibles(
oid: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/commission_records/deductibles/${oid}`,
        });
        return result.body;
    }

    /**
     * @param limit
     * @param page
     * @returns ReportLogDTO
     * @throws ApiError
     */
    public static async getReportLog(
limit: number,
page: number,
searchField?: string,
searchFieldValue?: string
): Promise<Array<ReportLogDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/report-log`,
            query: {
                'limit': limit,
                'page': page,
                'searchField': searchField,
                'searchFieldValue': searchFieldValue
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async exportCsvReportLog(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/report-log/export-csv`,
        });
        return result.body;
    }

    /**
     * @param batchOid
     * @param limit
     * @param page
     * @param retrieveAll
     * @returns CommissionRecordResponseDto
     * @throws ApiError
     */
    public static async getCommissionRecordsByBatch(
batchOid: string,
limit?: number,
page?: number,
retrieveAll?: boolean,
): Promise<{ data: Array<CommissionRecordDTO>; page: number; recordsFiltered: number; recordsTotal: number; isStatementGenerated: boolean }> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/batch/${batchOid}`,
            query: {
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param policyNumber
     * @returns CommissionRecordDTO
     * @throws ApiError
     */
    public static async getCommissionRecordsByPolicyNumber(
policyNumber: string,
): Promise<Array<CommissionRecordDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/${policyNumber}/policy`,
        });
        return result.body;
    }

    /**
     * @param policyNumber
     * @returns any
     * @throws ApiError
     */
    public static async downloadPolicyCommissionRecordsCsv(
policyNumber: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/${policyNumber}/csv`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateCommissionBatch(
requestBody: UpdateCommissionBatchDTO,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/commission_records/batch`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async validateTaxInvoiceNo(
requestBody: ValidateTaxInvoiceDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission_records/validate-tax-invoice-no`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateTransaction(
oid: string,
requestBody: CommissionRecordUpdateDTO,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/commission_records/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param module
     * @param advCode
     * @param policyNumber
     * @param planCode
     * @param productType
     * @param fileName
     * @param principal
     * @param type
     * @returns any
     * @throws ApiError
     */
    public static async exportCsv(
    module: string,
    type?: string,
    policyNumber?: string,
    planCode?: string,
    productType?: string,
    fileName?: string,
    principal?: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/export-csv-new`,
            query: {
                'module': module,
                'type': type,
                'policyNumber': policyNumber,
                'planCode': planCode,
                'productType': productType,
                'fileName': fileName,
                'principal': principal,
            },
        });
        return result.body;
    }

    /**
     * @param batchId The unique identifier for the batch
     * @returns any
     * @throws ApiError
     */
    public static async exportZip(
batchId: number,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/export-finance-report`,
            query: {
                'batchId': batchId,
            },
        });
        return result.body;
    }

    /**
     * @param oid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateCalculationExceptionCommission(
oid: string,
requestBody: CalculationExceptionCommissionRecordUpdateDTO,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/commission_records/update_calculated_exception_record/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async getFile(
oid: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/batch/file/${oid}`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getVoucherCode(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/commission_records/deductibles/voucher-code`,
        });
        return result.body;
    }

}
