import { Base } from '../Base'
import { MaxChar } from '@master/annotation/MaxChar'

export default class AdviserDetails extends Base {
  importantNoticeList: string[] = []

  @MaxChar(45)
  importantNoticeOthers = ''

  adviserFaCode = ''

  @MaxChar(32)
  adviserMasRepNo = ''

  @MaxChar(32)
  adviserName = ''

  adviserOid = ''

  afterExtractData (data: { importantNoticeList: string[] }) {
    data.importantNoticeList = data.importantNoticeList || []
    if (typeof data.importantNoticeList === 'string') {
      data.importantNoticeList = JSON.parse(data.importantNoticeList).map(valueData => valueData.trim())
    }
    return data
  }
}
