import { isEmpty } from 'lodash/fp'
import { ProductModel } from './ProductModel'
import LobToCategoryMap from '@core/pages/new-business-register/lob-to-category.json'

export class ProductList {
  originalList: ProductModel[]
  constructor (products: ProductModel[]) {
    this.originalList = products
  }

  retrieveRidersUsingFamilyCode (familyCode: string, issuerOid: string, needsCovered: string[]) {
    needsCovered = needsCovered.filter(needs => !isEmpty(needs))

    return this.originalList
      .filter(product => product.isRider())
      .map(product => {
        const productNeeds: string[] = JSON.parse(product.needsCovered)

        const isNeedFound = productNeeds.find(need => needsCovered.includes(need))
        const isFamilyCodeFound = !isEmpty(product.productFamilyCode) && product.productFamilyCode === familyCode
        const isSameIssuer = product.issuer_oid === issuerOid

        if (isFamilyCodeFound && isSameIssuer) {
          if (needsCovered.length && isNeedFound) {
            return product
          } else if (!needsCovered.length) {
            return product
          }
        }
        if (isEmpty(familyCode) && isSameIssuer && isEmpty(product.productFamilyCode)) {
          if (needsCovered.length && isNeedFound) {
            return product
          } else if (!needsCovered.length) {
            return product
          }
        }
        return null
      }).filter(product => !!product)
      .sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by name
  }

  retrieveInsuranceProductList (needsCovered: string[], issuerOids: string[]) {
    needsCovered = needsCovered.filter(needs => !isEmpty(needs))
    issuerOids = issuerOids.filter(oid => !isEmpty(oid))
    return this.originalList
      .filter(product => product.isInsurance() && !product.isRider())
      .map(product => {
        if (issuerOids.length === 0 && needsCovered.length === 0) return product
        const productNeeds: string[] = JSON.parse(product.needsCovered)

        const isNeedFound = productNeeds.find(need => needsCovered.includes(need))
        const isIssuerFound = issuerOids.includes(product.issuer_oid)
        if (needsCovered.length > 0 && issuerOids.length > 0) {
          if (isNeedFound && isIssuerFound) return product
        } else if (isNeedFound) {
          return product
        } else if (isIssuerFound) {
          return product
        }

        return null
      }).filter(product => !!product)
      .sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by name
  }

  retrieveMainInvestmentProductList (issuerOids: string[]) {
    issuerOids = issuerOids.filter(oid => !isEmpty(oid))
    return this.originalList
      .filter(product => product.category?.includes('Investment Account') || product.category?.includes('Collective Investment Scheme'))
      .map(product => {
        if (issuerOids.length === 0) return product
        const isIssuerFound = issuerOids.includes(product.issuer_oid)
        if (isIssuerFound) {
          return product
        }
        return null
      }).filter(product => !!product)
  }

  retrieveAllInvestmentProductList (issuerOids: string[]) {
    issuerOids = issuerOids.filter(oid => !isEmpty(oid))
    return this.originalList
      .filter(product => product.isInvestment())
      .map(product => {
        if (issuerOids.length === 0) return product
        const isIssuerFound = issuerOids.includes(product.issuer_oid)
        if (isIssuerFound) {
          return product
        }
        return null
      }).filter(product => !!product)
  }

  // if lob is cis, show product category of Investment Account
  // else return all products as usual
  retrieveProductFilteredList (policyType: string) {
    return this.originalList
      .filter(product => {
        if (policyType === 'collectiveinvestmentscheme') {
          return product.category === 'Investment Account'
        } else {
          return LobToCategoryMap[policyType].includes(product.category)
        }
      })
  }

  retrieveInvestmentProductListViaFamilyCode (familyCode: string, needsCovered: string[]) {
    needsCovered = needsCovered.filter(needs => !isEmpty(needs))
    return this.originalList
      .filter(product => product.isInvestment() && !product.category?.includes('Investment Account') && !product.category?.includes('Collective Investment Scheme'))
      .map(product => {
        const productNeeds: string[] = JSON.parse(product.needsCovered)

        const isNeedFound = productNeeds.find(need => needsCovered.includes(need))
        const isFamilyCodeFound = !isEmpty(product.productFamilyCode) && product.productFamilyCode === familyCode
        if (!isEmpty(familyCode) && needsCovered.length > 0) {
          if (isNeedFound && isFamilyCodeFound) return product
        } else if (isFamilyCodeFound) {
          return product
        }

        return null
      }).filter(product => !!product)
  }

  /**
   * This function is created for Wynnes v1 investment modal only.
   */
  retrieveInvestmentFundsWithOnlyNeeds (needsCovered: string[]) {
    return this.originalList
      .filter(product => product.isInvestment() && !product.category?.includes('Investment Account') && !product.category?.includes('Collective Investment Scheme'))
      .map(product => {
        const productNeeds: string[] = JSON.parse(product.needsCovered)
        const isNeedFound = productNeeds.find(need => needsCovered.includes(need))
        if (isNeedFound && needsCovered.length > 0) {
          return product
        }

        if (!needsCovered.length) {
          return product
        }

        return null
      }).filter(product => !!product)
  }

  retrieveInvestmentFundsVia (familyCode: string, issuerOids: string, needsCovered: string[]) {
    if (!issuerOids) return []

    return this.originalList
      .filter(product => product.isInvestment() && !product.category?.includes('Investment Account') && !product.category?.includes('Collective Investment Scheme'))
      .filter(product => product.issuer_oid === issuerOids)
      .map(product => {
        const productNeeds: string[] = JSON.parse(product.needsCovered)
        const isNeedFound = productNeeds.find(need => needsCovered.includes(need))
        const isFamilyCodeFound = !isEmpty(product.productFamilyCode) && product.productFamilyCode === familyCode
        const isSameIssuer = product.issuer_oid === issuerOids

        if (isFamilyCodeFound && isSameIssuer) {
          if (needsCovered.length && isNeedFound) {
            return product
          } else if (!needsCovered.length) {
            return product
          }
        }
        if (isEmpty(familyCode) && isSameIssuer && isEmpty(product.productFamilyCode)) {
          if (needsCovered.length && isNeedFound) {
            return product
          } else if (!needsCovered.length) {
            return product
          }
        }
        return null
      }).filter(product => !!product)
      .sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by name
  }

  retrieveEbProductList (familyCode: string, productOid: string, issuerOid: string) {
    return this.originalList
      .filter(product => product.productFamilyCode === familyCode && product.oid !== productOid && product.issuer_oid === issuerOid)
      .sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by name
  }
}
