import { ClientBase } from '../ClientBase'
import { IDateService } from '@master/services/IDateService'
import { Default } from '@master/annotation/Default'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

export default class SimplePersonalDetails extends ClientBase {
  dob = 0
  minClientAgeMismatched = false
  gender = ''
  included = false
  maritalStatus = ''
  familyMedical = ''
  familyMedicalSpecified = ''
  transactValue=''
  educationLevel=''

  @MaxChar(InputMaxChar.XSMALL)
  name = ''

  identityType = ''

  @MaxChar(InputMaxChar.XSMALL)
  nric = ''

  @MaxChar(InputMaxChar.XSMALL)
  occupation = ''

  smoker = ''
  pepAssociated = ''
  education = ''

  englishProficiency = ''

  @Default([])
  spokenLanguage: string[] = []

  title = ''

  @Default([])
  writtenLanguage: string[] = []

  @Ignore()
  @Pointer('ROOT.clientDeclaration', 'reviewDate')
  reviewDate?: number = 0

  @Ignore()
  dateService: IDateService

  constructor (type: 'client_0' | 'client_1' = 'client_0', dateService: IDateService) {
    super(type)
    this.dateService = dateService
  }

  @Ignore()
  get age () {
    // cast dob to number to handle MyInfo save dob as string into DB
    return this.dateService.calculateAge(this.reviewDate ? new Date(Number(this.reviewDate)) : new Date(), new Date(Number(this.dob)))
  }

  // for calculate other age if needed
  isAboveAge (referAge: number) {
    return this.age >= referAge
  }

  isAbove62 () {
    return this.isAboveAge(62)
  }

  isSecondaryAndBelow () {
    const validOptions = [undefined, '', 'primaryandbelow', 'secondary']
    return validOptions.includes(this.education)
  }

  isNoGoodEnglish () {
    return !this.spokenLanguage.includes('english') ||
      !this.writtenLanguage.includes('english')
  }

  isSelectedClient () {
    let flags = 0
    if (this.isAbove62()) {
      flags++
    }
    if (this.isSecondaryAndBelow()) {
      flags++
    }
    if (this.isNoGoodEnglish()) {
      flags++
    }
    return flags >= 2
  }

  countCriteriaMatched() {
    let flags = 0
    if (this.isAbove62()) {
      flags++
    }
    if (this.isSecondaryAndBelow()) {
      flags++
    }
    if (this.isNoGoodEnglish()) {
      flags++
    }
    return flags
  }
}
