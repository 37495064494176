import ExistingPortfolioDTO from '@master/model/kyc-form/existing-plans/v2/ExistingPortfolioDTO'
import ExistingPortfolioDeclaration from '@master/model/kyc-form/existing-plans/ExistingPortfolioDeclaration'
import CustomExistingPortfolioSummary from './CustomExistingPortfolioSummary'
import CustomExistingPortfolioV2 from '@company-common/model/kyc-form/existing-plans/CustomExistingPortfolioV2'

export default class CustomExistingPortfolioDTO extends ExistingPortfolioDTO {
  declare summary: CustomExistingPortfolioSummary
  portfolios: CustomExistingPortfolioV2[] = []

  constructor (type: 'existing_insurance' = 'existing_insurance') {
    super(type)
    this.declaration = new ExistingPortfolioDeclaration()
    this.summary = new CustomExistingPortfolioSummary(new CustomExistingPortfolioV2())
  }

  afterExtractData (data: { portfolios: any[] }) {
    const portfolios = data.portfolios || []
    data.portfolios = portfolios.map(element => {
      const portfolio = new CustomExistingPortfolioV2()
      Object.assign(portfolio, portfolio.extractData(element))
      return portfolio
    })
    return data
  }
}
