import { options } from '@master/model/options/Options'

export const FrequencyList = ['Annual', 'Half-Yearly', 'Quarterly', 'Monthly', 'Single'] as const
export type FrequencyType = typeof FrequencyList[number]
export const FrequencyOptions: options[] = FrequencyList.map(frequency => {
  return {
    label: frequency,
    value: frequency
  }
})
export const FrequencyMap = {
  1: 'Annual',
  2: 'Half-Yearly',
  4: 'Quarterly',
  12: 'Monthly'
}

export const GetPremiumType = function (paymentMode) {
  switch (paymentMode) {
    case 12:
    case 4:
    case 2:
    case -1:
      return 'Regular'
    case 1:
      return 'Single'
    default:
      return ''
  }
}

export const FrequencyToValueMapping = function (frequency: FrequencyType) {
  switch (frequency) {
    case 'Monthly':
      return 12
    case 'Quarterly':
      return 4
    case 'Half-Yearly':
      return 2
    case 'Single':
      return 1
    case 'Annual':
      return -1 // this is returned as -1 to differentiate between single and annually, since previously both used 1
    default:
      return 0
  }
}

export const FrequencyToValueMappingCalculation = function (frequency: FrequencyType) {
  return frequency === 'Annual' ? Math.abs(FrequencyToValueMapping(frequency)) : FrequencyToValueMapping(frequency)
}

export const GetPaymentModeNiceName = function (paymentMode) {
  switch (paymentMode) {
    case 12:
      return 'Monthly'
    case 4:
      return 'Quarterly'
    case 2:
      return 'Half-Yearly'
    case 1:
      return 'Single'
    case -1: // this is returned as -1 to differentiate between single and annually, since previously both used 1
      return 'Annual'
    default:
      return ''
  }
}

export const PremiumPurpose = [
  'RP', 'SP', 'RSP', 'Top-up', 'TR'
]
