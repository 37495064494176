import { isEmpty } from 'lodash/fp'
import { FormBase, signature } from '../FormBase'

export const BisoTypeOptions = [
  { label: 'New', value: 'new' },
  { label: 'Amendment', value: 'amend' }
]
export const BisoStatusOptions = [
  { label: 'If you are holding any directorship/ sole proprietorship/ partnership/ other business interests in any entity in Singapore or elsewhere.', value: 'holding' },
  { label: 'If you are ceasing your existing business interest', value: 'ceasing' }
]
export const DeclarantTypeOptions = [
  { label: 'New', value: 'new' },
  { label: 'Existing', value: 'existing' }
]
export const BiRoleTypeOptions = [
  { label: 'Executive Director', value: 'ed' },
  { label: 'Non-Executive Director', value: 'ned' },
  { label: 'Sole Proprietor', value: 'soleprop' },
  { label: 'Partner', value: 'partner' },
  { label: 'Others', value: 'others' }
  // { label: 'Special Advisor', value: 'others6' },
  // { label: 'Board Advisor', value: 'others2' },
  // { label: 'Chairman', value: 'others3' }
]
export class BisoFields {
  submissionType: typeof BisoTypeOptions[number]['value'] = 'new'
  declarantType: typeof DeclarantTypeOptions[number]['value'] = 'new'
  // shared.repCode
  // shared.name
  // shared.nric.value
  status: typeof BisoStatusOptions[number]['value'] = 'holding'

  biEntityName = ''
  biEntityCountry = '' // options
  biEntityNature = '' // options
  biEffectiveDate: number = undefined
  biRole: typeof BiRoleTypeOptions[number]['value'] = 'ed' // options
  biRoleSpecified = ''

  ownEntityName = ''
  ownEntityCountry = '' // options
  ownEntityNature = '' // options
  ownEffectiveDate: number = undefined
  ownPercentage: number = undefined

  declarantSignature: signature

  entityPurpose = ''
  entityService = ''
  entityRole = ''
  entityInvolvement = ''

  conflicted = false
  agree = false
}
export class BusinessInterestShareholding extends FormBase {
  type = 'business-interest-shareholding'
  data: BisoFields = new BisoFields()
  signatures = {
    applicant: '',
    applicantDate: undefined
  }

  get error () {
    const errs = []
    if (this.data.biRole === 'others' && isEmpty(this.data.biRoleSpecified)) {
      errs.push({
        key: 'biRoleSpecified',
        msg: 'Please specify role in your entity'
      })
    }
    if (!this.data.agree) {
      errs.push({
        key: 'agree',
        msg: 'Please read and confirm agreement on terms'
      })
    }
    return errs
  }
}
