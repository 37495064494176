import { ProductMetaData } from '../ProductMetaData'

export const DwellingOptions = [
  { value: '', label: '' },
  { value: 'HDB', label: 'HDB' },
  { value: 'Condominium', label: 'Condominium' },
  { value: 'Pte Apartment', label: 'Pte Apartment' },
  { value: 'Terrace', label: 'Terrace' },
  { value: 'Detached', label: 'Detached' },
  { value: 'Semi-Detached', label: 'Semi-Detached' },
  { value: 'Others', label: 'Others' }
]
export class GiHomeContents extends ProductMetaData {
  insuranceClass = undefined
  dwellingType = undefined
  othersDwellingType = undefined
  houseNo = undefined
  unitNo = undefined
  streetName = undefined
  building = undefined
  postalCode = undefined
  buildInArea = undefined
  underinsuredRemarks = undefined

  grossPremium = undefined
  gst = undefined
  commissionRate = undefined
  commissionPayment = undefined
  commissionPayable = undefined
  grossCommissionEarned = undefined
}
