import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import DefaultNeeds from '@master/config/default-needs.json'
import API from '@core/services/API'
import { inject } from 'inversify-props'
import { ProductDTO, ProductService } from '@core/pages/adminProductList/api'

@Module({
  namespaced: true,
  name: 'ProductStore'
})
export default class ProductStore extends VuexModule {
  //  @inject()
  // protected $api: API

  loading = false
  products: ProductDTO[] = []

  // @Action({rawError: true})
  // async getProductListNeeds () {
  //   const { value } = await this.$api.kvm.get({
  //     key: 'globalproducts_needs_abc'
  //   })
  //   const needsList = value ? JSON.parse(value) : DefaultNeeds
  //   return [...(new Set(needsList))]
  // }

  @Action({rawError: true})
  async retrieveTrainingRequiredProducts (request: { status: number[]; isTrainingRequired: number }) {
    this.context.commit('setLoading', true)
    
    try {
      const products: any = await ProductService.retrieveAllProducts(request)
      this.context.commit('setProducts', products.data)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Mutation
  setProducts (products: ProductDTO[]) {
    this.products = products
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }
}
