import { CpdMasterRecordDTO } from '../api'

export class CpdCourse implements CpdMasterRecordDTO {
  oid: string = undefined
  ctm?: number = undefined
  utm?: number = undefined
  courseId: string = undefined
  courseName: string = undefined
  courseDesc: string = undefined
  createdDate: number = undefined
  createdByOid: string = undefined
  courseType: string = undefined
  productOid?: string = undefined
  isDeleted?: boolean = undefined
  productName?: string = undefined
  productProvider?: string = undefined
  typeOfTraining: string = undefined
  anhHours: number = undefined
  coreHours: number = undefined
  suppHours: number = undefined
  giHours: number = undefined
}
