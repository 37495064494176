import { IRiskRatingService } from '@master/services/IRiskRatingService'
import ProductBase from './ProductBase'
import { FrequencyType } from '@master/model/options/FrequencyOptions'
import { ClientChoiceOptions } from '@master/model/options/ClientChoiceOptions'

export default class RiderProduct extends ProductBase {
  frequency: '' | FrequencyType = ''
  clientIndex?: 0 | 1 = undefined
  premiumTerm? = undefined
  coverageTerm? = undefined
  multiplieAdditional?:boolean = true
  ppi? = undefined
  additionalSumAssured? = undefined
  additionalPremium? = undefined
  additionalPremiumTerm? = undefined
  additionalPolicyTerm? = undefined
  mainProductClientChoice: '' | keyof typeof ClientChoiceOptions = '' // this is used to save its basic product's client choice, used in client choice table

  constructor (riskRatingService: IRiskRatingService, basicClientIndex?, basicProductClientChoice?: keyof typeof ClientChoiceOptions) {
    super(riskRatingService)
    // new rider client choice default match basic product
    this.clientChoice = basicProductClientChoice
    this.mainProductClientChoice = basicProductClientChoice
    this.clientIndex = basicClientIndex
    this.type = 'insurance-rider'
  }

  extractData(rawData: any, index?: number);
  extractData (rawData: any): {} {
    const data = super.extractData(rawData)
    data.clientIndex = this.clientIndex
    data.mainProductClientChoice = this.mainProductClientChoice
    data.type = 'insurance-rider'
    return data
  }
}
