/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RejectDto } from '../models/RejectDto';
import { request as __request } from '@openapi/request';

export class ESubmissionService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async generateAdviserReviewPdf(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/esub/update`,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async getESubmissionData(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esub/retrieve/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async rejectAllExistingESubmissions(
        requestBody: RejectDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/esub/reject`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async sendNotificationEmail(
        requestBody: RejectDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/esub/notify`,
            body: requestBody,
        });
        return result.body;
    }

}