import { snakeCase } from 'lodash/fp'

export default class NtucNric {
  clientName = ''
  ntucClientNricOid = ''
  nricList = []

  constructor (rawData) {
    this.extractData(rawData)
  }

  extractData (rawData: any) {
    console.log('init ntucnric', rawData)
    if (rawData) {
      Object.keys(this).forEach((key) => {
        if (rawData[snakeCase(key)]) {
          this[key] = rawData[snakeCase(key)]
          return
        }
        if (rawData[key]) this[key] = rawData[key]
      })
    }
  }

  updateProperties (latest: object) {
    Object.keys(latest).forEach((key) => {
      if (this[key]) this[key] = latest[key]
    })
  }

  pdfTeleporter () {
    return this.nricList.map((nlist) => {
      const forDisplay = { cname: '', url: '' }
      forDisplay.cname = this.clientName
      forDisplay.url = `/web/dsfNricView?ntucClientNricOid=${this.ntucClientNricOid}&fileName=${nlist.fileName}&fileType=${nlist.fileType}`
      return forDisplay
    })
  }
}
