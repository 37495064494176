import { round, fv, pv, sum, valueIsUndefined } from '../math'
import NeedsBase from '../NeedsBase'
import { Ignore } from '@master/annotation/Ignore'
import { isUndefined } from 'lodash/fp'

/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class RetirementNeeds extends NeedsBase {
  name = 'Retirement'
  needs = 'Retirement'
  years?: number = undefined
  incomePv?: number = undefined
  inflation?: number = undefined

  // required
  required1?: number = undefined
  required2?: number = undefined
  required3?: number = undefined
  required4?: number = undefined
  required5?: number = undefined

  // available
  available1?: number = undefined
  available2?: number = undefined
  available3?: number = undefined
  available4?: number = undefined
  available5?: number = undefined

  duration?: number = undefined
  infAdjReturn?: number = undefined

  additionalNotes = ''

  @Ignore()
  defaultInfAdjReturn?: number

  /**
   * this will auto set the corresponding rate to default if undefined
   * @param inDefaultRate
   * @param inDefaultInfAdjReturn
   */
  setupDefaultRates (inDefaultRate: number, inDefaultInfAdjReturn: number) {
    this.setupDefaultRate(inDefaultRate)
    this.defaultInfAdjReturn = inDefaultInfAdjReturn
    if (isUndefined(this.inflation)) this.inflation = this.defaultRate
    if (isUndefined(this.infAdjReturn)) this.infAdjReturn = this.defaultInfAdjReturn
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 's1') {
    super(`${type}_${id}`)
  }

  get firstYearIncome () {
    if (valueIsUndefined(this.incomeFv) && valueIsUndefined(this.available1)) return null
    const total = sum(this.incomeFv, -this.available1)
    return round(total, 0)
  }

  get incomeFv () {
    if (valueIsUndefined(this.incomePv)) return null
    const total = fv(this.incomePv, this.years, (this.inflation || 0) / 100)

    if (valueIsUndefined(total)) return null

    return round(total, 0)
  }

  get totalAmount () {
    if (valueIsUndefined(this.firstYearIncome) && valueIsUndefined(this.duration) && valueIsUndefined(this.infAdjReturn)) return null
    const total = pv(this.firstYearIncome, this.duration, this.infAdjReturn)
    return round(total, 0)
  }

  get surplusShortfall () {
    if (valueIsUndefined(this.available2) && valueIsUndefined(this.available3) && valueIsUndefined(this.totalAmount)) return null
    const total = sum(this.available2, this.available3, -this.totalAmount)
    return total
  }

  get isDefaultRate () {
    return this.defaultRate === this.inflation && this.defaultInfAdjReturn === this.infAdjReturn
  }
}
