import { MaxChar } from '@master/annotation/MaxChar'
import { ClientBase } from '../ClientBase'
import { Ignore } from '@master/annotation/Ignore'

export default class ThirdPartyPayor extends ClientBase {
  @MaxChar(73)
  budgetNameOfPayor = ''

  @MaxChar(73)
  budgetContactPayor = ''

  budgetIdentityType = ''

  @MaxChar(73)
  budgetNricppPayor = ''

  @MaxChar(73)
  budget3rdPartyPayor = ''

  @MaxChar(73)
  budgetPayorRelationship = ''

  @MaxChar(73)
  budgetOccupationPayor = ''

  payingPremium = ''

  productAccount = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0') { // previously was ma
    super(type)
  }

  /**
   * This function is currently in used by ifam-nest nbr v2 policy individual extraction function
   */
  @Ignore()
  get nric () {
    return this.budgetNricppPayor
  }

  /**
   * This function is currently in used by ifam-nest nbr v2 policy individual extraction function
   */
  @Ignore()
  get fullName () {
    return this.budgetNameOfPayor
  }

  isNRICOrFIN () {
    return this.budgetIdentityType === 'nric' || this.budgetIdentityType === 'fin'
  }
}
