/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { PicodedAccountDTO } from '@core/pages/account/api'
import { needToConvertJson } from '@master/annotation/Json';
import { convertJsonToMetaClass, needToConvertToMetaClass } from '@master/annotation/MetaClass';
import { getRawKey } from '@master/annotation/RawKey';
import { isEmpty, snakeCase, zipObject } from 'lodash/fp';

export class PicodedAccount implements PicodedAccountDTO {
    preferredName = ''
    reasonForMonitoring= ''
    monitoringEffectiveDate: number = null
    monitoringEndDate: number = null
    statusType= ''
    account_status= ''
    oid= ''
    systemrole= ''
    username = ''
    fullname= ''
    mobile= ''
    nric= ''
    email= ''
    branch= ''
    masRepno= ''
    companyCode= ''
    representativeCodes: Array<string> = []
    structureId: number = null
    typeOfMonitoring: Array<string> = []
    adviserProducts = ''

    // These three attributes are add on to get it to work
    // more efficiently because of the change in UI section
    // in user profile summary
    oldPassword= ''
    newPassword= ''
    confirmPassword= ''
    accountStatus= '';
    suspensionManagerFromDate= '';
    suspensionManagerToDate= '';

    snakeCaseKey (key: string) {
        return snakeCase(key)
    }

    /**
     * AssignData is used for get the data and fill into this object
     * @param headers headers from meta lis
     * @param data single row data from meta list
     */
    assignData (user: object) {
        Object.assign(this, user)
        // this.status = user['account_status']
    }

}
