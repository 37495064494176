/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CISDTO } from '../models/CISDTO';
import type { CsvFileResponseDTO } from '../models/CsvFileResponseDTO';
import type { ProductDTO } from '../models/ProductDTO';
import { request as __request } from '@openapi/request';

export class ProductMasterService {

    /**
     * Product master csv file validator
     * @param template
     * @returns CsvFileResponseDTO
     * @throws ApiError
     */
    public static async validateCsv(
        template: string,
        requestBody: any,
    ): Promise<CsvFileResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product-master/validate-csv`,
            query: {
                'template': template,
            },
            formData: { file: requestBody },
        });
        return result.body;
    }

    /**
     * Product master csv file upload
     * @param template
     * @returns CsvFileResponseDTO
     * @throws ApiError
     */
    public static async uploadCsv(
        template: string,
        requestBody: any,
    ): Promise<CsvFileResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product-master/import`,
            query: {
                'templateName': template,
            },
            formData: { file: requestBody },
        });
        return result.body;
    }

    /**
     * Gets list of product types
     * @returns any
     * @throws ApiError
     */
    public static async getProductTypes(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/product-types`,
        });
        return result.body;
    }

    /**
     * Gets list of product categories
     * @returns any
     * @throws ApiError
     */
    public static async getProductCategories(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/product-categories`,
        });
        return result.body;
    }

    /**
     * Gets list of fund types
     * @returns any
     * @throws ApiError
     */
    public static async getFundTypes(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/fund-types`,
        });
        return result.body;
    }

    /**
     * Gets non UT fund products list
     * @param searchField
     * @param searchFieldValue
     * @param limit
     * @param page
     * @param retrieveAll
     * @param sortBy
     * @param orderBy
     * @returns any
     * @throws ApiError
     */
    public static async getProductList(
        searchField?: string,
        searchFieldValue?: string,
        limit?: number,
        page?: number,
        retrieveAll?: boolean,
        sortBy?: string,
        orderBy?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
                'sortBy': sortBy,
                'orderBy': orderBy,
            },
        });
        return result.body;
    }

    public static async exportProductList(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/export-csv?template=product`,
        });
        return result.body;
    }

    /**
     * Gets CIS products list
     * @param searchField
     * @param searchFieldValue
     * @param limit
     * @param page
     * @param retrieveAll
     * @param sortBy
     * @param orderBy
     * @returns any
     * @throws ApiError
     */
    public static async getUtFundList(
        searchField?: string,
        searchFieldValue?: string,
        limit?: number,
        page?: number,
        retrieveAll?: boolean,
        sortBy?: string,
        orderBy?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/cis-funds`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'limit': limit,
                'page': page,
                'retrieveAll': retrieveAll,
                'sortBy': sortBy,
                'orderBy': orderBy,
            },
        });
        return result.body;
    }

    /**
     * Gets the products list based on the search criteria
     * @param oid
     * @param indicator
     * @param productName
     * @param productCode
     * @param productLine
     * @param providerName
     * @param productTypeName
     * @param productTypeCode
     * @param productCategory
     * @param productFamily
     * @param productFamilyTag
     * @param planName
     * @param planCode
     * @param productGroup
     * @param trainingMandatory
     * @param productNeeds
     * @returns any
     * @throws ApiError
     */
    public static async searchProdut(
        oid?: string,
        indicator?: string,
        productName?: string,
        productCode?: string,
        productLine?: string,
        providerName?: string,
        productTypeName?: string,
        productTypeCode?: string,
        productCategory?: string,
        productFamily?: string,
        productFamilyTag?: string,
        planName?: string,
        planCode?: string,
        productGroup?: string,
        trainingMandatory?: boolean,
        productNeeds?: string,
        isTrainingCompleted?: boolean
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/search`,
            query: {
                'oid': oid,
                'indicator': indicator,
                'productName': productName,
                'productCode': productCode,
                'productLine': productLine,
                'providerName': providerName,
                'productTypeName': productTypeName,
                'productTypeCode': productTypeCode,
                'productCategory': productCategory,
                'productFamily': productFamily,
                'productFamilyTag': productFamilyTag,
                'planName': planName,
                'planCode': planCode,
                'productGroup': productGroup,
                'trainingMandatory': trainingMandatory,
                'productNeeds': productNeeds,
                'isTrainingCompleted': isTrainingCompleted
            },
        });
        return result.body;
    }

    /**
     * Create new Product
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async createProduct(
        requestBody: ProductDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product-master/product`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Create new CIS Product
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async createCis(
        requestBody: CISDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/product-master/cis`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Update Product
     * @param oid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateProduct(
        oid: string,
        requestBody: ProductDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/product-master/product/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Update CIS Product
     * @param oid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateCis(
        oid: string,
        requestBody: CISDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/product-master/cis/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete CIS/Product record
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async deleteProductCis(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/product-master/${oid}`,
        });
        return result.body;
    }

    /**
     * Export csv for CIS/Product
     * @param template
     * @returns any
     * @throws ApiError
     */
    public static async exportCsv(
        template: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/export-csv`,
            query: {
                'template': template,
            },
        });
        return result.body;
    }

    /**
     * Check if given product can be deleted or not
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async checkDeletableProduct(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/check-deletable-product/${oid}`,
        });
        return result.body;
    }

    public static async getCisProviders(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/provider-names?type=CIS`,
        });
        return result.body;
    }

    public static async searchProduts(params: {
        oid?: string,
        indicator?: string,
        productName?: string,
        productCode?: string,
        productLine?: string,
        providerName?: string,
        productTypeName?: string,
        productTypeCode?: string,
        productCategory?: string,
        productFamily?: string,
        productFamilyTag?: string,
        planName?: string,
        planCode?: string,
        productGroup?: string,
        trainingMandatory?: boolean,
        productNeeds?: string,
        fundHouse?: string,
        fundType?: string,
        fundTypeOid?: string,
        fundRiskClassification?: string,
        fundRiskRating?: string,
        fundCurrency?: string,
        fundAssetClass?: string,
        fundGeographicalFocus?: string,
        fundSector?: string,
        fundIncomePayout?: string,
        fundCash?: boolean,
        fundCpfOa?: boolean,
        fundCpfSa?: boolean,
        fundCpfSrs?: boolean,
        fundIsin?: string,
        isTrainingCompleted?: boolean,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/product-master/search`,
            query: {
                ...params
            },
        });
        return result.body;
    }

}