/* eslint-disable @typescript-eslint/camelcase */
import { TypeBase } from '@master/model/TypeBase'
import ExistingPortfolioDeclaration from '@master/model/kyc-form/existing-plans/ExistingPortfolioDeclaration'
import ExistingPortfolioSummaryV2 from './ExistingPortfolioSummary'
import ExistingPortfolioV2 from '@master/model/kyc-form/existing-plans/ExistingPortfolioV2'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer, setupPointer } from '@master/annotation/Pointer'
import { IExistingPortfolioWrapper } from '@master/model/kyc-form/existing-plans/IExistingPortfolioWrapper'
import { IExistingPortfolio } from '../IExistingPortfolio'
export default class ExistingPortfolioDTO extends TypeBase implements IExistingPortfolioWrapper {
  declaration: ExistingPortfolioDeclaration
  summary: ExistingPortfolioSummaryV2
  portfolios: IExistingPortfolio[] = []

  @Ignore()
  @Pointer('ROOT', 'hasClient2')
  hasClient2?: boolean = false

  constructor (type: 'existing_insurance' = 'existing_insurance') {
    super(type)
    this.declaration = new ExistingPortfolioDeclaration()
    this.summary = new ExistingPortfolioSummaryV2(new ExistingPortfolioV2())
  }

  beforeExtractData (rawData: any) {
    Object.assign(this.declaration, this.declaration.extractData(rawData))

    // setupPointer is necessary to have the child's properties been reference to what we want
    // however, as we do not have access to KYCForm to be the ROOT, we will be using
    // ExistingPortfolioDTO as the root for the attributes.
    setupPointer(this.summary.getPointers(), this, this, this.summary)
    Object.assign(this.summary, this.summary.extractData(rawData))
    return rawData
  }

  afterExtractData (data: { portfolios: any[] }) {
    const portfolios = data.portfolios || []
    data.portfolios = portfolios.map(element => {
      const portfolio = new ExistingPortfolioV2()
      Object.assign(portfolio, portfolio.extractData(element))
      return portfolio
    })
    return data
  }

  convertToRawData () {
    const rawArray = this.portfolios.map(portfolio => portfolio.convertToRawData())
    const declaration = this.declaration.convertToRawData()
    const summary = this.summary.convertToRawData()
    const data = super.convertToRawData()
    data.existing_insurance_portfolios = rawArray
    return {
      existing_insurance_portfolios: rawArray,
      ...declaration,
      ...summary
    }
  }
}
