/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PicodedAccountDTO } from '../models/PicodedAccountDTO';
import type { UserMemberListRequestDTO } from '../models/UserMemberListRequestDTO';
import type { UserMemberListResponseDTO } from '../models/UserMemberListResponseDTO';
import { request as __request } from '@openapi/request';

export class UserMemberService {

    /**
     * @param requestBody
     * @returns UserMemberListResponseDTO
     * @throws ApiError
     */
    public static async listAllUserAccountBasedOnHierarchy(
        requestBody: UserMemberListRequestDTO,
    ): Promise<UserMemberListResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v3/user-member/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param accountOid
     * @returns string
     * @throws ApiError
     */
    public static async findMemberInStructure(
        accountOid: string,
    ): Promise<Array<string>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-member/find/${accountOid}`,
        });
        return result.body;
    }

    /**
     * @param accountOid
     * @returns string
     * @throws ApiError
     */
    public static async findMember(
        accountOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-member/${accountOid}`,
        });
        return result.body;
    }

    /**
     * @returns PicodedAccountDTO
     * @throws ApiError
     */
    public static async listOfMemberInStructure(): Promise<Array<PicodedAccountDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-member/list`,
        });
        return result.body;
    }

}