/* eslint-disable @typescript-eslint/camelcase */
import { TypeBase } from '@master/model/TypeBase'
import ExistingInvestDeclaration from '@master/model/kyc-form/existing-plans/ExistingInvestDeclaration'
import ExistingInvestSummaryV2 from './ExistingInvestSummary'
import ExistingInvestmentV2 from '@master/model/kyc-form/existing-plans/ExistingInvestmentV2'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer, setupPointer } from '@master/annotation/Pointer'
import { IExistingInvestmentWrapper } from '@master/model/kyc-form/existing-plans/IExistingInvestmentWrapper'

export default class ExistingInvestmentDTO extends TypeBase implements IExistingInvestmentWrapper {
  declaration: ExistingInvestDeclaration
  summary: ExistingInvestSummaryV2
  portfolios: ExistingInvestmentV2[] = []

  @Ignore()
  @Pointer('ROOT', 'hasClient2')
  hasClient2?: boolean = false

  constructor (type: 'existing_investment' = 'existing_investment') {
    super(type)
    this.declaration = new ExistingInvestDeclaration()
    this.summary = new ExistingInvestSummaryV2(new ExistingInvestmentV2())
  }

  beforeExtractData (rawData: any) {
    Object.assign(this.declaration, this.declaration.extractData(rawData))

    // setupPointer is necessary to have the child's properties been reference to what we want
    // however, as we do not have access to KYCForm to be the ROOT, we will be using
    // ExistingPortfolioDTO as the root for the attributes.
    setupPointer(this.summary.getPointers(), this, this, this.summary)
    Object.assign(this.summary, this.summary.extractData(rawData))
    return rawData
  }

  afterExtractData (data: { portfolios: any[] }) {
    const portfolios = data.portfolios || []
    data.portfolios = portfolios.map(element => {
      const portfolio = new ExistingInvestmentV2()
      Object.assign(portfolio, portfolio.extractData(element))
      return portfolio
    })
    return data
  }

  convertToRawData () {
    const rawArray = this.portfolios.map(portfolio => portfolio.convertToRawData())
    const declaration = this.declaration.convertToRawData()
    const summary = this.summary.convertToRawData()
    const data = super.convertToRawData()
    data.existing_investment_portfolios = rawArray
    return {
      existing_investment_portfolios: rawArray,
      ...declaration,
      ...summary
    }
  }
}
