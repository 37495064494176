// import { ClientBase } from '@master/model/ClientBase'
import CorePersonalDetails from '@master/model/kyc-form/PersonalDetails'
import highRiskCountryList from '@company-common/config/high-risk-country.json'
import { YearsSupportMap } from './CustomDependant'
import { isNaN, isUndefined } from 'lodash/fp'
export default class PersonalDetails extends CorePersonalDetails {
  familyMedical = ''
  familyMedicalSpecified = ''

  get customRetirementAge () {
    if (isUndefined(this.intendedRetirementAge)) {
      this.intendedRetirementAge = this.populatedRetirementAge
    }
    return this.intendedRetirementAge
  }

  set customRetirementAge (value) {
    this.intendedRetirementAge = value
  }

  get populatedRetirementAge () {
    if (isUndefined(this.age) || isNaN(this.age) || !this.gender) return
    const supportYear = this.expectedSupportYear
    let retirementAge = supportYear - this.age
    if (retirementAge < 0) {
      retirementAge = 0
    }
    if (isUndefined(this.intendedRetirementAge)) {
      this.intendedRetirementAge = retirementAge
    }
    return retirementAge
  }

  get expectedSupportYear () {
    if (isUndefined(this.age) || isNaN(this.age) || !this.gender) return
    return YearsSupportMap[this.gender]?.year
  }

  get retirementDuration () {
    const supportYear = this.expectedSupportYear
    if (!supportYear) return
    const duration = supportYear - this.age
    if (duration < 0) return 0
    return duration
  }

  // For JPara's AML
  get isSingaporean () {
    return this.citizenship === 'singaporean' ? 'yes' : 'no'
  }

  // For JPara's AML
  get isHighRiskPR () {
    return this.citizenship === 'pr' && highRiskCountryList.includes(this.citizenshipSpecified) ? 'yes' : 'no'
  }

  // For JPara's AML
  get isHighRiskForeign () {
    return this.citizenship !== 'pr' && this.isSingaporean === 'no' && highRiskCountryList.includes(this.citizenshipSpecified) ? 'yes' : 'no'
  }
}
