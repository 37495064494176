/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { CountryDTO } from '../models/CountryDTO';
import type { CountryFilter } from '../models/CountryFilter';
import type { CountryFilterV2 } from '../models/CountryFilterV2';
import { request as __request } from '@openapi/request';
import { CountryFilterDto } from '../models/CountryFilterDto';

export class CountryService {

    /**
     * @param requestBody
     * @returns Country
     * @throws ApiError
     */
    public static async createNewCountry(
        requestBody: CountryDTO,
    ): Promise<Country> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/country/create`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns Country
     * @throws ApiError
     */
    public static async updateCountry(
        requestBody: CountryDTO,
    ): Promise<Country> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/country/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @param requestBody
     * @returns Country
     * @throws ApiError
     */
    public static async deleteCountry(
        oid: string,
        requestBody: CountryDTO,
    ): Promise<Country> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/country/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @param requestBody
     * @returns Country
     * @throws ApiError
     */
    public static async retrieveCountry(
        oid: string,
        requestBody: CountryDTO,
    ): Promise<Country> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/country/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns Country
     * @throws ApiError
     */
    public static async updateOrCreateCountries(
        requestBody: CountryDTO,
    ): Promise<Array<Country>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/country/update-countries`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @param search
     * @returns Country
     * @throws ApiError
     */
    public static async listCountries(
        requestBody: CountryFilter,
        search?: string,
    ): Promise<Array<Country>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/country/list`,
            query: {
                'search': search,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns Country
     * @throws ApiError
     */
    public static async listCountriesV2(
        requestBody: CountryFilterV2,
    ): Promise<Array<Country>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/country/list`,
            body: requestBody,
        });
        return result.body;
    }


    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async importJson(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/country/import-json`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid
     * @returns Country
     * @throws ApiError
     */
    public static async checkDeletableCountry(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/country/check-deletable-country/${oid}`,
        });
        return result.body;
    }


}
