import { pv, sum, valueIsUndefined,infAdjReturn} from '@master/model/kyc-form/need-analysis/math'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { isUndefined } from 'lodash/fp'
import { Ignore } from '@master/annotation/Ignore'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import { Default } from '@master/annotation/Default'
import { Global, NA_ERROR_MSG } from '../utils/constants'
import { isEmpty } from 'class-validator'
// import validateIsComplete from '../utils/validateComplete'
// import { CriticalIllnessStructure } from './CriticalIllnessStructure'

export default class CriticalIllnessModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings

  selectedClient?:  KYCClient

  name = 'Critical Illness'
  needs = 'Critical'
  isProtection = true
  additionalNotes = ''
  CRITICAL_ILLNESS_YEARS_REQ = Global.CRITICAL_ILLNESS_YEARS_REQ
  ASSUMED_INFLATION_RATE = Global.ASSUMED_INFLATION_RATE

  // Critical Illness Needs Section
  incomeMonthly?: number = undefined
  cacheIncomeMonthly?: number = undefined

  yearsRequired: number = undefined//this.CRITICAL_ILLNESS_YEARS_REQ
  cachedYearsRequired?: number = undefined

  incomeInvRate?: number = undefined
  cachedIncomeInvRate?: number = undefined

  // initial value to undefined for SSP-4815
  incomeAssumedInfRate?: number = undefined//this.ASSUMED_INFLATION_RATE
  cachedIncomeAssumedInfRate?: number = this.incomeAssumedInfRate

  infAdjReturn?: number = undefined
  lumpSumRequired?:number = undefined
  criticalOtherDesc = ''
  criticalOtherAmmount?:number = undefined

  // Existing Resources Allocated (Current Values) Section
  exResourcesLIC?:number = undefined
  exResourcesCA?:number = undefined
  cachedExResourcesCA?:number = undefined
  cachedxResourcesLIC?:number = undefined

  exResourcesOthersDesc? = ''
  exResourcesOthersAmt?:number = undefined

  // Justification model
  incomeMonthlyReason?: string = undefined
  yearsRequiredReason?: string = undefined
  incomeInvRateReason?: string = undefined
  incomeAssumedInfRateReason?: string = undefined
  incomeInfAdjRateReason?:string = undefined
  exResourcesLICReason?:string = undefined
  exResourcesCAReason?:string = undefined

  cachedIncomeInfAdjRate?: number = undefined

  // confirm review changes
  incomeMonthlyCR?:boolean = undefined
  yearsRequiredCR?:boolean = undefined
  incomeInvRateCR?:boolean = undefined
  incomeAssumedInfRateCR?:boolean = undefined
  incomeInfAdjRateCR?:boolean = undefined
  exResourcesLICCR?:boolean = undefined
  exResourcesCACR?:boolean = undefined

  cachedExResourcesCAAuto?: number = undefined

  constructor (type: 'client_0' | 'client_1' = 'client_0', projectSettings: IProjectSettings, id = 'p1') {
    super(`${type}_ci_${id}`)
    this.projectSettings = projectSettings
  }

  set setSelectedClient (client) {
    this.selectedClient = client
  }

  setupDefaultRate (rate: number) {
    super.setupDefaultRate(rate)
    if (isUndefined(this.infAdjReturn)) this.infAdjReturn = this.defaultRate
  }

  get incomeAnnual () {
    if (valueIsUndefined(this.incomeMonthly)) return undefined
    return (12 * this.incomeMonthly) || undefined
  }

  get incomeInfAdjRate(){
    if (valueIsUndefined(this.incomeInvRate) && valueIsUndefined(this.incomeAssumedInfRate)) return undefined
    const rate = infAdjReturn(this.incomeInvRate ,this.incomeAssumedInfRate) || 0
    return parseFloat(rate.toFixed(2))
  }

  get fundsRequired () {
    if (valueIsUndefined(this.incomeAnnual) && valueIsUndefined(this.yearsRequired)) return undefined
    const total = pv(this.incomeAnnual, this.yearsRequired, this.incomeInfAdjRate)
    return total ? parseFloat(total.toFixed(2)) : undefined
  }

  get criticalIllnessTotal() {
    const total = sum(this.fundsRequired, this.lumpSumRequired, this.criticalOtherAmmount) || 0
    return parseFloat(total.toFixed(2))
  }

  get exResourcesTotal() {
    const total = sum(this.exResourcesLIC, this.exResourcesCA, this.exResourcesOthersAmt) || 0
    return parseFloat(total.toFixed(2))
  }

  get grandTotal () {
    const total = this.exResourcesTotal - this.criticalIllnessTotal
    return parseFloat(total.toFixed(2))
  }

  get isOtherFieldsValidated() {

    const isField1Filled = this.criticalOtherDesc !== "" && this.criticalOtherDesc !== undefined ;
    const isField2Filled = this.criticalOtherAmmount !== undefined && this.criticalOtherAmmount !== null;

    return (isField1Filled && isField2Filled) || (!isField1Filled && !isField2Filled);
  }

  get isResourcesOtherFieldsValidated() {

    const isField1Filled = this.exResourcesOthersDesc !== "" && this.exResourcesOthersDesc !== undefined ;
    const isField2Filled = this.exResourcesOthersAmt !== undefined && this.exResourcesOthersAmt !== null;

    return (isField1Filled && isField2Filled) || (!isField1Filled && !isField2Filled);
  }

  get isCompleted () {
    // const all_completed = validateIsComplete.call(this, CriticalIllnessStructure())
    // console.log("$$==============================\nall completed: ", all_completed);

    return this.incomeMonthly != null && this.yearsRequired != null && this.incomeInvRate != null && this.incomeAssumedInfRate != null 
                  && this.isOtherFieldsValidated && this.isResourcesOtherFieldsValidated
  }

  // Justification Sections
  get incomeMonthlyReasonStatus () {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByCashFlow = this.selectedClient?.cashFlow

    if (selectedClientByCashFlow?.isUserDeclinedCashFlow === true)  {
      this.incomeMonthlyReason = undefined
      return false
    }
    
    const higherSimplifiedTotalMonthlyAmount = Math.max(selectedClientByCashFlow?.simplifiedTotalInflow, selectedClientByCashFlow?.simplifiedTotalOutflow) || null
    const higherComprehensiveTotalMonthlyAmount = Math.max(selectedClientByCashFlow?.totalMonthlyOutflow, selectedClientByCashFlow?.netEmploymentIncome) || null
    const highestIncome = selectedClientByCashFlow?.isSimplifiedMode ? higherSimplifiedTotalMonthlyAmount : higherComprehensiveTotalMonthlyAmount
    
    if (highestIncome < this.incomeMonthly){
      isNeedReason = true
      if (this.cacheIncomeMonthly !== this.incomeMonthly) {
        this.cacheIncomeMonthly = this.incomeMonthly
        this.incomeMonthlyCR = false
      }
    } else {
      this.incomeMonthlyReason = undefined
      this.cacheIncomeMonthly = this.incomeMonthly
      this.incomeMonthlyCR = false
    }

    return isNeedReason
  }

  get yearsRequiredReasonStatus () {
    if (!this.selectedClient) return
    let isNeedReason = false

    if (this.yearsRequired !== this.CRITICAL_ILLNESS_YEARS_REQ){
      isNeedReason = true
      if (this.cachedYearsRequired !== this.yearsRequired) {
        this.cachedYearsRequired = this.yearsRequired
        this.yearsRequiredCR = false
      }
    } else {
      this.yearsRequiredReason = undefined
      this.cachedYearsRequired = this.yearsRequired
      this.yearsRequiredCR = false
    }

    return isNeedReason
  }

  get incomeInvRateReasonStatus () {
    if (!this.selectedClient) return
    let isNeedReason = false

    if (this.incomeInvRate < 0 || this.incomeInvRate > Global.MAX_INTEREST_RATE) {
      isNeedReason = true
      if (this.cachedIncomeInvRate !== this.incomeInvRate) {
        this.cachedIncomeInvRate = this.incomeInvRate
        this.incomeInvRateCR = false
      }
    } else {
      this.incomeInvRateReason = undefined
      this.cachedIncomeInvRate = this.incomeInvRate
      this.incomeInvRateCR = false
    }

    return isNeedReason
  }

  get incomeAssumedInfRateReasonStatus () {
    if (!this.selectedClient) return
    let isNeedReason = false

    if (this.incomeAssumedInfRate < 0 || this.incomeAssumedInfRate > Global.MAX_INTEREST_RATE) {
      isNeedReason = true
      if (this.cachedIncomeAssumedInfRate !== this.incomeAssumedInfRate) {
        this.cachedIncomeAssumedInfRate = this.incomeAssumedInfRate
        this.incomeAssumedInfRateCR = false
      }
    } else {
      this.incomeAssumedInfRateReason = undefined
      this.cachedIncomeAssumedInfRate = this.incomeAssumedInfRate
      this.incomeAssumedInfRateCR = false
    }

    return isNeedReason
  }

  get incomeInfAdjRateReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false

    if (this.incomeInfAdjRate < -2 || this.incomeInfAdjRate > 9) {
      isNeedReason = true
      if (this.cachedIncomeInfAdjRate !== this.incomeInfAdjRate) {
        this.cachedIncomeInfAdjRate = this.incomeInfAdjRate
        this.incomeInfAdjRateCR = false
      }
    } else {
      this.incomeInfAdjRateReason = undefined
      this.cachedIncomeInfAdjRate = this.incomeInfAdjRate
      this.incomeInfAdjRateCR = false
    }

    return isNeedReason
  }

  get exResourcesLICReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByExInsurance = this.selectedClient?.existingInsurence;
      let calcTotal = 0;
      // Sum sumAssuredCI in client's insuranceRecords
      if (selectedClientByExInsurance?.insuranceRecords) {
        calcTotal += selectedClientByExInsurance.insuranceRecords.reduce((sum, record) => sum + (record.sumAssuredCI || 0), 0);
      }
    
    if (this.exResourcesLIC < calcTotal ){
      isNeedReason = true
      if (this.cachedxResourcesLIC !== this.exResourcesLIC) {
        this.cachedxResourcesLIC = this.exResourcesLIC
        this.exResourcesLICCR = false
      }
    } else {
      this.exResourcesLICReason = undefined
      this.exResourcesLICCR = false
      this.cachedxResourcesLIC = this.exResourcesLIC
    }

    return isNeedReason
  }

  get exResourcesLICError () {
    if (!this.selectedClient) return
    const selectedClientByExInsurance = this.selectedClient?.existingInsurence;
      let calcTotal = 0;
      // Sum sumAssuredCI in client's insuranceRecords
      if (selectedClientByExInsurance?.insuranceRecords && selectedClientByExInsurance?.discloseExistingInsurance !== true) {
        calcTotal += selectedClientByExInsurance.insuranceRecords.reduce((sum, record) => sum + (record.sumAssuredCI || 0), 0);
      }
    
    if (this.exResourcesLIC > calcTotal ){
      return NA_ERROR_MSG.TB
    } else {
      return null
    }
  }

  get exResourcesCAReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth
      
    const calcTotal = selectedClientByNetWorth?.isSimplifiedMode ? selectedClientByNetWorth?.simplifiedTotalCash : sum(selectedClientByNetWorth?.savings,
      selectedClientByNetWorth?.fixedDeposits,
      selectedClientByNetWorth?.cashNearCashOthersAmount
    ) || 0

    // if (
    //   this.exResourcesCA === undefined ||
    //   this.cachedExResourcesCAAuto !== calcTotal
    // ) {
    //   this.exResourcesCA = calcTotal || undefined
    //   this.cachedExResourcesCAAuto = calcTotal
    // }
    
    if (this.exResourcesCA !== calcTotal && (!isEmpty(this.exResourcesCA) && !isEmpty(calcTotal))){
      isNeedReason = true
      if (this.cachedExResourcesCA !== this.exResourcesCA) {
        this.cachedExResourcesCA = this.exResourcesCA
        this.exResourcesCACR = false
      }
    } else {
      this.exResourcesCAReason = undefined
      this.cachedExResourcesCA = this.exResourcesCA
      this.exResourcesCACR = false
    }

    return isNeedReason
  }

  resetAllFields (): void {
    super.resetAllFields()
  }
}