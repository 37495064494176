import { MaxChar } from '@master/annotation/MaxChar'
import { TypeBase } from '../TypeBase'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

export default class TrustedIndividual extends TypeBase {
  accompanied = ''
  confirmLanguage = ''

  @MaxChar(InputMaxChar.XSMALL)
  confirmLanguageOthers = ''

  @MaxChar(InputMaxChar.XSMALL)
  contact = ''

  contactForSurvey = ''
  correspondenceAddress = ''
  dob?: number = undefined

  @MaxChar(InputMaxChar.XSMALL)
  name = ''

  @MaxChar(InputMaxChar.XSMALL)
  nric = ''

  toContact?: 'yes' | 'no' = 'yes'
  preferredDate?: number = undefined
  preferredTime = ''

  @MaxChar(InputMaxChar.XSMALL)
  relationship = ''

  residentialAddress = ''

  constructor (type: 'trusted_individual' = 'trusted_individual') {
    super(type)
  }
}
