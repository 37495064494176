/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdHocReportDto } from '../models/AdHocReportDto';
import type { CalculatedRecordResponseDTO } from '../models/CalculatedRecordResponseDTO';
import { request as __request } from '@openapi/request';

export class CommissionReportsService {

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async generateAdHocStatement(
requestBody: AdHocReportDto,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/commission_reports/generateAdHocStatement`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param calculationStatus 
     * @param searchField 
     * @param searchFieldValue 
     * @param limit 
     * @param page 
     * @param sortBy 
     * @param orderBy 
     * @returns CalculatedRecordResponseDTO 
     * @throws ApiError
     */
    public static async calculatedCommissions(
calculationStatus: string,
searchField?: string,
searchFieldValue?: string,
limit?: number,
page?: number,
sortBy?: string,
orderBy?: string,
): Promise<CalculatedRecordResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/commission_reports`,
            query: {
                'calculationStatus': calculationStatus,
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'limit': limit,
                'page': page,
                'sortBy': sortBy,
                'orderBy': orderBy,
            },
        });
        return result.body;
    }

}