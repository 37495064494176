import { ContractTypeList, ContractTypeOptions } from '@company-common/options/ContractTypeOptions'
import { IAdviserService } from '@master/services/IAdviserService'
import { options } from '@master/model/options/Options'

export class CustomAdviserService implements IAdviserService {
  adviserContractTypeOptions (): options[] {
    return ContractTypeOptions
  }

  adviserContractTypeList () {
    return ContractTypeList
  }
}
