import { MaxChar } from '@master/annotation/MaxChar'
import { VenueOptions } from '../options/VenueOptions'
import { TypeBase } from '../TypeBase'

export default class AdviserDeclaration extends TypeBase {
  clientInformation?: boolean = false
  clientMeetingDate?: number = undefined
  clientMeetingTime = ''
  clientMeetingVenue: typeof VenueOptions[number]['value'] = undefined
  clientMeetingVenueSpecify = ''
  statements?: boolean = false

  relationshipAdviser = ''
  relationshipAdviserSpecify = ''

  @MaxChar(78)
  jfwConsultantName = ''

  @MaxChar(550)
  jfwConsultantComment = ''

  recommendation = '' // previously was declaration_recommendation_by_fc
  acknowledgement = '' // previously was declaration_acknowledgement_by_fc
  acknowledgementNotes = '' // previously was declaration_acknowledgement_fc_notes
  haveJointFieldWork?: boolean = undefined
  haveJointSales?: boolean = undefined

  @MaxChar(78)
  financialConsultantName = ''

  @MaxChar(78)
  financialConsultantMasNo = ''

  @MaxChar(78)
  financialConsultantCompanyCode = ''

  @MaxChar(550)
  financialConsultantComment = ''

  @MaxChar(78)
  jointSalesFinancialConsultantName = ''

  @MaxChar(78)
  jointSalesFinancialConsultantMasNo = ''

  @MaxChar(78)
  jointSalesFinancialConsultantCompanyCode = ''

  @MaxChar(550)
  jointSalesFinancialConsultantComment = ''

  provideIncentives = '' //  use in financial consultant declaration part c

  constructor (type: 'fc_decl' = 'fc_decl') {
    super(type)
  }
}
