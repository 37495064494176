export const MaritalStatusOptions = [
  { label: '', value: '' },
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
  { label: 'Not available', value: 'not-available' }
]
export const  MaritalLabel = MaritalStatusOptions.map(item => item.label)
export const  MaritalValues = MaritalStatusOptions.map(item => item.value)
export const getMaritalStatusByLabel= (arg: string) => MaritalStatusOptions.find(item => item?.label?.toLowerCase() === arg.toLowerCase())