import { PDFDocument } from "pdf-lib";

export function getFileExtension(filePath) {
    const lastDotIndex = filePath.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return '';
    }

    return filePath.substring(lastDotIndex + 1);
}

export function debugBase64(base64URL){
    const win = window.open();
    win?.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}

export function debugBase64Pdf(base64URL) {
    const win = window.open();
    const pdfDataURL = 'data:application/pdf;base64,' + base64URL;
    win?.document.write(`
        <html>
        <head>
            <title>PDF Viewer</title>
        </head>
        <body>
            <iframe src="${pdfDataURL}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>
        </body>
        </html>
    `);
    
    win?.document?.close();
}

export function getSubmitionMode(arr = []) {
    if (arr.includes("manually") && arr.includes("ezsub")) {
      return "Mixture";
    } else if (arr.includes("manually")) {
      return "Manual";
    } else if (arr.includes("ezsub")) {
      return "eSubmission";
    }
    return ""; 
  }

function mobileNumberRectify(mobileNumber:string){
    let cleanNumber = mobileNumber.replace(/[^0-9+]/g, "")
    if (cleanNumber[0] !== "+") {
        cleanNumber = `+${cleanNumber}`
    }
    cleanNumber = cleanNumber.replace(/\++/g, "+")
    return cleanNumber
}
export function formatMobileNumber(countryCode: string, mobile: string): string {
    if (!countryCode || !mobile) return '';
    const regex = /\d+/
    const f = countryCode.match(regex);
    const formattedMobile = mobile.trim();
    if (f) {
      return `+${f[0]}${formattedMobile}`;
    }
    let mob = countryCode + formattedMobile
    mob = mobileNumberRectify(mob)
    return mob
}



export function PDFbase64ToBlob(base64String:string):Blob {
    const byteCharacters = atob(base64String);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: 'application/pdf' });

    return blob
}

export function PDFbase64ToFile(base64String:string, filename:string="document.pdf"):File {
    if (base64String?.length === 0) return
    const blob = PDFbase64ToBlob(base64String)
    const file = new File([blob], filename, { type: blob?.type || 'application/pdf' } )
    return file
}


export async function getPDFPageNumber (pdf:File) {
    let total = 0
    if (!pdf) return total;

    
    try {
        const existingPdfBytes = await pdf.arrayBuffer()
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pages = pdfDoc.getPages()
        total = pages?.length || 0
    } catch (error) {
        console.log("Error: ", error);
    }
    return total
}

export function scrollToKycFormTop(subject) {
  if(subject && subject[0] && subject[0] === 'kycForm') {
    const container = document.getElementById('efna-container');
    if(container) {
      const scrollContainer = container.querySelector('div');
      if (scrollContainer) {
        scrollContainer.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
      }
    }
  }
}
