import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import '@/DIContainer'
import { AllForms } from '@core/model/recruit/AllForms'
import { DataForm, FormsList } from '@core/model/recruit/FormsList'
import { isNull, isUndefined } from 'lodash/fp'
import { FormBase, FormError } from '@core/model/recruit/FormBase'
import RecruitDetails from '@core/model/recruit/RecruitDetail'
import { ExamForm } from '@core/model/recruit/ExamForm'

export type ComputedFormDetails = {
  exist: boolean;
  data: FormBase;
}
export type FormDetails = DataForm & ComputedFormDetails

@Module({
  namespaced: true
})
export default class RecruitFormStore extends VuexModule {
  forms: AllForms = new AllForms()
  _forms: AllForms = new AllForms()
  recruitDetails: RecruitDetails = new RecruitDetails()

  exams: ExamForm[] = []

  @Mutation
  addExistingExams (exams: ExamForm[]) {
    this.exams = exams
  }

  @Mutation
  setRecruitDetails (recruitDetails: RecruitDetails) {
    Object.assign(this.recruitDetails, recruitDetails)
  }

  @Mutation
  setCurUser (curUser: { _oid: string ; systemrole: string }) {
    this.recruitDetails.curUser = curUser
  }

  @Mutation
  setForms (forms: any[]) {
    this.forms.extractAndAssign(forms)
    this._forms.extractAndAssign(forms)
  }

  get isDirty () {
    // console.log('is Dirty check', this._forms, this.forms)
    return Object.entries(this.forms).some(([fk, fv]) => {
      if (fv instanceof FormBase && this._forms[fk] instanceof FormBase) {
        return fv.isDirty(this._forms[fk])
      } else return fv !== this._forms[fk]
    })
  }

  get formsDetails (): FormDetails[] {
    const currentForms = this.forms
    return FormsList.map((formConfig) => {
      const formConf = { ...formConfig }
      const formModel = currentForms.getForm(formConf.model)
      return {
        ...formConf,
        exist: !isUndefined(formModel) && ('oid' in formModel) && formModel.oid !== '',
        data: formModel,
        stamped: Object.values(formModel?.signatures).some((sign) => !isUndefined(sign) && !isNull(sign))
      }
    })
  }

  get createdForms (): FormDetails[] {
    return this.formsDetails.filter((form) => {
      return form.exist
    })
  }

  get allSigned (): boolean {
    return this.createdForms.every(form => form.data.signed)
  }

  get typesOptions () {
    return Object.values(this.formsDetails).map(form => {
      return {
        label: form.displayName,
        value: form.data.type
      }
    })
  }
}
