/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CpdAttendanceListResponseDTO } from '../models/CpdAttendanceListResponseDTO';
import type { CpdAttendanceRecordDto } from '../models/CpdAttendanceRecordDto';
import { request as __request } from '@openapi/request';

export class CpdAttendanceRecordService {

    /**
     * @param courseOid
     * @param classOids
     * @param attendanceOids
     * @param userOids
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns CpdAttendanceListResponseDTO Retrieve the list of CPD attendance records
     * @throws ApiError
     */
    public static async retrieveCpdAttendanceList(
        courseOid?: Array<string>,
        classOids?: Array<string>,
        attendanceOids?: Array<string>,
        userOids?: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<CpdAttendanceListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd-attendance/list`,
            query: {
                'courseOid': courseOid,
                'classOids': classOids,
                'attendanceOids': attendanceOids,
                'userOids': userOids,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param attendanceOid
     * @returns CpdAttendanceRecordDto Retrieve a specific CPD attendance record
     * @throws ApiError
     */
    public static async retrieveCpdAttendanceRecord(
        attendanceOid: string,
    ): Promise<CpdAttendanceRecordDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd-attendance/${attendanceOid}`,
        });
        return result.body;
    }

    /**
     * @param attendanceOid
     * @returns any Deletes a CPD classes record (soft delete)
     * @throws ApiError
     */
    public static async deleteCpdMasterRecord(
        attendanceOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/cpd-attendance/${attendanceOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CpdAttendanceRecordDto Creates CPD attendance record(s)
     * @throws ApiError
     */
    public static async createCpdAttendanceRecords(
        requestBody: Array<CpdAttendanceRecordDto>,
    ): Promise<Array<CpdAttendanceRecordDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-attendance`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CpdAttendanceRecordDto Updates a CPD attendance record
     * @throws ApiError
     */
    public static async updateCpdAttendanceRecord(
        requestBody: CpdAttendanceRecordDto,
    ): Promise<CpdAttendanceRecordDto> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/cpd-attendance`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any Validate CPD attendance import
     * @throws ApiError
     */
    public static async validateCpdAttendanceCsv(
        requestBody: Array<CpdAttendanceRecordDto>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-attendance/import/validate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CpdAttendanceRecordDto Import CPD attendance with csv
     * @throws ApiError
     */
    public static async importCpdAttendanceCsv(
        requestBody: Array<CpdAttendanceRecordDto>,
    ): Promise<Array<CpdAttendanceRecordDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-attendance/import/csv`,
            body: requestBody,
        });
        return result.body;
    }

}