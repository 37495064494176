import { MaxChar } from '@master/annotation/MaxChar'
import { TypeBase } from '@master/model/TypeBase'

export default class ExistingPortfolioDeclaration extends TypeBase {
  constructor (type: 'existing_portfolio' = 'existing_portfolio') {
    super(type)
  }

  review = ''

  @MaxChar(140)
  reviewReasons = ''
}
