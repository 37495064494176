import { options } from '@master/model/options/Options'

export const PaymentModeList = [
  'Cash & CPF Medisave',
  'Cash',
  'CPF Medisave',
  'CPFOA',
  'CPFSA',
  'SRS',
  'Paynow (UEN/NRIC/Mobile)',
  'Telegraphic Transfer',
  'Giro',
  'Credit Card',
  'Internet Transfer',
  'Cheque',
  'Others'
] as const
export const PaymentModeOptions: options[] = PaymentModeList.map(paymentMode => {
  return {
    label: paymentMode,
    value: paymentMode
  }
})
