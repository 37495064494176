import { options } from '@master/model/options/Options'
import { IUIOptionsService } from './IUIOptionsService'

export class UIOptionsService implements IUIOptionsService {
  myinfoAttributeOptions (): options[] {
    return [
      { label: 'Personal & Financial Information', value: 1 },
      { label: 'Personal Information Only', value: 2 }
    ]
  }
}
