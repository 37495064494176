import { isUndefined } from 'lodash/fp'

function valueIsUndefined (value: number | string) {
  return value !== 0 && !value
}
// function infAdjReturn (inflationRate: number, investmentReturn: number) {
//   return ((1 + investmentReturn) / (1 + inflationRate)) - 1
// }

function infAdjReturn(investmentReturn: number = 0, inflationRate: number) {
  const investmentReturnDecimal = investmentReturn / 100;
  const inflationRateDecimal = inflationRate / 100;
  // Calculate the inflation-adjusted return
  return (((1 + investmentReturnDecimal) / (1 + inflationRateDecimal)) - 1) * 100;
}

function pv (amount: number | undefined, period: number | undefined, rate: number | undefined, isEndMode = false) {
  const _amount = amount || 0
  const _period = period || 0
  const _rate = (rate || 0) / 100
  if (_rate === 0) {
    return _amount * _period
  } else {
    const dueFactor = (1 - (1 / (1 + _rate) ** _period)) * (isEndMode ? 1 : (1 + _rate))
    return _amount / _rate * dueFactor
  }
}

function fv (amount: number, period: number, rate: number) {
  if (isUndefined(period)) {
    period = 0
  }
  const result = amount * ((1 + rate) ** period)
  return isNaN(result) ? 0 : result
}

function savingLumpSum (amount: number, period: number, rate: number) {
  return amount / (1 + rate) ** period
}

function savingMonthly (amount: number, period: number, rate: number) {
  if (rate === 0) {
    return amount / period / 12
  }

  const monthlyRate = rate / 12
  const overallRate = (1 + monthlyRate) ** (12 * period + 1)
  const snRate = monthlyRate / (overallRate - (1 + monthlyRate))
  return amount * snRate
}

function round (value: number, digitNumber: number) {
  const decimal = 10 ** digitNumber
  const result = Math.round(value * decimal) / decimal
  return isNaN(result) ? 0 : result
}

function sum (...array: Array <string | number>): number {
  const allFieldIsEmpty = array.every(value => valueIsUndefined(value))
  if (allFieldIsEmpty) return null

  let total = null
  array.forEach(value => {
    if (typeof value === 'string') {
      total += parseFloat(value) || 0
    } else if (typeof value === 'number' && value) {
      total += value
    } else {
      total += 0
    }
  })

  return total
}

function difference(...array: Array<string | number>): number {
  const validNumbers = array
    .map(value => (typeof value === 'string' ? parseFloat(value) : value))
    .filter(value => !valueIsUndefined(value));

  if (validNumbers.length === 0) return null;
  let total = validNumbers[0];
  for (let i = 1; i < validNumbers.length; i++) {
    total -= validNumbers[i];
  }
  return parseFloat(total.toFixed(2));
}

function calculateROI(fieldValue, ROI, n) {
  let totalValue = fieldValue;

  for (let i = 0; i < n; i++) {
    totalValue += (ROI / 100) * totalValue;
  }

  return parseFloat(totalValue.toFixed(2));
}

function calculateFiftyPercent(amount) {
  return parseFloat((amount * 0.50).toFixed(2));
}

function formatLargeNumber(number) {
  // Find the order of magnitude (exponent)
  const magnitude = Math.floor(Math.log10(number));
  // Scale down the number to the range [1, 10)
  const scaledNumber = number / Math.pow(10, magnitude);
  // Format the scaled number to two decimal places
  const formattedNumber = scaledNumber.toFixed(2);
  // Return the formatted number
  return formattedNumber;
}

export { infAdjReturn, pv, fv, savingLumpSum, savingMonthly, round, sum, valueIsUndefined, calculateROI, difference, calculateFiftyPercent, formatLargeNumber }
