/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '@openapi/request';

export class AdviserCpdService {

    /**
     * @param userOid
     * @returns any
     * @throws ApiError
     */
    public static async retrieveAllAdviserAttendance(
        userOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-cpd/attendance/${userOid}`,
        });
        return result.body;
    }

    /**
     * @param userOid
     * @returns any
     * @throws ApiError
     */
    public static async retrieveAllAdviserClasses(
        userOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-cpd/classes/${userOid}`,
        });
        return result.body;
    }

    /**
     * @param userOid
     * @returns any Retrieve one adviser courses participated, products and hours
     * @throws ApiError
     */
    public static async adviserGetCourseAndClassParticipated(
        userOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-cpd/courses/${userOid}`,
        });
        return result.body;
    }

    /**
     * @param userOid
     * @param isAdviserCpdRecord Require if Adiviser records are fetching
     * @returns any Retrieve one adviser cumulated hours and branch
     * @throws ApiError
     */
    public static async adviserGetCumulatedHoursandBranch(
        userOid: string,
        isAdviserCpdRecord?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-cpd/hours/${userOid}`,
            query: {
                'isAdviserCpdRecord': isAdviserCpdRecord,
            },
        });
        return result.body;
    }

    /**
     * @param courseOid
     * @param classOids
     * @param attendanceOids
     * @param userOids
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns any Retrieve one adviser branch, adviser tier and course attended
     * @throws ApiError
     */
    public static async adviserGetCoursesAttended(
        courseOid?: Array<string>,
        classOids?: Array<string>,
        attendanceOids?: Array<string>,
        userOids?: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-cpd/courses`,
            query: {
                'courseOid': courseOid,
                'classOids': classOids,
                'attendanceOids': attendanceOids,
                'userOids': userOids,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

}