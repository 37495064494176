import { options } from './Options'

export const TypeOfServiceOptions: options[] = [
  { value: '', label: '' },
  { value: 'wills', label: 'Wills' },
  { value: 'financialplanning', label: 'Financial Planning' },
  { value: 'mortgageloan', label: 'Mortgage Loan' },
  { value: 'businessfinancing', label: 'Business Financing' },
  { value: 'businessconsulting', label: 'Business Consulting' },
  { value: 'trustplanning', label: 'Trust Planning' },
  { value: 'estateplanning', label: 'Estate Planning' },
  { value: 'trailerfee', label: 'Trailer Fee' },
  { value: 'others', label: 'Others' }
]
