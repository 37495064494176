import { needToConvertJson } from '@master/annotation/Json'
import { getRawKey } from '@master/annotation/RawKey'
import { getDefault } from '@master/annotation/Default'
import { get, isEmpty, isUndefined, snakeCase } from 'lodash/fp'
import { isIgnore } from '@master/annotation/Ignore'
import { IPointer, isPointer } from '@master/annotation/Pointer'
import { convertJsonToMetaClass, needToConvertToMetaClass } from '@master/annotation/MetaClass'

export class Base {
  snakeCaseKey (key: string) {
    return snakeCase(key)
  }

  getPointers (): IPointer[] {
    const pointers: IPointer[] = []
    Object.keys(this).forEach((attr) => {
      const grabProfile = isPointer(this, attr)
      if (grabProfile) {
        pointers.push({
          pointerKey: attr,
          ...grabProfile
        })
      }
    })
    return pointers
  }

  // life cycle hook
  beforeExtractData (rawData: any): any {
    return rawData
  }

  // call after success extract data
  // can used to set default if value is undefined
  afterExtractData (data: {}) {
    // base class do nothing, just return
    return data
  }

  // to get data from raw kycForm to fill in current class
  extractData(rawData: any, index?: number)
  extractData (rawData: any) {
    const editedRawData = this.beforeExtractData(rawData)
    const data = Object.keys(this)
      // skip when base object
      .filter(key => !(get(key, this) instanceof Base))
      .filter(key => !isIgnore(this, key) && !isPointer(this, key))
      .reduce((result, key) => {
        const snakeCaseKey = getRawKey(this, key) || this.snakeCaseKey(key)
        let value = get(snakeCaseKey, editedRawData)
        if (needToConvertToMetaClass(this, key) && !isEmpty(value)) {
          value = convertJsonToMetaClass(this, key, JSON.parse(value))
        }
        if (needToConvertJson(this, key) && !isEmpty(value)) {
          value = JSON.parse(value)
        }
        if (isUndefined(value)) {
          value = getDefault(this, key)
        }
        result = { ...result, [key]: value }
        return result
      }, {})
    return this.afterExtractData(data)
  }

  // to get back the raw data for saving to backend
  convertToRawData(index?: number)
  convertToRawData () {
    let parent = Object.getPrototypeOf(this)
    let getterKeys = []

    // loop all parent to get all the getter keys
    while (parent instanceof Base) {
      const keys = Object.entries(Object.getOwnPropertyDescriptors(parent))
        .filter(([, descriptor]) => typeof descriptor.get === 'function')
        .map(([key]) => key)

      getterKeys = getterKeys.concat(keys)
      parent = Object.getPrototypeOf(parent)
    }

    // remove duplicate key
    getterKeys = [...new Set(getterKeys)]

    const propertyKeys = Object.keys(this)
      // skip when base object
      .filter(key => !(get(key, this) instanceof Base))

    return [...getterKeys, ...propertyKeys]
      .filter(key => !isIgnore(this, key) && !isPointer(this, key))
      .reduce((result, key) => {
        const snakeCaseKey = getRawKey(this, key) || this.snakeCaseKey(key)
        let value = get(key, this)
        // convert back to stringify json
        if (needToConvertToMetaClass(this, key) && value !== undefined) {
          value = JSON.stringify(value.convertToRawData(null))
        }
        if (needToConvertJson(this, key)) {
          value = JSON.stringify(value)
        }
        result = { ...result, [snakeCaseKey]: value }
        return result
      }, {})
  }
}
