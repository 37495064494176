/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportStatementListDto } from '../models/ReportStatementListDto';
import type { ReportStatementListResponseDTO } from '../models/ReportStatementListResponseDTO';
import { request as __request } from '@openapi/request';

export class GeneratedReportStatementsService {

    /**
     * @param requestBody 
     * @returns ReportStatementListResponseDTO Gets the list of report statement
     * @throws ApiError
     */
    public static async retrieveBranchList(
requestBody: ReportStatementListDto,
): Promise<Array<ReportStatementListResponseDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/report-statement/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param reportStatementOid 
     * @returns any 
     * @throws ApiError
     */
    public static async downloadReportFile(
reportStatementOid: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/report-statement/download-file/${reportStatementOid}`,
        });
        return result.body;
    }

}