import 'reflect-metadata'

const expandableMetadataKey = Symbol('ExpandableArray')

export type ExpandableArrayConfig = {
  maxSize: number;
  clone: () => {};
}
/**
 * Add Expandable Properties for target array
 * @param maxSize the size of the array allowed (number of items)
 * @param clone function to construct item to be added when expansion is required
 */
export function ExpandableArray ({ maxSize, clone }: ExpandableArrayConfig) {
  return Reflect.metadata(expandableMetadataKey, { maxSize, clone })
}

/**
 * Get MaxArraySize value, if no MaxArraySize value set on the property key return undefined
 * @param target Target object
 * @param propertyKey property key of target object
 */
export function getMaxSize (target: any, propertyKey: string) {
  return Reflect.getMetadata(expandableMetadataKey, target, propertyKey)?.maxSize
}

/**
 * Get cloning function for the class
 * @param target Target object
 * @param propertyKey property key of target object
 */
export function getClone (target: any, propertyKey: string) {
  return Reflect.getMetadata(expandableMetadataKey, target, propertyKey)?.clone
}
