import { ProductMetaData } from '../ProductMetaData'

export const CoverageTypeOptions = [
  { value: '', label: '' },
  { value: 'basic', label: 'Basic' },
  { value: 'plus', label: 'Plus' },
  { value: 'member', label: 'Member' },
  { value: 'memberfamily', label: 'Member & Family' },
  { value: 'silver', label: 'Silver' },
  { value: 'gold', label: 'Gold' },
  { value: 'platinum', label: 'Platinum' },
  { value: 'diamond', label: 'Diamond' },
  { value: 'self', label: 'Self' },
  { value: 'couple', label: 'Couple' },
  { value: 'n.a.', label: 'N.A.' }
]
export class GiOthers extends ProductMetaData {
  insuranceClass = undefined
  coverageType = undefined
  listOfInsured = undefined

  grossPremium = undefined
  gst = undefined
  commissionRate = undefined
  commissionPayment = undefined
  commissionPayable = undefined
  grossCommissionEarned = undefined
}
