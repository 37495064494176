import { FNAStructure } from '@master/model/structure/FNAStructure'
import SavingsNeeds from '@master/model/kyc-form/need-analysis/savings/SavingsNeeds'

export function SavingsStructure (defaultValue: { interestRate: number}) {
  const structure: FNAStructure<SavingsNeeds> = {
    title: 'S2. Savings',
    data: [
      {
        label: 'What are you planning for',
        key: 'planning',
        ejsClass: 'ejs-plan-div',
        type: 'text',
        validationMsg: 'Savings requires to indicate what are you planning for.'
      }, {
        label: 'Accumulation period (Years)',
        key: 'period',
        validationMsg: 'Savings requires to indicate accumulation period (Years).'
      }, {
        label: 'Amount needed (FV) ($)',
        key: 'amountFv',
        type: 'currency',
        validationMsg: 'Savings requires to indicate amount needed (FV).'
      }, {
        label: 'Value of fund to be used ($)',
        key: 'fundValue',
        type: 'currency',
        validationMsg: 'Savings requires to indicate value of fund to be used.'
      }, {
        label: 'Interest rate (%)',
        key: 'interestRate',
        defaultTo: 'defaultRate',
        validationMsg: 'Savings requires to indicate interest rate.'
      }, {
        label: '- Total funds available (FV) ($)',
        key: 'totalFundsFv',
        type: 'currency',
        disabled: true
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }, {
        label: 'Assuming investment rate of return to reach your savings goal you will need to save (%)',
        key: 'rateOfReturn',
        validationMsg: 'Savings requires to indicate value of fund to be used.'
      }, {
        label: 'Monthly REGULAR Contribution required ($)',
        key: 'contribution',
        type: 'currency',
        disabled: true
      }, {
        label: 'SINGLE Lump Sum required ($)',
        key: 'lumpSum',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
