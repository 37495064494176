import CoreRecommendation from '@master/model/kyc-form/RecommendationV2'
import { BasicProductV2 as BasicProduct } from '@master/model/kyc-form/product/BasicProductV2'

export type SelectedClient = 'Client 1' | 'Client 2' | 'client1' | 'client2' // FE is using 'client1' and 'client2' as identifier

export default class CustomRecommendation extends CoreRecommendation {
  // to get back the raw data for saving to backend
  convertToRawData () {
    const data = super.convertToRawData()
    return {
      ...data,
      ma_transact_value: this.c1TransactValue, // eslint-disable-line @typescript-eslint/camelcase
      ja_transact_value: this.c2TransactValue, // eslint-disable-line @typescript-eslint/camelcase
      ma_total_annual_premium: this.totalAnnualPremiumForClient1, // eslint-disable-line @typescript-eslint/camelcase
      ja_total_annual_premium: this.totalAnnualPremiumForClient2, // eslint-disable-line @typescript-eslint/camelcase
      ma_total_single_premium: this.totalSinglePremiumForClient1, // eslint-disable-line @typescript-eslint/camelcase
      ja_total_single_premium: this.totalSinglePremiumForClient2 // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  get c1hasMedical () {
    return this.rcmd.filter((rmd) => {
      return rmd.clientIndex === 0
    }).some((rmd) => {
      const accepted = rmd.clientChoice === 'accept' || rmd.clientChoice === 'ownchoice'
      const covered = rmd.productNeedsCovered ? rmd.productNeedsCovered.includes('Hospitalisation Expenses') : null
      const categorized = ['Health Insurance', 'Hospital Cash Insurance'].includes(rmd.productCategory)
      return accepted && (covered || categorized)
    }) ? 'yes' : 'no'
  }

  get c2hasMedical () {
    return this.rcmd.filter((rmd) => {
      return rmd.clientIndex === 1
    }).some((rmd) => {
      const accepted = rmd.clientChoice === 'accept' || rmd.clientChoice === 'ownchoice'
      const covered = rmd.productNeedsCovered ? rmd.productNeedsCovered.includes('Hospitalisation Expenses') : null
      const categorized = ['Health Insurance', 'Hospital Cash Insurance'].includes(rmd.productCategory)
      return accepted && (covered || categorized)
    }) ? 'yes' : 'no'
  }

  // for JPara's AML & recommendation premium summary table
  get c1TransactValue () {
    return this.totalAnnualPremiumForClient1 + this.totalSinglePremiumForClient1
  }

  // for JPara's AML & recommendation premium summary table
  get c2TransactValue () {
    return this.totalAnnualPremiumForClient2 + this.totalSinglePremiumForClient2
  }

  get totalAnnualPremiumForClient1 () {
    return this.totalAnnualizedPremiumForClient(0)
  }

  get totalAnnualPremiumForClient2 () {
    return this.totalAnnualizedPremiumForClient(1)
  }

  get totalSinglePremiumForClient1 () {
    return this.totalSinglePremiumForClient(0)
  }

  get totalSinglePremiumForClient2 () {
    return this.totalSinglePremiumForClient(1)
  }

  get uncategorisedProductLists () {
    return this.rcmd.filter(each => !each.type) || []
  }

  getClientIndex (client: SelectedClient) {
    return client === 'Client 1' ? 0 : 1
  }

  isProductsWithPriorityExistsInRcmd (targetPrioirty, client: SelectedClient) {
    return this.rcmd.some(each => each.priority === targetPrioirty && each.clientIndex === this.getClientIndex(client))
  }

  // 1. check if rcmd has this new priority and clientIndex, if yes, do thing
  // 2. if dun have then add a new product and set the priotity and client index
  addUnCategoriesProduct (priority, client: SelectedClient) {
    if (!this.isProductsWithPriorityExistsInRcmd(priority, client)) {
      const newProduct = new BasicProduct(this.riskRatingService)
      newProduct.type = ''
      newProduct.priority = priority
      newProduct.clientIndex = this.getClientIndex(client)
      this.rcmd.push(newProduct)
    }
  }

  removeUnCategoriesProduct (priority, client: SelectedClient) {
    const toDeleteIndex = this.rcmd.findIndex(each => each.priority === priority && each.clientIndex === this.getClientIndex(client) && !each.type)
    if (toDeleteIndex > -1) {
      this.rcmd.splice(toDeleteIndex, 1)
    }
  }

  get productReasons (): string {
    return this.flatAllProducts.filter(prod => !prod.isCustom && ['accept', 'ownchoice'].includes(prod.clientChoice)).map(prod => {
      if (prod instanceof BasicProduct) {
        if (!this.hasClient2 && prod.clientIndex === 1) {
          return ''
        }
      }
      return prod.getReasons()
    }).join('\n\n')
  }

  get productLimitations (): string {
    return this.flatAllProducts.filter(prod => !prod.isCustom && ['accept', 'ownchoice'].includes(prod.clientChoice)).map(prod => {
      if (prod instanceof BasicProduct) {
        if (!this.hasClient2 && prod.clientIndex === 1) {
          return ''
        }
      }
      return prod.getLimitations()
    }).join('\n\n')
  }

  get hasNtuc (): boolean {
    return this.rcmd.some(prod => {
      return prod.isNtuc()
    })
  }
}
