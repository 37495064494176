import { Base } from '@master/model/Base'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import Recommendation from '@master/model/kyc-form/Recommendation'

export const RiskRatingOptions = ['LOW', 'MEDIUM', 'HIGH', 'REJECTED/STR'] as const
export default class AML extends Base {
  @Ignore()
  customType = ''

  adverseDBChecks = ''
  redFlag = ''
  sanctioned = ''
  financialFlowHighRisk = ''
  pepAssociated = ''
  transactValueBelowThreshold = ''
  applyMedicalProduct = ''

  riskRating: typeof RiskRatingOptions[number] = 'LOW'

  dateOfAssessment: string | number = ''

  rcmd: Recommendation = new Recommendation(null)

  isSingaporean = ''

  isHighRiskPR = ''

  isHighRiskForeign = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super()
    this.customType = type + '_aml'
  }

  // custom method to extract since aml is a single object packed with the properties
  extractData (rawKycForm) {
    return super.extractData(rawKycForm[this.customType])
  }

  afterExtractData (data: any) {
    Object.keys(data).map(key => {
      if(
        !data[key] 
        && 
        ['isSingaporean', 'isHighRiskPR', 'isHighRiskForeign', 'applyMedicalProduct', 'transactValueBelowThreshold', 
        'pepAssociated', 'adverseDBChecks', 'redFlag', 'sanctioned', 'financialFlowHighRisk']
        .includes(key)
      ) {
        data[key] = 'no'
      }
    })
    return data;
  }

  convertToRawData () {
    this.dateOfAssessment = Date.now()
    const results = super.convertToRawData()
    return {
      [this.customType]: results
    }
  }
}
