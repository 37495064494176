/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScheduleTimeDto } from '../models/ScheduleTimeDto';
import type { ScheduleTimeResponseDto } from '../models/ScheduleTimeResponseDto';
import { request as __request } from '@openapi/request';

export class ScheduleService {

    /**
     * @param requestBody
     * @returns ScheduleTimeResponseDto Creates a new schedule timing, overriding the previous settings
     * @throws ApiError
     */
    public static async updateScheduleTaskTime(
        requestBody: ScheduleTimeDto,
    ): Promise<ScheduleTimeResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/schedule`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns ScheduleTimeResponseDto List all schedules in the system.
     * @throws ApiError
     */
    public static async listSchedules(): Promise<Array<ScheduleTimeResponseDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/schedule/list`,
        });
        return result.body;
    }

}