import store from "src/store/index";

export default class triggerAuditLog {
    static logCheck(oldData, newData, entityIdentifier, mainModule, subModule) {
        const auditLogArr = []
        const dataForKeys = this.deepDiff(oldData, newData)
        for (const key in dataForKeys) {
          if (dataForKeys.hasOwnProperty(key)) {
            if (key !== 'utm' &&  key !== 'ctm' && dataForKeys[key] !== undefined) { //exclude utm, ctm in payload and undefined values

            // This is for boolean transformation
            let newValue = dataForKeys[key].newValue;
            if (newValue === true || newValue === 1 || newValue === 'yes') {
                newValue = 'Yes';
            }
            else if (newValue === false || newValue === 0 || newValue === 'no') {
                newValue = 'No';
            }
            let oldValue = dataForKeys[key].oldValue;

            if (oldValue === true || oldValue === 1 || oldValue === 'yes') {
                newValue = 'Yes';
            }
            else if (oldValue === false || oldValue === 0 || oldValue === 'no') {
                oldValue = 'No';
            }

            // this is for Date conversion from milliseconds to normal date
            if (typeof newValue === 'number' && newValue > 1000000000000) {
                console.log(23432342)
                newValue = new Date(newValue).toLocaleDateString("en-US", {timeZone: "Asia/Singapore"});
                console.log(newValue)
            }
            if (typeof oldValue === 'number' && oldValue > 1000000000000) {
                oldValue = new Date(oldValue).toLocaleDateString("en-US", {timeZone: "Asia/Singapore"});
                console.log(newValue)
            }
            const newObj = {
              mainModule,
              subModule,
              fieldName: key,
              entityIdentifier,
              oldValue,
              newValue,
              userOid: store.getters.currentUser.oid,
              ipAddress: store.getters.IPConfigData?.ip
            };
            auditLogArr.push(newObj);
          }
        }
        }
        console.log(auditLogArr)
        return auditLogArr;
      }
     static deepDiff(oldObj, newObj) {
        let updatedValues: any = {};

        const isObject = (obj: any) => typeof obj === 'object';

    // Iterate through keys in the new object
    for (const key in newObj) {
        if (isObject(newObj[key])  && (!Array.isArray(newObj[key]) && !Array.isArray(oldObj[key]))) {
            // If the value is an object, recursively check for updates
            const nestedUpdates = this.deepDiff(oldObj[key], newObj[key]);
            Object.assign(updatedValues, nestedUpdates);
            console.log(updatedValues)
        } else {
            // Compare non-object values
            if (oldObj[key] !== newObj[key] && (!Array.isArray(newObj[key]) && !Array.isArray(oldObj[key]))) {
                updatedValues[key] = {
                    oldValue: oldObj[key] ? oldObj[key] : '',
                    newValue: newObj[key] ? newObj[key] : ''
                };
                console.log(updatedValues)
            }
        }

        // Check if the value is an array
        if (Array.isArray(newObj[key])) {
            // Check if the key exists in the old object and if it's an array
            if (Array.isArray(oldObj[key])) {
                // Compare arrays
                for (let i = 0; i < newObj[key].length || i < oldObj[key].length; i++) {
                    if (newObj[key][i] !== oldObj[key][i]) {
                        updatedValues[key] = {
                            oldValue: oldObj[key].join(','),
                            newValue: newObj[key].join(',')
                        }
                    console.log(updatedValues)
                    }
                }
            }
        }
        }
        return updatedValues;
    }
}
