import { Base } from '../../Base'
import { isEmpty } from 'lodash/fp'
import { Ignore } from '@master/annotation/Ignore'
import { IClientOptions, IAssuredOptions, FormOptions } from '../FormOptions'
import { MaxChar } from '@master/annotation/MaxChar'
import { isSummaryField, SummaryField } from '@master/annotation/SummaryField'
import { IExistingPortfolio } from './IExistingPortfolio'

export default class ExistingPortfolioV2 extends Base implements IExistingPortfolio {
  @MaxChar(20)
  companyName = ''

  owner: keyof IClientOptions = ''
  policyInsured: keyof IAssuredOptions = ''
  policyType = '' // JPara specific

  @MaxChar(52)
  policyName = ''

  @SummaryField('AP')
  premium?: number = null

  @MaxChar(20)
  policyTerms = ''

  date?: number = null

  @SummaryField('DP')
  lifeProtection?: number = null

  @SummaryField('TPD')
  disabilityProtection?: number = null

  disabilityIncome?: number = null

  @SummaryField('PA')
  accidentProtection?: number = null

  @SummaryField('CIP')
  criticalIllnessProtection?: number = null

  @SummaryField('R&B')
  roomAndBoard?: number = null

  @SummaryField('H&S')
  hospitalIncome?: number = null

  roomBoardHospitalIncome = ''
  others?: number = null // JPara specific

  @SummaryField('Maturity Value (MV)')
  maturityValue?: number = null

  maturityAge?: number = null

  @MaxChar(36)
  remarks = ''

  @Ignore()
  formOptions?: FormOptions

  constructor (formOptions: FormOptions = null) {
    super()
    this.formOptions = formOptions
  }

  // this is used to excluded the summary fields that do not want to show on plan summary page
  get excludeKeysToShowOnSummary (): string[] {
    return []
  }

  getSummaryFields (): { attrName: string; abbre: string }[] {
    const summaryFields: { attrName: string; abbre: string }[] = []
    Object.keys(this).forEach(attr => {
      if (this.excludeKeysToShowOnSummary.includes(attr as any)) return // exclude keys
      const grabSummaryField = isSummaryField(this, attr)
      if (grabSummaryField) {
        summaryFields.push({
          attrName: attr,
          ...grabSummaryField
        })
      }
    })
    return summaryFields
  }

  isValid () {
    return !isEmpty(this.owner) && this.owner.length > 0
  }

  belongsTo (owner: 'C1' | 'C2') {
    return this.isValid() && this.policyInsured === owner
  }

  reset () {
    Object.keys(this).filter(key => {
      return key !== 'type' && key !== 'startIndex' && key !== 'formOptions' && key !== 'curIndex'
    }).map(key => {
      this[key] = null
    })
  }
}
