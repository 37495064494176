import { options } from './Options'

export const NBRProductStatusOptions: options[] = [
  { value: 'active', label: 'Active' },
  { value: 'pending', label: 'Pending' },
  { value: 'terminated', label: 'Terminated' }
]

export const NBRProductCISStatusOptions: options[] = [
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' }
]
export const NBRProductStatusWithReasonOptions = [
  {
    value: "active",
    label: 'Active',
    reasons: [
      "Active",
      "App Converted to Policy",
      "Awaiting Renewal",
      "Denied",
      "First Reminder",
      "In Force",
      "Issued",
      "Manually Reviewed",
      "On Claim",
      "On Waiver",
      "Review Required",
      "Second Reminder",
    ]
  },
  {
    value: "pending",
    label: 'Pending',
    reasons: [
      "App Received",
      "Contract Underwritten",
      "In Complete",
      "In Process",
      "Payment/Acceptance",
      "Pending Endorsement",
      "Pending New Business",
      "Proposal",
      "Underwriting/Info",
    ]
  },
  {
    value: "terminated",
    label: 'Terminated',
    reasons: [
      "Cancel from Inception",
      "Cancelled",
      "Death Claim",
      "Deceased",
      "Declined",
      "Dread Disease Claim",
      "Free look Cancellation",
      "Lapsed",
      "Matured",
      "Non-Forfeiture Loan Surrender",
      "Other Claim",
      "Postponed",
      "Rejected",
      "Severe Disability Claim",
      "Surrendered",
      "Terminal Illness Claim",
      "Terminated",
      "TPD Claim",
      "Withdrawn"
    ]
  }
]