import { sum, round, savingMonthly, savingLumpSum, valueIsUndefined } from '@master/model/kyc-form/need-analysis/math'
import NeedsBase from '../NeedsBase'
import { Ignore } from '@master/annotation/Ignore'
import { isUndefined } from 'lodash/fp'
/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class SavingsNeeds extends NeedsBase {
  name = 'Savings'
  needs = 'Savings and Investment'
  planning = ''
  period?: number = undefined
  interestRate?: number = undefined
  amountFv?: number = undefined
  fundValue?: number = undefined
  rateOfReturn?: number = undefined
  additionalNotes = ''

  @Ignore()
  declare defaultRate?: number

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 's2') {
    super(`${type}_${id}`)
  }

  setupDefaultRate (rate: number) {
    super.setupDefaultRate(rate)
    if (isUndefined(this.interestRate)) this.interestRate = this.defaultRate
  }

  get totalFundsFv () {
    if (valueIsUndefined(this.fundValue) && valueIsUndefined(this.period)) return null
    const rate = this.interestRate || 0
    const compiledInterestRate = (1 + rate / 100) ** this.period
    const total = this.fundValue * compiledInterestRate

    return round(total, 0)
  }

  get surplusShortfall () {
    if (valueIsUndefined(this.totalFundsFv) && valueIsUndefined(this.amountFv)) return null
    const total = sum(this.totalFundsFv, -this.amountFv)
    return total
  }

  get rate () {
    return (this.rateOfReturn / 100) || 0
  }

  get contribution () {
    if (valueIsUndefined(this.surplusShortfall) && valueIsUndefined(this.period)) return null
    const total = savingMonthly(Math.abs(this.surplusShortfall), this.period, this.rate)
    return round(total, 0)
  }

  get lumpSum () {
    if (valueIsUndefined(this.surplusShortfall) && valueIsUndefined(this.period)) return null
    const total = savingLumpSum(Math.abs(this.surplusShortfall), this.period, this.rate)
    return round(total, 0)
  }

  get isDefaultRate () {
    return this.defaultRate === this.interestRate
  }
}
