import 'reflect-metadata' // Import only once
import { container } from 'inversify-props'
import { IDateService } from '@master/services/IDateService'
import { DateService } from '@master/services/DateService'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { RiskRatingService } from '@master/services/RiskRatingService'
import API from '@core/services/API'
import { IProjectSettings } from '@master/config/IProjectSettings'
import ProjectSettings from '@company-common/config/ProjectSettings'
import IVersionInfo from '@master/config/IVersionInfo'
import IfamVersion from './config/IfamVersion'
import ProjectVersion from './config/ProjectVersion'
import { ProjectInfo } from '@company-common/config/ProjectInfo'
import { IProjectInfo } from '@master/config/IProjectInfo'
import { SharedVariable } from '@core/model/SharedVariable'
import { IAdviserService } from '@master/services/IAdviserService'
import { CustomAdviserService } from '@company-common/services/AdviserService'
import { IUserProfileService } from '@core/services/IUserProfileService'
import { DefaultUserProfileService } from '@core/services/DefaultUserProfileService'
import { IUIOptionsService } from '@master/services/IUIOptionsService'
import { UIOptionsService } from '@master/services/UIOptionsService'
import { IPrioritiesStructure } from '@master/model/kyc-form/need-analysis/IPrioritiesStructure'
import { ThePrioritiesNeeds } from '@company-common/model/kyc-form/needs-analysis/ThePrioritiesNeeds'
import KYCClient from '@company-common/model/kyc-form/KYCClient'

container.addSingleton<IDateService>(DateService, 'DateService')
container.addSingleton<IRiskRatingService>(RiskRatingService, 'RiskRatingService')
container.addSingleton<API>(API, '$api')
container.addSingleton<IProjectSettings>(ProjectSettings, 'ProjectSettings')
container.addSingleton<IVersionInfo>(IfamVersion, 'IfamVersion')
container.addSingleton<IVersionInfo>(ProjectVersion, 'ProjectVersion')
container.addSingleton<IProjectInfo>(ProjectInfo, 'ProjectInfo')
container.addSingleton<SharedVariable>(SharedVariable, 'SharedVariable')
container.addSingleton<IAdviserService>(CustomAdviserService, 'AdviserService')
container.addSingleton<IUserProfileService>(DefaultUserProfileService, 'UserProfileService')
container.addSingleton<IUIOptionsService>(UIOptionsService, 'UIOptionsService')
container.addSingleton<IPrioritiesStructure<KYCClient>>(ThePrioritiesNeeds, 'ThePrioritiesNeeds')
