import { IProjectInfo } from '@master/config/IProjectInfo'

export class ProjectInfo implements IProjectInfo {
  brandName = 'Propel'
  companyShortName = 'Propel'
  companyLongName = 'Alpha Wealth Financial Advisers'
  companyCapName = 'Propel'
  printerCompany = ''
  dataProtectionOfficerEmail = 'pdpa@abcfinancial.com'
  email = 'enquiry@mocha.com.sg'
  contactNumber = '+65 6589 8198'
  address = '180 Kitchener Rd, #09-01, Singapore 208539'
  regNumber = '200606266H'
  url = 'https://alpha.mocha.com.sg'
  urlName = 'alpha.mocha.com.sg'
  agentRepresentative = 'Financial Adviser'
  personalFinancialReview = 'Personal Financial Review'
  electronicFormName = 'ePFR'

  pdpaWithdrawalForm = 'Propel PDPA Withdrawal Consent Form'

  currency = '' // no need show currencySymbol

  get rpaFormName () {
    return 'PFR'
  }
}
