/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '@openapi/request';

export class BscSupervisorReviewService {

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async addSupervisorReview(
        requestBody: Array<string>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/bsc-supervisor-review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param reviewYear
     * @param reviewQuarter
     * @param reviewOid
     * @returns any
     * @throws ApiError
     */
    public static async fetchSupervisorWithAttachedCase(
        reviewYear: number,
        reviewQuarter: number,
        reviewOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/bsc-supervisor-review`,
            query: {
                'reviewYear': reviewYear,
                'reviewQuarter': reviewQuarter,
                'reviewOid': reviewOid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async deleteSupervisorReviews(
        requestBody: Array<string>,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/bsc-supervisor-review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param bscGrade
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async updateData(
        bscGrade: number,
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/bsc-supervisor-review`,
            query: {
                'bscGrade': bscGrade,
                'oid': oid,
            },
        });
        return result.body;
    }

    /**
     * @param supervisorOid
     * @returns any
     * @throws ApiError
     */
    public static async fetchSupervisorDetails(
        supervisorOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/bsc-supervisor-review/${supervisorOid}`,
        });
        return result.body;
    }

    /**
     * @param reviewOid
     * @param reviewYear
     * @param reviewQuarter
     * @returns any
     * @throws ApiError
     */
    public static async generateSupervisorReviewPdf(
        reviewOid: string,
        reviewYear: number,
        reviewQuarter: number,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/bsc-supervisor-review/generate-pdf`,
            query: {
                'reviewOid': reviewOid,
                'reviewYear': reviewYear,
                'reviewQuarter': reviewQuarter,
            },
        });
        return result.body;
    }

}