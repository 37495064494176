import EducationNeedsDetail from '@master/model/kyc-form/need-analysis/savings/EducationNeedsDetail'
import { TableFormStructure } from '@master/model/structure/FormStructure'

export function EducationStructure (dependantOptions) {
  const structure: TableFormStructure<EducationNeedsDetail> = [
    { label: 'Who is this provided for', key: 'providedFor', type: 'dropdown', classes: ['wrap-select'], options: dependantOptions },
    { label: 'Current age of child', key: 'age', type: 'number', disabled: (edu) => { return !edu.isValid() } },
    { label: 'Cost of education today / Amount needed ($) (Tuition + Allowances)', key: 'costOfEducation', type: 'currency', disabled: (edu) => { return !edu.isValid() } },
    { label: 'Country of study', key: 'countryOfStudy', type: 'text', disabled: (edu) => { return !edu.isValid() } },
    { label: 'Course Duration (years)', key: 'courseDuration', type: 'number', disabled: (edu) => { return !edu.isValid() } },
    { label: 'Number of years to save', key: 'yearsToSave', type: 'number', disabled: (edu) => { return !edu.isValid() } },
    { label: 'Average education inflation rate (%)', key: 'inflation', type: 'number', disabled: (edu) => { return !edu.isValid() } },
    { label: '<b>Total cost of education at entry age ($)</b>', key: 'totalEducationCost', type: 'currency', disabled: true },
    { label: '- Maturity value of existing insurance policies for this planning ($)', key: 'maturityValueOfExistingPolicies', type: 'currency', disabled: (edu) => { return !edu.isValid() } },
    { label: '- Existing resources ($)', key: 'existingResources', type: 'currency', disabled: (edu) => { return !edu.isValid() } },
    { label: '<div class="bold-text">Surplus / <span style="color: darkred" class="red-text">(Shortfall)</span></div>', key: 'surplusShortfall', type: 'currency', disabled: true }
  ]

  return structure
}
