import { Base } from '@master/model/Base'
import FormKeyConverter from '@master/model/kyc-form/FormKeyConverter'
import KYCPdf from '../KYCPdf'

// Adding new signers / Removing signer can be done in here
export default class SignPadSigners extends Base {
  client0Signature = ''
  client0SignatureDate?: number = undefined
  client1Signature = ''
  client1SignatureDate?: number = undefined
  trustedIndividual1Signature = ''
  trustedIndividual1SignatureDate?: number = undefined
  trustedIndividual2Signature = ''
  trustedIndividual2SignatureDate?: number = undefined
  adviserSignature = ''
  adviserSignatureDate?: number = undefined
  adviser2Signature = ''
  adviser2SignatureDate?: number = undefined
  pep1Signature = ''
  pep1SignatureDate?: number = undefined
  pep2Signature = ''
  pep2SignatureDate?: number = undefined
  pep3Signature = ''
  pep3SignatureDate?: number = undefined
  beneficialOwner1Signature = ''
  beneficialOwner1SignatureDate?: number = undefined
  beneficialOwner2Signature = ''
  beneficialOwner2SignatureDate?: number = undefined

  getAllSigners () {
    return ['client0', 'client1', 'trustedIndividual1', 'trustedIndividual2', 'pep1', 'pep2', 'pep3', 'beneficialOwner1', 'beneficialOwner2', 'adviser', 'adviser2']
  }

  getApplicableSigners (kycPdf: KYCPdf) {
    const allsigners = this.getAllSigners()
    let applicableSigners = []
    try {
      applicableSigners = allsigners.map((signer) => {
        let profile = {}
        switch (true) {
          case (typeof signer !== 'string' || typeof signer.includes !== 'function') :
            profile = false
            break
          case signer === 'adviser' : {
            const adv = kycPdf.adviser.details
            if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            break
          }
          case signer === 'adviser2' : {
            const adv = kycPdf.adviser.declaration
            if (!adv.haveJointFieldWork && !adv.haveJointSales) {
              profile = false
            } else {
              profile = { name: adv.financialConsultantName, nric: adv.financialConsultantMasNo }
            }
            break
          }
          case signer === 'client0' : {
            const c1 = kycPdf.client1.personalDetails
            if (c1) profile = { name: c1.name, nric: c1.nric }
            break
          }
          case signer === 'client1' :
            if (!kycPdf.hasClient2) {
              profile = false
            } else {
              const c2 = kycPdf.client2.personalDetails
              if (c2) profile = { name: c2.name, nric: c2.nric }
            }
            break
          case signer === 'trustedIndividual' :
            if (!kycPdf.hasAccompaniment) {
              profile = false
            } else {
              const ti = kycPdf.trustedIndividual;
              profile = { name: ti.name, nric: ti.nric };
            }
            break
          case signer === 'trustedIndividual1' :
            if ((kycPdf as any)?.client1TrustedIndividual?.TIaccompanied === 'yes') {
              const ti1 = (kycPdf as any)?.client1TrustedIndividual
              if (ti1?.TIname && ti1?.TInric) profile = { name: ti1.TIname, nric: ti1.TInric }
              else profile = false
            } else {
              profile = false
            }
            break
          case signer === 'trustedIndividual2' :
            if ((kycPdf as any)?.client2TrustedIndividual?.TIaccompanied === 'yes') {
              const ti2 = (kycPdf as any)?.client2TrustedIndividual
              if (ti2?.TIname && ti2?.TInric) profile = { name: ti2.TIname, nric: ti2.TInric }
              else profile = false
            } else {
              profile = false
            }
            break
          case signer.includes('pep') : { // pep1 , pep2, pep3
            const pepn = parseInt(signer.replace('pep', '')) - 1
            const targetPEP = kycPdf.clientDeclaration.pepList[pepn] // pep1 , pep2, pep3

            if (!kycPdf.hasValidC1PEP && !kycPdf.hasValidC2PEP) {
              // REJECT : both dont declare PEP flag and PEP profile
              profile = false
            } else if (!targetPEP || !targetPEP.fullName.length) {
              // REJECT : pep not found or no full name
              profile = false
            } else if (!['C1', 'C2'].includes(targetPEP.policyholderName)) {
              // REJECT : pep found but not assigned to either C1 or C2
              profile = false
            } else if (targetPEP.policyholderName === 'C1' && !kycPdf.hasValidC1PEP) {
              // REJECT : pep for client 1, but is incomplete
              profile = false
            } else if (targetPEP.policyholderName === 'C2' && !kycPdf.hasValidC2PEP) {
              // REJECT : pep for client 2, but is incomplete
              profile = false
            } else {
              if (targetPEP) profile = { name: targetPEP.fullName, nric: targetPEP.nric }
            }
            break
          }
          case signer.includes('beneficialOwner') : {
            const bon = parseInt(signer.replace('beneficialOwner', ''))
            if ((bon === 1 && !kycPdf.hasValidBO1) || (bon === 2 && !kycPdf.hasValidBO2)) {
            // if (!kycPdf.hasValidBO1 || !kycPdf.hasValidBO2) {
              profile = false
            } else {
              const targetBO = kycPdf['client' + bon].beneficialOwner.beneficialOwnerParticulars
              if (targetBO) profile = { name: targetBO.fullName, nric: targetBO.nric }
            }
            break
          }
          default :
            profile = false
        }
        if (profile !== false) {
          Object.assign(profile, {
            role: signer,
            signatureOriKey: signer + 'Signature',
            signature: this[signer + 'Signature'],
            signaturedateOriKey: signer + 'SignatureDate',
            signaturedate: this[signer + 'SignatureDate']
          })
        }
        return profile
      }).filter(signer => signer !== false)
    } catch (ex) {
      return []
    }
    return applicableSigners
  }

  getDisplayNames () {
    return {
      client0: 'Client 1',
      client1: 'Client 2',
      trustedIndividual: 'Accompaniment',
      trustedIndividual1: 'Accompaniment',
      trustedIndividual2: 'Accompaniment',
      adviser: 'Adviser',
      adviser2: 'Adviser 2',
      pep1: 'PEP 1',
      pep2: 'PEP 2',
      pep3: 'PEP 3',
      beneficialOwner1: 'Beneficial Owner Client 1',
      beneficialOwner2: 'Beneficial Owner Client 2'
    }
  }

  extractData (rawKYCPdf: any) {
    const data = {}
    Object.keys(this).forEach((key) => {
      const snakeKey = this.snakeCaseKey(key)
      const fetched = rawKYCPdf[snakeKey]
      if (fetched !== undefined) data[key] = fetched
    })
    return data
  }

  getRawKey (formattedKey: string) {
    const fkConverter = new FormKeyConverter()
    return fkConverter.convertToRawKey(this.snakeCaseKey(formattedKey))
  }

  saveSignature (newSignature: object) {
    console.log('saving signature', newSignature)
    Object.keys(newSignature).forEach((key) => {
      this[key] = newSignature[key]
    })
  }
}
