import { MaxChar } from '@master/annotation/MaxChar'
import { ClientBase } from '../ClientBase'

export default class RecommendationBasis extends ClientBase {
  liquidityConsideration = ''
  liquidityEmergencyFundsDuration = ''
  riskConcentration = ''
  riskToleranceInTune = ''
  timeHorizonProtection = ''
  timeHorizonSavings = ''
  productDeviationsExistence = ''

  @MaxChar(640)
  liquidityDeviationReason = ''

  @MaxChar(100)
  productDeviationsExistenceReason = ''

  productRecommendationElaboration = ''
  productRiskAndLimitationsElaboration = ''
  recommendationDecision = '' // became clientChoiceProductDetails depend on Client's Declaration's clientDecisionAgree == false (double check)

  @MaxChar(640)
  riskConcentrationDeviationReason = ''

  @MaxChar(640)
  riskToleranceDeviationReason = ''

  @MaxChar(640)
  timeHorizonDeviationReason = ''
}
