import { FormBase } from '../FormBase'

export const SubsidyTypeOptions = [
  { label: '', value: '' },
  { label: 'Examinations', value: 'exam' }
]
export class RecruitSubsidyReimbursementFields {
  submissionDate: number = undefined
  payableTo = ''
  subsidy = 'exam' // options
  exams = [] // options
  totalCosts: number = undefined
  reimbursed: number = undefined
  rndApprovalDate: number = undefined
  // adviserSignature: signature
  // rmSignature: signature
  // bdm: {
  //   name = ''
  //   signature: signature
  // }
  // hdb: {
  //   name = ''
  //   signature: signature
  // }
  // finance: {
  //   name = ''
  //   signature: signature
  // }
}
export class RecruitSubsidyReimbursement extends FormBase {
  // shared.name
  // shared.repCode
  // shared.branch
  // shared.rm
  type = 'recruit-subsidy-reimbursement'

  data: RecruitSubsidyReimbursementFields = new RecruitSubsidyReimbursementFields()
}
