import { Ignore } from '@master/annotation/Ignore'
import { ProductMeta, ProductHeaders } from './ProductMeta'

export const ProductFullHeaders = [
  ...ProductHeaders,
  'basic_comm_sp',
  'basic_comm_rsp',
  'basic_comm_tu',
  'basic_comm_year1',
  'basic_comm_year2',
  'basic_comm_year3',
  'basic_comm_year4',
  'basic_comm_year5',
  'basic_comm_year6',
  'basic_comm_year7',
  'basic_comm_year8',
  'basic_comm_year9',
  'basic_comm_year10',
  'overriding_comm_sp',
  'overriding_comm_rsp',
  'overriding_comm_tu',
  'overriding_comm_year1',
  'overriding_comm_year2',
  'overriding_comm_year3',
  'overriding_comm_year4',
  'overriding_comm_year5',
  'overriding_comm_year6',
  'overriding_comm_year7',
  'overriding_comm_year8',
  'overriding_comm_year9',
  'overriding_comm_year10'
]

export class ProductFullMeta extends ProductMeta {
  basicCommSp = undefined
  basicCommRsp = undefined
  basicCommTu = undefined
  basicCommYear1 = undefined
  basicCommYear2 = undefined
  basicCommYear3 = undefined
  basicCommYear4 = undefined
  basicCommYear5 = undefined
  basicCommYear6 = undefined
  basicCommYear7 = undefined
  basicCommYear8 = undefined
  basicCommYear9 = undefined
  basicCommYear10 = undefined

  overridingCommSp = undefined
  overridingCommRsp = undefined
  overridingCommTu = undefined
  overridingCommYear1 = undefined
  overridingCommYear2 = undefined
  overridingCommYear3 = undefined
  overridingCommYear4 = undefined
  overridingCommYear5 = undefined
  overridingCommYear6 = undefined
  overridingCommYear7 = undefined
  overridingCommYear8 = undefined
  overridingCommYear9 = undefined
  overridingCommYear10 = undefined

  snakeCaseKey (key: string) {
    const snake = super.snakeCaseKey(key)
    const uds = snake.match(/_\d/g)
    let final = snake
    if (uds) {
      uds.forEach((ud) => {
        const d = ud.replaceAll('_', '')
        final = snake.replaceAll(ud, d)
      })
    }
    return final
  }

  @Ignore()
  get maxCommYear () {
    return 10
  }
}
