/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FileListDto = {
    kycPdfOid?: string;
    kycFormOid?: string;
    type: FileListDto.type;
    fileType?: string;
}

export namespace FileListDto {

    export enum type {
        MISC = 'MISC',
        ACRA = 'ACRA',
        SPF = 'SPF',
        CREDIT_BUREAU = 'CREDIT_BUREAU',
        DASHBOARD = 'DASHBOARD',
        EFNA = 'EFNA',
    }


}
