import { FormBase } from '../FormBase'

export const ReportingTier = [
  { label: '', value: '' },
  { label: 'T1', value: 't1' },
  { label: 'T2', value: 't2' },
  { label: 'T3', value: 't3' }
]
export class RecruitManagerAssessmentFields {
  channel = ''
  reporting: typeof ReportingTier[number]['value'] = ''
  manager = ''
  // managerSignature: signature
  banding: number = undefined// percentage
  bandingExpiry: number = undefined// date
  fsmBanding: number = undefined// percentage
  designation = ''
  rcmdNote = ''
}
export class RecruitManagerAssessment extends FormBase {
  // shared.name
  // shared.nric.value
  // shared.branch
  type = 'recruiting-manager-assessment'
  data: RecruitManagerAssessmentFields = new RecruitManagerAssessmentFields()
  signatures = {
    applicant: '',
    applicantDate: undefined,
    recruiter: '',
    recruiterDate: undefined
  }
}
