/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdviserReviewDeleteRequestDTO } from '../models/AdviserReviewDeleteRequestDTO';
import type { AdviserReviewUpdateRequestDTO } from '../models/AdviserReviewUpdateRequestDTO';
import type { BscInscopeProductDTO } from '../models/BscInscopeProductDTO';
import type { BscInscopeRequestBody } from '../models/BscInscopeRequestBody';
import type { BscPolicyInfractionCreationDTO } from '../models/BscPolicyInfractionCreationDTO';
import type { BscPolicyInfractionDTO } from '../models/BscPolicyInfractionDTO';
import type { BscPolicyInfractionListResponseDTO } from '../models/BscPolicyInfractionListResponseDTO';
import type { BscPolicyInfractionUpdateDTO } from '../models/BscPolicyInfractionUpdateDTO';
import type { BscQuarterDTO } from '../models/BscQuarterDTO';
import type { BscQuarterListResponseDTO } from '../models/BscQuarterListResponseDTO';
import type { BscQuarterNewRequestDTO } from '../models/BscQuarterNewRequestDTO';
import type { BscQuarterUpdateRequestDTO } from '../models/BscQuarterUpdateRequestDTO';
import type { BscQuarterWithUserReviewDTO } from '../models/BscQuarterWithUserReviewDTO';
import type { BscUserReviewQuarterDTO } from '../models/BscUserReviewQuarterDTO';
import { request as __request } from '@openapi/request';

export class BscService {

    /**
     * @param requestBody
     * @returns BscInscopeProductDTO
     * @throws ApiError
     */
    public static async checkProductsInscope(
        requestBody: BscInscopeRequestBody,
    ): Promise<Array<BscInscopeProductDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/bsc/check-products`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async savePolicyInfraction(
        requestBody: BscPolicyInfractionDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/bsc/save-meta`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns BscQuarterDTO
     * @throws ApiError
     */
    public static async saveBscQuarterWithUsers(
        requestBody: BscQuarterNewRequestDTO,
    ): Promise<BscQuarterDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-review/save`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns BscQuarterDTO
     * @throws ApiError
     */
    public static async updateBscQuarterWithUsers(
        requestBody: BscQuarterUpdateRequestDTO,
    ): Promise<BscQuarterDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-review/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns BscQuarterListResponseDTO
     * @throws ApiError
     */
    public static async retrieveBscQuarterList(): Promise<BscQuarterListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-review/list`,
        });
        return result.body;
    }

    /**
     * @param bscReviewOid
     * @returns BscQuarterWithUserReviewDTO
     * @throws ApiError
     */
    public static async retrieveBscQuarterReview(
        bscReviewOid: string,
    ): Promise<BscQuarterWithUserReviewDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-review/${bscReviewOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns BscPolicyInfractionDTO
     * @throws ApiError
     */
    public static async savePolicyInfractionV2(
        requestBody: BscPolicyInfractionCreationDTO,
    ): Promise<BscPolicyInfractionDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc/save-meta`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns BscPolicyInfractionDTO
     * @throws ApiError
     */
    public static async updatePolicyInfractionV2(
        requestBody: BscPolicyInfractionUpdateDTO,
    ): Promise<BscPolicyInfractionDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param adviserReviewOid
     * @returns BscPolicyInfractionListResponseDTO
     * @throws ApiError
     */
    public static async listPolicyInfractionOfAdviserReview(
        adviserReviewOid: string,
    ): Promise<BscPolicyInfractionListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc/list/${adviserReviewOid}`,
        });
        return result.body;
    }

    /**
     * @param policyOid
     * @returns BscPolicyInfractionListResponseDTO
     * @throws ApiError
     */
    public static async listPolicyInfractionByPolicyId(
        policyOid: string,
    ): Promise<BscPolicyInfractionListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc/policyInfractions/list/${policyOid}`,
        });
        return result.body;
    }

    /**
     * @param infractionOid
     * @returns BscPolicyInfractionDTO
     * @throws ApiError
     */
    public static async retrieveInfractionDetails(
        infractionOid: string,
    ): Promise<BscPolicyInfractionDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc/${infractionOid}`,
        });
        return result.body;
    }

    /**
     * @param reviewOid
     * @returns any
     * @throws ApiError
     */
    public static async generateAdviserReviewPdf(
        reviewOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-adviser-review/generate-pdf`,
            query: {
                'reviewOid': reviewOid,
            },
        });
        return result.body;
    }

    /**
     * @param adviserReviewOid
     * @returns BscUserReviewQuarterDTO
     * @throws ApiError
     */
    public static async retrieveAdviserReview(
        adviserReviewOid: string,
    ): Promise<BscUserReviewQuarterDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-adviser-review`,
            query: {
                'adviserReviewOid': adviserReviewOid,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async massDeleteAdviserReview(
        requestBody: AdviserReviewDeleteRequestDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/v2/bsc-adviser-review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateAdviserReview(
        requestBody: AdviserReviewUpdateRequestDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-adviser-review`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param currentReviewOid
     * @returns BscUserReviewQuarterDTO
     * @throws ApiError
     */
    public static async retrieveLastGrade(
        currentReviewOid: string,
    ): Promise<BscUserReviewQuarterDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-adviser-review/last-grade/${currentReviewOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async saveBscQuarterWithUsers1(
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-previous/new`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param previousRecordOid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateBscQuarterWithUsers1(
        previousRecordOid: string,
        requestBody: any,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/v2/bsc-previous/update`,
            query: {
                'previous_record_oid': previousRecordOid,
            },
            body: requestBody,
        });
        return result;
    }

    /**
     * @param oid
     * @returns any
     * @throws ApiError
     */
    public static async retrieveBscQuarterList1(
        oid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-previous/get`,
            query: {
                '_oid': oid,
            },
        });
        return result.body;
    }

    /**
     * 
     * @param userOid
     * @returns BscQuarterWithUserReviewDTO
     * @throws ApiError
     */
    public static async retrieveBscReviewForUser(
        userOid: string
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/bsc-review/user/${userOid}`,
        });
        return result.body;
    }

}