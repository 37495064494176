import { TypeBase } from '../../TypeBase'
import { Default } from '@master/annotation/Default'
import { sum } from '@master/model/kyc-form/need-analysis/math'

export default class InvestmentRiskProfile extends TypeBase {
  @Default(null)
  id: string = null;

  @Default(false)
  enabled: boolean = false

  @Default(0)
  question1?: number = 0

  @Default(null)
  question2?: number = null

  @Default(null)
  question3?: number = null

  @Default(null)
  question4?: number = null

  @Default(null)
  question5?: number = null

  @Default(null)
  question6?: number = null

  investmentRiskProfile = null
  riskProfileSelectedPortfolio = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0', id: string, enabled: boolean) {
    super(type);
    this.id = id;
    this.enabled = enabled;
  }

  get riskProfileScore() {
    return sum(this.question1 ?? 0, this.question2 ?? 0, this.question3 ?? 0, this.question4 ?? 0, this.question5 ?? 0, this.question6 ?? 0) ?? 0;
  }
}
