import { MaxChar } from '@master/annotation/MaxChar'
import { TypeBase } from '../TypeBase'

export default class ClientAssumptions extends TypeBase {
  adjustmentsAssumptionsEducation?: number = undefined
  adjustmentsAssumptionsInflation?: number = undefined
  adjustmentsAssumptionsInvestment?: number = undefined

  @MaxChar(768)
  adjustmentsAssumptionsRemarks = ''

  adjustmentsAssumptionsRetirement?: number = undefined
  adjustmentsAssumptionsSalary?: number = undefined

  constructor () {
    super('client_0')
  }
}
