import { RouteRecordRaw } from 'vue-router'

export const compensationRoutes: Array<RouteRecordRaw> = [
  {
    path: '/compensation-setup',
    name: 'Compensation Setup',
    component: () => import('@core/pages/compensation-setup/views/CompensationSetupPage.vue'),
    meta: {
      subject: ['compensationSetup']
    }
  }
]
