/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdviserSalesCodesDto } from '../models/AdviserSalesCodesDto';
import type { CreateAdviserSalesCodesDto } from '../models/CreateAdviserSalesCodesDto';
import type { FilterAdviserSalesCodesDto } from '../models/FilterAdviserSalesCodesDto';
import type { UpdateAdviserSalesCodesDto } from '../models/UpdateAdviserSalesCodesDto';
import { request as __request } from '@openapi/request';

export class AdviserSalesCodesService {

    /**
     * @param requestBody 
     * @returns AdviserSalesCodesDto Retrieve the list of Adviser Sales Codes records
     * @throws ApiError
     */
    public static async retrieveAdviserSalesCodesList(
requestBody: FilterAdviserSalesCodesDto,
): Promise<Array<AdviserSalesCodesDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser-sales-codes/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns AdviserSalesCodesDto Save the Adviser Sales Codes record
     * @throws ApiError
     */
    public static async saveAdviserSalesCodes(
requestBody: UpdateAdviserSalesCodesDto,
): Promise<AdviserSalesCodesDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser-sales-codes/save`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns AdviserSalesCodesDto Save the Adviser Sales Codes record
     * @throws ApiError
     */
    public static async createAdviserSalesCodes(
requestBody: CreateAdviserSalesCodesDto,
): Promise<AdviserSalesCodesDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser-sales-codes/create`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody Validate multiple Adviser Sales Codes records
     * @returns any 
     * @throws ApiError
     */
    public static async validateAdviserSalesCodes(
requestBody: Array<CreateAdviserSalesCodesDto>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser-sales-codes/validate`,
            body: {
                importData: requestBody
            },
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns AdviserSalesCodesDto Save multiple Adviser Sales Codes records
     * @throws ApiError
     */
    public static async massUpdateAdviserSalesCodes(
requestBody: any,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser-sales-codes/import`,
            formData: requestBody
        });
        return result.body;
    }

    /**
     * @returns any Export the Adviser Sales Codes records to a CSV file
     * @throws ApiError
     */
    public static async csvExport(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser-sales-codes/csv-export`,
        });
        return result.body;
    }

}