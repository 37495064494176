import { MaxChar } from '@master/annotation/MaxChar'
import { IProjectInfo } from '@master/config/IProjectInfo'
import { container } from 'inversify-props'
import { FormBase } from '../FormBase'

export class SharedField {
  @MaxChar(100)
  name = ''

  nric = ''
  nricType: 'Pink'

  rnfNumber = ''
  corporateEmail = ''
  hpNumber = ''
  branch = ''
  rm = ''
  fsm = ''

  companyLongName = ''
}

export class Shared extends FormBase {
  constructor () {
    super()
    const prjInfo = container.get<IProjectInfo>('ProjectInfo')
    this.data.companyLongName = prjInfo.companyLongName
  }

  type = 'shared'
  data: SharedField = new SharedField()

  get error () {
    const errs = []
    if (!this.data.name || this.data.name.length === 0) {
      errs.push({
        key: 'name',
        msg: 'Required'
      })
    }
    const emailFormat = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$")
    if (!this.data.corporateEmail || this.data.corporateEmail.length === 0) {
      errs.push({
        key: 'corporateEmail',
        msg: 'Required'
      })
    }
    if (!emailFormat.test(this.data.corporateEmail)) {
      errs.push({
        key: 'corporateEmail',
        msg: 'Required Format: abc@abc.com'
      })
    }
    return errs
  }
}
