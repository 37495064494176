import CoreRecommendationBasis from '@master/model/kyc-form/RecommendationBasis'

export default class RecommendationBasis extends CoreRecommendationBasis {
  liquidityConsideration = ''
  liquidityEmergencyFundsDuration = ''
  // riskConcentration = ''
  riskToleranceInTune = ''
  timeHorizonProtection = ''
  timeHorizonSavings = ''
  productDeviationsExistence = ''
  liquidityDeviationReason = ''
  productDeviationsExistenceReason = ''
  productRecommendationElaboration = ''
  productRecommendationReason = '' // auto populated
  productRiskAndLimitationsElaboration = ''
  productRiskAndLimitationsReason = '' // auto populated
  recommendationDecision = '' // became clientChoiceProductDetails depend on Client's Declaration's clientDecisionAgree == false (double check)
  // riskConcentrationDeviationReason = ''
  riskToleranceDeviationReason = ''
  timeHorizonDeviationReason = ''
}
