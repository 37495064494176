import SimplePersonalDetails from '@master/model/kyc-form/SimplePersonalDetails'
import { IDateService } from '@master/services/IDateService'
import { Default } from '@master/annotation/Default'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

export default class FullPersonalDetails extends SimplePersonalDetails {
  @MaxChar(InputMaxChar.SMALL)
  activitiesSpecified = ''

  annualIncome = ''
  citizenship = ''

  race=''
  
  transactValue=''

  @MaxChar(InputMaxChar.XSMALL)
  citizenshipSpecified = ''

  @MaxChar(InputMaxChar.XSMALL)
  citizenshipSpecified2 = ''

  countryOfBirth = ''

  @MaxChar(InputMaxChar.XSMALL)
  companyName = ''

  @MaxChar(InputMaxChar.SMALL)
  contactEmail = ''

  @MaxChar(InputMaxChar.XSMALL)
  contactHome = ''

  contactHomeCountryCode = ''

  contactOffice = ''

  contactOfficeCountryCode = ''

  @MaxChar(InputMaxChar.XSMALL)
  contactMobile = ''

  contactMobileCountryCode = ''

  employmentStatus = ''

  @MaxChar(InputMaxChar.XSMALL)
  exactDuties = ''

  intendedRetirementAge?: number = undefined
  involvedInActivities = ''

  @Default([])
  pdpaMarketing: string[] = [] // was ppdaMarketing

  @MaxChar(InputMaxChar.XSMALL)
  previousOccupation = '' // was plsSpecify

  @MaxChar(InputMaxChar.XSMALL)
  natureOfBusiness = ''

  @MaxChar(InputMaxChar.MEDIUM)
  residentialAddress = ''

  @MaxChar(InputMaxChar.XXSMALL)
  postalCode = ''

  residentialCountry = ''

  blockNumber = ''

  streetName = ''

  unitNumber = ''

  buildingName = ''

  isLandedProperty = ''

  smoker = ''

  ecddFormStatus = ''

  @MaxChar(InputMaxChar.XSMALL)
  spokenLanguageSpecified = ''

  title = ''

  @MaxChar(InputMaxChar.XSMALL)
  writtenLanguageSpecified = ''

  @Ignore()
  @Pointer('ROOT.clientDeclaration', 'reviewDate')
  reviewDate?: number = 0

  isCountryProhibitted = false;
  isUserIdProhibitted = false;

  constructor (type: 'client_0' | 'client_1' = 'client_0', dateService: IDateService) {
    super(type, dateService)
  }

  afterExtractData (data: any) {
    // client 1 should always be included
    if (this.type === 'client_0') data.included = true
    return data
  }
}
