import 'reflect-metadata'

const jsonMetadataKey = Symbol('Json')

export function Json () {
  return Reflect.metadata(jsonMetadataKey, true)
}

export function needToConvertJson (target: any, propertyKey: string) {
  return Reflect.getMetadata(jsonMetadataKey, target, propertyKey)
}
