/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMemberDTO } from '../models/AddMemberDTO';
import type { PicodedAccountDTO } from '../models/PicodedAccountDTO';
import { request as __request } from '@openapi/request';

export class AccountStructureMemberService {

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async addMember(
requestBody: AddMemberDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/account-structure/member`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param accountOid 
     * @returns any 
     * @throws ApiError
     */
    public static async deleteMember(
accountOid: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/account-structure/member/${accountOid}`,
        });
        return result.body;
    }

    /**
     * @param accountOid 
     * @returns string 
     * @throws ApiError
     */
    public static async findMemberInStructure(
accountOid: string,
): Promise<Array<string>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account-structure/member/find/${accountOid}`,
        });
        return result.body;
    }

    /**
     * @returns PicodedAccountDTO 
     * @throws ApiError
     */
    public static async listOfMemberInStructure(): Promise<Array<PicodedAccountDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/account-structure/member/list`,
        });
        return result.body;
    }

}