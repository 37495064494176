// Structure
import { DeathProtectionStructure } from '@company-common/model/kyc-form/needs-analysis/structure/protection/DeathProtectionStructure'
import { DisabilityProtectionStructure } from '@company-common/model/kyc-form/needs-analysis/structure/protection/DisabilityProtectionStructure'
import { CriticalIllnessProtectionStructure } from '@company-common/model/kyc-form/needs-analysis/structure/protection/CriticalIllnessProtectionStructure'
import { DisabilityIncomeProtectionStructure } from '@company-common/model/kyc-form/needs-analysis/structure/protection/DisabilityIncomeProtectionStructure'
import { LongTermCareProtectionStructure } from '@company-common/model/kyc-form/needs-analysis/structure/protection/LongTermCareProtectionStructure'

import { PersonalAccidentStructure } from '@company-common/model/kyc-form/needs-analysis/structure/accidentHealth/PersonalAccidentStructure'
import { HospitalisationStructure } from '@company-common/model/kyc-form/needs-analysis/structure/accidentHealth/HospitalisationStructure'

import { RetirementStructure } from '@company-common/model/kyc-form/needs-analysis/structure/savingInvestment/RetirementStructure'
import { SavingsStructure } from '@company-common/model/kyc-form/needs-analysis/structure/savingInvestment/SavingsStructure'
import { InvestmentStructure } from '@company-common/model/kyc-form/needs-analysis/structure/savingInvestment/InvestmentStructure'
import { EducationStructure } from '@company-common/model/kyc-form/needs-analysis/structure/savingInvestment/EducationStructure'
import { OthersStructure } from '@company-common/model/kyc-form/needs-analysis/structure/others/OthersStructure'

import NeedsBase, { priorityLevel } from '@master/model/kyc-form/need-analysis/NeedsBase'
import KYCForm from '@company-common/model/kyc-form/KYCForm'
import { isEmpty } from 'lodash/fp'

import CustomExistingPortfolioV2 from '@company-common/model/kyc-form/existing-plans/CustomExistingPortfolioV2'
import { PriorityStructure } from '@master/model/kyc-form/need-analysis/PrioritiesNeeds2'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import { YearsSupportMap } from '@company-common/model/kyc-form/CustomDependant'

export class ThePrioritiesNeeds {
  protected prioritiesStructure: PriorityStructure<KYCClient, CustomExistingPortfolioV2>[] = [
    {
      type: 'Protection',
      sharedNoteKey: 'deathProtectionNeeds',
      items: [
        {
          id: 'P1',
          key: 'deathProtectionNeeds',
          name: 'Death Protection',
          plan: 'lifeProtection',
          needs: 'Death',
          structure: DeathProtectionStructure,
          defaultValue: this.getDPDefaultValue
        },
        {
          id: 'P2',
          key: 'disabilityProtectionNeeds',
          name: 'Total Permanent Disability Protection',
          plan: 'disabilityProtection',
          needs: 'Disability (TPD)',
          structure: DisabilityProtectionStructure,
          defaultValue: this.getTPDDefaultValue
        },
        {
          id: 'P3',
          key: 'criticalIllnessNeeds',
          name: 'Critical Illness Protection',
          plan: 'criticalIllnessProtection',
          needs: 'Critical Illness',
          structure: CriticalIllnessProtectionStructure,
          defaultValue: this.getCIDefaultValue
        },
        {
          id: 'P4',
          key: 'disabilityIncomeNeeds',
          name: 'Disability Income Protection',
          plan: 'disabilityIncome',
          needs: 'Loss of income due to illness or disability',
          structure: DisabilityIncomeProtectionStructure,
          defaultValue: this.getDIDefaultValue
        },
        {
          id: 'P5',
          key: 'longTermProtectionClient',
          name: 'Long Term Care Protection',
          needs: 'Old Age Disabilities',
          structure: LongTermCareProtectionStructure
        }
      ]
    },
    {
      type: 'Accident & Health',
      sharedNoteKey: 'hospitalizationNeeds',
      items: [
        {
          id: 'H1',
          key: 'personalAccidentNeeds',
          name: 'Personal Accident',
          needs: 'Personal Accident',
          plan: 'accidentProtection',
          defaultValue: this.getPADefaultValue,
          structure: PersonalAccidentStructure
        },
        {
          id: 'H2',
          key: 'hospitalizationNeeds',
          name: 'Hospitalisation Expenses',
          needs: 'Hospitalisation Expenses',
          structure: HospitalisationStructure
        }
      ]
    },
    {
      type: 'Saving & Investment',
      items: [
        {
          id: 'S1',
          key: 'retirementNeeds',
          name: 'Retirement',
          needs: 'Retirement',
          plan: 'maturityValue',
          structure: RetirementStructure,
          defaultValue: this.getRetirementDefaultValue
        },
        {
          id: 'S2',
          key: 'savingsNeeds',
          name: 'Savings',
          needs: 'Savings and Investment',
          structure: SavingsStructure
        },
        {
          id: 'S3',
          key: 'investmentNeeds',
          name: 'Investment',
          needs: 'Savings and Investment',
          structure: InvestmentStructure
        },
        {
          id: 'S4',
          key: 'educationNeeds',
          name: 'Education Planning',
          needs: 'Children\'s Education',
          structure: EducationStructure,
          dataDetail: 'educationNeedsDetails',
          optionsDropDown: true
        }
      ]
    },
    {
      type: 'Others',
      sharedNoteKey: 'other1Needs',
      items: [
        {
          id: 'M1',
          key: 'other1Needs',
          name: 'Others 1',
          structure: OthersStructure,
          optionsDropDown: true
        },
        {
          id: 'M2',
          key: 'other2Needs',
          name: 'Others 2',
          structure: OthersStructure,
          optionsDropDown: true
        }
      ]
    }
  ]

  protected prioritiesStructureV2: any[] = [
    {
      type: 'A. Protection',
      items: [
        {
          key: 'FoProtectingPriority',
          name: 'Protecting my Family / Assets in the event of Death'
        },
        {
          key: 'FoDisabilityPriority',
          name: 'Providing for Disability'
        },
        {
          key: 'FoCriticalIllenessPriority',
          name: 'Providing for  Critical Illnesses'
        },
        {
          key: 'FoDisabilityIncomePriority',
          name: 'Providing for  Long Term Care / Disability Income'
        },
        {
          key: 'FoMedicalCostPriority',
          name: 'Providing for Hospital, Surgical, Medical Costs'
        },
        {
          key: 'FoPregnencyRelatedePriority',
          name: 'Providing for Pregnancy Complication / Pregnancy-Related Death'
        },
        {
          key: 'FoPersonalAccidentPriority',
          name: 'Providing for Personal Accident'
        },
        {
          key: 'FoProtectionNeedsPriority',
          name: "Providing for Dependant's Protection Needs"
        },
      ]
    },
    {
      type: 'B. Wealth Accumulation',
      items: [
        {
          key: 'FoRetirementPriority',
          name: 'Planning for Retirement'
        },
        {
          key: 'FoChildrenEducationPriority',
          name: "Providing for Children's Education"
        },
        {
          key: 'FoOtherPurposePriority',
          name: 'Saving for Other Purposes'
        }
      ]
    },
    {
      type: 'C. Wealth Preservation / Distribution*',
      subtitle: "SRS and Estate planning are financial planning tools. If SRS and/or Estate planning is/are selected, please select at least one (1) of the Financial Objectives in 'Protection' or 'Wealth Accumulation'  where you wish to apply the financial planning tool(s).",
      items: [
        {
          key: 'FoRetirementSchemePriority',
          name: 'Supplementary Retirement Scheme (SRS) Planning'
        },
        {
          key: 'FoEstatePlanningPriority',
          name: 'Estate Planning (e.g. Will, Trusts, CPF Nomination, Lasting power of Attorney)'
        }
      ]
    },
  ]

  getPrioritiesStructure () {
    return this.prioritiesStructure
  }

  getPrioritiesStructureV2 () {
    return this.prioritiesStructureV2
  }

  getProrityLevel () {
    return priorityLevel
  }

  getPriorities () {
    return this.prioritiesStructure.flatMap(({ items }) => items)
  }

  getAllNeedAnalysisId () {
    return this.getPriorities().map(({ id }) => id)
  }

  getNeedAnalysisWithId (targetId) {
    return this.getPriorities().find(need => need.id === targetId)
  }

  getFilteredPriorities (kycForm: KYCForm) {
    const includeClient2 = kycForm.hasClient2
    return this.getPriorities()
    // filter all need that client selected to plan
      .filter(({ key }) => {
        const client1Plan = (kycForm.client1[key] as NeedsBase).plan
        const client2Plan = includeClient2 ? (kycForm.client2[key] as NeedsBase).plan : false
        return client1Plan || client2Plan
      })
  }

  getNeeds () {
    const needs = this.prioritiesStructure
    // return all items in list
      .flatMap(({ items }) => items)
      .map(needs => needs.needs)
      .filter(needs => !isEmpty(needs))
    return [...(new Set(needs))]
  }

  getPriorityId (targetKey) {
    const needs = this.prioritiesStructure
      .flatMap(({ items }) => items).find(need => need.key === targetKey)
    return needs?.id
  }

  // this function return the priority list that has been checked on identifying priority view
  getSelectedPriorityList (type: string, kycForm: KYCForm, selectedClient) {
    const client = selectedClient === 'Client 1' ? 'client1' : 'client2'

    const priorityInType = this.prioritiesStructure
      .find(priority => priority.type === type).items
      .filter(item => {
        const needsModal = kycForm[client][item.key] as NeedsBase
        return needsModal.plan
      })
    return priorityInType
  }

  getSelectedPlan (key) {
    return this.prioritiesStructure
      .flatMap(({ items }) => items)
      .find(plan => plan.key === key)
  }

  getProfolioPrefix (selectedClient: string) {
    if (!selectedClient) return
    return selectedClient === 'Client 1' ? 'c1' : 'c2'
  }

  getClientPrefix (selectedClient: string) {
    if (!selectedClient) return
    return selectedClient === 'Client 1' ? 'client1' : 'client2'
  }

  getAvailable1DefaultValue (kycForm, planKey, portfolioPrefix) {
    return kycForm.existingPortfolio.summary?.existingPortfolioSummary[portfolioPrefix][planKey]
  }

  getPlanStructureWithDefaultValue (key, selectedClient, kycForm) {
    const selectedPlan = this.getSelectedPlan(key)
    const portfolioPrefix = this.getProfolioPrefix(selectedClient)
    const clientPrefix = this.getClientPrefix(selectedClient)

    const defaultValue = selectedPlan.defaultValue ? selectedPlan.defaultValue(kycForm, selectedPlan.plan, portfolioPrefix, clientPrefix) : undefined
    return selectedPlan.structure(defaultValue)
  }

  getPlanStructureWithOptions (key, options) {
    const selectedPlan = this.getSelectedPlan(key)
    return selectedPlan.structure(options)
  }

  getDPDefaultValue (kycForm, planKey, portfolioPrefix, clientPrefix) {
    // total liabilities (required1) = networth's total liabilities
    // Existing life insurance (available1) = Your Current Plans -> Summary of Existing Policy Portfolio (DE)
    // -Other funds available (available2) = Net Worth -> Invested Asset + Cash/Cash Equivalents
    const considerExistingPortfolio = kycForm.existingPortfolio.declaration.review === 'yes'

    const required1 = kycForm[clientPrefix].netWorth?.totalLiabilities
    const available1 = considerExistingPortfolio ? kycForm.existingPortfolio.summary?.existingPortfolioSummary[portfolioPrefix][planKey] : 0
    const available2 = (kycForm[clientPrefix].netWorth?.totalInvestedAssets || 0) + (kycForm[clientPrefix].netWorth?.totalCashAssets || 0)

    const totalAnnualIncome = kycForm[clientPrefix].cashFlow?.totalAnnualIncome

    return { available1: available1 || 0, required1: required1 || 0, available2: available2 || 0, annualIncome: totalAnnualIncome }
  }

  getTPDDefaultValue (kycForm, planKey, portfolioPrefix, clientPrefix) {
    // Existing disability benefits (available1) = Your Current Plans -> Summary of Existing Policy Portfolio (TPD)
    // -Other funds available (available2) = Net Worth -> (Total Invested Assets excluding CPF OA and CPF SA) + Total Cash/Cash Equivalents
    const considerExistingPortfolio = kycForm.existingPortfolio.declaration.review === 'yes'

    const available1 = considerExistingPortfolio ? kycForm.existingPortfolio.summary?.existingPortfolioSummary[portfolioPrefix][planKey] : 0
    const available2 = (kycForm[clientPrefix].netWorth?.totalInvestedAssets || 0) + (kycForm[clientPrefix].netWorth?.totalCashAssets || 0) - (kycForm[clientPrefix].netWorth?.cpfOrdinary || 0) - (kycForm[clientPrefix].netWorth?.cpfSpecial || 0)

    const totalAnnualIncome = kycForm[clientPrefix].cashFlow?.totalAnnualIncome

    return { available1: available1 || 0, available2: available2 || 0, annualIncome: totalAnnualIncome }
  }

  getCIDefaultValue (kycForm, planKey, portfolioPrefix, clientPrefix) {
    // -Existing CI Insurance (available 1) = Your Current Plans -> Summary of Existing Policy Portfolio(CI)
    // Other funds available (available2) = Net Worth -> CPF MA + Total Cash/Cash Equivalents
    const considerExistingPortfolio = kycForm.existingPortfolio.declaration.review === 'yes'

    const available1 = considerExistingPortfolio ? kycForm.existingPortfolio.summary?.existingPortfolioSummary[portfolioPrefix][planKey] : 0
    const available2 = (kycForm[clientPrefix].netWorth?.cpfMedisave || 0) + (kycForm[clientPrefix].netWorth?.totalCashAssets || 0)

    const totalAnnualIncome = kycForm[clientPrefix].cashFlow?.totalAnnualIncome

    return { available1: available1 || 0, available2: available2 || 0, annualIncome: totalAnnualIncome }
  }

  getDIDefaultValue (kycForm, planKey, portfolioPrefix, clientPrefix) {
    // -Existing CI Insurance (available 1) = Your Current Plans -> Summary of Existing Policy Portfolio(DI)
    // Other funds available (available2) = Net Worth -> Total Cash/Cash Equivalents
    const considerExistingPortfolio = kycForm.existingPortfolio.declaration.review === 'yes'

    const available1 = considerExistingPortfolio ? kycForm.existingPortfolio.summary?.existingPortfolioSummary[portfolioPrefix][planKey] : 0
    const available2 = kycForm[clientPrefix].netWorth?.totalCashAssets

    const totalAnnualIncome = kycForm[clientPrefix].cashFlow?.totalAnnualIncome

    return { available1: available1 || 0, available2: available2 || 0, annualIncome: totalAnnualIncome }
  }

  getPADefaultValue (kycForm, planKey, portfolioPrefix) {
    // --Current resources/provisions (available 1) = Your Current Plans -> Summary of Existing Policy Portfolio(PA)
    const considerExistingPortfolio = kycForm.existingPortfolio.declaration.review === 'yes'

    const available1 = considerExistingPortfolio ? kycForm.existingPortfolio.summary?.existingPortfolioSummary[portfolioPrefix][planKey] : 0
    return { available1: available1 || 0 }
  }

  getRetirementDefaultValue (kycForm, planKey, portfolioPrefix, clientPrefix) {
    // Maturity value of insurance plan(s) at retirement (available2) = Your Current Plans -> Summary of Existing Policy Portfolio(MV)
    // Years to retirement (years) = Client Age - Personal Details Intended Retirement Age
    const considerExistingPortfolio = kycForm.existingPortfolio.declaration.review === 'yes'

    const available1 = considerExistingPortfolio ? kycForm.existingPortfolio.summary?.existingPortfolioSummary[portfolioPrefix][planKey] : 0
    const incomePv = kycForm.clientAssumptions.adjustmentsAssumptionsRetirement / 100 * kycForm[clientPrefix].cashFlow.totalIncome
    const retirementAge = kycForm[clientPrefix].personalDetails.intendedRetirementAge
    const year = retirementAge - kycForm[clientPrefix].personalDetails.age
    const yearsToSupport = YearsSupportMap[kycForm[clientPrefix].personalDetails?.gender]?.year
    const duration = Math.max(0, (yearsToSupport - retirementAge))

    return { incomePv: incomePv, available2: available1, retirementYear: year, duration }
  }

  isArrayStructure (key: string) {
    const targetStructure = this.getSelectedPlan(key).structure()
    return targetStructure instanceof Array
  }
}
