/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdviserLicenceDto = {
    oid?: string;
    userOid: string;
    productLicence: string;
    status: AdviserLicenceDto.status;
    issueDate?: any;
    statusDate?: any;
    licenceName?: string;
}

export namespace AdviserLicenceDto {

    export enum status {
        ACTIVE = 'active',
        CANCELLED = 'cancelled',
        INACTIVE = 'inactive',
    }


}
