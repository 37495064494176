import { Meta } from '@master/model/common/Meta'
import { MaxChar } from '@master/annotation/MaxChar'

export default class PersonalDetailsMeta extends Meta {
  placeOfBirth = ''
  gender = ''
  nationality = ''
  nationalitySpecified = ''
  maritalStatus = ''
  race = ''
  immigrationNumber = ''
  employmentPassNumber = ''
  mobile = ''
  residentialNumber = ''
  email = ''
  residentialAddress = ''
  postalCode = ''

  bankName = '' // personal commission bank name

  @MaxChar(20)
  branchName = '' // personal commission bank branch name/ code

  @MaxChar(34)
  bankAccountNo = '' // personal commission bank account number

  @MaxChar(20)
  financingType = ''

  @MaxChar(50)
  reasons = ''

  paymentStructure = ''

  overridingBankCode = '' // overriding commission bank name

  @MaxChar(20)
  overridingBankBranchCode = '' // commission bank branch name/ code

  @MaxChar(34)
  overridingBankAccountNo = '' // commission bank account number
}
