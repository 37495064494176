import { Json } from '@master/annotation/Json'
import { RawKey } from '@master/annotation/RawKey'
import { Meta } from '@master/model/common/Meta'

export const header = [
  '_oid',
  'names',
  'fullname',
  'adviser_branch',
  'account_status',
  'systemrole',
  'company_code',
  'mas_repno',
  'email',
  'mobile',
  'adviser_products',
  'other_adviser_products',
  'facebook',
  'instagram',
  'linkedin'
]

export class UserMeta extends Meta {
  @RawKey('_oid')
  oid = ''

  names = ''
  nric = ''
  fullname = ''
  username = ''
  preferredName = ''
  systemrole = ''
  email = ''
  mobile: number = undefined
  companyCode = ''
  masRepno = ''
  adviserBranch = ''
  account_status = ''

  @Json()
  adviserProducts: string[] = []

  otherAdviserProducts = ''
  idDocType = ''
  idNumber = ''
  withExp = ''
  groups: any[] = []
  oldPassword = ''
  newPassword = ''
  confirmPassword = ''

  facebook = ''
  instagram = ''
  linkedin = ''

  suspensionManagerFromDate = ''
  suspensionManagerToDate = ''
  userCompanyInfo?: any
  userPersonalInfo?: any
  // ctm = ''
  // isDeleted = ''
  // oid = ''
  // pky = ''
  // status = ''
  systemRole?: string = ''
  status: string
  // userCompanyInfo = {}
  // userPersonalInfo = {}
  // username = ''
  // utm = ''
}
