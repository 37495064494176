import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import KYCPdfSubmission from '@/views/kyc-pdf/KYCPdfSubmission'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { IDateService } from '@master/services/IDateService'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { container, cid } from 'inversify-props'

const dateService = container.get<IDateService>(cid.IDateService)
const projectSettings = container.get<IProjectSettings>(cid.IProjectSettings)
const riskRatingService = container.get<IRiskRatingService>(cid.IRiskRatingService)

@Module({
  namespaced: true,
  name: 'KYCSubmissionStore'
})
export default class KYCSubmissionStore extends VuexModule {
  // Private variables
  private _kycPdf = new KYCPdfSubmission(dateService, projectSettings, riskRatingService)

  get kycPdf () {
    return this._kycPdf
  }

  get getPTCChecklist () {
    this._kycPdf.preTransactionalChecks.validate(this.context.rootState.KYCFormStore.computedValidationResult)
    return this._kycPdf.preTransactionalChecks.preTransactionalList
  }

  get isSuperiorVerified () {
    return this._kycPdf.selectedSupervisorOid !== null && typeof this._kycPdf.selectedSupervisorOid !== 'undefined' && this._kycPdf.selectedSupervisorOid !== ''
  }

  get isPTCVerified () {
    return this.getPTCChecklist.every(module => {
      return module.sections.every(section => {
        return section.isVerified()
      })
    })
  }

  @Mutation
  private setKycPdf (kycPdf: KYCPdfSubmission) {
    Object.assign(this._kycPdf, kycPdf)
  }

  @Action
  updateKycPDF () {
    this.context.commit('setKycPdf', this.context.rootState.KYCFormStore.kycForm)
  }

  @Action
  updateSuperiors (superiors: { supervisorOid: string; proxySupervisorOid: string }) {
    this._kycPdf.selectedSupervisorOid = superiors.supervisorOid
    this._kycPdf.selectedProxySupervisorOid = superiors.proxySupervisorOid
  }
}
