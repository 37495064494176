import result from 'lodash/result'
import filter from 'lodash/filter'
import map from 'lodash/map'
import pick from 'lodash/pick'
import min from 'lodash/min'
import max from 'lodash/max'
import moment from 'moment'

import { IProjectSettings } from '@master/config/IProjectSettings'
import { Ignore } from '@master/annotation/Ignore'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import { calculateROI, fv, sum, valueIsUndefined,formatLargeNumber } from '@master/model/kyc-form/need-analysis/math'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import store from "src/store/index";
import { Global, NA_ERROR_MSG } from '../utils/constants'
import { isEmpty } from 'class-validator'

export default class PlanningForChildrenEducationModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings

  selectedClient?:  KYCClient

  isProtection = true
  childName?: string = undefined
  cachedChildName?: string = undefined
  noOfYearsToUni?: number = undefined
  cachedNoOfYearsToUni?: number = undefined
  annualCost?: number = undefined
  educationInvRate?: number = undefined
  cachedEducationInvRate?: number = undefined
  courseDurationInYears?: number = undefined

  roiLICashValue?: number = undefined
  liCashValue?: number = undefined
  cachedLiCashValue?: number = undefined
  roiCashAssets?: number = undefined
  cashAssets?: number = undefined
  cachedCashAssets?: number = undefined
  roiInvestments?: number = undefined
  investments?: number = undefined
  cachedInvestments?: number = undefined
  roiOthers?: number = undefined
  othersDesc?: string = undefined
  othersAmt?: number = undefined
  additionalNotes: string = ''
  noOfYearsToUniReason?: string = undefined
  educationInvRateReason?: string = undefined
  liCashValueReason?: string = undefined

  noOfYearsToUniCR?:boolean = undefined
  educationInvRateCR?:boolean = undefined
  liCashValueCR?:boolean = undefined
  UNI_ENTRY_AGE_SON = Global.UNI_ENTRY_AGE_SON
  UNI_ENTRY_AGE_DAUGH = Global.UNI_ENTRY_AGE_DAUGH

  get fundsRequired() {
    if (valueIsUndefined(this.educationInvRate) && valueIsUndefined(this.noOfYearsToUni)) return 0
    // const totalCost = this.annualCost * this.courseDurationInYears;
    // const total = fv(this.educationInvRate, this.noOfYearsToUni, totalCost)
    // const totalIncludsE = total.toString().includes("e");
    // const finalTotalCost : any = total && totalIncludsE? formatLargeNumber(total) : total && !totalIncludsE? total : 0;
    // return finalTotalCost ;

    const inflationRateDecimal = this.educationInvRate / 100;
    const futureValue = this.annualCost * this.courseDurationInYears * Math.pow(1 + inflationRateDecimal, this.noOfYearsToUni);
    return parseFloat(futureValue.toFixed(2)) || 0;
  }

  get exResourceAllocated() {
    let cashAssetsTotal = this.cashAssets || 0;
    let investmentsTotal = this.investments || 0;
    let liCashValueTotal = this.liCashValue || 0;
    let othersAmtTotal = this.othersAmt || 0;
    const n = this.noOfYearsToUni || 0;

    let total = 0;
    if (!(this.roiCashAssets == 0 || valueIsUndefined(this.roiCashAssets))) {
      cashAssetsTotal = calculateROI(this.cashAssets, this.roiCashAssets, n)
    }
    if (!(this.roiInvestments === 0 || valueIsUndefined(this.roiInvestments))) {
      investmentsTotal = calculateROI(this.investments, this.roiInvestments, n)  
    }
    if (!(this.roiLICashValue == 0 || valueIsUndefined(this.roiLICashValue))) {
      liCashValueTotal = calculateROI(this.liCashValue, this.roiLICashValue, n)
    }
    if (!(this.roiOthers == 0 || valueIsUndefined(this.roiOthers))) {
      othersAmtTotal = calculateROI(this.othersAmt, this.roiOthers, n)
    }

    total = sum(investmentsTotal, liCashValueTotal, cashAssetsTotal, othersAmtTotal);
    return parseFloat(total?.toFixed(2));
  }

  get grandTotal() {
    return parseFloat((this.exResourceAllocated - this.fundsRequired).toFixed(2));
  }

  get isCompleted () {
    // if (this.childName) {
    //   const dependents: any[] = result(store, 'state.KYCFormStore.kycForm.client1DependantDetails.DDdepentants')
    //   const selectedChild = dependents.find(dependent => dependent.dependantOid === this.childName)
    //   const difference = moment().year() - moment(selectedChild.dob).year()
    //   if (difference !== this.incomeNoOfYears) {
    //     this.incomeNoOfYears = moment().year() - moment(selectedChild.dob).year()
    //   }
    // }
    let fundsRequiredSec = !!this.childName && this.noOfYearsToUni != null && this.annualCost != null && this.educationInvRate != null && this.courseDurationInYears != null
    if (fundsRequiredSec) {
      fundsRequiredSec = (this.othersDesc && this.othersAmt != null) || (!this.othersDesc && !this.othersAmt ) ? true : false
    }
    const reasonCheck = (this.noOfYearsToUniReasonStatus && !this.noOfYearsToUniReason)

    return fundsRequiredSec && !reasonCheck
  }

  set setSelectedClient (client) {
    this.selectedClient = client
  }

  get timeHorizons() {
    const timeHorizonMap = {
      1: [0, 3],
      2: [3, 5],
      3: [5, 7],
      4: [7, 10],
      5: [10, 50]
    }
    const riskProfilePortfolioList: any[] = map(filter(result(store, 'state.KYCFormStore.kycForm.listIRP') , 'enabled'), 'question2')
    const timeHorizons: number[] = Object.values(pick(timeHorizonMap, riskProfilePortfolioList)).flat()
    return timeHorizons;
  }

  get noOfYearsToUniReasonStatus() {
    const dependents: any[] = result(store, 'state.KYCFormStore.kycForm.client1DependantDetails.DDdepentants')
    const selectedChild = dependents?.find(dependent => dependent.dependantOid === this.childName)      
        const birthYear = new Date(selectedChild?.dob).getFullYear();
        const currentYear = new Date().getFullYear();
        const dependantAge = currentYear - birthYear;
        const UNI_ENTRY_AGE = selectedChild?.gender === "male" ? this.UNI_ENTRY_AGE_SON : this.UNI_ENTRY_AGE_DAUGH;
        const noOfYearsToUniCalc = UNI_ENTRY_AGE - dependantAge;

    let isNeedReason = false
    if (this.noOfYearsToUni < noOfYearsToUniCalc || this.noOfYearsToUni > noOfYearsToUniCalc) {
      isNeedReason = true
      if (this.cachedNoOfYearsToUni !== this.noOfYearsToUni) {
        this.cachedNoOfYearsToUni = this.noOfYearsToUni
        this.noOfYearsToUniCR = false
      }
    } else {
      this.cachedNoOfYearsToUni = this.noOfYearsToUni
      this.noOfYearsToUniReason = undefined
      this.noOfYearsToUniCR = false
    }
    return isNeedReason
  }

  get educationInvRateReasonStatus(){
    let isNeedReason = false
    if (this.educationInvRate < 0 || this.educationInvRate > 9) {
      isNeedReason = true
      if (this.cachedEducationInvRate !== this.educationInvRate) {
        this.cachedEducationInvRate = this.educationInvRate
        this.educationInvRateCR = false
      }
    } else {
      this.educationInvRateReason = undefined
      this.cachedEducationInvRate = this.educationInvRate
      this.educationInvRateCR = false
    }
    return isNeedReason
  }

   calculateTotalLifeInsuranceCash (data) {
    let total = 0;
     if (data?.dependants) {
      data.dependants.forEach(dependant => {
        if (dependant?.insuranceRecords) {
          total += dependant.insuranceRecords.reduce((sum, record) => sum + (record.cashValue || 0), 0);
        }
      });
    }
    return total;
  }
  get liCashValueReasonStatus () {
    let isNeedReason = false
    const exInsurance = this.selectedClient?.existingInsurence
    
    const totalDependantCash = this.calculateTotalLifeInsuranceCash(exInsurance)
    if (totalDependantCash) {

      if ( this.liCashValue < totalDependantCash) {
        isNeedReason = true
        if (this.cachedLiCashValue !== this.liCashValue) {
          this.cachedLiCashValue = this.liCashValue
          this.liCashValueCR = false
        }
      } else {
        this.liCashValueReason = undefined
        this.cachedLiCashValue = this.liCashValue
        this.liCashValueCR = false
      }
    }
    return isNeedReason
  }

  get liCashValueError () {
    const exInsurance = this.selectedClient?.existingInsurence
    
    const totalDependantCash = this.calculateTotalLifeInsuranceCash(exInsurance)
    if (totalDependantCash) {
      if (this.liCashValue > totalDependantCash) {
          this.liCashValueReason = undefined
          return NA_ERROR_MSG.TB
      } else {
          return null
      }
    }
  }

  resetAllFields (): void {
    super.resetAllFields()
    this.isProtection = true
  }
}