import { FNAStructure } from '@master/model/structure/FNAStructure'
import { CriticalIllnessProtectionClient } from '@master/model/kyc-form/need-analysis/protection/CriticalIllnessProtectionClient'

export function CriticalIllnessProtectionStructure (defaultValue: { available1: number; annualIncome: number }) {
  const structure: FNAStructure<CriticalIllnessProtectionClient> = {
    title: 'P3. Critical Illness Protection',
    data: [
      {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Desired annual income required to sustain lifestyle during illness ($)',
        key: 'income',
        type: 'currency',
        default: defaultValue?.annualIncome,
        validationMsg: 'Critical Illness Protection requires to indicate desired annual income.'
      }, {
        label: 'Number of years required',
        key: 'yearsRequired',
        validationMsg: 'Critical Illness Protection requires to indicate years required.'
      }, {
        label: 'Inflation adjusted rate return (%)',
        key: 'infAdjReturn',
        defaultTo: 'defaultRate',
        validationMsg: 'Critical Illness Protection requires to indicate inflation adjusted rate return.'
      }, {
        label: 'Lump sum required to maintain lifestyle (PV) ($)',
        key: 'fundsRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '+ Cost of medical treatment ($)',
        key: 'required1',
        type: 'currency',
        validationMsg: 'Critical Illness Protection requires to indicate cost of medical treatment.'
      }, {
        label: '+ Other funding needs ($)',
        key: 'required2',
        type: 'currency',
        validationMsg: 'Critical Illness Protection requires to indicate other funding needs.'
      }, {
        label: 'Estimated amount required for illness ($)',
        key: 'totalRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '- Existing CI insurance ($)',
        key: 'available1',
        type: 'currency',
        default: defaultValue?.available1,
        validationMsg: 'Critical Illness Protection requires to indicate existing CI insurance.'
      }, {
        label: '- Other funds available ($)',
        key: 'available2',
        type: 'currency',
        validationMsg: 'Critical Illness Protection requires to indicate other funds available.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
