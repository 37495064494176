import { Ignore } from '@master/annotation/Ignore'
import { Json, needToConvertJson } from '@master/annotation/Json'
import { getRawKey, RawKey } from '@master/annotation/RawKey'
import { Base } from '@master/model/Base'
import { Meta } from '@master/model/common/Meta'
import { CIS } from './metadata/CIS'
import { EmployeeBenefit } from './metadata/EmployeeBenefit'
import { FBS } from './metadata/FBS'
import { GiHomeContents } from './metadata/GiHomeContents'
import { GiMotor } from './metadata/GiMotor'
import { GiOthers } from './metadata/GiOthers'
import { GiTravel } from './metadata/GiTravel'
import { LifeInsurance } from './metadata/LifeInsurance'
import { Others } from './metadata/Others'

export const StatusOptions = [
  { label: 'Application', value: 'application' },
  { label: 'In-force', value: 'in-force' },
  { label: 'Withdrawn / Surrender', value: 'withdrawnsurrender' },
  { label: 'Lapsed', value: 'lapsed' },
  { label: 'Free look', value: 'freelook' },
  { label: 'Not taken', value: 'nottaken' },
  { label: 'Approved by Ops', value: 'approved_by_ops' },
  { label: 'Pending Approval', value: 'pending' },
  { label: 'Rejected by Ops', value: 'rejected' }
]

export const header = [
  '_oid',
  'client_oid',
  'servicing_oid',
  'owners_name',
  'owners_nric',
  'name',
  'policy_number',
  'type',
  'insured_name',
  'status',
  'business_id'
]
export const ProductTypeOptions = [
  {
    label: 'Life Insurance',
    value: 'lifeinsurance',
    model: new LifeInsurance()
  },
  {
    label: 'Collective Investment Scheme',
    value: 'collectiveinvestmentscheme',
    model: new CIS()
  },
  {
    label: 'GI - Motors',
    value: 'gi-motors',
    model: new GiMotor()
  },
  {
    label: 'GI - Travel',
    value: 'gi-travel',
    model: new GiTravel()
  },
  {
    label: 'GI - Home Contents',
    value: 'gi-homecontents',
    model: new GiHomeContents()
  },
  {
    label: 'GI - Others',
    value: 'gi-others',
    model: new GiOthers()
  },
  {
    label: 'Employee Benefits',
    value: 'employeebenefits',
    model: new EmployeeBenefit()
  },
  {
    label: 'Fee Based Service',
    value: 'feebasedservice',
    model: new FBS()
  },
  {
    label: 'Others',
    value: 'others',
    model: new Others()
  }
]
export type ProductsMetadata = LifeInsurance & CIS & GiMotor & GiTravel & GiHomeContents & GiOthers & EmployeeBenefit & FBS;
export class ClientProductMeta extends Meta {
  @RawKey('_oid')
  oid = undefined

  clientOid = undefined
  adviserOid = undefined
  introducerOid = undefined
  introducerPct = undefined
  introducerIfam = undefined
  servicingOid = undefined
  servicingPct = undefined
  servicingIfam = undefined
  vestingOid = undefined
  vestingPct = undefined
  vestingIfam = undefined
  ownersOid = undefined
  ownersName = undefined
  ownersNric = undefined
  insuredTitle = undefined
  insuredName = undefined
  insuredNric = undefined
  insuredTitleTwo = undefined
  insuredNameTwo = undefined
  insuredNricTwo = undefined
  productOid = undefined
  name = undefined
  productTypeDetailed = undefined
  productCategory = undefined
  taggedKyc = undefined
  type = undefined
  policyNumber = undefined
  businessId = undefined
  principal = undefined
  status = undefined
  applicationDate = undefined
  opsApprovalDate = undefined
  approvedDate = undefined
  startDate = undefined
  endDate = undefined
  bscClawbackStatus = undefined
  bscClawbackAmount = undefined
  clientRebateStatus = undefined
  clientRebateAmount = undefined
  clientAiStatus = undefined

  @Json()
  productMetadata = undefined

  @Json()
  clientMetadata = undefined

  creationType = undefined
  faceToFace = undefined
  policyProfileRemarks = undefined
  level1ComOid = undefined
  level2ComOid = undefined
  level3ComOid = undefined
  level4ComOid = undefined
  level1ComPct = undefined
  level2ComPct = undefined
  level3ComPct = undefined
  level4ComPct = undefined

  extractMetaObject (data: { meta: {}; _oid: string }) {
    Object.keys(this)
      .forEach(key => {
        if (key === 'productMetadata') return
        const rawKey = getRawKey(this, key) || this.snakeCaseKey(key)
        let value = data.meta[rawKey]

        if (needToConvertJson(this, key) && value !== undefined) {
          value = JSON.parse(value as string)
        }
        if (this[key] instanceof Base) {
          this[key].extractAndAssignData(value)
        } else {
          this[key] = value
        }
      })
    const metaDataRawValue = data.meta[getRawKey(this, 'productMetadata') || this.snakeCaseKey('productMetadata')]
    this.extractMetaData(metaDataRawValue)
    this.oid = data._oid
    this.afterAssign()
  }

  extractMetaData (value) {
    if (needToConvertJson(this, 'productMetadata') && value !== undefined) {
      value = JSON.parse(value as string)
    }
    const productSpecificModel = ProductTypeOptions.find(typ => typ.value === this.type)?.model
    if (productSpecificModel) {
      productSpecificModel.extractAndAssignData(value)
      this.productMetadata = productSpecificModel as ProductsMetadata
    } else {
      throw Error(`Unknown product type : ${this.type}`)
    }
  }

  convertToRawData (keys?: string[]) {
    const raw = super.convertToRawData(keys)
    return raw
  }

  @Ignore()
  get typeDisplay () {
    return ProductTypeOptions.find(topt => topt.value === this.type)?.label
  }

  @Ignore()
  get statusDisplay () {
    return StatusOptions.find(opt => opt.value === this.status)?.label
  }
}
