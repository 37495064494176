import { Ignore } from '@master/annotation/Ignore'
import { TypeBase } from '@master/model/TypeBase'
import { IDateService } from '@master/services/IDateService'
import { inject } from 'inversify-props'

export class ClientProductILPRP extends TypeBase {
  @Ignore()
  @inject()
  protected dateService: IDateService

  constructor (idx: number) {
    super(`ilp_${idx}_regular`)
  }

  name = ''
  amt = undefined
  freq = undefined
  approvedDate = undefined
  endDate = undefined
  unitBalance = undefined
  riskClass = undefined
  cash = undefined
  stock = undefined
  bonds = undefined

  afterExtractData (data) {
    data.unitBalance = Number(data.unitBalance)
    data.premium = Number(data.premium)
    data.cash = Number(data.cash)
    data.stock = Number(data.stock)
    data.bonds = Number(data.bonds)
    // existing data store date string
    data.approvedDate = new Date(data.approvedDate)?.getTime()
    data.endDate = new Date(data.endDate)?.getTime()
    return data
  }

  formattedDate (unixTime: number | string) {
    return this.dateService.format(Number(unixTime), 'YYYY-MM-DD')
  }

  convertToRawData () {
    const raw = super.convertToRawData()
    // special handling to store the date as string ( existing data )
    raw[this.snakeCaseKey('approvedDate')] = this.formattedDate(raw[this.snakeCaseKey('approvedDate')])
    raw[this.snakeCaseKey('endDate')] = this.formattedDate(raw[this.snakeCaseKey('endDate')])
    return raw
  }
}
