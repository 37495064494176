import { ProductMetaData } from '../ProductMetaData'

export const SourceOfFundsOptions = [
  { value: 'cash', label: 'Cash' },
  { value: 'cpf-oa', label: 'CPF-OA' },
  { value: 'cpf-sa', label: 'CPF-SA' },
  { value: 'cpf-ma', label: 'CPF-MA' },
  { value: 'srs', label: 'SRS' }
]
export class CIS extends ProductMetaData {
  investmentAmount = undefined
  onshoreOffshore = undefined
  upfrontFees = undefined
  transactionFees = undefined
  sourceOfFund = undefined
  upfrontComm = undefined

  afterExtractData (data) {
    data.investmentAmount = Number(data.investmentAmount)
    data.upfrontFees = Number(data.upfrontFees)
    data.transactionFees = Number(data.transactionFees)
    data.upfrontComm = Number(data.upfrontComm)
    return data
  }
}
