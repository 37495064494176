/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CpdClassesRecordDto } from '../models/CpdClassesRecordDto';
import type { CpdClassListResponseDTO } from '../models/CpdClassListResponseDTO';
import type { CpdClassRecordUpsertDTO } from '../models/CpdClassRecordUpsertDTO';
import { request as __request } from '@openapi/request';

export class CpdClassesRecordService {

    /**
     * @param courseOid
     * @param classOids
     * @param classIds
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns CpdClassListResponseDTO Retrieve the list of CPD classes records
     * @throws ApiError
     */
    public static async retrieveCpdClassesList(
        courseOid: string,
        classOids: Array<string>,
        classIds: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<CpdClassListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd-classes/list`,
            query: {
                'courseOid': courseOid,
                'classOids': classOids,
                'classIds': classIds,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param classOid
     * @returns CpdClassesRecordDto Retrieve a specific CPD class record
     * @throws ApiError
     */
    public static async retrieveCpdClassesRecord(
        classOid: string,
    ): Promise<CpdClassesRecordDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd-classes/${classOid}`,
        });
        return result.body;
    }

    /**
     * @param classOid
     * @returns any Deletes a CPD classes record (soft delete)
     * @throws ApiError
     */
    public static async deleteCpdMasterRecord(
        classOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/cpd-classes/${classOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any Creates CPD classes record(s)
     * @throws ApiError
     */
    public static async createCpdClassesRecords(
        requestBody: CpdClassRecordUpsertDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-classes`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CpdClassesRecordDto Updates a CPD class record
     * @throws ApiError
     */
    public static async updateCpdClassesRecord(
        requestBody: CpdClassesRecordDto,
    ): Promise<CpdClassesRecordDto> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/cpd-classes`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any Validate CPD classes import
     * @throws ApiError
     */
    public static async validateCpdClassesCsv(
        requestBody: Array<CpdClassesRecordDto>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-classes/import/validate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CpdClassesRecordDto Import CPD classes with csv
     * @throws ApiError
     */
    public static async importCpdClassesCsv(
        requestBody: Array<CpdClassesRecordDto>,
    ): Promise<Array<CpdClassesRecordDto>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-classes/import/csv`,
            body: requestBody,
        });
        return result.body;
    }

}