import { FNAStructure } from '@master/model/structure/FNAStructure'
import DeathProtectionClient from '@master/model/kyc-form/need-analysis/protection/DeathProtectionClient'

export function DeathProtectionStructure (defaultValue: { available1: number; available2: number; required1: number; annualIncome: number }) {
  const structure: FNAStructure<DeathProtectionClient> = {
    title: 'P1. Death Protection',
    data: [
      {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Desired annual income required to cover family expenses ($)',
        key: 'income',
        type: 'currency',
        default: defaultValue?.annualIncome,
        validationMsg: 'Death Protection requires to indicate annual income.'
      }, {
        label: 'Number of years required',
        key: 'yearsRequired',
        validationMsg: 'Death Protection requires to indicate years required.'
      }, {
        label: 'Inflation adjusted rate return (%)',
        key: 'infAdjReturn',
        defaultTo: 'defaultRate',
        validationMsg: 'Death Protection requires to indicate inflation adjusted rate return.'
      }, {
        label: 'Funds required to cover income(PV) ($)',
        key: 'fundsRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '+ Total liabilities ($)',
        key: 'required1',
        type: 'currency',
        default: defaultValue?.required1,
        validationMsg: 'Death Protection requires to indicate total liabilities.'
      }, {
        label: '+ Final expenses (includes funeral expenses, last hospitalization bill) ($)',
        key: 'required2',
        type: 'currency',
        validationMsg: 'Death Protection requires to indicate final expenses.'
      }, {
        label: '+ Other funding needs: ($)',
        key: 'required3',
        type: 'currency',
        validationMsg: 'Death Protection requires to indicate others funding needs.'
      }, {
        label: 'Total required ($)',
        key: 'totalRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '- Existing life insurance ($)',
        key: 'available1',
        type: 'currency',
        default: defaultValue?.available1,
        validationMsg: 'Death Protection requires to indicate existing life insurance.'
      }, {
        label: '- Other funds available ($)',
        key: 'available2',
        type: 'currency',
        default: defaultValue?.available2,
        validationMsg: 'Death Protection requires to indicate other funds available.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
