import { sum } from './../math'
import NeedsBase from '../NeedsBase'
/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class LongTermProtectionClient extends NeedsBase {
  name = 'Long Term Care Protection'
  needs = 'Old Age Disabilities'
  isProtection = true
  amountNeeded?: number = undefined
  period?: number = undefined

   // required
   required1?: number = undefined
   required2?: number = undefined
   required3?: number = undefined
   required4?: number = undefined
   required5?: number = undefined

   // available
   available1?: number = undefined
   available2?: number = undefined
   available3?: number = undefined
   available4?: number = undefined
   available5?: number = undefined

  additionalNotes = ''

  get sumAvailable () {
    return sum(this.available1, this.available2, this.available3, this.available4, this.available5)
  }

  get sumRequired () {
    return sum(this.required1, this.required2, this.required3, this.required4, this.required5)
  }

  get annualAmountNeeded () {
    return this.amountNeeded * 12
  }

  get surplusShortfall () {
    return sum(this.sumAvailable, -(this.annualAmountNeeded * this.period))
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 'p5') {
    super(`${type}_${id}`)
  }

  resetAllFields (): void {
    super.resetAllFields()
    this.isProtection = true
  }
}
