import { FNAStructure } from '@master/model/structure/FNAStructure'
import InvestmentNeeds from '@master/model/kyc-form/need-analysis/savings/InvestmentNeeds'

export function InvestmentStructure () {
  const structure: FNAStructure<InvestmentNeeds> = {
    title: 'S3. Investment',
    data: [
      {
        label: 'What are you planning for',
        key: 'planning',
        type: 'text',
        ejsClass: 'ejs-plan-div',
        validationMsg: 'Investment requires to indicate what are you planning for.'
      }, {
        label: 'Accumulation period (Years)',
        key: 'period',
        validationMsg: 'Investment requires to indicate accumulation period (Years).'
      }, {
        label: 'Amount needed (FV) ($)',
        key: 'amountFv',
        type: 'currency',
        validationMsg: 'Investment requires to indicate amount needed (FV).'
      }, {
        label: 'Value of fund to be used ($)',
        key: 'fundValue',
        type: 'currency',
        validationMsg: 'Investment requires to indicate value of fund to be used.'
      }, {
        label: 'Interest rate (%)',
        key: 'interestRate',
        defaultTo: 'defaultRate',
        validationMsg: 'Investment requires to indicate interest rate.'
      }, {
        label: '- Total funds available (FV) ($)',
        key: 'totalFundsFv',
        type: 'currency',
        disabled: true
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
