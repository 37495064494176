/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReqESignBody } from '../models/ReqESignBody';
import type { SaveSignerBody } from '../models/SaveSignerBody';
import { request as __request } from '@openapi/request';

export class EsignService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async checkEnabled(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esign/checkEnabled`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async retrieveQuotaForAll(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esign/reqEQuota`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async setupESigners(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esign/setupSigners`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async clearESign(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esign/clearESign`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getSignUrl(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esign/getSignURL`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async reqDocStat(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esign/reqDocStat`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async getDocFile(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/esign/getDocFile`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async saveSigners(
        requestBody: SaveSignerBody,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/esign/saveSigners`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async reqESign(
        requestBody: ReqESignBody,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/esign/reqESign`,
            body: requestBody,
        });
        return result.body;
    }

}