import { RouteRecordRaw } from 'vue-router'

export const cpdRoutes = ((): RouteRecordRaw[] => {
  return [
    {
      path: '/cpd/summary',
      name: 'cpd-summary',
      component: () => import('@core/pages/cpd/summary/CpdSummary.vue'),
      meta: {
        subject: ['cpdAttendance', 'cpdClasses', 'cpdMaster']
      }
    },
    {
      path: '/cpd/course/:courseId',
      name: 'CPD Course',
      component: () => import('@/views/cpd/CpdParentPage.vue'),
      meta: {
        subject: ['cpdAttendance', 'cpdClasses', 'cpdMaster']
      },
      redirect: { name: 'Course Overview' },
      children: [
        {
          path: 'overview',
          name: 'Course Overview',
          component: () => import('@core/pages/cpd/page/CpdCourse.vue')
        },
        {
          path: 'classes',
          name: 'Classes',
          component: () => import('@core/pages/cpd/page/CpdClassPage.vue')
        }
      ]
    }
  ]
})()
