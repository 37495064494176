import { ExpandableArray } from '@core/annotation/ExpandableArray'
import ActivityCard from '@core/pages/activity/model/ActivityCard'
import { Ignore } from '@master/annotation/Ignore'
import { FormBase } from '../FormBase'

export const DeclarationOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
]
export const ChannelOptions = [
  { label: '', value: '' },
  { label: 'Career Fair', value: 'fair' },
  { label: 'Job Portal', value: 'portal' },
  { label: 'Company\'s Website', value: 'website' },
  { label: 'Magazine', value: 'magazine' },
  { label: 'Newspaper', value: 'newspaper' },
  { label: 'Others', value: 'other' }
]
export class BisoDeclaration {
  bi: typeof DeclarationOptions[number]['value'] = undefined
  biEntityCount: number = undefined
  own: typeof DeclarationOptions[number]['value'] = undefined
  ownEntityCount: number = undefined
  connectedBi: typeof DeclarationOptions[number]['value'] = undefined
  connectedOwn: typeof DeclarationOptions[number]['value'] = undefined
  connectedOwnEntityCount: number = undefined

  @Ignore()
  @ExpandableArray({ maxSize: 6, clone: () => new ActivityCard() })
  uploadForms: ActivityCard[] = Array(1).fill(null).map(() => new ActivityCard())
}
export class ReferenceDeclaration {
  byRep: typeof DeclarationOptions[number]['value'] = undefined
  repName = ''
  channel = ''
  channelSpecified = ''
}
export class FinancialActivityDeclaration {
  conducted: typeof DeclarationOptions[number]['value'] = undefined
  prevRepCode = ''
  noRepCode: typeof DeclarationOptions[number]['value'] = undefined
  medisaveOs: typeof DeclarationOptions[number]['value'] = undefined
  basedOverseas: typeof DeclarationOptions[number]['value'] = undefined
  deedPoll: typeof DeclarationOptions[number]['value'] = undefined
}

export class ConnectedPerson {
  name: string;
  nric: string;
  relationship: string;
  business: string;
  role: string;
}

export class NonFinancialActivityDeclaration {
  currentEmployed: typeof DeclarationOptions[number]['value'] = undefined
  connectedEmployed: typeof DeclarationOptions[number]['value'] = undefined
  connected: typeof DeclarationOptions[number]['value'] = undefined
  moneyLender: typeof DeclarationOptions[number]['value'] = undefined
  junketPromoter: typeof DeclarationOptions[number]['value'] = undefined
  realEstate: typeof DeclarationOptions[number]['value'] = undefined
  unregulatedProduct: typeof DeclarationOptions[number]['value'] = undefined

  @ExpandableArray({ maxSize: 6, clone: () => new ConnectedPerson() })
  connectedPersons: ConnectedPerson[] = Array(1).fill(null).map(() => new ConnectedPerson())
}

export class DeclarationFields {
  reference: ReferenceDeclaration = new ReferenceDeclaration()
  biso: BisoDeclaration = new BisoDeclaration()
  fad: FinancialActivityDeclaration = new FinancialActivityDeclaration()
  nfad: NonFinancialActivityDeclaration = new NonFinancialActivityDeclaration()
}

export class GeneralDeclaration extends FormBase {
  type = 'general-declaration'
  data: DeclarationFields = new DeclarationFields()
}
