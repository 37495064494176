import ExistingPortfolioV2 from '@master/model/kyc-form/existing-plans/ExistingPortfolioV2'
import { isSummaryField } from '@master/annotation/SummaryField'

export default class CustomExistingPortfolioV2 extends ExistingPortfolioV2 {
  getSummaryFields (): { attrName: string; abbre: string }[] {
    const excludedKeys = ['roomAndBoard', 'hospitalIncome', 'maturityValue']
    const summaryFields: { attrName: string; abbre: string }[] = []
    Object.keys(this).forEach(attr => {
      if (excludedKeys.includes(attr)) return // exclude keys
      const grabSummaryField = isSummaryField(this, attr)
      if (grabSummaryField) {
        summaryFields.push({
          attrName: attr,
          ...grabSummaryField
        })
      }
    })
    return summaryFields
  }
}
