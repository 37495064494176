import { Base } from '../../Base'
import { isEmpty } from 'lodash/fp'
import { Default } from '@master/annotation/Default'
import { IClientOptions } from '../FormOptions'
import { MaxChar } from '@master/annotation/MaxChar'

export default class PoliticalExposedPerson extends Base {
  @MaxChar(70)
  policyholderName: keyof IClientOptions = ''

  @MaxChar(152)
  fullName = ''

  @MaxChar(70)
  nric = ''

  @MaxChar(70)
  occupation = ''

  @MaxChar(70)
  nameOfEmployer = ''

  sameAsHolder = false

  @Default([])
  sourceOfFunds?: string[] = []

  @MaxChar(48)
  fundOthers = ''

  @Default([])
  sourceOfWealth?: string[] = []

  @MaxChar(48)
  wealthOthers = ''

  category = ''

  @MaxChar(70)
  familyMemberRelationship = ''

  @MaxChar(70)
  closeAssociateRelationship = ''

  @MaxChar(70)
  clientRelationship = ''

  @MaxChar(70)
  personName = ''

  @MaxChar(70)
  personRank = ''

  @MaxChar(70)
  personCountryOfAppointment = ''

  /**
   * To be completed - moved here for maintainability (follow BOP's pattern)
   * @param pep
   */
  isNotComplete () {
    return isEmpty(this.fullName) ||
    isEmpty(this.nric) ||
    isEmpty(this.occupation) // ||
    // this.isEmpty(pep.familyMemberRelationship)
  }
}
