import { IProjectSettings, Theme } from '@master/config/IProjectSettings'

export default class ProjectSettings implements IProjectSettings {
  needs = {
    period: 1
  }

  useNewApi = {
    bsc: true,
    myinfo: true,
    version: 2
  }

  hierarchicalStructure = {
    maxLevel: 3
  }

  useResourcesModule = true
  useNewProfilePage = true
  useReportModule = true
  useNewCommissionModule = true
  useSalesTeamAdmin = true
  cloneKycForm = true
  deleteKycForm = true
  useFileSystem = true
  useScheduler = true
  theme: Theme = 'Modern'
  displayBrandInLogin = false
  useHierarchySetup = true
  useAssumptionsList = true
  useSeparateAML = true
  useDynamicPdf = {
    enable: true,
    pdf: {
      kycPdf: true,
      ecdd: false,
      manager: true
    },
    apiVersion: 'v2'
  };

  usePIASCommissionReportVersion = true

  generateNbrUponSupApproval = true
}
