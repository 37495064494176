/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdviserQualificationDTO } from '../models/AdviserQualificationDTO';
import { request as __request } from '@openapi/request';

export class AdviserQualificationService {

    /**
     * @returns AdviserQualificationDTO Retrieve the list of Adviser Qualification records
     * @throws ApiError
     */
    public static async retrieveAdviserQualificationList(): Promise<Array<AdviserQualificationDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_qualifications/get_list`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns AdviserQualificationDTO Retrieve a specific  Adviser Qualification record
     * @throws ApiError
     */
    public static async retrieveAdviserQualification(
oid: string,
): Promise<AdviserQualificationDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_qualifications/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns AdviserQualificationDTO Updates a Adviser Qualification record
     * @throws ApiError
     */
    public static async updateAdviserQualification(
oid: string,
requestBody: AdviserQualificationDTO,
): Promise<AdviserQualificationDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/adviser_qualifications/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns any Deletes a Adviser Qualification record (hard delete)
     * @throws ApiError
     */
    public static async deleteAdviserQualification(
oid: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/adviser_qualifications/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns AdviserQualificationDTO Retrieve Adviser Qualification records by user oid 
     * @throws ApiError
     */
    public static async retrieveAdviserQualificationByUserOid(
oid: string,
): Promise<Array<AdviserQualificationDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_qualifications/user/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Creates Adviser Qualification record(s)
     * @throws ApiError
     */
    public static async createAdviserQualifications(
requestBody: Array<AdviserQualificationDTO>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser_qualifications`,
            body: requestBody,
        });
        return result.body;
    }

}