import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { HierarchySetupDTO, HierarchySetupService } from '../api'
import { cid, container } from 'inversify-props'
import { IDateService } from '@master/services/IDateService'

const dateService = container.get<IDateService>(cid.IDateService)

@Module({
  namespaced: true
})
export default class HierarchySetupStore extends VuexModule {
  hierarchySetupRows: HierarchySetupDTO[] = null

  hierarchyInView: HierarchySetupDTO = null

  @Action
  async importHierarchySetup (data: HierarchySetupDTO[]) {
    const res = await HierarchySetupService.importHierarchySetup(data)
    return res
  }

  @Action
  async fetchHierarchySetup () {
    const res = await HierarchySetupService.getHierarchyList()
    this.context.commit('setHierarchySetup', res)
  }

  @Mutation
  setHierarchySetup (res: HierarchySetupDTO[]) {
    this.hierarchySetupRows = res.map(record => {
      const startDateMillis = dateService.format(record.startDate, 'DD-MM-YYYY')
      const endDateMillis = dateService.format(record.endDate, 'DD-MM-YYYY')
      return {
        ...record,
        startDateMillis,
        endDateMillis,
        emit: true // required to display dialog on row click
      }
    })
  }

  @Action
  selectHierarchyToView (row: HierarchySetupDTO) {
    this.context.commit('setHierarchyInView', row)
  }

  @Mutation
  setHierarchyInView (row: HierarchySetupDTO) {
    this.hierarchyInView = row
  }
}
