export const RelationshipOptions = [
  { value: 'Family Members', label: 'Family Members' },
  { value: 'Employer', label: 'Employer' },
  { value: 'Fiancee', label: 'Fiancee' },
  { value: 'Friend', label: 'Friend' },
  { value: 'Relative', label: 'Relative' },
  { value: 'Others', label: 'Others' },
]

export const DependantRelationshipOptions = [
  { value: 'Spouse', label: 'Spouse' },
  { value: 'Son', label: 'Son' },
  { value: 'Daughter', label: 'Daughter' },
  { value: 'Father', label: 'Father' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Brother', label: 'Brother' },
  { value: 'Sister', label: 'Sister' },
  { value: 'Grandfather', label: 'Grandfather' },
  { value: 'Grandmother', label: 'Grandmother' },
]

export const RelationshipLabel = RelationshipOptions.map(item => item.label)
export const RelationshipValues = RelationshipOptions.map(item => item.value)
export const getRelationshipByLabelValues = (arg: string) => RelationshipOptions.find(item => item?.label?.toLowerCase() === arg.toLowerCase() || item?.value?.toLowerCase() === arg?.toLowerCase() )