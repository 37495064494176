import { MaxChar } from '@master/annotation/MaxChar'
import { sum } from '../math'
import NeedsBase from '../NeedsBase'

/**
 * Depending on the structure of the PDF, additionalNotes may not be in used,
 * text can also be shared
 */
export default class OtherNeeds extends NeedsBase {
  planningFor = ''
  providedForWho = ''
  amountNeeded?: number = undefined
  existingBenefits?: number = undefined

  text = '' // previously was identifying_priorities_m*_text

  @MaxChar(100)
  additionalNotes = '' // using m1 additional notes, previously was others_additional_notes

  constructor (type: 'client_0'| 'client_1' = 'client_0', otherIndex: 'm1' | 'm2' = 'm1', name: string) {
    super(`${type}_${otherIndex}`)
    this.name = name
  }

  get surplusShortfall () {
    const total = sum(this.existingBenefits, -this.amountNeeded)
    return total
  }
}
