// This is unique id for each of the instantiated reusable component
// Created to resolve the overlapping AppCheckboxGroup's input-id and label-for for different instance
export default class UUID {
  private runningId = 0

  get newId () {
    // console.log('getting newId -' + this.runningId)
    this.runningId++
    return this.runningId - 1
  }
}
