import { isEmpty } from 'lodash/fp'

export interface SimpleForm {
  client1: {
    personalDetails: {
      name: string;
    };
  };
  client2: {
    personalDetails: {
      name: string;
      included: boolean;
    };
  };
  dependants: { name: string }[];
  hasClient2: boolean;
}
export interface IClientOptions {
  '': string;
  C1: string;
  C2: string;
}
export interface IDependantOptions {
  '': string;
  D1: string;
  D2: string;
  D3: string;
  D4: string;
}
export interface IAssuredOptions extends IClientOptions, IDependantOptions {
}
export class FormOptions {
  kycForm: SimpleForm

  constructor (kycForm: SimpleForm) {
    this.kycForm = kycForm
  }

  get clientOptions () {
    if (!this.kycForm || !this.kycForm.client1) return
    const client1 = this.kycForm.client1.personalDetails
    const client2 = this.kycForm.client2.personalDetails
    const options = [
      { value: '', label: '' },
      { value: 'C1', label: client1.name }
    ]
    if (this.kycForm.hasClient2) {
      options.push({ value: 'C2', label: client2.name })
    }
    return options
  }

  get dependantOptions () {
    const dependantsNames = this.kycForm.dependants.filter(
      (d) => !isEmpty(d.name)
    ).map(
      (d, index) => {
        return {
          value: 'D' + (index + 1),
          label: d.name
        }
      }
    )
    return [...dependantsNames]
  }

  get assuredOptions () {
    return [...this.clientOptions, ...this.dependantOptions]
  }
}
