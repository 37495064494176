import { SharedVariable } from './../model/SharedVariable'
import { SingPassSettings } from './../model/singpass/SingPassSettings'
import axios from 'axios'
import { cid, container } from 'inversify-props'
import { IProjectSettings } from '@master/config/IProjectSettings'
import qs from 'qs'

export default class API {
  private BASE_URL = '/web/api'
  private useNewApi = container.get<IProjectSettings>(cid.IProjectSettings).useNewApi
  private sharedVariable = container.get<SharedVariable>(cid.SharedVariable)

  private projectSettings = container.get<IProjectSettings>(cid.IProjectSettings)

  private apiTimeout = 100

  timeStampFromServer = (data: {}) => this.GET('/timeStamp', data)

  system = {
    information: () => this.GET('/system/information', null)
  }

  assumptions = {
    get: (params: {}) => this.GET('/kvmApi/getValues', params),
    update: (params: {}) => this.POST('/kvmApi/setValues', params)
  }

  account = {
    login: (data: {}, options?: {}) => this.POST('/account/login', data, false, options),
    loginGet: () => this.GET('/account/login', null),
    loginInfoGet: (data: {}) => this.GET('/account/info/id', data),
    loginMetaGet: (data: {}) => this.GET('/account/meta', data),
    meta: (data: {}) => this.POST('/account/meta', data),
    logout: () => this.GET('/account/logout', null),
    password: (data: {}) => this.POST('/account/password', data),
    getSupervisorList: (params: {}) => this.GET('/account/supervisorlist', params),
    getReassignSupervisorList: () => this.POST('/account/meta/list', {
      query: 'systemrole= ? OR systemrole= ? OR systemrole= ?',
      queryArgs: JSON.stringify(['supervisor', 'director', 'ops-admin']),
      groupStatus: 'user',
      headers: JSON.stringify(['_oid', 'names', 'fullname', 'mas_repno'])
    }),
    list: (data: {}) => this.GET('/account/meta/list', data),
    singpassSettings: (): Promise<SingPassSettings> => this.GET('/account/singpass/settings', null),
    otpRequest: (data: {}) => this.POST('/account/otp/request', data),
    otpLogin: (data: {}) => this.POST('/account/otp/login', data),
    otpRefresh: (data: {}) => this.GET('/account/otp/refresh', data),
    new: (data: {}) => this.POST('/account/new', data),
    memberList: (oid: string, data: {}) => this.GET(`/account/members/list/${oid}`, data),
    saveMemberList: (oid: string, data: {}) => this.POST(`/account/members/list/${oid}`, data),
    remove: (data: {}) => this.POST('/account/remove', data),
    // reset password
    verify: (data: {}) => this.POST('/account/verify-token', data, true),
    getOTP: (data: {}) => this.POST('/account/generate-otp', data, true),
    getToken: (data: {}) => this.POST('/account/request-reset-password', data, true),
    reset: (data: {}) => this.POST('/account/reset-password', data, true)
  }

  kycPdf = {
    list: (data: {}) => this.POST('/kycPdf/list', data),
    meta: (data: {}) => this.POST('/kycPdf/meta', data),
    metaGet: (data: {}) => this.GET('/kycPdf/meta', data),
    create: (data: {}) => this.POST('/kycPdf/createKycPdfEntry', data),
    saveSignature: (data: {}) => this.POST('/kycPdf/saveSignature', data),
    submitSignatures: (data: {}) => this.POST('/kycPdf/submitSignatures', data),
    stampedKycPdf: async (data: {}) => await this.stampedPdfApiCall(data),
    stampedBeneficialOwnerPdf: (data: {}) => this.POST('/stampedpdf/stamp_beneficial_owner_pdf', data),
    stampedBeneficialOwnerPdfSignatures: (data: {}) => this.POST('/stampedpdf/stamp_beneficial_owner_pdf_signature', data),
    stampPEPPdf: (data: {}) => this.POST('/stampedpdf/stamp_pep_pdfs', data),
    stampPEPPdfSignatures: (data: {}) => this.POST('/stampedpdf/stamp_pep_pdfs_signatures', data),
    stampECDDPdf: (data: {}) => this.POST('/stampedpdf/stamp_ecdd_pdf', data),
    stampECDDPdfSignatures: (data: {}) => this.POST('/stampedpdf/stamp_ecdd_pdf_signature', data),
    stampAiPdf: (data: {}) => this.POST('/stampedpdf/stamp_ai_pdf', data),
    stampAiPdfSignatures: (data: {}) => this.POST('/stampedpdf/stamp_ai_pdf_signature', data),
    stampedAMLPdf: (data: {}) => this.POST('/stampedamlpdf/stamp_aml_pdf', data),
    stampedInsurerPdf: (data: {}) => this.POST('/stampedinsurerpdf/stamp_insurer_pdf', data),
    updateConsolidateGroupsStatus: (data: {}) => this.POST('/kycPdf/updateConsolidatedGroupsStatus', data),
    emailNotice: (data: {}) => this.GET('/kycPdf/emailNotice', data),
    addUserRemarks: (data: {}) => this.POST('/kycPdf/addUserRemarks', data),
    adviserCancel: (data: {}) => this.POST('/kycPdf/adviserCancel', data),
    supervisorEmailWarning: (data: {}) => this.GET('/kycPdf/supervisorEmailWarning', data),
    finalise: (data: {}) => this.POST('/kycPdf/finalise', data),
    generateKycPdfNest: (data: {}) => this.GET(`${this.kycPdfGeneratorApiVersion}/kycPdf/generate`, data),
    stampedKycPdfNest: (data: {}) => this.GET(`${this.kycPdfGeneratorApiVersion}/kycPdf/stamped`, data),
    splitKycPdfNest: (data: {}) => this.GET(`${this.kycPdfGeneratorApiVersion}/kycPdf/split`, data),
    getSignaturePosition: (data: {}) => this.GET(`${this.kycPdfGeneratorApiVersion}/kycPdf/signaturePosition`, data),
    generateEcddNest: (data: {}) => this.GET(`${this.kycPdfGeneratorApiVersion}/kycPdf/ecdd/generate`, data),
    setCustomProperties: (formData: {}) => this.POST('/kycPdf/setProperties', formData)
  }

  pdf={
    stitch: (data: {}) => this.POST('/pdf/stitch', data),
    delete: (data: {}) => this.POST('/pdf/delete', data)
  }

  eventLog = {
    list: (data: {}) => this.POST('/log/list', data, true)
  }

  esign = {
    checkEnabled: (data: {}) => this.GET('/esign/checkEnabled', data),
    reqEQuota: (data: {}) => this.GET('/esign/reqEQuota', data),
    clearESign: (data: {}) => this.GET('/esign/clearESign', data),
    saveSigners: (data: {}) => this.POST('/esign/saveSigners', data),
    setupSigners: (data: {}) => this.GET('/esign/setupSigners', data),
    reqDocFile: (data: {}) => this.GET('/esign/reqDocFile', data),
    reqDocStat: (data: {}) => this.GET('/esign/reqDocStat', data),
    reqESign: (data: {}) => this.POST('/esign/reqESign', data),
    reqSignURL: (data: {}) => this.GET('/esign/reqSignURL', data),
    completeESign: (data: {}) => this.GET('/esign/completeESign', data),
    getPlans: () => this.GET('/esign/plan/list', null),
    activatePlan: (data: {}) => this.POST('/esign/plan/activate', data),
    getSignaturePosition: (data: {}) => this.GET('/esign/getSignaturePosition', data)
  }

  esubPrefix = 'esub'
  esub = {
    retrieve: (data: {}) => this.GET('/' + this.esubPrefix + '/retrieve', data),
    create: (data: {}) => this.POST('/' + this.esubPrefix + '/create', data),
    notify: (data: {}) => this.POST('/' + this.esubPrefix + '/notify', data),
    reject: (data: {}) => this.POST('/' + this.esubPrefix + '/reject', data),
    update: (data: {}) => this.POST('/' + this.esubPrefix + '/update', data)
  }

  dsf = {
    checkMode: (data: {}) => this.GET('/dsf/mode/check', data),
    verifyFields: (data: {}) => this.POST('/dsf/fields/verify', data),
    getClientNricList: (data: {}) => this.GET('/dsf/client/nric/list', data),
    ws3CreateMfp: (data: {}) => this.POST('/dsf/mfp/create', data),
    ws5UpdateKyc: (data: {}) => this.POST('/dsf/kyc/update', data),
    ws5ApproveKyc: (data: {}) => this.POST('/dsf/kyc/approve', data),
    getDsfWebUrl: (data: {}) => this.GET('/dsf/web/url', data),
    ws4GetPdfsByCaseId: (data: {}) => this.GET('/dsf/pdfs', data),
    ws6RetrievePolicy: (data: {}) => this.GET('/dsf/policy', data)
  }

  onemap = {
    getAddress: (data: { postcode: string }) => this.GET('/onemap/get_address', data)
  }

  kycForm = {
    metaGet: (data: { _oid: string }) => this.GET('/kycForm/meta', data),
    meta: (data: {}) => this.POST('/kycForm/meta', data, true),
    list: (data: {}) => this.POST('/kycForm/list', data),
    delete: (data: { _oid: string }) => this.POST('/kycForm/delete', data),
    exportClients: (data) => this.POST('/kycForm/export-clients', data, false, {}, false, true),
    importClients: (data) => this.POST('/kycForm/import-clients', data, false, {}, false, true)
  }

  uploadDoc = {
    list: (data: {}) => this.GET('/kyc-doc-upload', data),
    upload: (data: {}) => this.POST('/kyc-doc-upload', data),
    delete: (kycFormOid: string, kycPdfOid: string) => this.DELETE(`/kyc-doc-upload/${kycFormOid}/${kycPdfOid}`),
    merge: (data: {}) => this.POST('/kyc-doc-upload/mergeAppendix', data),
    getAppendix: (data: {}) => this.GET('/kyc-doc-upload/appendix', data)
  }

  adviserAgentCodes = {
    meta: (data: {}) => this.POST('/adviserAgentCodes/meta', data),
    list: (data: {}) => this.POST('/adviserAgentCodes/list', data),
    update: (data: {}) => this.POST('/adviserAgentCodes/massUpdate', data)
  }

  adviserDetails = {
    getAccountMeta: (id: {}) => this.GET('/account/meta', id),
    getDetails: (params: {}) => this.GET('/adviser_details/get_details', params),
    saveDetails: (params: {}) => this.POST('/adviser_details/save_details', params),
    getEducationList: (params: {}) => this.GET('/adviser_education/get_list', params),
    getQualificationList: (params: {}) => this.GET('/adviser_qualifications/get_list', params),
    getEmploymentList: (params: {}) => this.GET('/adviser_employment/get_list', params),
    saveEducation: (data: {}) => this.POST('/adviser_education/save_details', data),
    deleteEducation: (data: {}) => this.POST('/adviser_education/delete_details', data),
    saveQualification: (data: {}) => this.POST('/adviser_qualifications/save_details', data),
    deleteQualification: (data: {}) => this.POST('/adviser_qualifications/delete_details', data),
    saveEmployment: (data: {}) => this.POST('/adviser_employment/save_details', data),
    deleteEmployment: (data: {}) => this.POST('/adviser_employment/delete_details', data)
  }

  bscRecords = !this.useNewApi?.bsc ? {
    /** BSC Metatable API */
    // For API /list all will be using POST to avoid wrong character error since POST and GET return the same value
    listReviewMeta: (params: {}) => this.POST('/bscUserQuarter/list', params),
    saveReviewMeta: (params: {}) => this.POST('/bscUserQuarter/meta', params),
    getReviewMeta: (params: {}) => this.GET('/bscUserQuarter/meta', params),
    saveClientMeta: (params: {}) => this.POST('/bscUserPolicyInfractions/meta', params),
    getClientMeta: (params: {}) => this.GET('/bscUserPolicyInfractions/meta', params),
    listClientsReviewed: (params: {}) => this.POST('/bscUserPolicyInfractions/list', params),

    /** BSC Rest API */
    saveReview: (params: {}) => this.POST('/bscUserQuarter/saveNewBSCQuarter', params),
    updateReview: (params: {}) => this.POST('/bscUserQuarter/updateBSCQuarter', params),
    removeReviewUsers: (params: {}) => this.POST('/bscUserQuarter/removeUserFromBSCQuarter', params),
    listPrev: (params: {}) => this.GET('/bsc_previous_records/get_records', params),
    new: (params: {}) => this.POST('/bsc_previous_records/new_record', params),
    update: (params: {}) => this.POST('/bsc_previous_records/update_record', params)
  } : {
    /** BSC Metatable API */
    // For this API all will be using POST to avoid wrong character error
    listReviewMeta: (params: {}) => this.GET('/bsc/review/list', params),
    saveReviewMeta: (params: {}) => this.POST('/bsc/review/meta', params),
    getReviewMeta: (params: {}) => this.GET('/bsc/review/meta', params),
    updateInfractionTotal: (params: {}) => this.POST('/bsc/review/updateInfractionTotals', params),
    saveClientMeta: (params: {}) => this.POST('/bsc/policyInfractions/meta', params),
    getClientMeta: (params: {}) => this.GET('/bsc/policyInfractions/meta', params),
    listClientsReviewed: (params: {}) => this.POST('/bsc/policyInfractions/list', params),

    /** BSC Rest API */
    saveReview: (params: {}) => this.POST('/bsc/review/save', params),
    updateReview: (params: {}) => this.POST('/bsc/review/update', params),
    removeReviewUsers: (params: {}) => this.POST('/bsc/review/remove', params),
    listPrev: (params: {}) => this.GET('/bsc/previous/get', params),
    new: (params: {}) => this.POST('/bsc/previous/new', params),
    update: (params: {}) => this.POST('/bsc/previous/update', params)
  }

  bsc = {
    supervisorReview: {
      add: (params: {}) => this.POST('/bsc-supervisor-review', params, false, {}, false, true),
      fetch: (params: {}) => this.GET('/bsc-supervisor-review', params),
      delete: (params: {}) => this.DELETE('/bsc-supervisor-review', { data: params }),
      update: (params: {}) => this.PUT('/bsc-supervisor-review', params, {}, true),
      generatePdf: (params: {}) => this.GET('/bsc-supervisor-review/generate-pdf', params),
      getDetails: (supervisorOid: string) => this.POST(`/bsc-supervisor-review/${supervisorOid}`, {})
    },
    adviserReview: {
      generatePdf: (params: {}) => this.GET('/bsc-adviser-review/generate-pdf', params)
    }

  }

  cpd = {
    list: (params: {}) => this.GET('/userCpd/list', params),
    listPost: (params: {}) => this.POST('/userCpd/list', params),
    getDetails: (params: {}) => this.GET('/adviser_cpd_hours/get_details', params),
    getHours: (params: {}) => this.GET('/kvmApi/getValues', params),
    save: (params: {}) => this.POST('/adviser_cpd_hours/save_details', params),
    saveMany: (params: {}) => this.POST('/adviser_cpd_hours/save_many_details', params),
    course: {
      new: (params: { course_info: any; attendees: { user_oid: string; username: string; user_fullname: string }[] }) => this.POST('/cpd/course/new', params),
      update: (params: { course_id: string; data: any }) => this.POST('/cpd/course/details/update', params),
      delete: (params: { course_id: string }) => this.POST('/cpd/course/delete', params)
    },
    attendees: {
      new: (params: { course_id: string; new_attendees: any[] }) => this.POST('/cpd/attendees/add', params),
      update: (params: { data: any }) => this.POST('/cpd/course/attendees/update', params),
      delete: (params: { course_id: string; removed_users: string[] }) => this.POST('/cpd/attendees/remove', params)
    }
  }

  commissionStructure = {
    getCommissionStructure: (params: {}) => this.GET('/commission_structure/get_commission_structure', params),
    deleteCommissionStructure: (params: {}) => this.POST('/commission_structure/delete_commission_structure', params),
    saveCommissionLevel: (params: {}) => this.POST('/commission_structure/save_commission_level', params)
  }

  commissionRecordsRel = {
    list: (params: {}) => this.POST('/commissionRecordsRel/list', params),
    saveNewRecord: (params: {}) => this.POST('/commission_records/new_commission_data', params),
    updateRecord: (params: {}) => this.POST('/commission_records/update_commission_data', params),
    report: (params: {}) => this.GET('/commission_records/report', params),
    setScheduleTime: (params: {}) => this.POST('/commission_records/setScheduleJobTime', params),
    getScheduleTime: () => this.GET('/commission_records/getScheduleJobTime', {}),
    generateAdHocStatement: (params: {}) => this.POST('/commission_records/generateAdHocStatement', params),
    downloadCsv: (params: {}) => this.POST('/commission_records/downloadCsv', params),
    generateReport: (params: {}) => this.POST('/commission_records/reportGenerator', params),
    reportList: (params: {}) => this.GET('/commission_records/reportList', params),
    getUsersAndPrincipals: () => this.GET('/commission_records/listOfPrincipalsAndUsers', {})
  }

  relProductList = {
    list: (data: {}) => this.POST('/relProductList/list', data),
    restoreDefault: (data: {}) => this.POST('/relProductList/restoreDefaultProducts', data),
    deleteProducts: (data: {}) => this.POST('/relProductList/deleteProducts', data),
    saveNewProducts: (data: {}) => this.POST('/relProductList/saveNewProducts', data),
    metaGet: (data: {}) => this.GET('/relProductList/meta', data),
    updateProduct: (data: {}) => this.POST('/relProductList/meta', data),
    multiSaveProducts: (data: {}) => this.POST('/relProductList/multiSaveProducts', data)
  }

  clientPolicy = {
    list: (params: {}) => this.POST('/clientPolicy/list', params),
    meta: (params: {}) => this.GET('/clientPolicy/meta', params),
    update: (params: {}) => this.POST('/clientPolicy/update_policy', params),
    new: (params: {}) => this.POST('/clientPolicy/save_new_policy', params),
    newBusiness: (params: {}, version = 1) => this.POST(`/${!version ? '' : 'v' + version + '/'}clientPolicy/save_new_business`, params, false, {}, false, true),
    saveExcelInfo: (params: {}) => this.POST('/clientPolicy/save_excel_infosheet', params),
    amlImportCsv: (params: {}) => this.POST('/clientPolicy/aml/save_check_results', params, false, {}, false, true),
    amlExportCsv: (params: {}) => this.GET('/clientPolicy/generate_aml_report', params),
    reconExportCsv: (params: {}) => this.GET('/clientPolicy/generate_recon_report', params),
    extractProducts: (params: { kycPdfOid: string }, version = 1) => this.POST(`/${!version ? '' : 'v' + version + '/'}clientPolicy/extract_products`, params, false, {}, false, true),
    policyDocumentsList: (params: {}) => this.POST('/policy_documents/list', params),
    uploadNewPolicyDocument: (params: {}) => this.POST('/clientPolicy/upload_policy_document', params, false, {}, false, true),
    deletePolicyDocument: (params: {}) => this.POST('/clientPolicy/delete_policy_document', params),
    getTaggedKycDocuments: (params: {}) => this.GET('/clientPolicy/get_tagged_kyc_documents', params),
    uploadCancellationLetter: (params: {}) => this.POST('/clientPolicy/cancellationLetter', params),
    deleteCancellationLetter: (params: {}) => this.POST('/clientPolicy/delete_cancellation_letter', params),

    // IFAM Nest API
    saveIntermediary: (params: {}) => this.POST('/clientPolicy/save_intermediary', params),
    saveManyIntermediaries: (params: {}) => this.POST('/clientPolicy/save_many_intermediaries', params)
  }

  intermediaries = {
    get: (params: {}) => this.GET('/clientPolicy/get_intermediaries', params),
    update: (params: {}) => this.POST('/clientPolicy/save_intermediaries', params),
    list: (data: {}) => this.POST('/payee/list', data),
    meta: (data: {}) => this.POST('/payee/meta', data)
  }

  payee = {
    list: (params: {}) => this.POST('/payee/list', params),
    update: (params: {}) => this.POST('/payee/meta', params)
  }

  clientDetails = {
    newClient: (data: {}) => this.POST('/client_details/new_client', data),
    list: (data: {}) => this.POST('/client_details/list', data),
    profile: (data: {}) => this.GET('/client_details/client_info', data),
    update: (data: {}) => this.POST('/client_details/update_client', data),
    linkKycForm: (data: {}) => this.POST('/client_details/link_kyc_form', data)
  }

  clientDependants = {
    get: (data: {}) => this.GET('/client_dependants/get_dependants', data),
    update: (data: {}) => this.POST('/client_dependants/update_dependant', data),
    new: (data: {}) => this.POST('/client_dependants/new_dependant', data),
    delete: (data: {}) => this.POST('/client_dependants/delete_dependant', data)
  }

  clientScreenings = {
    get: (data: {}) => this.GET('/clientScreenings/get_screenings', data),
    new: (data: {}) => this.POST('/clientScreenings/save_new_screening', data)
  }

  clientInterest = {
    get: (data: {}) => this.GET('/client_interest/get_interests', data),
    update: (data: {}) => this.POST('/client_interest/save_interests', data)
  }

  clientCommunication = {
    get: (data: {}) => this.GET('/client_communication/get_communication_choices', data),
    update: (data: {}) => this.POST('/client_communication/save_communication_choices', data)
  }

  // companyDetails = {
  //   list: (data: {}) => this.GET('/companyDetails/list', data),
  //   save: (data: {}) => this.POST('/companyDetails/meta', data)
  // }

  myInfo = !this.useNewApi?.myinfo ? {
    requestDirectMyinfo: (data: {}) => this.POST('/myinfo/request/direct', data),
    sendAuthorizationEmail: (data: {}) => this.POST('/myinfo/send_authorization_email', data),
    sendAuthorizationEmailToAdviser: (data: {}) => this.POST('/myinfo/email/auth/recruit/send', data),
    fetchUnconfirmPersonInfo: (params: {}) => this.GET('/myinfo/unconfirmed_person_info', params),
    postClientDecision: (params: {}) => this.POST('/myinfo/person_info_client_decision', params),
    newPersonInfoAvailable: (params: {}) => this.GET('/myinfo/new_person_info_available', params, true),
    linkPersonInfo: (params: {}) => this.POST('/myinfo/link_person_info', params),
    unlinkPersonInfo: (params: {}) => this.POST('/myinfo/unlink_person_info', params),
    fetchComfirmedPersonInfo: (params: {}) => this.GET('/myinfo/person_info', params),
    syncMyInfo: (params: {}) => this.POST('/myinfo/sync_person_info', params),
    syncAdviserMyInfo: (params: {}) => this.POST('/myinfo/recruit/action', params),
    newRecruit: (params: {}) => this.POST('/myinfo/email/auth/recruit/new', params)
  } : {
    requestDirectMyinfo: (data: {}) => this.POST('/myinfo/request/direct', data),
    sendAuthorizationEmail: (data: {}) => this.POST('/myinfo/email/auth/send', data),
    sendAuthorizationEmailToAdviser: (data: {}) => this.POST('/myinfo/email/auth/recruit/send', data),
    fetchUnconfirmPersonInfo: (params: {}) => this.GET('/myinfo/person/unconfirmed', params),
    postClientDecision: (params: {}) => this.POST('/myinfo/client/decide', params),
    newPersonInfoAvailable: (params: {}) => this.GET('/myinfo/person/available', params, true),
    linkPersonInfo: (params: {}) => this.POST('/myinfo/client/action', params),
    unlinkPersonInfo: (params: {}) => this.POST('/myinfo/client/action', params),
    fetchComfirmedPersonInfo: (params: {}) => this.GET('/myinfo/person/confirmed', params),
    syncMyInfo: (params: {}) => this.POST('/myinfo/client/action', params),
    syncAdviserMyInfo: (params: {}) => this.POST('/myinfo/recruit/action', params),
    newRecruit: (params: {}) => this.POST('/myinfo/email/auth/recruit/new', params)
  }

  signature = {
    upsert: (params: {}) => this.POST('/signatures/upsert', params, true),
    retrieve: (params: {}) => this.GET('/signatures/retrieve', params)
  }

  // recruitment modules
  forms = {
    create: (params: {}) => this.POST('/forms', params, false, {}, false, true),
    search: (params: {}) => this.POST('/forms/search', params, false, {}, false, true),
    update: (params: {}) => this.PUT('/forms', params, {}, true),
    sign: (params: { recruitOid: string; signature: string }) => this.POST('/forms/sign', params, false, {}, false, true)
  }

  activity = {
    create: (params: {}) => this.POST('/activity', params, false, {}, false, true),
    update: (params: {}) => this.PUT('/activity', params, {}, true),
    search: (params: {accountOid: string}) => this.GET(`/activity/${params.accountOid}`, {}),
    upload: (params: { oid: string; files: File[]}) => {
      const formData = new FormData()
      formData.append('fileUploadOid', params.oid)
      params.files.forEach((fileEntry: any) => {
        formData.append('files[]', fileEntry.raw)
      })
      return this.POST('/activity/upload', formData)
    },
    remove: (params: { oid: string; filename: string }) => this.DELETE('/activity', { data: params }),
    filelist: (params: { oid: string }) => this.GET(`/activity/filelist?fileUploadOid=${params.oid}`, {}),
    finalise: (params: { oids: string[] }) => this.POST('/activity/finalise', params, false, {}, false, true),
    submit: (params: { oids: string[] }) => this.PUT('/activity/submit', params, {}, true),
    decide: (params: { ids: string[]; status: 'COMPLETED' | 'PENDING' }) => this.PUT('/activity/decide', params, {}, true)
  }

  recruit = {
    detail: {
      create: (params: {}) => this.POST('/recruit/details', params, false, {}, false, true),
      search: (params: {}) => this.POST('/recruit/details/search', params, false, {}, false, true),
      update: (params: {}) => this.PUT('/recruit/details', params, {}, true),
      submitApplication: (params: {}) => this.POST('/recruit/details/submit', params, false, {}, false, true),
      reworkApplication: (params: {}) => this.POST('/recruit/details/rework', params, false, {}, false, true),
      approve: (params: {}) => this.POST('/recruit/details/approve', params, false, {}, false, true),
      findAll: (params: {}) => this.POST('/recruit/details/findAll', params, false, {}, false, true),
      retrieve: (params: { recruitOid: string }) => this.GET(`/recruit/details/${params.recruitOid}`, {})
    },
    exam: {
      create: (params: {}) => this.POST('/exam/record', params, false, {}, false, true),
      search: (params: {}) => this.POST('/exam/record/search', params, false, {}, false, true),
      update: (params: {}) => this.POST('/exam/record/update', params, false, {}, false, true),
      delete: (params: { oid: string }) => this.DELETE(`/exam/record/${params.oid}`, params),
      approval: (params: {examRecordOid: string; decision: 'approve' | 'rework'}) => this.POST('/exam/record/approval', params, false, {}, false, true),
      cert: {
        upload: (params: { oid: string; files: File[]}) => {
          const formData = new FormData()
          params.files.forEach((fileEntry: any) => {
            formData.append('files[]', fileEntry.raw)
          })
          return this.POST(`/exam/cert/upload/${params.oid}`, formData)
        },
        remove: (params: { examCertOid: string; filename: string }) => this.DELETE('/exam/cert', { data: params }),
        list: (examCertOid: string) => this.GET(`/exam/cert/filelist?examCertOid=${examCertOid}`, {}),
        verify: (params: { oids: string[] }) => this.POST('/exam/cert/verify', params, false, {}, false, true)
      }
    },
    upload: {
      create: (params: {}) => this.POST('/recruit-upload/create', params, false, {}, false, true),
      remove: (params: { oid: string; filename: string }) => this.DELETE('/recruit-upload', { data: params }),
      upload: (params: { oid: string; files: File[] }) => {
        const formData = new FormData()
        formData.append('fileUploadOid', params.oid)
        params.files.forEach((fileEntry: any) => {
          formData.append('files[]', fileEntry.raw)
        })
        return this.POST(`/recruit-upload/upload/${params.oid}`, formData)
      },
      delete: (params: {}) => this.DELETE('/recruit-upload/entry', { data: params })
    }
  }

  resources = {
    getAllFolders: () => this.GET('/resources/folders', {}),
    getAllFilesFolders: (params: { filePath: string }) => this.POST('/resources/files', params, false, {}, false, true),
    createFolder: (params: { folderName: string }) => this.POST('/resources/createFolder', params, false, {}, false, true),
    createFile: (data: {}) => this.POST('/resources/createFile', data),
    deleteFiles: (params: { fileName: string }) => this.DELETE('/resources', { data: params })
  }

  ifamFileSystem = {
    uploadFile: (formData: FormData, moduleType: string, oid: string) => this.POST(`/ifam-file-system/${moduleType}/${oid}`, formData),
    fetch: (data: {}) => this.GET('/ifam-file-system', data),
    delete: (params: {uniqueOid: string; moduleType: string}) => this.DELETE('/ifam-file-system', { data: params })
  }

  changelog = {
    getFA: () => this.GET('/changelog/faportal', null)
  }

  kvm = {
    get: (params: {}) => this.GET('/kvmApi/getValue', params),
    update: (params: {}) => this.POST('/kvmApi/setValue', params)
  }

  survey = {
    fetchSurveyWithToken: (params: { token: string }) => this.GET(`/survey?token=${params.token}`, {}),
    fetchSurveyWithKycPdfOid: (params: { kycPdfOid: string }) => this.GET(`/survey/${params.kycPdfOid}`, {}),
    submitSurvey: (data: { token: string; survey }) => this.POST('/survey/submit', data, false, {}, false, true),
    sendSurveyEmail: (params: { kycPdfOid: string }) => this.POST(`/survey/${params.kycPdfOid}/generate`, {}),
    refreshAndResendSurveyEmail: (params: { token: string }) => this.POST(`/survey/resend?token=${params.token}`, {})
  }

  get useEjsTemplate () {
    return this.projectSettings?.useDynamicPdf?.enable ?? false
  }

  get kycPdfGeneratorApiVersion () {
    if (!this.useEjsTemplate) {
      return ''
    }
    const apiVersion = this.projectSettings.useDynamicPdf.apiVersion
    return apiVersion === 'v1' || !apiVersion ? '' : `/${this.projectSettings.useDynamicPdf.apiVersion}`
  }

  private async stampedPdfApiCall (data) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (!this.useEjsTemplate) {
      return this.POST('/stampedkycpdf/stamp_kyc_pdf', data)
    }
    const version = this.kycPdfGeneratorApiVersion
    // get the existing pdf, stamp the signature
    return this.GET(`${version}/kycPdf/stamped`, data)
  }

  private GET (url: string, params: {}, hideLoading = false) {
    const { uiService } = this.sharedVariable

    let id
    // uiService will be not available during routerRedirect
    // during this time, the UI is not ready too, therefore no need to show loading
    if (uiService && !hideLoading) {
      id = uiService.randomID()
      uiService.loading.addLoadingTask(id)
    }

    // todo: handle user being online or offline
    return axios
      .get(this.BASE_URL + url, {
        params: this.transformParams(params),
        withCredentials: true,
        paramsSerializer: params => {
          return qs.stringify(this.transformParams(params), { arrayFormat: 'repeat' })
        }
      })
      .then((res) => {
        if (typeof res.data.error !== 'undefined' && res.data.error !== null) {
          const error = res.data.error
          // error.response = res
          console.error(error)
          return Promise.reject(error) /* {code, message, stack} */
        }
        // if (typeof res.data.result === 'undefined'){
        //   return Promise.reject('missing `result` from response')
        // }
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        /**
         * error should look like:
         * {
         *     // from uilicious-api
         *     code,
         *     message,
         *     stack,
         *     // from Axios
         *     response:
         *     {
         *         data,
         *         status,
         *         headers
         *     },
         * }
         */
        return Promise.reject(error)
      }).finally(() => {
        if (uiService) {
          setTimeout(() => uiService.loading.removeLoadingTask(id), this.apiTimeout)
        }
      })
  }

  private POST (url: string, data: {}, useSearchParams = false, options?: {}, hideLoading = false, skipTransform = false) {
    const { uiService } = this.sharedVariable

    let id
    // uiService will be not available during routerRedirect
    // during this time, the UI is not ready too, therefore no need to show loading
    if (uiService && !hideLoading) {
      id = uiService.randomID()
      uiService.loading.addLoadingTask(id)
    }

    // todo: handle user being online or offline
    const requestData = useSearchParams ? new URLSearchParams(this.stringifyData(data)) : (skipTransform ? data : this.transformRequestData(this.stringifyData(data)))

    return axios
      .post(this.BASE_URL + url, requestData, this.transformOptions(options))
      .then((res) => {
        if (typeof res.data.error !== 'undefined' && res.data.error !== null) {
          const error = res.data.error
          // error.response = res
          console.error(error)
          return Promise.reject(error) /* {code, message, stack} */
        }
        // if (typeof res.data.result === 'undefined'){
        //   return Promise.reject('missing `result` from response')
        // }
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        /**
         * error should look like:
         * {
         *     // from uilicious-api
         *     code,
         *     message,
         *     stack,
         *     // from Axios
         *     response:
         *     {
         *         data,
         *         status,
         *         headers
         *     },
         * }
         */
        console.error(error)
        return Promise.reject(error)
      }).finally(() => {
        if (uiService) {
          setTimeout(() => uiService.loading.removeLoadingTask(id), this.apiTimeout)
        }
      })
  }

  private transformOptions (options: any) {
    if (typeof options === 'undefined' || null) {
      options = {}
    }
    // always set as true to reuse the cookies
    options.withCredentials = true
    return options
  }

  private transformParams (data: {}) {
    if (typeof data === 'undefined' || null) {
      data = {}
    }
    return data
  }

  private stringifyData (data: {}) {
    if (typeof data === 'undefined' || null) {
      return {}
    }
    if (data instanceof FormData) return data
    const stringifiedData = {}
    Object.keys(data).forEach((key) => {
      let value = data[key]
      const skipStringify = value?.raw instanceof File || value?.raw instanceof Blob
      if (value instanceof Object && !skipStringify) {
        value = JSON.stringify(value)
      }
      stringifiedData[key] = value
    })
    return stringifiedData
  }

  private transformRequestData (data: {}) {
    if (typeof data === 'undefined' || null) {
      return new FormData()
    }
    if (data instanceof FormData) return data
    // if its not FormData, construct
    const formData = new FormData()
    if (typeof data === 'object') {
      Object.keys(data).forEach((key) => {
        const value = data[key]
        if (value?.raw instanceof File) {
          formData.append('files[]', value.raw)
        } else if (value?.raw instanceof Blob && value?.raw.type.includes('image/')) {
          const imgType = value?.raw.type
          imgType.replace('image/', '')
          formData.append('files[]', value.raw, `${imgType}.${imgType}`)
        } else if (Array.isArray(value) || value instanceof Object) {
          formData.append(key, JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      })
    }
    return formData
  }

  private DELETE (url: string, options?: {}) {
    return axios.delete(this.BASE_URL + url, this.transformOptions(options))
      .then((res) => {
        if (typeof res.data.error !== 'undefined' && res.data.error !== null) {
          const error = res.data.error
          // error.response = res
          return Promise.reject(error) /* {code, message, stack} */
        }
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  private PUT (url: string, data: {}, options?: {}, skipTransform = false) {
    const requestData = skipTransform ? data : this.transformRequestData(this.stringifyData(data))

    return axios
      .put(this.BASE_URL + url, requestData, this.transformOptions(options))
      .then((res) => {
        if (typeof res.data.error !== 'undefined' && res.data.error !== null) {
          const error = res.data.error
          console.error(error)
          return Promise.reject(error) /* {code, message, stack} */
        }
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}
