import { FormBase } from './FormBase'
import { BDMAssessment } from './forms/BDMAssessment'
import { BusinessInterestShareholding } from './forms/BusinessInterestShareholding'
import { CPFAuthorization } from './forms/CPFAuthorization'
import { DeclarationClauses } from './forms/DeclarationClauses'
import { EmployerConsent } from './forms/EmployerConsent'
import { FitAndProper } from './forms/FitAndProper'
import { GeneralDeclaration } from './forms/GeneralDeclaration'
import { LetterOfUndertaking } from './forms/LetterOfUndertaking'
import { NameCardOrder } from './forms/NameCardOrder'
import { Personal } from './forms/Personal'
import { PrevEmploymentInquiry } from './forms/PrevEmploymentInquiry'
import { QualificationEmployment } from './forms/QualificationEmployment'
import { RecruitManagerAssessment } from './forms/RecruitManagerAssessment'
import { RecruitSubsidyReimbursement } from './forms/RecruitSubsidyReimbursement'
import { SecuritiesDealing } from './forms/SecuritiesDealing'
import { Shared } from './forms/Shared'
import { TransferRulesAcknowledgement } from './forms/TransferRulesAcknowledgement'

export type DataForm = {
  path: string;
  displayName: string;
  model?: typeof FormBase;
  name: string;
  component: () => void;
}
export const FormsList: DataForm[] = [
  {
    path: 'info',
    displayName: 'Representative Information',
    model: Shared,
    name: 'recruit-forms-shared',
    component: () => import('@core/pages/recruit/forms/SharedForm.vue')
  },
  {
    path: 'personal',
    displayName: 'Personal Information',
    model: Personal,
    name: 'recruit-forms-personal',
    component: () => import('@core/pages/recruit/forms/PersonalInfo.vue')
  },
  {
    path: 'general-declaration',
    displayName: 'General Declaration',
    model: GeneralDeclaration,
    name: 'recruit-forms-general-declaration',
    component: () => import('@core/pages/recruit/forms/GeneralDeclaration.vue')
  },
  {
    path: 'fit-proper',
    displayName: 'Fit and Proper Criteria',
    model: FitAndProper,
    name: 'recruit-forms-fit-proper',
    component: () => import('@core/pages/recruit/forms/FitAndProper.vue')
  },
  {
    path: 'quali-employ',
    displayName: 'Qualification & Employment',
    model: QualificationEmployment,
    name: 'recruit-forms-qualification-employment',
    component: () => import('@core/pages/recruit/forms/QualificationEmployment.vue')
  },
  {
    path: 'sec-deal',
    displayName: 'Letter of Undertaking for Securities Dealing',
    model: SecuritiesDealing,
    name: 'recruit-forms-sec-deal',
    component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  },
  {
    path: 'bi-so',
    displayName: 'Declaration of Business Interest & Shareholdings / Ownership',
    model: BusinessInterestShareholding,
    name: 'recruit-forms-bi-so',
    component: () => import('@core/pages/recruit/forms/DeclarationBusinessInterest.vue')
  },
  {
    path: 'name-card-order',
    displayName: 'Name Card Order',
    model: NameCardOrder,
    name: 'recruit-forms-name-card-order',
    component: () => import('@core/pages/recruit/forms/NameCardOrderForm.vue')
  },
  {
    path: 'transfer-rules',
    displayName: 'Transfer Rules Acknowledgement',
    model: TransferRulesAcknowledgement,
    name: 'recruit-forms-name-transfer-rules',
    component: () => import('@core/pages/recruit/forms/TransferRulesAcknowledgement.vue')
  },
  {
    path: 'cpf',
    displayName: 'CPF Authorization',
    model: CPFAuthorization,
    name: 'recruit-forms-cpf',
    component: () => import('@core/pages/recruit/forms/CpfAuthorization.vue')
  },
  {
    path: 'undertaking',
    displayName: 'Letter of Undertaking Without CMFAS M8 & MA8',
    model: LetterOfUndertaking,
    name: 'recruit-forms-undertaking',
    component: () => import('@core/pages/recruit/forms/LetterOfUndertaking.vue')
  },
  {
    path: 'prev-employment',
    displayName: 'Current and/or Previous Employment/Appointment Inquiry',
    model: PrevEmploymentInquiry,
    name: 'recruit-forms-prev-employment',
    component: () => import('@core/pages/recruit/forms/PrevEmploymentInquiry.vue')
  },
  {
    path: 'declaration-clauses',
    displayName: 'Declaration Clauses Acknowledgement',
    model: DeclarationClauses,
    name: 'recruit-forms-declaration-clauses',
    component: () => import('@core/pages/recruit/forms/DeclarationClauses.vue')
  },
  {
    path: 'rma',
    displayName: 'Recruiting Manager Assessment',
    model: RecruitManagerAssessment,
    name: 'recruit-forms-rma',
    component: () => import('@core/pages/recruit/forms/RecruitManagerAssessment.vue')
  },
  {
    path: 'bdma',
    displayName: 'BDM Assessment',
    model: BDMAssessment,
    name: 'recruit-forms-bdma',
    component: () => import('@core/pages/recruit/forms/BdmAssessment.vue')
  },
  {
    path: 'employer-consent',
    displayName: 'Employer Consent',
    model: EmployerConsent,
    name: 'recruit-forms-ec',
    component: () => import('@core/pages/recruit/forms/EmployerConsent.vue')
  },
  {
    path: 'rsr',
    displayName: 'Recruitment Subsidy Reimbursement Form',
    model: RecruitSubsidyReimbursement,
    name: 'recruit-forms-rsr',
    component: () => import('@core/pages/recruit/forms/RecruitSubsidyReimbursement.vue')
  }
  // {
  //   path: 'sec-deal-1',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal-1',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal-2',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal-2',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal-3',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal-4',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal-5',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal-6',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // }
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },{
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // },
  // {
  //   path: 'sec-deal',
  //   displayName: 'Letter of Undertaking for Securities Dealing',
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // }
  //   model: SecuritiesDealing,
  //   name: 'recruit-forms-sec-deal',
  //   component: () => import('@core/pages/recruit/forms/SecuritiesDealingForm.vue')
  // }
]
