import { Meta } from '../model/common/Meta'
import { isUndefined } from 'lodash/fp'
import 'reflect-metadata'

const metaClassMetadataKey = Symbol('MetaClass')

class MetaClassOptions {
  isArray = false
}

interface MetaClassMetadata<T extends Meta> {
  MetaClass: new () => T;
  options: MetaClassOptions;
}

export function MetaClass<T extends Meta> (MetaClass: new () => T, options?: MetaClassOptions) {
  return Reflect.metadata(metaClassMetadataKey, { MetaClass, options })
}

export function needToConvertToMetaClass (target: any, propertyKey: string): boolean {
  return !isUndefined(Reflect.getMetadata(metaClassMetadataKey, target, propertyKey))
}

export function convertJsonToMetaClass<T extends Meta> (target: any, propertyKey: string, value: object | object[]): T | T[] {
  const metaClassMetadata: MetaClassMetadata<T> = Reflect.getMetadata(metaClassMetadataKey, target, propertyKey)
  const MetaClass = metaClassMetadata.MetaClass
  const { isArray } = metaClassMetadata.options || new MetaClassOptions()

  if (isArray) {
    if (!value || !(value instanceof Array)) {
      return []
    }

    return (value as object[]).map(e => {
      const newMetaClass = new MetaClass()
      newMetaClass.assignData(Object.keys(e), Object.values(e))
      return newMetaClass
    })
  } else {
    const newMetaClass = new MetaClass()

    if (!value) {
      return newMetaClass
    }

    newMetaClass.assignData(Object.keys(value), Object.values(value))
    return newMetaClass
  }
}
