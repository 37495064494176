import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { DataLoadService } from '@core/openapi/services/dataload/api'

@Module({
  namespaced: true,
  name: 'DataLoadStore'
})
export default class DataLoadStore extends VuexModule {
  loading = false
  dataLoadListRecordsTotal = 0
  dataLoadList = []
  dataloadImportResItems = []
  dataloadImportResError = []

  @Action({rawError: true})
  async retrieveDataLoadList (payload) {
    this.context.commit('setLoading', true)
    console.log(payload)
    try {
      const list = await DataLoadService.getDataLoadSummaryList(payload)
      this.context.commit('setDataLoadList', list)
    } catch (error) {
      alert(error.message)
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Action({rawError: true})
  async importCSV (payload) {
    console.log(payload)
    try {
      const data = await DataLoadService.importCsvUsersIntoSystemV3(payload)
      this.context.commit('setImportData', data)
    } catch (error) {
      alert(error.message)
    }
  }

  @Mutation
  setDataLoadList ({data, recordsTotal}) {
    this.dataLoadList = data
    this.dataLoadListRecordsTotal = recordsTotal
  }

  @Mutation
  setImportData ({imported, exceptions}) {
    this.dataloadImportResItems = imported
    this.dataloadImportResError = exceptions

    // this.dataLoadListRecordsTotal = recordsTotal
  }

  @Action ({rawError: true})
  async download (payload) {
   const res =  await DataLoadService.getFile(payload)

   return res;
  }

  @Mutation
  setLoading (loading: boolean) {
    this.loading = loading
  }
}
