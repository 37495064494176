import { Ignore } from '@master/annotation/Ignore'
import { RawKey } from '@master/annotation/RawKey'
import { Meta } from '@master/model/common/Meta'

export default class EmploymentMeta extends Meta {
  @RawKey('employment_oid')
  @Ignore()
  oid = ''

  nameOfEmployer = ''
  address = ''
  dateFrom: number = undefined
  dateTo: number = undefined
  designation = ''
  jobDescription = ''
  idx: number = undefined
}
