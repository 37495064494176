import { snakeCase } from 'lodash/fp'
import { TypeBase } from './TypeBase'
import { Ignore } from '@master/annotation/Ignore'

export class ArrayBase extends TypeBase {
  @Ignore()
  startIndex = 0

  @Ignore()
  curIndex = 0

  constructor () {
    super('')
  }

  snakeCaseKey (key: string) {
    return snakeCase(`${this.type} ${this.curIndex + this.startIndex} ${key}`)
  }

  // to get data from raw kycForm to fill in current class
  extractData (rawData: any, index: number) {
    this.curIndex = index
    return super.extractData(rawData)
  }
}
