import { FNAStructure } from '@master/model/structure/FNAStructure'
import LongTermProtectionClient from '@master/model/kyc-form/need-analysis/protection/LongTermProtectionClient'

export function LongTermCareProtectionStructure () {
  const structure: FNAStructure<LongTermProtectionClient> = {
    title: 'P5. Long Term Care Protection',
    data: [
      {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Monthly amount needed ($)',
        key: 'amountNeeded',
        type: 'currency',
        validationMsg: 'Long Term Care Protection requires to indicate monthly amount.'
      }, {
        label: 'Preferred benefit payout period (years)',
        key: 'period',
        validationMsg: 'Long Term Care Protection requires to indicate preferred payout period is required.'
      }, {
        label: '- Existing insurance ($)',
        key: 'available1',
        type: 'currency',
        validationMsg: 'Long Term Care Protection requires to indicate existing insurance amount.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
