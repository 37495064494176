import { Default } from '@master/annotation/Default'
import RiderProduct from '@master/model/kyc-form/product/RiderProduct'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import ProductBase from './ProductBase'
import { PaymentModeList } from '@master/model/options/PaymentModeOptions'
import { ClientChoiceOptions } from '@master/model/options/ClientChoiceOptions'

export default class BasicProduct extends ProductBase {
  clientIndex?: 0 | 1 = 0
  jointClientIndex?: 0 | 1 = undefined

  // insurance
  lifeAssured = ''
  lifeAssuredTwo = ''

  paymentMode: '' | typeof PaymentModeList[number] = ''
  policyTerm: number = undefined
  premiumTerm: number = undefined

  @Default([])
  riders: RiderProduct[] = []

  afterExtractData (data: { riders: any[] }) {
    const defaultMap = super.afterExtractData(data)

    data.riders = data.riders.map((element) => {
      // eslint-disable-next-line dot-notation
      const rider = new RiderProduct(this.riskRatingService, defaultMap['clientIndex'])
      Object.assign(rider, rider.extractData(element))
      return rider
    })

    Object.assign(data, defaultMap)
    return data
  }

  convertToRawData () {
    const data: any = super.convertToRawData()

    // Convert riders
    const rawRiders = this.riders.map(riderProduct => riderProduct.convertToRawData())
    data.riders = rawRiders

    return data
  }

  get totalAnnualisedPremium () {
    let total = 0
    if (this.isAcceptedProduct) {
      total += this.annualisedPremium
      total += this.riders.reduce((total, rider) => total + rider.annualisedPremium, 0)
    }

    // Ensure that the number is always to 2 decimal places and not
    // random floating point value if the total is decimal place value
    if (total % 1 !== 0) {
      total = Math.round((total + Number.EPSILON) * 100) / 100
    }

    return total
  }

  get totalSinglePremium () {
    let total = 0
    if (this.isAcceptedProduct) {
      total += this.singlePremium
      total += this.riders.reduce((total, rider) => total + rider.singlePremium, 0)
    }

    // Ensure that the number is always to 2 decimal places and not
    // random floating point value if the total is decimal place value
    if (total % 1 !== 0) {
      total = Math.round((total + Number.EPSILON) * 100) / 100
    }

    return total
  }

  constructor (riskRatingService: IRiskRatingService) {
    super(riskRatingService)

    this.riskRatingService = riskRatingService

    const rejectValues = ['reject', 'kiv']
    // add proxy to get onChange on clientChoice and set all the riders to the save value if client reject or kiv
    // this is used in Our Recommendation client's decision tab
    // to make sure user can't reject or kiv on basic product and make riders accept or own choice at the same time
    return new Proxy(this, {
      set: function (obj, prop, value) {
        if (prop === 'clientChoice' && rejectValues.includes(value)) {
          obj.riders.forEach(rider => { rider.clientChoice = value as keyof typeof ClientChoiceOptions })
        }
        obj[prop] = value
        return true
      }
    })
  }
}
