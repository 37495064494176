import { FormBase } from './FormBase'
import { FormsList } from './FormsList'
import { BDMAssessment } from './forms/BDMAssessment'
import { BusinessInterestShareholding } from './forms/BusinessInterestShareholding'
import { CPFAuthorization } from './forms/CPFAuthorization'
import { DeclarationClauses } from './forms/DeclarationClauses'
import { EmployerConsent } from './forms/EmployerConsent'
import { FitAndProper } from './forms/FitAndProper'
import { GeneralDeclaration } from './forms/GeneralDeclaration'
import { LetterOfUndertaking } from './forms/LetterOfUndertaking'
import { NameCardOrder } from './forms/NameCardOrder'
import { Personal } from './forms/Personal'
import { PrevEmploymentInquiry } from './forms/PrevEmploymentInquiry'
import { QualificationEmployment } from './forms/QualificationEmployment'
import { RecruitManagerAssessment } from './forms/RecruitManagerAssessment'
import { RecruitSubsidyReimbursement } from './forms/RecruitSubsidyReimbursement'
import { SecuritiesDealing } from './forms/SecuritiesDealing'
import { Shared } from './forms/Shared'
import { TransferRulesAcknowledgement } from './forms/TransferRulesAcknowledgement'

export class AllForms {
  shared = new Shared()
  personal = new Personal()
  qualificationEmployment = new QualificationEmployment()
  generalDeclaration = new GeneralDeclaration()
  fitProper = new FitAndProper()
  // app = new Application()
  bdm = new BDMAssessment()
  bis = new BusinessInterestShareholding()
  cpf = new CPFAuthorization()
  ec = new EmployerConsent()
  lou = new LetterOfUndertaking()
  nco = new NameCardOrder()
  pei = new PrevEmploymentInquiry()
  rma = new RecruitManagerAssessment()
  rsr = new RecruitSubsidyReimbursement()
  sd = new SecuritiesDealing()
  tra = new TransferRulesAcknowledgement()
  dc = new DeclarationClauses()

  getForm (formType: typeof FormBase): FormBase {
    if (!formType) return new FormBase()
    const targetKey = Object.keys(this).find((formKey) => {
      return this[formKey] instanceof formType
    })
    if (!targetKey) alert(`Unable to retrieve corresponding form for : ${formType}`)
    return this[targetKey]
  }

  extractAndAssign (forms: any[]) {
    // console.time('extractAndAssign AllForms')
    forms.forEach((form) => {
      Object.keys(this).forEach((formKey) => {
        const formModel = this[formKey]
        if (!(formModel instanceof FormBase)) return
        if (formModel.type !== form.type) return
        formModel.extractAndAssign(form)
      })
    })
    // console.timeEnd('extractAndAssign AllForms')
  }

  /**
   * compile and return errors from individual forms
   * TIP : add your inter-form validation (requiring values from 2 forms or more) here
   * @returns array of error messages
   */
  errors (): string[] {
    return Object.values(this).map((form) => {
      return form.error
    }).flat()
  }

  get asOptions (): { label: string; value: string }[] {
    return Object.keys(this).map((formKey) => {
      if (!(this[formKey] instanceof FormBase)) return null
      const target = FormsList.find(formConfig => this[formKey] instanceof formConfig.model)
      return target ? { label: target.displayName, value: this[formKey].type } : null
    }).filter(form => form !== null)
  }

  get configs (): any[] {
    return Object.keys(this).map((formKey) => {
      if (!(this[formKey] instanceof FormBase)) return null
      const target = FormsList.find(formConfig => this[formKey] instanceof formConfig.model)
      return target ? {
        ...target,
        ...this[formKey]
      } : null
    }).filter(form => form !== null)
  }
}
