import { FNAStructure } from '@master/model/structure/FNAStructure'
import DisabilityIncomeProtectionClient from '@master/model/kyc-form/need-analysis/protection/DisabilityIncomeProtectionClient'

export function DisabilityIncomeProtectionStructure (defaultValue: { available1: number; annualIncome: number }) {
  const structure: FNAStructure<DisabilityIncomeProtectionClient> = {
    title: 'P4. Disability Income Protection',
    data: [
      {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Desired annual income required to sustain lifestyle during disability ($)',
        key: 'income',
        type: 'currency',
        default: defaultValue?.annualIncome,
        validationMsg: 'Disability Income Protection requires to indicate desired annual income.'
      }, {
        label: 'Number of years required',
        key: 'yearsRequired',
        validationMsg: 'Disability Income Protection requires to indicate years required.'
      }, {
        label: 'Inflation adjusted rate return (%)',
        key: 'infAdjReturn',
        defaultTo: 'defaultRate',
        validationMsg: 'Disability Income Protection requires to indicate inflation adjusted rate return.'
      }, {
        label: 'Lump sum required to maintain lifestyle (PV) ($)',
        key: 'fundsRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '+ Cost of medical treatment ($)',
        key: 'required1',
        type: 'currency',
        validationMsg: 'Disability Income Protection requires to indicate the medical cost.'
      }, {
        label: '+ Other funding needs ($)',
        key: 'required2',
        type: 'currency',
        validationMsg: 'Disability Income Protection requires to indicate other funding needs.'
      }, {
        label: 'Estmated amount required for disability ($)',
        key: 'totalRequired',
        type: 'currency',
        disabled: true
      }, {
        label: '- Existing disability insurance ($)',
        key: 'available1',
        type: 'currency',
        default: defaultValue?.available1,
        validationMsg: 'Disability Income Protection requires to indicate existing disability insurance.'
      }, {
        label: 'Other funds available ($)',
        key: 'available2',
        type: 'currency',
        validationMsg: 'Disability Income Protection requires to indicate other funds available.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
