import { Default } from '@master/annotation/Default'
import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { TypeBase } from '@master/model/TypeBase'
import { sum } from 'lodash/fp'

export interface IWynnesAI<T> {
  desc: string;
  key: keyof T;
}

export default class WynnesAI extends TypeBase {
  criteria1 = ''
  criteria2 = ''
  criteria3 = ''
  criteria4 = ''

  @Ignore()
  @Pointer('netWorth', 'residentialAssets')
  residentialProp = ''

  @Ignore()
  @Pointer('netWorth', 'totalCashAssets')
  cash = ''

  @Ignore()
  @Pointer('netWorth', 'cpfOrdinary')
  cpfOA = ''

  @Ignore()
  @Pointer('netWorth', 'cpfSpecial')
  cpfSA = ''

  get cpf () {
    return sum([Number(this.cpfOA) || 0, Number(this.cpfSA) || 0])
  }

  @Ignore()
  @Pointer('netWorth', 'totalAssets')
  totalAssets = 0

  get otherAssets () {
    return this.totalAssets - sum([Number(this.residentialProp) || 0, Number(this.cash) || 0, Number(this.cpf) || 0])
  }

  @Ignore()
  @Pointer('netWorth', 'personalLoans')
  loansOverdraft = ''

  @Ignore()
  @Pointer('netWorth', 'tax')
  tax = ''

  @Ignore()
  @Pointer('netWorth', 'homeMortgage')
  mortgage = ''

  @Ignore()
  @Pointer('netWorth', 'carLoans')
  hp = ''

  get mortgageHp () {
    return sum([Number(this.mortgage) || 0, Number(this.hp) || 0])
  }

  @Ignore()
  @Pointer('netWorth', 'totalLiabilities')
  totalLiabilities = 0

  get otherLiabilities () {
    return Number(this.totalLiabilities) - sum([Number(this.loansOverdraft) || 0, Number(this.tax) || 0, Number(this.mortgageHp) || 0])
  }

  // Supporting Documents
  @Default(false)
  residentialPropDoc = false

  @Default(false)
  investmentPropDoc = false

  @Default(false)
  cashDoc = false

  @Default(false)
  cpfDoc = false

  @Default(false)
  securitiesDoc = false

  @Default(false)
  otherAssetsDoc = false

  @Default(false)
  loansOverdraftDoc = false

  @Default(false)
  taxDoc = false

  @Default(false)
  mortgageHpDoc = false

  @Default(false)
  otherLiabilitiesDoc = false

  constructor (client: 'client_0' | 'client_1' = 'client_0') {
    super(client + '_ai')
  }

  @Ignore()
  assetFormFields (): IWynnesAI<this>[] {
    return [
      {
        key: 'residentialProp',
        desc: 'Residential Property'
      },
      {
        key: 'cash',
        desc: 'Cash / Cash Deposit'
      },
      {
        key: 'cpf',
        desc: 'CPF Ordinary & Special Account Balances'
      },
      {
        key: 'otherAssets',
        desc: 'Others'
      }
    ]
  }

  @Ignore()
  liabilitiesFormFields (): IWynnesAI<this>[] {
    return [
      {
        key: 'loansOverdraft',
        desc: 'Personal Loans, Overdrafts'
      },
      {
        key: 'tax',
        desc: 'Tax Payable'
      },
      {
        key: 'mortgageHp',
        desc: 'Mortgages, Hire Purchase'
      },
      {
        key: 'otherLiabilities',
        desc: 'Others'
      }
    ]
  }

  @Ignore()
  get largeAssetsInvestor () {
    return [this.criteria2, this.criteria3, this.criteria4].includes('yes')
  }

  @Ignore()
  get isAccredited () {
    return [this.criteria1, this.criteria2, this.criteria3, this.criteria4].includes('yes')
  }

  @Ignore()
  get netTotal () {
    return sum([Number(this.totalAssets) || 0, -Number(this.totalLiabilities)])
  }
}
