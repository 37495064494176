/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyInformationImportDTO = {
    paymentStatus?: CompanyInformationImportDTO.paymentStatus | string;
    userOid?: string;
    corporateEmail: string;
    companyCode: string;
    masRepNo?: string;
    masApplicationDate?: number;
    masYearlyRenewalDate?: number;
    masTriYearlyRenewalDate?: number;
    closeMonitoringFromDate?: number;
    closemonitoringToDate?: number;
    remark?: string;
    suspendedFromDate?: number|string;
    suspendedToDate?: number|string;
    requirementCheckList?: any;
    contractType?: any;
    ifpasNo?: string;
    contractDetails?: any;
    contractedFromDate?: number;
    terminatedDate?: number;
    issuedDate?: number;
    lastPromotionDate?: number;
    withExperience?: string;
    personalItemsIssued?: any;
    channel?: CompanyInformationImportDTO.channel| string;
    isChannelVisible?: boolean;
    contractTypeLabel: string;
  
}

export namespace CompanyInformationImportDTO {

    export enum paymentStatus {
        COMMISSION_PAID = 'commissionPaid',
        COMMISSION_HOLD = 'commissionHold',
    }

    export enum channel {
        SG = 'SG',
        PS = 'PS',
        PFP = 'PFP',
    }


}