/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HierarchySetupDTO } from '../models/HierarchySetupDTO';
import { request as __request } from '@openapi/request';

export class HierarchySetupService {

    /**
     * @returns HierarchySetupDTO
     * @throws ApiError
     */
    public static async getHierarchyList(): Promise<Array<HierarchySetupDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/hierarchy_setup`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateHierarchyRecord(
        requestBody: HierarchySetupDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/hierarchy_setup`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async downloadHierarchyListCsv(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/hierarchy_setup/download_hierarchy_csv`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns HierarchySetupDTO
     * @throws ApiError
     */
    public static async importHierarchySetup(
        requestBody: Array<HierarchySetupDTO>,
    ): Promise<Array<HierarchySetupDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/hierarchy_setup/import_hierarchy_setup`,
            body: requestBody,
        });
        return result.body;
    }

}