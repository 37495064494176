import ESubProduct from './ESubProduct'

export default class ExternalSub {
  _oid = ''
  caseId = ''
  caseNo = ''
  companyName = ''
  eSubApprovedTimestamp = -1
  eSubFinalisedTimestamp = -1
  eSubmissionMasterOid = ''
  expired = false
  pdfs = []
  policyStatusJson = ''
  products: ESubProduct[] = []
  status = 'Cancel'
  isEnabled = false

  constructor (rawData) {
    this.extractData(rawData)
  }

  extractData (rawData: any) {
    if (rawData) {
      Object.keys(this).forEach((key) => {
        if (key === 'products') {
          rawData[key].forEach((raw, idx) => {
            this.products[idx] = new ESubProduct(raw)
          })
          return
        }
        if (key in this) this[key] = rawData[key]
      })
    }
  }

  updateProperties (latest: any) {
    Object.keys(latest).forEach((key) => {
      if (key === 'products') {
        latest.products.forEach((newdata, idx) => {
          this.products[idx].updateProperties(newdata)
        })
        return
      }
      if (key in this) this[key] = latest[key]
    })
  }

  pdfTeleporter () {
    const teleporters = this.pdfs.map((pdf) => {
      const pdfLinks = {}
      if (pdf.hasProposal) Object.assign(pdfLinks, { Proposal: `/web/dsfPdfView?pdfOid=${pdf.pdfOid}&pdfType=proposal` })
      if (pdf.hasQuotation) Object.assign(pdfLinks, { Quotation: `/web/dsfPdfView?pdfOid=${pdf.pdfOid}&pdfType=quotation` })
      if (pdf.hasQuestionnaire) Object.assign(pdfLinks, { Questionnaire: `/web/dsfPdfView?pdfOid=${pdf.pdfOid}&pdfType=questionnaire` })
      return pdfLinks
    })
    // the following is for testing purpose ( dummy data)
    // teleporters.push({
    //   Proposal: '/dsfPdfView?pdfOid=test&pdfType=proposal',
    //   Quotation: '/dsfPdfView?pdfOid=test&pdfType=proposal',
    //   Questionnaire: '/dsfPdfView?pdfOid=test&pdfType=proposal'
    // })
    return teleporters
  }

  get policyStatusParsed () {
    let policyDetails
    // if a string is passed in, convert to a json array
    if (typeof this.policyStatusJson === 'string' && this.policyStatusJson !== '') {
      policyDetails = JSON.parse(this.policyStatusJson)
    } else {
      policyDetails = this.policyStatusJson
    }
    if (policyDetails === null || typeof policyDetails === 'undefined') {
      return 'No policy statuses found from dsf for this case id.'
    }
    let policyStatusString = ''
    for (let i = 0; i < policyDetails.length; ++i) {
      let productId = policyDetails[i].productId
      if (productId === null || typeof productId === 'undefined') {
        productId = ''
      }
      let policyNo = policyDetails[i].policyNo
      if (policyNo === null || typeof policyNo === 'undefined') {
        policyNo = ''
      }
      let status = policyDetails[i].status
      if (status === null || typeof status === 'undefined') {
        status = ''
      }
      policyStatusString = policyStatusString.concat(`Product ID: ${productId}<br>`)
      policyStatusString = policyStatusString.concat(`Policy No.: ${policyNo}<br>`)
      policyStatusString = policyStatusString.concat(`Status: ${status}<br><br>`)
    }
    return policyStatusString
  }

  isNotFinalised () {
    return ['not submitted', 'in progress', 'completed'].includes(this.status?.toLowerCase())
  }

  isSubmittedOrApproved () {
    return ['submitted', 'approved'].includes(this.status?.toLowerCase())
  }

  isSubmitted () {
    return this.status?.toLowerCase() === 'submitted'
  }

  isCancelled () {
    return this.status?.toLowerCase() === 'cancel'
  }

  canFinaliseESub () {
    return this.status?.toLowerCase() === 'completed'
  }

  isNtuc () {
    const companyNameMin = this.companyName.replace(/ /g, '').toLowerCase()
    if (companyNameMin === 'ntuc' || companyNameMin === 'income' || companyNameMin === 'ntucincome') {
      return true
    }
    return false
  }
}
