export class ProductILPSingle {
  name = ''
  amt = ''
  approved_date: number = undefined
  unit_balance = ''
  risk_class = ''
  cash = ''
  stock = ''
  bonds = ''
}
