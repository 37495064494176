import { sum, round, valueIsUndefined } from '@master/model/kyc-form/need-analysis/math'
import NeedsBase from '../NeedsBase'
import { isUndefined } from 'lodash/fp'
/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class InvestmentNeeds extends NeedsBase {
  name = 'Investment'
  needs = 'Savings and Investment'
  planning = ''
  interestRate?: number = undefined
  period?: number = undefined
  amountFv?: number = undefined
  fundValue?: number = undefined
  additionalNotes = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 's3') {
    super(`${type}_${id}`)
  }

  setupDefaultRate (rate: number) {
    super.setupDefaultRate(rate)
    if (isUndefined(this.interestRate)) this.interestRate = this.defaultRate
  }

  get totalFundsFv () {
    if (valueIsUndefined(this.fundValue) && valueIsUndefined(this.period)) return null
    const rate = this.interestRate || 0

    const accumulatedInterestRate = (1 + rate / 100) ** this.period
    const total = this.fundValue * accumulatedInterestRate

    return round(total, 0)
  }

  get surplusShortfall () {
    if (valueIsUndefined(this.totalFundsFv) && valueIsUndefined(this.amountFv)) return null
    return sum(this.totalFundsFv, -this.amountFv)
  }

  get isDefaultRate () {
    return this.defaultRate === this.interestRate
  }
}
