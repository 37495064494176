/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchCreateDTO } from '../models/BranchCreateDTO';
import type { BranchDirectorDTO } from '../models/BranchDirectorDTO';
import type { BranchDTO } from '../models/BranchDTO';
import type { BranchUpdateDTO } from '../models/BranchUpdateDTO';
import type { ManagerDTO } from '../models/ManagerDTO';
import type { ManagerGroupCreateDTO } from '../models/ManagerGroupCreateDTO';
import type { ManagerGroupDTO } from '../models/ManagerGroupDTO';
import type { ManagerGroupUpdateDTO } from '../models/ManagerGroupUpdateDTO';
import { request as __request } from '@openapi/request';
import type { BranchListDTO } from '../models/BranchListDTO';
import type { ManagerListDTO } from '../models/ManagerListDTO';
export class BranchManagerService {

    /**
     * @param withAssignedLeader
     * @returns BranchDTO Gets the list of branches
     * @throws ApiError
     */
    public static async retrieveBranchList(
        requestBody: BranchListDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/branch-manager/branch/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns BranchDirectorDTO Gets the list of branch directors
     * @throws ApiError
     */
    public static async retrieveDirectorList(): Promise<Array<BranchDirectorDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/branch-manager/director/list`,
        });
        return result.body;
    }

    public static async retrieveBranchDropdown(): Promise<Array<any>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/branch-manager/branch/dropdown`,
        });
        return result.body;
    }

    public static async retrieveManagerDropdown(): Promise<Array<any>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/branch-manager/manager-group/dropdown`,
        });
        return result.body;
    }
    

    /**
     * @param requestBody
     * @returns BranchDTO Creates a new branch
     * @throws ApiError
     */
    public static async createBranch(
        requestBody: BranchCreateDTO,
    ): Promise<BranchDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/branch-manager/branch`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns BranchDTO Updates a branch
     * @throws ApiError
     */
    public static async updateBranch(
        requestBody: BranchUpdateDTO,
    ): Promise<BranchDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/branch-manager/branch`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param withAssignedLeader
     * @returns ManagerGroupDTO Gets the list of manager groups
     * @throws ApiError
     */
    public static async retrieveManagerGroupList(
        requestBody: ManagerListDTO
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/branch-manager/manager-group/list`,
            body: requestBody
        });
        return result.body;
    }

    /**
     * @returns ManagerDTO Gets the list of managers
     * @throws ApiError
     */
    public static async retrieveManagerList(): Promise<Array<ManagerDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/branch-manager/manager/list`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ManagerGroupDTO Creates a new manager group
     * @throws ApiError
     */
    public static async createManagerGroup(
        requestBody: ManagerGroupCreateDTO,
    ): Promise<ManagerGroupDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/branch-manager/manager-group`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns ManagerDTO Updates a manager group
     * @throws ApiError
     */
    public static async updateManagerGroup(
        requestBody: ManagerGroupUpdateDTO,
    ): Promise<ManagerDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/branch-manager/manager-group`,
            body: requestBody,
        });
        return result.body;
    }

}