import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { GeneratedReportStatementsService } from '@core/openapi/services/generated-report-statements/api/services/GeneratedReportStatementsService'

@Module({
  namespaced: true,
  name: "ExportedStore",
})

export default class ExportedStore extends VuexModule {
  exportedReportsData = []
  exportedReportsRecordTotal = 0

  @Mutation 
  setExportedRecords ({data, recordsTotal}) {
    this.exportedReportsData = data;
    this.exportedReportsRecordTotal = recordsTotal;
  } 
  
  @Action
  async exportedRecordsList(params) {
      const {page, limit} = params
      try {
    const result = await GeneratedReportStatementsService.retrieveBranchList({page, limit });
    console.log(result);

    this.context.commit("setExportedRecords", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Action({ rawError: true})
  async downloadExport(params) {
    const result = await GeneratedReportStatementsService.downloadReportFile(params);
    return result
    // this.context.commit("setExportedRecords", result);
  }
}