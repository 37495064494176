import { Ignore } from '@master/annotation/Ignore'
import { TypeBase } from '../../TypeBase'

export const priorityLevel = {
  '': '',
  h: 'High',
  m: 'Medium',
  l: 'Low',
  na: 'Not Applicable'
} as const

export default class NeedsBase extends TypeBase {
  plan = false
  level: keyof typeof priorityLevel = ''

  // To indicate if this needs is protection
  // is used for PTC No recommendation for high priority needs
  @Ignore()
  isProtection = false

  @Ignore()
  name = ''

  @Ignore()
  needs: string = undefined

  @Ignore()
  defaultRate?: number

  /**
   * this is to allow implementation side to set default rate to the model
   * @param inDefaultRate
   */
  setupDefaultRate (inDefaultRate: number) {
    this.defaultRate = inDefaultRate
  }

  resetAllFields () {
    const excludedKeys = ['type', 'needs', 'name', 'defaultRate', 'inflation', 'interestRate', 'infAdjReturn', 'projectSettings']
    Object.keys(this).filter(key => !excludedKeys.includes(key)).map(key => {
      this[key] = undefined
    })
  }
}
