import { getClone } from '@core/annotation/ExpandableArray'
export class FormBase {
  oid = ''
  version = '1'
  type = ''
  accountOid = ''
  activityId = ''
  signed = false
  data = {}
  signatures = {
    applicant: '',
    applicantDate: undefined
  }

  pdfName = '' // this is for composite form reference, e.g. application form (which consist of subforms)

  extractAndAssign (formData: { data: {} }) {
    // console.time(`extractAndAssign ${this.type}`)
    Object.keys(this).forEach((key) => {
      if (this[key] instanceof Array && formData[key] instanceof Array) {
        this.extractArray(this, key, this[key], formData[key])
      } else if (this[key] instanceof Object && formData[key] instanceof Object) {
        this.extractObject(this[key], formData[key])
      } else if (key in formData && key in this) {
        this[key] = formData[key]
      }
    })
    // console.timeEnd(`extractAndAssign ${this.type}`)
  }

  resizeArray (parent: any, key: any, target: any[], source: any[]) {
    const required = source.length - target.length
    if (required > 0 && getClone(parent, key)) {
      Array(required).fill(null).forEach(() => {
        target.splice(target.length, 1, getClone(parent, key)())
      })
    } else if (required < 0) {
      target.splice(source.length - 1)
    }
  }

  extractArray (parent: any, key: any, target: any[], source: any[]) {
    let i = 0
    this.resizeArray(parent, key, target, source)
    while (source.length > i) {
      if (target[i] instanceof Array && source[i] instanceof Array) {
        this.extractArray(target, i, target[i], source[i])
      } else if (target[i] instanceof Object && source[i] instanceof Object) {
        this.extractObject(target[i], source[i])
      } else {
        target.splice(i, 1, source[i])
      }
      i++
    }
  }

  extractObject (target: object, source: object) {
    Object.entries(target).forEach(([fk, fv]) => {
      // if both match
      if (fv instanceof Array && source[fk] instanceof Array) {
        this.extractArray(target, fk, fv, source[fk])
      } else if (fv instanceof Object && source[fk] instanceof Object) {
        this.extractObject(fv, source[fk])
      } else {
        // console.log(target, fk, source[fk])
        if (fk in source) Object.assign(target, { [fk]: source[fk] })
      }
    })
  }

  isDirty (saved: FormBase) {
    return Object.entries(this).some(([fk, fv]) => {
      if (fv instanceof Array && saved[fk] instanceof Array) {
        return this.isArrayDirty(fv, saved[fk])
      } else if (fv instanceof Object && saved[fk] instanceof Object) {
        return this.isObjectDirty(fv, saved[fk])
      } else {
        if (fv !== saved[fk]) console.log('isDirty', fk, fv, saved[fk])
        return fv !== saved[fk]
      }
    })
  }

  isObjectDirty (obj1: object, obj2: object) {
    return Object.entries(obj1).some(([fk, fv]) => {
      if (fv instanceof Array && obj2[fk] instanceof Array) {
        return this.isArrayDirty(fv, obj2[fk])
      } else if (fv instanceof Object && obj2[fk] instanceof Object) {
        return this.isObjectDirty(fv, obj2[fk])
      } else {
        if (fv !== obj2[fk]) console.log('isObjectDirty', fk, fv, obj1, obj2)
        return fv !== obj2[fk]
      }
    })
  }

  isArrayDirty (arr1: any[], arr2: any[]) {
    // console.log(arr1, arr2)
    if (arr1.length !== arr2.length) return true
    let i = 0
    let dirty = false
    while (arr1.length > i && arr2.length > i && dirty === false) {
      if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
        dirty = this.isArrayDirty(arr1[i], arr2[i])
      } else if (arr1[i] instanceof Object && arr2[i] instanceof Object) {
        dirty = this.isObjectDirty(arr1[i], arr2[i])
      } else {
        if (arr1[i] !== arr2[i]) console.log('isArrayDirty', arr1[i], arr2[i])
        dirty = arr1[i] !== arr2[i]
      }
      i++
    }
    return dirty
  }

  /**
   * this returns list of error pertaining the current form
   * Note : the form class should implement the error checking
   */
  get error (): FormError[] {
    return []
  }

  /**
   * override this method if any transformation of data is required before saving
   * @returns object (key-value)
   */
  transform () {
    const saveable = { ...this }
    delete saveable.pdfName
    return saveable
  }
}

export type FormError = {
  key: string;
  msg?: string;
  msgs?: { key: string; msg: string }[];
}

export type signature = {
  base64: string;
  date: number;
}
