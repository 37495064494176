import { Meta } from './Meta'

export class MetaList<T extends Meta> {
  protected list: T[]
  constructor (headers: string[], data: (string | number)[][], MetaClass: new () => T) {
    this.list = data.map(array => {
      const product = new MetaClass()
      product.assignData(headers, array)
      return product
    })
  }

  getFullList () {
    return this.list
  }
}
