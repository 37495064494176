import { Meta } from '@master/model/common/Meta'

export default class PersonalItemsIssuedMeta extends Meta {
  accessCardNumber = ''
  lockerNumber = ''
  pigeonBoxNumber = ''
  securityLevel = ''
  pedestalNumber = ''
  remarks = ''
}
