
import ExternalSub from '@master/model/kyc-pdf/ExternalSub'
import InternalSub from '@master/model/kyc-pdf/InternalSub'

export default class ESub {
  client: [
    {
      internal: Array<InternalSub>;
      external: Array<ExternalSub>;
    },
    {
      internal: Array<InternalSub>;
      external: Array<ExternalSub>;
    }
  ] = [
    {
      internal: [],
      external: []
    },
    {
      internal: [],
      external: []
    }
  ]

  dateOrApplication = ''

  extractData (esubData: any) {
    esubData.client.forEach((clien: { external: any[]; internal: any[] }, cindex: string | number) => {
      clien.external.forEach((exsub: any) => {
        const exsubObj = new ExternalSub(exsub)
        this.client[cindex].external.push(exsubObj)
      })
      clien.internal.forEach((insub) => {
        const insubObj = new InternalSub(insub)
        this.client[cindex].internal.push(insubObj)
      })
    })
    Object.keys(this).forEach((key) => {
      if (key !== 'client') {
        this[key] = esubData[key]
      }
    })
  }

  enabled () {
    // to check if esub is enabled
    // because the esub enabled flag is stored in external esub product, need check each of them
    // step:
    // 1. to extract all external first
    // 2. and flat it
    // 3. check if any one of esub product have esub isEnabled flag
    const externalList = this.client.map((cl) => cl.external).filter(ex => ex.length > 0).flat()
    return externalList.some(eSub => eSub.isEnabled)
  }

  allFinalised () {
    if (this.client === null || typeof this.client === 'undefined') {
      return true // if no proposal data was found, just return true
    }
    const clientData = this.client
    if (clientData === null || typeof clientData === 'undefined' || clientData.length <= 0) {
      return true
    }
    // is not all finalised if one is not finalised
    return !this.client.map((cl) => {
      return cl.external.map((ex) => {
        return ex.isNotFinalised() && ex.isEnabled // only check when it is enabled
      }).includes(true)
    }).includes(true)
  }

  allNtucFinalised () {
    if (this.client === null || typeof this.client === 'undefined') {
      return true // if no proposal data was found, just return true
    }
    const clientData = this.client
    if (clientData === null || typeof clientData === 'undefined' || clientData.length <= 0) {
      return true
    }
    // is not all finalised if either one is ntuc and not finalised
    return !this.client.map((cl) => {
      return cl.external.map((ex) => {
        return ex.isNtuc() && ex.isNotFinalised() && ex.isEnabled // only check when it is enabled
      }).includes(true)
    }).includes(true)
  }

  hasNtuc () {
    if (this.client === null || typeof this.client === 'undefined') {
      return true // if no proposal data was found, just return true
    }
    const clientData = this.client
    if (clientData === null || typeof clientData === 'undefined' || clientData.length <= 0) {
      return true
    }
    return this.client.map((cl) => {
      return cl.external.map((ex) => {
        return ex.isNtuc() && ex.isEnabled // only check when it is enabled; if it disabled, consider no NTUC
      }).includes(true)
    }).includes(true)
  }

  allCancelled () {
    if (this.client === null || typeof this.client === 'undefined') {
      return true // if no proposal data was found, just return true
    }
    const clientData = this.client
    if (clientData === null || typeof clientData === 'undefined' || clientData.length <= 0) {
      return true
    }
    return !this.client.map((cl) => {
      return !cl.external.map((ex) => {
        return ex.isCancelled() || !ex.isEnabled // if esub is disabled, consider it is cancel
      }).includes(false)
    }).includes(false)
  }
}
