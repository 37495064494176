import { Meta } from '@master/model/common/Meta'

export class RecruitmentChecklistMeta extends Meta {
  atLeast21Remarks = ''
  atLeast4GceRemarks = ''
  aLvlCheckedRemarks = ''
  examRequirementsRemarks = ''
  examRequirements2Remarks = ''
  examRequirements3Remarks = ''
  examRequirements4Remarks = ''
  examRequirements5Remarks = ''
  solvencySearchRemarks = ''
  contractStatusRemarks = ''
  marketConductRemarks = ''
  employerRemarks = ''
  masProhibitionCheckRemarks = ''
}
