export class RecruitRequirements {
  exams: string[] = [];
  personalityTest: string[] = [];
}
export default class RecruitDetails {
  oid = '';
  status = '';
  rnfTime: number = undefined;
  rnfDetails = '';
  referenceTime: number = undefined;
  referenceDetails = '';
  masnetTime: number = undefined;
  masnetDetails = '';
  accountOid = '';
  recruiterOid = '';
  requirements: RecruitRequirements = new RecruitRequirements();
  approvedTime: number = undefined;
  approvedOid = '';

  curUser: { _oid: string ; systemrole: string }

  get isCurrentRecruit () {
    return this.curUser?._oid === this.accountOid
  }

  get isCurrentRecruiter () {
    return this.curUser?._oid === this.recruiterOid
  }

  get isAdmin () {
    return ['ops-admin', 'it-super-admin'].includes(this.curUser?.systemrole)
  }
}
