import { Ignore } from '@master/annotation/Ignore'
import { RawKey } from '@master/annotation/RawKey'
import { Meta } from '@master/model/common/Meta'

export default class QualificationMeta extends Meta {
  @RawKey('qualification_oid')
  @Ignore()
  oid = ''

  nameOfQualification = ''
  attainmentBasis = ''
  institution = ''
  dateYearConferred: number = undefined
  idx: number = undefined
}
