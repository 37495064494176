/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MochaConfigUpsertDTO } from '../models/MochaConfigUpsertDTO';
import type { QuestionTemplateOptions } from '../models/QuestionTemplateOptions';
import { request as __request } from '@openapi/request';

export class MochaConfigService {

    /**
     * @param kid 
     * @returns MochaConfigUpsertDTO Gets the value of provided KID
     * @throws ApiError
     */
    public static async retrieveConfigValue(
kid: string,
): Promise<MochaConfigUpsertDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/mocha-config/${kid}`,
        });
        return result.body;
    }

    /**
     * @param kid 
     * @returns MochaConfigUpsertDTO Deletes a config value
     * @throws ApiError
     */
    public static async deleteConfigValue(
kid: string,
): Promise<MochaConfigUpsertDTO> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/mocha-config/${kid}`,
        });
        return result.body;
    }

    /**
     * @returns MochaConfigUpsertDTO Gets the value of assumptions KID
     * @throws ApiError
     */
    public static async retrieveAssumptionsConfigValue(): Promise<MochaConfigUpsertDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/mocha-config/assumptions`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns MochaConfigUpsertDTO Upserts a config value
     * @throws ApiError
     */
    public static async upsertConfigValue(
requestBody: MochaConfigUpsertDTO,
): Promise<MochaConfigUpsertDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/mocha-config`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns QuestionTemplateOptions Retrieves BSC Infraction Template UI
     * @throws ApiError
     */
    public static async retrieveBscInfractionTemplate(): Promise<Array<QuestionTemplateOptions>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/mocha-config/bsc/template`,
        });
        return result.body;
    }

}