import { Dependant } from '@master/model/kyc-form/Dependant'

export const YearsSupportMap = {
  Son: {
    label: 'Son',
    year: 25
  },
  Daughter: {
    label: 'Daughter',
    year: 23
  },
  Father: {
    label: 'Male',
    year: 81
  },
  Mother: {
    label: 'Female',
    year: 86
  },
  male: {
    label: 'Male',
    year: 81
  },
  female: {
    label: 'Female',
    year: 86
  }
}

export class CustomDependant extends Dependant {
  get customYearsSupport () {
    if (!this.yearsSupport && this.defaultYearsSupport) {
      this.yearsSupport = this.defaultYearsSupport.toString()
    }
    return this.yearsSupport
  }

  set customYearsSupport (value: string | number) {
    this.yearsSupport = value.toString()
  }

  get defaultYearsSupport () {
    if (!this.relationship || (this.age !== 0 && !this.age)) { return }
    const involvedRelationship = Object.keys(YearsSupportMap)
    if (involvedRelationship.includes(this.relationship as string)) {
      const endYear = YearsSupportMap[this.relationship].year
      let yearToSupport = endYear - this.age
      if (yearToSupport < 0) {
        yearToSupport = 0
      }
      if (!this.yearsSupport) {
        this.yearsSupport = yearToSupport.toString()
      }
      return yearToSupport
    }
    return ''
  }

  get requiredDeviationExplanation () {
    return this.yearsSupport && (this.yearsSupport !== this.defaultYearsSupport)
  }
}
