/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export interface ProductDTO {
    pky?: number;
    oid?: string;
    ctm?: number;
    utm?: number;
    commissionSchedule?: any;
    isBscClassify?: number;
    productFamilyName?: string;
    productFamilyCode?: string;
    productCode?: string;
    cat?: string;
    name?: string;
    type?: string;
    category?: string;
    needsCovered?: string;
    recommendedRiskProfile?: number;
    riskRating?: string;
    currency?: string;
    others?: string;
    paymentType?: string;
    premiumType?: string;
    // status?: ProductDTO.status;
    status?: string;
    launchDateMillis?: number;
    withdrawnDateMillis?: number;
    reasons?: string;
    limitations?: string;
    supplementaryBenefits?: string;
    commReceivableFromDateMillis?: number;
    commReceivableToDateMillis?: number;
    basicCommSp?: number;
    basicCommRsp?: number;
    basicCommTu?: number;
    basicCommYear1?: number;
    basicCommYear2?: number;
    basicCommYear3?: number;
    basicCommYear4?: number;
    basicCommYear5?: number;
    basicCommYear6?: number;
    basicCommYear7?: number;
    basicCommYear8?: number;
    basicCommYear9?: number;
    basicCommYear10?: number;
    overridingCommSp?: number;
    overridingCommRsp?: number;
    overridingCommTu?: number;
    overridingCommYear1?: number;
    overridingCommYear2?: number;
    overridingCommYear3?: number;
    overridingCommYear4?: number;
    overridingCommYear5?: number;
    overridingCommYear6?: number;
    overridingCommYear7?: number;
    overridingCommYear8?: number;
    overridingCommYear9?: number;
    overridingCommYear10?: number;
    commPayable?: string;
    commPayableFromDateMillis?: number;
    commPayableToDateMillis?: number;
    isin?: string;
    assetClass?: string;
    geographicalFocus?: string;
    fundSector?: string;
    isTrainingRequired?: boolean;
    // indicator: ProductDTO.indicator;
    indicator?: string;
    productName: string;
    providerName: string;
    providerOid?: string;
    productTypeName?: string;
    productTypeCode?: string;
    productTypeOid?: string;
    // mdrtCategory: ProductDTO.mdrtCategory;
    mdrtCategory?: string;
    productCategory: string;
    productCategoryOid?: string;
    productFamilyTag?: string;
    planName?: string;
    planCode?: string;
    // productGroup?: ProductDTO.productGroup;
    productGroup?: string;
    effectiveFrom?: string;
    effectiveTo?: string;
    trainingMandatory: boolean;
    productNeeds?: string;
    productFeature?: string;
    productRisk?: string;
    productLimitation?: string;
    productPremiumTerm?: string;
    productPolicyTerm?: string;
    productFrequency?: string;
    premiumMode?: string;
    paymentMethod?: string;
    bscClassification: boolean;
    productFeeCharges?: string;
    fundHouse?: string;
    fundType?: string;
    fundTypeOid?: string;
    // fundRiskClassification?: ProductDTO.fundRiskClassification;
    fundRiskClassification?: string;
    fundRiskRating?: string;
    // fundCurrency?: ProductDTO.fundCurrency;
    fundCurrency?: string;
    // fundAssetClass?: ProductDTO.fundAssetClass;
    fundAssetClass?: string;
    fundGeographicalFocus?: string;
    aplFund?: boolean;
    approvalOid?: string;
    issuer?: string;
    issuer_oid?: string;
    risk?: string;
}

export namespace ProductDTO {

    export enum status {
        ACTIVE = 'Active',
        INACTIVE = 'Inactive',
        HOLD = 'Hold',
    }

    export enum indicator {
        INSURANCE_TRADITIONAL = 'Insurance - Traditional',
        INSURANCE_GENERAL = 'Insurance - General',
        INSURANCE_RIDER = 'Insurance - Rider',
        INSURANCE_ILP = 'Insurance - ILP',
        INSURANCE_FAMILY_TAG = 'Insurance - FamilyTag',
        ILP_FUND = 'ILP Fund',
        FEES_AND_REFERRALS = 'Fees and Referrals',
        CIS = 'CIS',
        UT_FUND = 'UT Fund',
    }

    export enum mdrtCategory {
        NOT_APPLICABLE = 'Not Applicable',
        RISK_PROTECTION = 'Risk Protection',
        OTHERS = 'Others',
    }

    export enum productGroup {
        A_H = 'A&H',
        ILP = 'ILP',
        PAR = 'Par',
        SP_NON_PAR = 'SP Non-Par',
        UL = 'UL',
        NON_PAR = 'Non-Par',
        TERM = 'Term',
        RIDER = 'Rider',
    }

    export enum fundRiskClassification {
        LOW = 'Low',
        MODERATELY_LOW = 'Moderately Low',
        MODERATE = 'Moderate',
        MODERATELY_HIGH = 'Moderately High',
        HIGH = 'High',
    }

    export enum fundCurrency {
        AUD = 'AUD',
        CNH = 'CNH',
        EUR = 'EUR',
        GBP = 'GBP',
        HKD = 'HKD',
        NZD = 'NZD',
        RMB = 'RMB',
        SGD = 'SGD',
        USD = 'USD',
        JPY = 'JPY',
    }

    export enum fundAssetClass {
        ALTERNATIVES = 'Alternatives',
        EQUITIES = 'Equities',
        FIXED_INCOME = 'Fixed Income',
        MIXED_ASSETS = 'Mixed Assets',
        MONEY_MARKET = 'Money Market',
        SHORT_DURATION_FIXED_INCOME = 'Short Duration Fixed Income',
    }


}
