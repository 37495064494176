/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRecruitDetailDto } from '../models/CreateRecruitDetailDto';
import type { UpdateRecruitDetailDto } from '../models/UpdateRecruitDetailDto';
import { request as __request } from '@openapi/request';

export class RecruitDetailsService {

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async create(
        requestBody: CreateRecruitDetailDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/recruit/details`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async update(
        requestBody: UpdateRecruitDetailDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/recruit/details`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async find(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/recruit/details/search`,
        });
        return result.body;
    }

    /**
     * @param recruitOid
     * @returns any
     * @throws ApiError
     */
    public static async retrieve(
        recruitOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/recruit/details/${recruitOid}`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async findAll(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/recruit/details/findAll`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async submitRecruitApplication(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/recruit/details/submit`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async approve(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/recruit/details/approve`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async reworkApplication(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/recruit/details/rework`,
        });
        return result.body;
    }

}