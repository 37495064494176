import { TypeBase } from '../TypeBase'
import { Default } from '@master/annotation/Default'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

/**
 * AKA Customer Knowledge Assessment
 * By default, completed, *_specify is used by both
 * client 1 and 2
 */
export default class CKA extends TypeBase {
  @Default(false)
  ckaCompleted = false // was complete_cka

  @Default([])
  educationList: string[] = []

  @MaxChar(InputMaxChar.SMALL)
  educationSpecify = ''

  higherEducation = ''
  investmentExperience = ''

  @Default([])
  investmentList: string[] = []

  @MaxChar(InputMaxChar.SMALL)
  investmentSpecify = ''

  ckaMet = '' // was ckaMet
  otherInvestmentExp = ''
  otherProfessional = ''

  @MaxChar(InputMaxChar.SMALL)
  profSpecify = ''

  @Default([])
  professionalList: string[] = []

  professionalQualification = ''
  otherProfessionalQualification = ''
  workExperience = ''

  @Default([])
  workList: string[] = []

  @MaxChar(InputMaxChar.SMALL)
  workSpecify = ''

  eLearning = ''
  eLearningSpecify = ''

  ckaCarOutcome = {}

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(`${type}`)
  }

  reset () {
    this.ckaCompleted = false
    this.educationList = []
    this.educationSpecify = ''
    this.higherEducation = ''
    this.investmentExperience = ''
    this.investmentList = []
    this.investmentSpecify = ''
    this.ckaMet = ''
    this.otherInvestmentExp = ''
    this.otherProfessional = ''
    this.profSpecify = ''
    this.professionalList = []
    this.professionalQualification = ''
    this.otherProfessionalQualification = ''
    this.workExperience = ''
    this.workList = []
    this.workSpecify = ''
    this.eLearning = ''
    this.eLearningSpecify = ''
    this.ckaCarOutcome = {}
  }
}
