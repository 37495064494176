/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export type ListAuditLogsRequestDto = {
    limit?: number;
    page?: number;
    search?: any;
    sortBy?: ListAuditLogsRequestDto.sortBy;
    orderBy?: string;
    filters?: any;
}

export namespace ListAuditLogsRequestDto {

    export enum sortBy {
        ASC = 'ASC',
        DESC = 'DESC',
    }


}