import { snakeCase } from 'lodash/fp'
import { Base } from '@master/model/Base'

export default class OfficerApproval extends Base {
  adminFullName = ''
  adminDecidedUserName = ''
  adminDecidedOid = ''
  supervisorDecidedUserName = ''
  supervisorDecidedOid = ''
  supervisorFullname = ''
  dateOpsDecide: string | number = null
  dateOpsDecideDate: string | number = null
  managerEndorsedSignature = ''
  managerEndorsedSignatureDate: string | number = null
  dateSupervisorDecide: string | number = null
  dateSupervisorDecideDate = ''
  proxySupervisorDecided = false

  getKey (key: string) {
    const regex = new RegExp(/(User|Full)Name$/g)
    return regex.test(key) ? snakeCase(key).replace('_n', 'N') : snakeCase(key)
  }

  extractData (rawData: any) {
    const data = {}
    if (rawData) {
      Object.keys(this).forEach((key) => {
        const val = this[key]
        data[key] = rawData[this.getKey(key)] || val
      })
    }
    return data
  }

  packData () {
    const data = {}
    if (data) {
      Object.keys(this).forEach((key) => {
        data[this.getKey(key)] = this[key]
      })
    }
    return data
  }

  supervisorDecide (curUser: any, nowInISO: string, proxySupervisorDecided: boolean, signatureData: string) {
    this.supervisorDecidedOid = curUser.oid
    this.supervisorDecidedUserName = curUser.username
    this.supervisorFullname = curUser.fullname
    this.dateSupervisorDecideDate = nowInISO
    this.dateSupervisorDecide = Date.now()
    this.managerEndorsedSignature = signatureData
    this.managerEndorsedSignatureDate = Date.now()
    this.proxySupervisorDecided = proxySupervisorDecided
  }

  opsDecide (curUser: any, nowInISO: string) {
    this.adminDecidedOid = curUser.oid
    this.adminDecidedUserName = curUser.username
    this.adminFullName = curUser.fullname
    this.dateOpsDecide = Date.now()
    this.dateOpsDecideDate = nowInISO
  }
}
