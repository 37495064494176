import IVersionInfo from '@master/config/IVersionInfo'
export default class IfamVersion implements IVersionInfo {
  credit = 'Powered by Wen Consulting - '
  name = 'Mocha '
  version = 'Version 1.0.0'
  changeLog = [
    {
      version: 'Version 1.0.0',
      date: '31/12/2019',
      changes: [
        'Initial core version 1.0.0 tracking'
      ]
    }
  ]
}
