import { Base } from '../Base'
import { IClientOptions } from './FormOptions'
import { Ignore } from '@master/annotation/Ignore'

export default class PoliticalExposedPerson extends Base {
  @Ignore()
  customType = 'pep_list'

  policyholderName: keyof IClientOptions = ''

  fullName = ''

  nric = ''

  occupation = ''

  nameOfEmployer = ''

  sameAsHolder = false

  sourceOfFunds?: string[] = []

  fundOthers = ''

  sourceOfWealth?: string[] = []

  wealthOthers = ''

  category = ''

  familyMemberRelationship = ''

  closeAssociateRelationship = ''

  clientRelationship = ''

  personName = ''

  personRank = ''

  personCountryOfAppointment = ''

  extractData (rawKycForm) {
    return super.extractData(rawKycForm[this.customType] ? rawKycForm[this.customType][0] : rawKycForm)
  }

  beforeExtractData (data: any) {
    return data;
  }

  afterExtractData (data: any) {
    return data;
  }

  convertToRawData () {
    const results = super.convertToRawData()
    return {
      [this.customType]: results
    }
  }
}
