import { TypeBase } from '@master/model/TypeBase'

export default class AIOI extends TypeBase {
  constructor (clientIndex: string) {
    super(`${clientIndex}_aioi`)
  }

  isAi = undefined
  isIndividualTypeA = undefined
  isCorporateTypeA = undefined
  isIndividualTypeB = undefined
  isCorporateTypeB = undefined
  isIndividualTypeC = undefined
  isTrustee = undefined
  isOi = undefined

  reset () {
    this.isIndividualTypeA = undefined
    this.isCorporateTypeA = undefined
    this.isIndividualTypeB = undefined
    this.isCorporateTypeB = undefined
    this.isIndividualTypeC = undefined
    this.isTrustee = undefined
  }
}
