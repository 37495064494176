export const CurrencyOptions = [
  { label: '', value: '' },
  { label: 'SGD', value: 'sgd' },
  { label: 'AUD', value: 'aud' },
  { label: 'CNH', value: 'cnh' },
  { label: 'EUR', value: 'eur' },
  { label: 'GBP', value: 'gbp' },
  { label: 'HKD', value: 'hkd' },
  { label: 'JPY', value: 'jpy' },
  { label: 'NZD', value: 'nzd' },
  { label: 'RMB', value: 'rmb' },
  { label: 'USD', value: 'usd' }
]
