/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdviserEmploymentDTO } from '../models/AdviserEmploymentDTO';
import { request as __request } from '@openapi/request';

export class AdviserEmploymentService {

    /**
     * @returns AdviserEmploymentDTO Retrieve the list of Adviser Employment records
     * @throws ApiError
     */
    public static async retrieveAdviserEmploymentList(): Promise<Array<AdviserEmploymentDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_employment/get_list`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns AdviserEmploymentDTO Retrieve a specific  Adviser Employment record
     * @throws ApiError
     */
    public static async retrieveAdviserEmployment(
oid: string,
): Promise<AdviserEmploymentDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_employment/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param requestBody 
     * @returns AdviserEmploymentDTO Updates a Adviser Employment record
     * @throws ApiError
     */
    public static async updateAdviserEmployment(
oid: string,
requestBody: AdviserEmploymentDTO,
): Promise<AdviserEmploymentDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/adviser_employment/${oid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns any Deletes a Adviser Employment record (hard delete)
     * @throws ApiError
     */
    public static async deleteAdviserEmployment(
oid: string,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/adviser_employment/${oid}`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns AdviserEmploymentDTO Retrieve Adviser Employment records by user oid 
     * @throws ApiError
     */
    public static async retrieveAdviserEmploymentByUserOid(
oid: string,
): Promise<Array<AdviserEmploymentDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/adviser_employment/user/${oid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Creates Adviser Employment record(s)
     * @throws ApiError
     */
    public static async createAdviserEmployments(
requestBody: Array<AdviserEmploymentDTO>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/adviser_employment`,
            body: requestBody,
        });
        return result.body;
    }

}