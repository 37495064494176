import PersonalDetails from '@master/model/kyc-form/PersonalDetails'
import { isUndefined, isNull, isEmpty } from 'lodash/fp'
import NeedsBase, { priorityLevel as PriorityLevel } from '@master/model/kyc-form/need-analysis/NeedsBase'
import CKA from '@master/model/kyc-form/CKA'
import Switching from '@master/model/kyc-form/Switching'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import BasicProduct from '@master/model/kyc-form/product/BasicProduct'

function noRankedHighNeedsCheck (client) {
  const keys = Object.keys(client)
    .filter(key => {
      return client[key] instanceof NeedsBase
    })
  let foundHigh = false
  for (let i = 0; i < keys.length; ++i) {
    if (PriorityLevel[client[keys[i]].level] === PriorityLevel.h) {
      foundHigh = true
      break
    }
  }

  return !foundHigh
}

function noFullDisclosure (...discloseArray: boolean[]) {
  return discloseArray.every(disclose => !disclose)
}

function budgetLimitBreachedCheck (annualBudget: number, singleBudget: number, budgetMoreThanHalfString: string, totalAnnualisedPremium: number, totalSinglePremium: number) {
  let budgetLimitBreached = budgetMoreThanHalfString === 'yes'

  annualBudget = annualBudget || 0
  singleBudget = singleBudget || 0

  if (totalAnnualisedPremium > 0 && totalAnnualisedPremium > annualBudget) {
    budgetLimitBreached = true
  }

  if (totalSinglePremium > 0 && totalSinglePremium > singleBudget) {
    budgetLimitBreached = true
  }

  return budgetLimitBreached
}

function clientFailedCKACheck (cka: CKA) {
  return cka.ckaMet === 'yes'
}

function clientRiskProfileConservativeCheck (clientRiskProfile: string) {
  return clientRiskProfile === 'conservative'
}

function clientDidNotProvideEmailAddressCheck (personalDetail: PersonalDetails) {
  let isInvalidEmail = false
  // regex check for valid email format
  if (!(/^\S+@\S+\.\S+$/).test(personalDetail.contactEmail)) {
    isInvalidEmail = true
  }

  return isUndefined(personalDetail.contactEmail) || isNull(personalDetail.contactEmail) || personalDetail.contactEmail === '' || isInvalidEmail
}

function recommendationForProductReplacementSwitchingCheck (switching: Switching) {
  return switching.topUp === 'yes' || switching.advisedBefore === 'yes'
}

function clientDisagreesWithAdviserRecommendationCheck (clientIndex: 0 | 1, recommendations: BasicProduct[]) {
  return recommendations
    .filter(product => Number(product.clientIndex) === clientIndex)
    .some(product => product.clientChoice === 'ownchoice')
}

function clientHasIncompleteFieldsCheck (validationResult) {
  return 'Client\'s Declaration' in validationResult
}

function incompleteBopPepSectionCheck (validationResult) {
  // No BO & PEP at this moment
  // change the key accordingly when added
  return 'BO' in validationResult || 'PEP' in validationResult
}

function highRiskCheck (clientIndex: 0 | 1, citizenship: string, country: string, recommendations: BasicProduct[], highRiskCountryList: string[], country2?: string) {
  const isFromHighRiskCountry = citizenship !== 'singaporean' && (highRiskCountryList.includes(country) || highRiskCountryList.includes(country2))

  const isOverLimit = recommendations.map(product => {
    // checking on insurance-rider wont affect BasicProductV1 because V1 rider is nested
    const amount = (product.type === 'insurance') ? product.premiumAmount : product.sumAssured
    const array = [{
      clientIndex: product.clientIndex,
      amount,
      frequency: product.frequency,
      clientChoice: product.clientChoice
    }]

    if (product.riders.length > 0) { // BasicProductV1 handler
      array.concat(product.riders.map(rider => {
        return {
          clientIndex: product.clientIndex,
          amount: rider.premiumAmount,
          frequency: rider.frequency,
          clientChoice: rider.clientChoice
        }
      }))
    }

    return array
  })
    // flatten rider
    .flat()
    // remove product that not for this client
    .filter(({ clientIndex: _clientIndex }) => _clientIndex === clientIndex)
    // remove not single payment and not not selected product
    .filter(({ frequency, clientChoice }) => frequency === 'Single' && (clientChoice === 'accept' || clientChoice === 'ownchoice'))
    // amount must more than 500k
    .some(({ amount }) => Number(amount) >= 500000)

  return isFromHighRiskCountry || isOverLimit
}

function isRcmdRiskRatingGreaterThanClientRPA (riskRatingService: IRiskRatingService, rcmdRiskRating: number, clientRiskProfile: string, exceedAllowed = 0) {
  // if client risk profile result is null, always return true
  if (isEmpty(clientRiskProfile)) return true

  const rawToValueMap = riskRatingService.rawToValueMap()
  let enumClientRiskProfile = 0
  if (rawToValueMap[clientRiskProfile]) {
    enumClientRiskProfile = rawToValueMap[clientRiskProfile]
  }
  if (rcmdRiskRating > enumClientRiskProfile + exceedAllowed) {
    return true
  }

  return false
}

function productRiskRating (clientIndex: 0 | 1, clientRiskProfile: string, recommendations: BasicProduct[], riskRatingService: IRiskRatingService) {
  // returns true when a product whose recommended risk rating is greater than client RPA
  return recommendations.some(product => {
    if (product.clientIndex === clientIndex) {
      const clientChoice = product.clientChoice
      const isSelectedProduct = clientChoice === 'accept' || clientChoice === 'ownchoice'

      if (!isSelectedProduct) {
        return false
      }

      // investmentSubFunds only appear on BasicProductV2
      const isInvestmentProduct = product.type === 'investment' || product.type === 'investment-subFunds'// insurance or investment
      const isILPType = product.productType?.toLowerCase().includes('ilp') // 'product type' value from the product library
      const isInvestmentLinkCategory = product.productCategory?.toLowerCase().includes('investment-linked') // 'category' value from the product library

      if (isInvestmentProduct || isILPType || isInvestmentLinkCategory) {
        const rcmdRiskRating = product.recommendedRiskProfile
        if (rcmdRiskRating) {
          return isRcmdRiskRatingGreaterThanClientRPA(riskRatingService, rcmdRiskRating, clientRiskProfile)
        }
      }
    }
    return false
  })
}

export {
  noRankedHighNeedsCheck,
  noFullDisclosure,
  budgetLimitBreachedCheck,
  clientFailedCKACheck,
  clientRiskProfileConservativeCheck,
  clientDidNotProvideEmailAddressCheck,
  recommendationForProductReplacementSwitchingCheck,
  clientDisagreesWithAdviserRecommendationCheck,
  clientHasIncompleteFieldsCheck,
  incompleteBopPepSectionCheck,
  highRiskCheck,
  isRcmdRiskRatingGreaterThanClientRPA,
  productRiskRating
}
