import { FormBase } from '../FormBase'

export const DesignationOptions = [
  { label: 'Financial Services Manager', value: 'fsm' },
  { label: 'Executive Financial Consultant', value: 'efc' },
  { label: 'Senior Financial Consultant', value: 'sfc' },
  { label: 'Financial Consultant', value: 'fc' },
  { label: 'Senior Financial Services Director', value: 'sfsd' },
  { label: 'Financial Services Director', value: 'fsd' },
  { label: 'Associate Financial Services Director', value: 'afsd' }
]
export const ProfesQualiOptions = [
  { label: 'MDRT', value: 'MDRT' },
  { label: 'COT', value: 'COT' },
  { label: 'TOT', value: 'TOT' }
]
export class NameCardOrderField {
  printedName = ''
  chineseName = ''
  designation = '' // options

  // shared.rnfNumber
  // shared.branch
  // shared.corporateEmail

  // qualification
  academic = ''
  professional = [] // options

  acknowledgement = []
  // pdConsent: boolean
  // trueInfo: boolean
  // randomChecks: boolean
}
export class NameCardOrder extends FormBase {
  type = 'name-card-order'
  data: NameCardOrderField = new NameCardOrderField()

  get error () {
    const errs = []
    return errs
  }
}
