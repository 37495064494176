import { getRawKey } from '@master/annotation/RawKey'
import { needToConvertJson } from '@master/annotation/Json'
import { snakeCase, zipObject, get, isEmpty } from 'lodash/fp'
import { isIgnore } from '@master/annotation/Ignore'
import { convertJsonToMetaClass, needToConvertToMetaClass } from '@master/annotation/MetaClass'

export class Meta {
  snakeCaseKey (key: string) {
    return snakeCase(key)
  }

  // assignData lifeCycle
  /**
   * beforeAssign is used for manipulate headers or data before assign, override this function in extended class when needed
   * @param headers headers from meta list
   * @param data single row data from meta list
   */
  beforeAssign (headers: string[], data: (string | number)[]): { headers: string[]; data: (string | number)[] } {
    return { headers, data }
  }

  /**
   * afterAssign is used to manipulate current object after completed assign
   */
  afterAssign () {
    // Example:
    // Object.freeze(this)
  }

  /**
   * AssignData is used for get the data and fill into this object
   * @param headers headers from meta lis
   * @param data single row data from meta list
   */
  assignData (headers: string[], data: (string | number)[]) {
    const { headers: editedHeaders, data: editedData } = this.beforeAssign(headers, data)

    const result = zipObject(editedHeaders, editedData)

    Object.keys(this)
      .forEach(key => {
        const rawKey = getRawKey(this, key) || this.snakeCaseKey(key)
        if (!(rawKey in result) && !(key in result)) return
        let value = result[rawKey] || result[key] as any

        if (needToConvertToMetaClass(this, key) && !isEmpty(value)) {
          value = convertJsonToMetaClass(this, key, JSON.parse(value))
        }
        if (needToConvertJson(this, key) && !isEmpty(value)) {
          // Prevents conversion of empty string values
          if (value.startsWith('""')) {
            value = {}
          } else {
            value = JSON.parse(value).map(valueData => valueData.trim())
          }
        }
        this[key] = value
      })

    this.afterAssign()
  }

  private convertAllKeyToRawData (convertNested: boolean) {
    let parent = Object.getPrototypeOf(this)
    let getterKeys = []

    // loop all parent to get all the getter keys
    while (parent instanceof Meta) {
      const keys = Object.entries(Object.getOwnPropertyDescriptors(parent))
        .filter(([, descriptor]) => typeof descriptor.get === 'function')
        .map(([key]) => key)

      getterKeys = getterKeys.concat(keys)
      parent = Object.getPrototypeOf(parent)
    }

    // remove duplicate key
    getterKeys = [...new Set(getterKeys)]

    const propertyKeys = convertNested ? Object.keys(this) : Object.keys(this).filter(key => !(get(key, this) instanceof Meta))

    return this.convertSpecifiedKeyToRawData([...getterKeys, ...propertyKeys], convertNested)
  }

  private convertSpecifiedKeyToRawData (keyToConvert: string[], convertNested: boolean) {
    return keyToConvert
      .filter(key => !isIgnore(this, key))
      .reduce((result, key) => {
        const snakeCaseKey = getRawKey(this, key) || this.snakeCaseKey(key)
        let value = get(key, this)
        // convert back to stringify json
        if (needToConvertToMetaClass(this, key) && value !== undefined) {
          value = JSON.stringify(value.convertToRawData(null, convertNested))
        }
        if (needToConvertJson(this, key)) {
          value = JSON.stringify(value)
        }
        result = { ...result, [snakeCaseKey]: value }
        return result
      }, {})
  }

  convertToRawData (keyToConvert?: string[], convertNested = false) {
    return keyToConvert ? this.convertSpecifiedKeyToRawData(keyToConvert, convertNested) : this.convertAllKeyToRawData(convertNested)
  }
}
