/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BscAgentFindingRequestDTO = {
    year: number;
    quarter: BscAgentFindingRequestDTO.quarter;
    adviserCode: string;
    roundNumber?: number;
    policyNumber?: string;
    infraction: string;
    infractionArray?: any;
    clientName?: string;
    transactionDate: number;
    reviewDate?: number;
    isClientServiceRecoveryNeeded: boolean;
    outcomeOfAssessmentCat1: string;
    outcomeOfAssessmentCat2: string;
    outcomeOfAssessmentAdminLapses: string;
    csrRemarks?: string;
    csrDueDate?: number;
    sourceOfReview: BscAgentFindingRequestDTO.sourceOfReview;
    productName?: string;
}

export namespace BscAgentFindingRequestDTO {

    export enum quarter {
        Q1 = 'Q1',
        Q2 = 'Q2',
        Q3 = 'Q3',
        Q4 = 'Q4',
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }

    export enum sourceOfReview {
        MYSTERY_SHOPPING = 'Mystery Shopping',
        SUBSTANTIATED_COMPLAINTS = 'Substantiated Complaints',
        POST_TRANSACTION_DOCUMENT_CHECK = 'Post Transaction Document Check',
    }


}