import { Base } from '@master/model/Base'
import FormKeyConverter from '@master/model/kyc-form/FormKeyConverter'
import KYCForm from '@master/model/kyc-form/KYCForm'

// Adding new signers / Removing signer can be done in here
export default class SignPadSigners extends Base {
  client0Signature = ''
  client0SignatureDate?: number = undefined
  client1Signature = ''
  client1SignatureDate?: number = undefined
  trustedIndividual1Signature = ''
  trustedIndividual1SignatureDate?: number = undefined
  trustedIndividual2Signature = ''
  trustedIndividual2SignatureDate?: number = undefined
  adviserSignature = ''
  adviserSignatureDate?: number = undefined
  adviser2Signature = ''
  adviser2SignatureDate?: number = undefined
  pep1Signature = ''
  pep1SignatureDate?: number = undefined
  pep2Signature = ''
  pep2SignatureDate?: number = undefined
  pep3Signature = ''
  pep3SignatureDate?: number = undefined
  beneficialOwner1Signature = ''
  beneficialOwner1SignatureDate?: number = undefined
  beneficialOwner2Signature = ''
  beneficialOwner2SignatureDate?: number = undefined

  /**
   * Only EFNA form PDF Preview flag
   */
  isPreviewedKycPDF?:boolean = false

  /**
   * This is for Signing Method selection
   */
  signingMethod?: 'disign' | 'sign' = 'sign'
  signing_status?: 'draft' | 'pending' | 'completed' = 'draft'

  getAllSigners () {
    return ['client0', 'client1', 'adviser', 'adviser2', 'ecdd1', 'ecdd1Adviser', 'ecdd2', 'ecdd2Adviser', 'switching1', 'switching1Adviser', 'switching2', 'switching2Adviser', 'tpp', 'tppAdviser', 'trustedIndividual1', 'trustedIndividual2', 'pep1', 'pep2', 'pep3', 'beneficialOwner', 'beneficialOwnerAdviser','listECDD1', 'listECDD2', 'listECDD3', 'listECDD4','listECDD5','listECDD1Adviser', 'listECDD2Adviser', 'listECDD3Adviser', 'listECDD4Adviser','listECDD5Adviser']
  }

  getApplicableSigners (kycForm: any) {
    const allsigners = this.getAllSigners()
    let applicableSigners = []
    try {
      applicableSigners = allsigners.map((signer) => {
        let profile = {}
        const adviserKeys = ['switching1Adviser', 'switching2Adviser', 'beneficialOwnerAdviser', 'tppAdviser','listECDD1Adviser', 'listECDD2Adviser', 'listECDD3Adviser', 'listECDD4Adviser','listECDD5Adviser']
        switch (true) {
          case (typeof signer !== 'string' || typeof signer.includes !== 'function') :
            profile = false
            break
          case signer === 'adviser' : {
            const adv = kycForm.adviser.details
            if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            break
          }
          case signer === 'adviser2' : {
            const adv = kycForm.adviser.declaration
            if (!adv.haveJointFieldWork && !adv.haveJointSales) {
              profile = false
            } else {
              profile = { name: adv.financialConsultantName, nric: adv.financialConsultantMasNo }
            }
            break
          }
          case signer === 'client0' : {
            const c1 = kycForm.client1.personalDetails
            if (c1) profile = { name: c1.name, nric: c1.nric }
            break
          }
          case signer === 'client1' :
            if (!kycForm.hasClient2) {
              profile = false
            } else {
              const c2 = kycForm.client2.personalDetails
              if (c2) profile = { name: c2.name, nric: c2.nric }
            }
            break
          case signer === 'trustedIndividual' :
            if (!kycForm.hasAccompaniment) {
              profile = false
            } else {
              const ti = kycForm.trustedIndividual;
              profile = { name: ti.name, nric: ti.nric };
            }
            break
          case signer === 'trustedIndividual1' :
            if (kycForm.client1TrustedIndividual?.TIaccompanied === 'yes') {
              const ti1 = (kycForm as any)?.client1TrustedIndividual
              if (ti1?.TIname && ti1?.TInric) profile = { name: ti1.TIname, nric: ti1.TInric }
              else profile = false
            } else {
              profile = false
            }
            break
          case signer === 'trustedIndividual2' :
            if (kycForm.client2TrustedIndividual?.TIaccompanied === 'yes') {
              const ti2 = (kycForm as any)?.client2TrustedIndividual
              if (ti2?.TIname && ti2?.TInric) profile = { name: ti2.TIname, nric: ti2.TInric }
              else profile = false
            } else {
              profile = false
            }
            break
          case signer.includes('pep') : { // pep1 , pep2, pep3
            const pepn = parseInt(signer.replace('pep', '')) - 1
            const targetPEP = kycForm.clientDeclaration.pepList[pepn] // pep1 , pep2, pep3

            if (!kycForm.hasValidC1PEP && !kycForm.hasValidC2PEP) {
              // REJECT : both dont declare PEP flag and PEP profile
              profile = false
            } else if (!targetPEP || !targetPEP.fullName.length) {
              // REJECT : pep not found or no full name
              profile = false
            } else if (!['C1', 'C2'].includes(targetPEP.policyholderName)) {
              // REJECT : pep found but not assigned to either C1 or C2
              profile = false
            } else if (targetPEP.policyholderName === 'C1' && !kycForm.hasValidC1PEP) {
              // REJECT : pep for client 1, but is incomplete
              profile = false
            } else if (targetPEP.policyholderName === 'C2' && !kycForm.hasValidC2PEP) {
              // REJECT : pep for client 2, but is incomplete
              profile = false
            } else {
              if (targetPEP) profile = { name: targetPEP.fullName, nric: targetPEP.nric }
            }
            break
          }
          case signer === 'beneficialOwner' : {
            if (!kycForm.ecddForm.beneficialOwner?.included) {
              profile = false
            } else {
              const bon = kycForm.ecddForm.beneficialOwner
              if (bon) profile = { name: bon.name, nric: bon.nric }
            }
            break
            // const bon = parseInt(signer.replace('beneficialOwner', ''))
            // if ((bon === 1 && !kycForm.hasValidBO1) || (bon === 2 && !kycForm.hasValidBO2)) {
            // // if (!kycForm.hasValidBO1 || !kycForm.hasValidBO2) {
            //   profile = false
            // } else {
            //   const targetBO = kycForm['client' + bon].beneficialOwner.beneficialOwnerParticulars
            //   if (targetBO) profile = { name: targetBO.fullName, nric: targetBO.nric }
            // }
            // break
          }
          case signer === 'listECDD1' : {
            if (!kycForm.ecddForm?.listEcdd?.[0]){
              profile = false
            } else {
              const listECDD1 =  kycForm.ecddForm.listEcdd[0]
              if (listECDD1) profile = { name: listECDD1.name, nric: listECDD1.nric }
            }
            break
          }
          case signer === 'listECDD2' : {
            if (!kycForm.ecddForm?.listEcdd?.[1]){
              profile = false
            } else {
              const listECDD1 =  kycForm.ecddForm.listEcdd[1]
              if (listECDD1) profile = { name: listECDD1.name, nric: listECDD1.nric }
            }
            break
          }
          case signer === 'listECDD3' : {
            if (!kycForm.ecddForm?.listEcdd?.[2]){
              profile = false
            } else {
              const listECDD1 =  kycForm.ecddForm.listEcdd[2]
              if (listECDD1) profile = { name: listECDD1.name, nric: listECDD1.nric }
            }
            break
          }
          case signer === 'listECDD4' : {
            if (!kycForm.ecddForm?.listEcdd?.[3]){
              profile = false
            } else {
              const listECDD1 =  kycForm.ecddForm.listEcdd[3]
              if (listECDD1) profile = { name: listECDD1.name, nric: listECDD1.nric }
            }
            break
          }
          case signer === 'listECDD5' : {
            if (!kycForm.ecddForm?.listEcdd?.[4]){
              profile = false
            } else {
              const listECDD1 =  kycForm.ecddForm.listEcdd[4]
              if (listECDD1) profile = { name: listECDD1.name, nric: listECDD1.nric }
            }
            break
          }
          case signer === 'ecdd1' : {
            if (!kycForm.ecddForm.cOne?.included) {
              profile = false
            } else {
              const c1 = kycForm.ecddForm.cOne
              if (c1) profile = { name: c1.name, nric: c1.nric }
            }
            break
          }
          case signer === 'ecdd2' : 
            if (!kycForm.ecddForm.cTwo?.included) {
              profile = false
            } else {
              const c2 = kycForm.ecddForm.cTwo
              if (c2) profile = { name: c2.name, nric: c2.nric }
            }
            break
          case signer === 'tpp' : {
            if (!kycForm.ecddForm.thirdParty?.included) {
              profile = false
            } else {
              const tpp = kycForm.ecddForm.thirdParty
              if (tpp) profile = { name: tpp.tpName, nric: tpp.tpnric }
            }
            break
          }
          case signer === 'tppAdviser' : {
            if (!kycForm.ecddForm.thirdParty?.included) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'listECDD1Adviser' : {
            if (!kycForm.ecddForm.listEcdd[0]) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'listECDD2Adviser' : {
            if (!kycForm.ecddForm.listEcdd[1]) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'listECDD3Adviser' : {
            if (!kycForm.ecddForm.listEcdd[2]) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'listECDD4Adviser' : {
            if (!kycForm.ecddForm.listEcdd[3]) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'listECDD5Adviser' : {
            if (!kycForm.ecddForm.listEcdd[4]) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'ecdd1Adviser' : {
            if (!kycForm.ecddForm.cOne?.included) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'ecdd2Adviser' : {
            if (!kycForm.ecddForm.cTwo?.included) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'beneficialOwnerAdviser' : {
            if (!kycForm.ecddForm.beneficialOwner?.included) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'switching1' : {
            if (!kycForm?.switchReplaceAdditionalForm?.switchReplaceAdditionalData?.client1Details?.clientName) {
              profile = false
            } else {
              const c1 = kycForm?.switchReplaceAdditionalForm?.switchReplaceAdditionalData?.client1Details
              if (c1) profile = { name: c1.clientName, nric: c1.identityNumber?.number }
            }
            break
          }
          case signer === 'switching2' : {
            if (!kycForm?.switchReplaceAdditionalForm?.switchReplaceAdditionalData?.client2Details?.clientName) {
              profile = false
            } else {
              const c2 = kycForm?.switchReplaceAdditionalForm?.switchReplaceAdditionalData?.client2Details
              if (c2) profile = { name: c2.clientName, nric: c2.identityNumber?.number }
            }
            break
          }
          case signer === 'switching1Adviser' : {
            if (!kycForm?.switchReplaceAdditionalForm?.switchReplaceAdditionalData?.client1Details?.clientName) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          case signer === 'switching2Adviser' : {
            if (!kycForm?.switchReplaceAdditionalForm?.switchReplaceAdditionalData?.client2Details?.clientName) {
              profile = false
            } else {
              const adv = kycForm.adviser.details
              if (adv) profile = { name: adv.adviserName, nric: adv.adviserMasRepNo }
            }
            break
          }
          default :
            profile = false
        }
        if (profile !== false) {
          Object.assign(profile, {
            role: signer,
            signatureOriKey: signer + 'Signature',
            signature: this[signer + 'Signature'],
            signaturedateOriKey: signer + 'SignatureDate',
            signaturedate: this[signer + 'SignatureDate']
          })
        }
        return profile
      }).filter(signer => signer !== false)
    } catch (ex) {
      return []
    }
    return applicableSigners
  }

  getDisplayNames () {
    return {
      client0: 'Client 1',
      client1: 'Client 2',
      trustedIndividual: 'Accompaniment',
      trustedIndividual1: 'Accompaniment',
      trustedIndividual2: 'Accompaniment',
      adviser: 'Adviser',
      adviser2: 'Adviser 2',
      pep1: 'PEP 1',
      pep2: 'PEP 2',
      pep3: 'PEP 3',
      beneficialOwner1: 'Beneficial Owner Client 1',
      beneficialOwner2: 'Beneficial Owner Client 2'
    }
  }

  extractData (rawKYCPdf: any) {
    const data = {}
    Object.keys(this).forEach((key) => {
      const snakeKey = this.snakeCaseKey(key)
      const fetched = rawKYCPdf[snakeKey]
      if (fetched !== undefined) data[key] = fetched
    })
    return data
  }

  getRawKey (formattedKey: string) {
    const fkConverter = new FormKeyConverter()
    return fkConverter.convertToRawKey(this.snakeCaseKey(formattedKey))
  }

  saveSignature (newSignature: object) {
    console.log('saving signature', newSignature)
    Object.keys(newSignature).forEach((key) => {
      this[key] = newSignature[key]
    })
  }
}
