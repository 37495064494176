import { Ignore, isIgnore } from '@master/annotation/Ignore'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { isUndefined, snakeCase } from 'lodash/fp'
import { ArrayBase } from '../../../ArrayBase'
import { pv, sum, valueIsUndefined } from './../math'

export default class DependantsProtectionClient extends ArrayBase {
  @Ignore()
  projectSettings: IProjectSettings

  type = 'p6_c'

  depName = ''
  preferredHospitalType = ''
  preferredWardType = ''
  riderDeductible = ''
  existingCoverage = ''

  totalFundsRequired?: number = undefined
  iiLessExisting?: number = undefined
  iSurplus?: number = undefined

  annualExpenses?: number = undefined
  yearsRequired?: number = undefined
  infAdjusted?: number = undefined
  lumpSum?: number = undefined
  medicalTreatment?: number = undefined
  estimatedAmount?: number = undefined
  otherFunds?: number = undefined
  iiSurplus?: number = undefined

  finalExpenses?: number = undefined
  loans?: number = undefined
  others?: number = undefined
  totalCashNeeds?: number = undefined
  ivLessExisting?: number = undefined
  ivSurplus?: number = undefined

  @Ignore()
  defaultRate?: number

  @Ignore()
  disabledInput = false

  constructor (projectSettings: IProjectSettings, id = 'p6') {
    super()
    this.type = `${id}_c`
    this.projectSettings = projectSettings
  }

  /**
     * this is to override default behavior where p6 is transformed to p_6
     */
  snakeCaseKey (key: string) {
    return this.type + snakeCase(`${this.curIndex + this.startIndex} ${key}`)
  }

  setupDefaultRate (inDefaultRate: number) {
    this.defaultRate = inDefaultRate
    if (isUndefined(this.infAdjusted)) this.infAdjusted = this.defaultRate
  }

  // ii. In the Event of Critical Illness
  get criticalIllnessSurplus () {
    if (valueIsUndefined(this.iiLessExisting) && valueIsUndefined(this.totalFundsRequired)) return null
    return sum(this.iiLessExisting, -this.totalFundsRequired)
  }

  // iii. In the Event of Disability
  get annualIncome () {
    if (valueIsUndefined(this.annualExpenses)) return null
    return (this.projectSettings.needs.period * this.annualExpenses) || 0
  }

  get disabilityLumpSum () {
    if (valueIsUndefined(this.annualIncome) && valueIsUndefined(this.yearsRequired) && valueIsUndefined(this.infAdjusted)) return null
    const total = pv(this.annualIncome, this.yearsRequired, this.infAdjusted)
    return Math.round(total)
  }

  get disabilityEstimatedAmount () {
    return sum(this.disabilityLumpSum, this.medicalTreatment)
  }

  get disabilitySurplus () {
    if (valueIsUndefined(this.disabilityEstimatedAmount) && valueIsUndefined(this.otherFunds)) return null
    return sum(-this.disabilityEstimatedAmount, this.otherFunds)
  }

  // iv. In the Event of Death
  get deathCashNeeds () {
    return sum(this.finalExpenses, this.loans, this.others)
  }

  get deathSurplus () {
    if (valueIsUndefined(this.ivLessExisting) && valueIsUndefined(this.deathCashNeeds)) return null
    return sum(this.ivLessExisting, -this.deathCashNeeds)
  }

  get shouldDisabledInput () {
    return this.disabledInput || !this.depName
  }

  clear () {
    Object.keys(this).forEach(key => {
      if (key === 'type' || isIgnore(this, key)) {
        return
      }
      this[key] = null
    })
  }
}
