import { FNAStructure } from '@master/model/structure/FNAStructure'
import PersonalAccidentNeeds from '@company-common/model/kyc-form/needs-analysis/health/CustomPersonalAccidentNeeds'

export function PersonalAccidentStructure (defaultValue: { amount: number }) {
  const structure: FNAStructure<PersonalAccidentNeeds> = {
    title: 'H1. Personal Accident',
    data: [
      {
        label: 'When do you need this fund',
        value: 'Upon occurrence'
      }, {
        label: 'Desired amount for personal accident benefit ($)',
        key: 'amount',
        type: 'currency',
        default: defaultValue?.amount,
        validationMsg: 'Personal Accident requires to indicate desired amount.'
      }, {
        label: '+ Immediate expenses and liabilities ($)',
        key: 'required1',
        type: 'currency',
        validationMsg: 'Personal Accident requires to indicate immediate expenses.'
      }, {
        label: '- Current resources/provisions ($)',
        key: 'available1',
        type: 'currency',
        validationMsg: 'Personal Accident requires to indicate current resources.'
      }, {
        label: '<b>Surplus / <span class="red-text">(Shortfall)</span></b>',
        key: 'surplusShortfall',
        type: 'currency',
        disabled: true
      }
    ]
  }
  return structure
}
