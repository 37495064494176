import { sum, round, savingMonthly, savingLumpSum } from '@master/model/kyc-form/need-analysis/math'
import NeedsBase from '../NeedsBase'
import { isUndefined } from 'lodash/fp'

/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class SpecificGoalsNeeds extends NeedsBase {
  name = '' // TODO: add in name
  needs = undefined
  planning = ''
  lifestyleGoals = ''
  period?: number = undefined
  interestRate?: number = undefined
  amountFv?: number = undefined
  fundValue?: number = undefined
  rateOfReturn?: number = undefined
  additionalNotes = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 's5') {
    super(`${type}_${id}`)
  }

  setupDefaultRate (rate: number) {
    super.setupDefaultRate(rate)
    if (isUndefined(this.interestRate)) this.interestRate = this.defaultRate
  }

  get totalFundsFv () {
    const compiledInterestRate = (1 + this.interestRate / 100) ** this.period
    const total = this.fundValue * compiledInterestRate
    return round(total, 0)
  }

  get surplusShortfall () {
    const total = sum(this.totalFundsFv, -this.amountFv)
    return total
  }

  get rate () {
    return this.rateOfReturn / 100
  }

  get contribution () {
    const total = savingMonthly(Math.abs(this.surplusShortfall), this.period, this.rate)
    return round(total, 0)
  }

  get lumpSum () {
    const total = savingLumpSum(Math.abs(this.surplusShortfall), this.period, this.rate)
    return round(total, 0)
  }

  get isDefaultRate () {
    return this.defaultRate === this.interestRate
  }
}
