/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataLoadListDto } from '../models/DataLoadListDto';
import type { DataLoadListResponseDTO } from '../models/DataLoadListResponseDTO';
import { request as __request } from '@openapi/request';

export class DataLoadService {

    /**
     * @param requestBody 
     * @returns DataLoadListResponseDTO 
     * @throws ApiError
     */
    public static async getDataLoadSummaryList(
requestBody: DataLoadListDto,
): Promise<DataLoadListResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/dataload/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async importCsvUsersIntoSystemV3(
requestBody: any,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/dataload/import`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static async systemUpload(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/dataload/system-upload`,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns any 
     * @throws ApiError
     */
    public static async getFile(
oid: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/dataload/file/${oid}`,
        });
        return result.body;
    }

}