import { Base } from '../../Base'
import { isEmpty } from 'lodash/fp'
import { Ignore } from '@master/annotation/Ignore'
import { FormOptions, IAssuredOptions } from '../FormOptions'
import { MaxChar } from '@master/annotation/MaxChar'
import { IExistingInvestment } from './IExistingInvestment'
import { isSummaryField, SummaryField } from '@master/annotation/SummaryField'

export const AssetTypeOptions = [
  'UT/ETF - Equity',
  'UT/ETF - Bond/Income',
  'UT/ETF - Balanced',
  'Equity',
  'Bonds',
  'Structured products',
  'Others e.g PE, gold etc.'
]
export default class ExistingInvestmentV2 extends Base implements IExistingInvestment {
  platform = ''

  @MaxChar(74)
  fundName = ''

  assetType = ''
  ownedBy: keyof IAssuredOptions = ''

  @SummaryField('Investment Amount')
  investmentAmt?: number = undefined

  @SummaryField('Current Realisable Value')
  currentRealisableValue?: number = undefined

  @SummaryField('Investment Amount RSP')
  investmentAmtRsp?: number = undefined

  @MaxChar(36)
  fundManager = ''

  @MaxChar(30)
  remarks = ''

  @Ignore()
  formOptions?: FormOptions

  constructor (formOptions: FormOptions = null) {
    super()
    this.formOptions = formOptions
  }

  /**
   * Somehow implementing Object.keys(this) may trigger
   * an infinite loop (it is a warning on Vue in the browser)
   * but I have yet to face infinite loop of it yet.
   *
   * Putting here just in case we face it.
   */
  getSummaryFields (): { attrName: string; abbre: string }[] {
    const summaryFields: { attrName: string; abbre: string }[] = []
    Object.keys(this).forEach(attr => {
      const grabSummaryField = isSummaryField(this, attr)
      if (grabSummaryField) {
        summaryFields.push({
          attrName: attr,
          ...grabSummaryField
        })
      }
    })
    return summaryFields
  }

  isValid () {
    return !isEmpty(this.ownedBy) && this.ownedBy.length > 0
  }

  belongsTo (owner: 'C1' | 'C2') {
    return this.isValid() && this.ownedBy === owner
  }
}
