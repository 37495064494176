import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CommissionRecordsService } from "../../CommissionModule/api";
import { AuditLogsService, CsvExportDto } from '@core/openapi/services/audit-logs/api'

@Module({
  namespaced: true,
  name: "AuditLogStore",
})
export default class AuditLogStore extends VuexModule {
  auditLogs = [];
  auditLogsCount = 0;
  exportCsvData = null;
  successResponse = "";
  filterOptions: {
    mainModules: string[];
    subModules: { [key: string]: string[] };
    users: { userOid: string, changedBy: string }[];
} = null;


  @Action
  async retrieveAuditLogs(params) {
      const {limit, page, filters, search} = params

      try {
         const result = await AuditLogsService.getAuditLogsList({
              limit,
              page,
              filters: {
                fromDate: !!filters.fromDate ? filters.fromDate : undefined,
                toDate: !!filters.toDate? filters.toDate: undefined,
                mainModule: !!filters.mainModule ? filters.mainModule : undefined,
                subModule: !!filters.subModule && !!filters.subModule.length ? filters.subModule : undefined,
                changedBy: !!filters.changedBy && !!filters.changedBy.length ? filters.changedBy : undefined,
            },
              search
          });
          this.context.commit('setAuditLogs', result)
      } catch (error) {
          alert(error.message)
      }
  }

  @Mutation
  setAuditLogs(result) {
        this.auditLogsCount = result.recordsTotal;
        this.auditLogs = result.data;
  }

  @Action
  async exportAuditLogsCSV(requestBody: CsvExportDto) {
      try {
          const result = await AuditLogsService.productCsvExport(requestBody);
          this.context.commit('setProductCsv', result);
      } catch (error) {
          alert(error.message)
      }
  }

  @Mutation
  setProductCsv(response) {
      this.exportCsvData = response;
  }

  @Action
  async getFilterOptions(requestBody: CsvExportDto) {
      try {
        const result = await AuditLogsService.filterOptions(requestBody);
        this.context.commit('setFilterOptions', result)
      } catch (error) {
          alert(error.message)
      }
  }

  @Mutation
  setFilterOptions(data) {
      this.filterOptions = data ?? {};
 }

}
