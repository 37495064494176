import NeedsBase from '../NeedsBase'

/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class HospitalizationNeeds extends NeedsBase {
  name = 'Hospitalisation Expenses'
  needs = 'Hospitalisation Expenses'
  coverage?: number = undefined
  roomCharges?: number = undefined
  surgicalExpenses?: number = undefined
  desiredAmount?: number = undefined
  hospitalType = ''
  wardType = ''
  other = ''
  rider = ''
  additionalNotes = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 'h2') {
    super(`${type}_${id}`)
  }
}
