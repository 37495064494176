/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompensationSetupDTO } from '../models/CompensationSetupDTO';
import { request as __request } from '@openapi/request';

export class CompensationSetupService {

    /**
     * @param searchField 
     * @param searchFieldValue 
     * @param page 
     * @param limit 
     * @param retrieveAll 
     * @returns CompensationSetupDTO Retrieves the list of all Compensation Setup Records
     * @throws ApiError
     */
    public static async getCompensationList(
searchField?: string,
searchFieldValue?: string,
page?: number,
limit?: number,
retrieveAll?: boolean,
): Promise<Array<CompensationSetupDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/compensation-setup/list`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue,
                'page': page,
                'limit': limit,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @returns any Exports all Compensation Setup Records into CSV
     * @throws ApiError
     */
    public static async downloadCompensationListCsv(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/compensation-setup/export-csv`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CompensationSetupDTO Updates an existing Compensation Setup record
     * @throws ApiError
     */
    public static async updateCompensationRecord(
requestBody: CompensationSetupDTO,
): Promise<CompensationSetupDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/compensation-setup`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns CompensationSetupDTO 
     * @throws ApiError
     */
    public static async importCompensationSetup(
requestBody: Array<CompensationSetupDTO>,
): Promise<Array<CompensationSetupDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/compensation-setup/import-csv`,
            body: requestBody,
        });
        return result.body;
    }

}