/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractDetailDTO } from './ContractDetailDTO';
import type { PersonalItemIssuedDetailDTO } from './PersonalItemIssuedDetailDTO';
import type { RequirementCheckListDTO } from './RequirementCheckListDTO';

export type CompanyInformationImportDTO = {
    paymentStatus?: string;
    userOid?: string;
    corporateEmail: string;
    companyCode: string;
    masRepNo?: string;
    masApplicationDate?: number;
    masYearlyRenewalDate?: number;
    masTriYearlyRenewalDate?: number;
    closeMonitoringFromDate?: number;
    closemonitoringToDate?: number;
    remark?: string;
    suspendedFromDate?: number;
    suspendedToDate?: number;
    requirementCheckList?: RequirementCheckListDTO;
    contractType?: any;
    ifpasNo?: string;
    contractDetails?: ContractDetailDTO;
    contractedFromDate?: number;
    terminatedDate?: number;
    issuedDate?: number;
    lastPromotionDate?: number;
    withExperience?: string;
    personalItemsIssued?: PersonalItemIssuedDetailDTO;
    channel?: CompanyInformationImportDTO.channel;
    contractTypeLabel?: string;
}

export namespace CompanyInformationImportDTO {

    export enum paymentStatus {
        COMMISSION_PAID = 'commissionPaid',
        COMMISSION_HOLD = 'commissionHold',
    }

    export enum channel {
        SG = 'SG',
        PS = 'PS',
        PFP = 'PFP',
    }


}