import { ProductMetaData } from '../ProductMetaData'

export const CoverageTypeOptions = [
  { value: '', label: '' },
  { value: 'na', label: 'NA' },
  { value: 'premier', label: 'Premier' },
  { value: 'superior', label: 'Superior' },
  { value: 'classic', label: 'Classic' },
  { value: 'deluxe', label: 'Deluxe' },
  { value: 'essential', label: 'Essential' },
  { value: 'comprehensive', label: 'Comprehensive' },
  { value: 'elite', label: 'Elite' },
  { value: 'economy', label: 'Economy' },
  { value: 'standard', label: 'Standard' }
]

export const TravelTypeOptions = [
  { value: '', label: '' },
  { value: 'singletrip', label: 'Single Trip' },
  { value: 'annualplan', label: 'Annual Plan' },
  { value: 'group', label: 'Group' },
  { value: 'family', label: 'Family' },
  { value: 'individual', label: 'Individual' }
]

export const ZoneOptions = [
  { value: '', label: '' },
  { value: 'asean', label: 'Asean' },
  { value: 'asia', label: 'Asia' },
  { value: 'worldwide', label: 'Worldwide' },
  { value: 'global', label: 'Global' },
  { value: 'malaysiaonly', label: 'Malaysia Only' }
]

export const NoOfChildrenOptions = [
  { value: '', label: '' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' }
]
export class GiTravel extends ProductMetaData {
  insuranceClass = undefined
  coverageType = undefined
  travelType = undefined
  departureDate = undefined
  returnDate = undefined
  zone = undefined
  lengthOfTrip = undefined

  grossPremium = undefined
  gst = undefined
  commissionRate = undefined
  commissionPayment = undefined
  commissionPayable = undefined
  grossCommissionEarned = undefined
  adult1 = undefined
  adult2 = undefined
  child1 = undefined
  child2 = undefined
  child3 = undefined
  child4 = undefined
  noOfChildren = undefined

  afterExtractData (data) {
    data = super.afterExtractData(data)
    data.gst = Number(data.gst)
    data.grossPremium = Number(data.grossPremium)
    data.commissionRate = Number(data.commissionRate)
    data.commissionPayment = Number(data.commissionPayment)
    data.commissionPayable = Number(data.commissionPayable)
    data.grossCommissionEarned = Number(data.grossCommissionEarned)
    return data
  }
}
