import { Default } from "@master/annotation/Default";
import { Base } from "../Base";

export default class CommonProductErrors extends Base {
  // NOTE: create a 2 keys for each product validation to handle general validations in side navi and next button

  // Risk Profile Validation
  @Default(false)
  isRiskProfileMismatchedC1;

  @Default(false)
  isRiskProfileMismatchedC2;

  @Default("")
  profileMismatchJustificationC1;

  @Default("")
  profileMismatchJustificationC2;

  // Profile Mismatch Time Horizon Validation
  @Default(false)
  isRiskPofileMismatchTimeHorizonMatchedC1;

  @Default(false)
  isRiskPofileMismatchTimeHorizonMatchedC2;

  @Default("")
  profileMismatchTimeHorizonJustificationC1;

  @Default("")
  profileMismatchTimeHorizonJustificationC2;

  // Expense Validation
  @Default(false)
  isExpenseMismatchValidationC1;

  @Default(false)
  isExpenseMismatchValidationC2;

  @Default("")
  expenseMismatchJustificationC1;

  @Default("")
  expenseMismatchJustificationC2;

  // death Validation
  @Default(false)
  deathMismatchValidationC1;

  @Default(false)
  deathMismatchValidationC2;

  @Default("")
  deathMismatchJustificationC1;

  @Default("")
  deathMismatchJustificationC2;

  // retrement Validation
  @Default(false)
  retireMismatchValidationC1;

  @Default(false)
  retireMismatchValidationC2;

  @Default("")
  retireMismatchJustificationC1;

  @Default("")
  retireMismatchJustificationC2;

  // saving Validation
  @Default(false)
  saveMismatchValidationC1;

  @Default(false)
  saveMismatchValidationC2;

  @Default("")
  saveMismatchJustificationC1;

  @Default("")
  saveMismatchJustificationC2;

  // dependant Validation
  @Default(false)
  dependMismatchValidationC1 = false;

  @Default(false)
  dependMismatchValidationC2 = false;

  @Default("")
  dependMismatchJustificationC1;

  @Default("")
  dependMismatchJustificationC2;

  // Budget Validation
  @Default(false)
  isBudgetMismatchValidationC1;

  @Default(false)
  isBudgetMismatchValidationC2;

  @Default("")
  budgetMismatchJustificationC1;

  @Default("")
  budgetMismatchJustificationC2;

  // Budget Utilization Validation
  @Default(false)
  isBudgetUtilizationMismatchValidationC1;

  @Default(false)
  isBudgetUtilizationMismatchValidationC2;

  @Default("")
  budgetUtilizationMismatchJustificationC1;

  @Default("")
  budgetUtilizationMismatchJustificationC2;

  // Premium Term vs Retirement Age
  @Default(false)
  isPremiumTermVsRetirementAgeC1;

  @Default(false)
  isPremiumTermVsRetirementAgeC2;

  @Default("")
  premiumTermVsRetirementAgeJustificationC1;

  @Default("")
  premiumTermVsRetirementAgeJustificationC2;


  //Needs Validation
  @Default(false)
  isOverSellingProductsC1;

  @Default(false)
  isOverSellingProductsC2;

  @Default({})
  overSellingJustificationC1;

  @Default({})
  overSellingJustificationC2;

  @Default(false)
  isPriorityMismatchedInsurenceC1;

  @Default(false)
  isPriorityMismatchedInsurenceC2;

  @Default("")
  priorityMismatchInsurenceJustificationC1;

  @Default("")
  priorityMismatchInsurenceJustificationC2;

  @Default(false)
  timeHorizonDeclaration: boolean = false;

  constructor() {
    super();
  }
}
