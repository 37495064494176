/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IndividualMyinfoDTO } from '../models/IndividualMyinfoDTO';
import { request as __request } from '@openapi/request';

export class UserMyinfoService {

    /**
     * @param ownerOid
     * @returns IndividualMyinfoDTO Retrieve the owner myinfo details if exists
     * @throws ApiError
     */
    public static async retrieveIndividualMyinfo(
        ownerOid: string,
    ): Promise<IndividualMyinfoDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/owner/myinfo/${ownerOid}`,
        });
        return result.body;
    }

}