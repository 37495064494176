import 'reflect-metadata'

/**
 * Ignore annotation can support getter now, but it needs extra implementation in Base.ts
 * and new version will found the key via Object.keys()
 */
const jsonMetadataKey = Symbol('Ignore')

export function Ignore () {
  return Reflect.metadata(jsonMetadataKey, true)
}

export function isIgnore (target: any, propertyKey: string) {
  return Reflect.getMetadata(jsonMetadataKey, target, propertyKey)
}
