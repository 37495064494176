/* istanbul ignore file */
/* tslint:disable */

import { Expose } from "class-transformer";
import { mixins } from "vue-facing-decorator";

/* eslint-disable */
export class ClientPolicyInformationMetadataDTO {
    name?: string;
    typeOfService?: string;
    linkedPolicyNo?: string;
    productStatus: string;
    productStatusDate?: bigint;
    statusReason: string;
    statusReasonDate?: bigint;
    providerApplicationNumber?: string;
    prematureTermination?: bigint;
    renewalDate?: bigint;
    paidToDate?: bigint;
    anniversaryDate?: bigint;
    mailingDate?: bigint;
    receivedDate?: bigint;
    submittedDate?: bigint;
    productTypeCode?: string;
    productCode?: string;
    replacementStatus?: string;
    replacementStatusReason?: string;
    replacedPolicyNumber?: string;
    replacedPolicyWritingAdvise?: string;
    Category?: string;
    componentCode?: string;
}

export class ClientPolicyPolicyInformationRequestDTO {
    principal?: string;
    productOid?: string;
    policyNumber: string;
    applicationDate?: bigint;
    approvedDate?: bigint;
    fpEfpExistingPolicy?: string;
    productMetadata: ClientPolicyInformationMetadataDTO;
}


export class ClientPolicyBasicInformationRequestDTO {
    orignalEffectiveDate?: bigint;
    startDate: bigint;
    endDate: bigint;
    productMetadata: ClientPolicyBasicInformationProductMetadataDTO;
}

export class ClientPolicyBasicInformationProductMetadataDTO {
    issueAge?: string;
    expiryAge?: string;
    sumAssured: number;
    currency?: string;
    frequency: string;
    premiumAmount: number;
    ncBasicModalPremium?: number;
    modalPremium: number;
    asp: number;
    onshoreOffshore?: string;
    paymentMode: string;
    paymentMethodSpecify?: string;
    premiumTerm: string;
    policyTerm: string;
    ape?: number;
    modal_premium?: number;
    nc_basic_modal_premium?: number;
    basic_modal_premium?: number;
}
export class NbrProductMetadataDTO {

    constructor(data: object = null) {
        if (data && typeof data == 'object' && Object.keys(data).length >= 0) {
            for (const key in data) {
                if (key in this) {
                    this[key] = data[key];
                }
            }
        }
    }

    issueAge?: string;
    expiryAge?: string;
    sumAssured: number;
    currency?: string;
    frequency: string;
    premiumAmount: number;
    ncBasicModalPremium?: number;
    modalPremium: number;
    asp: number;
    onshoreOffshore?: string;
    paymentMode: string;
    paymentMethodSpecify?: string;
    premiumTerm: string;
    policyTerm: string;
    ape?: number;
    modal_premium?: number;
    nc_basic_modal_premium?: number;
    basic_modal_premium?: number;

    name?: string; // product name
    productName?: string // product name 
    typeOfService?: string;
    linkedPolicyNo?: string;
    productStatus: string;
    productStatusDate?: bigint;
    statusReason: string;
    statusReasonDate?: bigint;
    providerApplicationNumber?: string;
    prematureTermination?: bigint;
    renewalDate?: bigint;
    paidToDate?: bigint;
    anniversaryDate?: bigint;
    mailingDate?: bigint;
    receivedDate?: bigint;
    submittedDate?: bigint;
    productTypeCode?: string;
    productCode?: string;
    replacementStatus?: string;
    replacementStatusReason?: string;
    replacedPolicyNumber?: string;
    replacedPolicyWritingAdvise?: string;
    Category?: string;
    componentCode?: string;
    componentName?: string
}

export class ComponentDetailSectionA {
    constructor(data: object = null) {
        if (data && typeof data == 'object' && Object.keys(data).length >= 0) {
            for (const key in data) {
                if (key in this) {
                    this[key] = data[key];
                }
            }
        }
    }
    productOid?: string
    startDate?: bigint
    endDate?: bigint
    duration?: string
    ncp?: number = 0
    sumAssured?: number = 0
    premiumAmount?: number = 0
    name?: string
    productName?:string
    componentName?:string
}
export class ClientPolicyOtherPremiumInformationDTO extends ComponentDetailSectionA {
    constructor(data: object = null) {
        super ()
        if (data && typeof data == 'object' && Object.keys(data).length >= 0) {
            for (const key in data) {
                if (key in this) {
                    this[key] = data[key];
                }
            }
        }
    }
    adminFee?: number = null;
    targetPremium?: number = null;
    writtenPremium?: number = null;
    initialAnnualPremium?: number = null;
    actualPremium?: number = null;
    commissionPremium?: number = null;
    installmentPremium?: number = null;
    loadingPremium?: number = null;
    totalPremiumReceived?: number = null;
    premiumCessationDate?: bigint;
    extraPremium?: number = null;
    paymentStatus?: string;
    paymentMethodDetail?: string;
    mandateStatus?: string;
    mandateApplicationDate?: bigint;
    applicationRejectionReason?: bigint;
    latestGiroPaymentCollectionStatus?: string;
    isFollowupRequired: string;
    premiumStatus?: string;
    lastPremiumDeductionDate?: bigint;
    lastPremiumDecutionStatus?: number;
    faceValue?: number = null;
    coverageCategory?: string;
    coverageNumber?: string;
    coverageName?: string;
    coverageStatus?: string;
    coverageSumAssured?: number = null;
    coverageInstallmentPremium?: number = null;
    riskCommencementDate?: bigint;
    renewalFrom?: bigint;
    renewalTo?: bigint;
    lifeNumber?: string;
    jointLifeNumber?: string;
    riderNumber?: string;
    clientNumberOfMailingAddress?: string;
    businessFax?: string;
    componentSinglePremium?: number = null
    exchangeRate?: number = null
    componentPremium?: number = null
    componentPremiumTerm?: string
    componentPolicyTerm?: string
    focIndicator?: string
    mtaIndicator?: string
    initialCommissionAdviser?: string
    componentIssueDate?: bigint
    componentType?: string
    componentCode?: string
    componentAPE?: string
    providerShortName?: string
    providerCode?: string
    providerOid?: string
    clientOid?: string
    adviserOid?: string
    lineOfBusiness?: string
    applicationDate?: string
    mainNbrOid?: string
    issuerOid?: string
    planCode?:string
    numberOfMembers?:string
    numberOfSubscribers?: string
    faceAmount?: number
    value?: string
    cash?: string
    maxCapAmount?: number
    renewalFee?: string
    policyInsuredAge?: number
    policySpouseAge?: number
    paidToDays?: number
    paidToMonths?: number
    appSubmissionSource?: string
    billType?: string
    referralType?: string
    sourceSystemName?: string
    groupNumber?: string
    leadCode?: string
    leadSource?: string
    contractedNoOfSubscribers?: string
    contractedNoOfMembers?: string
    usePolicyLevelRate?: string
    changeDate?: bigint
    campaignCode?: string
    failedLodgment?: string
    category?: string
    coverType?: string
    coverCode?: string
    transactionType?: string
    subTransactionType?: string
    transactionDate?: bigint
    transactionAmount?: number
    netSurrenderValue?: number
    outstandingNFSurrenderLoan?: number
    automaticPremiumLoan?: number
    loanValuesEffectiveDate?: bigint
    outstandingPolicyLoan?: number
    revisionaryBonus?: number
    cashbackAmount?: number
    bankName?:string
    bankAccountNumber?:string
}

export class ClientPolicyOtherInformationDTO extends ClientPolicyOtherPremiumInformationDTO {

}
export interface UpdateClientPolicyInformationRequestDTO {
    nbrOid: string;
    data: any;
    tab: UpdateClientPolicyInformationRequestDTO.tab;
    section: UpdateClientPolicyInformationRequestDTO.section;
}

export namespace UpdateClientPolicyInformationRequestDTO {

    export enum tab {
        POLICY_INFORMATION = 'policyInformation',
        POLICY_INDIVIDUAL = 'policyIndividual',
        UNDERWRITING_DETAIL = 'underwritingDetail',
        ILP_DETAIL = 'ilpDetail',
        PORTFOLIO = 'portfolio',
    }

    export enum section {
        POLICY_INFORMATION = 'policyInformation',
        BASIC_INFORMATION = 'basicInformation',
        OTHER_PREMIUM_INFORMATION = 'otherPremiumInformation',
    }


}
