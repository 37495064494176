import { Ignore } from '@master/annotation/Ignore'
import { Pointer } from '@master/annotation/Pointer'
import { IDateService } from '@master/services/IDateService'
import { isNull, isUndefined } from 'lodash/fp'
import { ArrayBase } from '../ArrayBase'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'
import { ClientBase } from '@master/model/ClientBase';

export const YearsSupportMap = {
  son: {
    label: 'Son',
    year: 25
  },
  daughter: {
    label: 'Daughter',
    year: 23
  },
  male: {
    label: 'Male',
    year: 82
  },
  female: {
    label: 'Female',
    year: 86
  }
}

export class DependantDetails extends ClientBase {
  DDskipDependant: 'no_dependant' | 'no_disclose' | '' = '';

  DDskipRemark = '';

  DDdepentants = [];

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type);
    this.DDdepentants = [];
    // this.DDdepentants = Array(4).fill(null).map(() => new Dependant(dateService));
  }
}

export class Dependant extends ArrayBase {
  type = 'dependant'

  dependantOid: string = ''

  @MaxChar(InputMaxChar.XSMALL)
  name = ''

  @MaxChar(InputMaxChar.XSMALL)
  nric = ''

  identityType: string = ''

  race: string = ''

  dob?: number = undefined

  gender: string = ''

  yearsSupport: string = ''

  remarks: string = ''

  @MaxChar(InputMaxChar.XSMALL)
  relationship: string = ''

  @Ignore()
  dateService: IDateService

  @Ignore()
  @Pointer('ROOT.clientDeclaration', 'reviewDate')
  reviewDate?: number = 0

  constructor(dateService: IDateService) {
    super()
  }

  isValid() {
    return !isUndefined(this.name) && !isNull(this.name) && this.name !== ''
  }

  get age() {
    if (!this.dob) {
      return null;
    }
    return this.dateService.calculateAge(this.reviewDate ? new Date(this.reviewDate) : new Date(), new Date(this.dob));
  }

  // get dobFormat() {
  //   if (!this.dob) {
  //     return '';
  //   }
  //   return this.dateService.format(this.dob, 'dd MMM yyyy');
  // }

  // get customYearsSupport() {
  //   if (!this.yearsSupport && this.defaultYearsSupport) {
  //     this.yearsSupport = this.defaultYearsSupport.toString()
  //   }
  //   return this.yearsSupport
  // }

  // set customYearsSupport(value: string | number) {
  //   this.yearsSupport = value.toString()
  // }

  // get defaultYearsSupport() {
  //   if (!this.relationship || (this.age !== 0 && !this.age)) { 
  //     return;
  //   }
  //   const involvedRelationship = Object.keys(YearsSupportMap)
  //   const relationshipLowerCase = this.relationship.toLowerCase();
  //   const genderLowerCase = this.gender?.toLowerCase();
  //   if (involvedRelationship.includes(relationshipLowerCase)) {
  //     const endYear = YearsSupportMap[relationshipLowerCase].year;
  //     let yearToSupport = endYear - this.age;
  //     if (yearToSupport < 0) {
  //       yearToSupport = 0;
  //     }
  //     if (!this.yearsSupport) {
  //       this.yearsSupport = yearToSupport.toString();
  //     }
  //     return yearToSupport;
  //   } else if (involvedRelationship.includes(genderLowerCase)) {
  //     const endYear = YearsSupportMap[genderLowerCase].year;
  //     let yearToSupport = endYear - this.age
  //     if (yearToSupport < 0) {
  //       yearToSupport = 0;
  //     }
  //     if (!this.yearsSupport) {
  //       this.yearsSupport = yearToSupport.toString();
  //     }
  //     return yearToSupport;
  //   }
  //   return null;
  // }

  // get requiredDeviationExplanation () {
  //   return this.yearsSupport && (this.yearsSupport !== this.defaultYearsSupport)
  // }
}