import { get, isUndefined } from 'lodash/fp'
import 'reflect-metadata'

const pointerMetadataKey = Symbol('Pointer')
/**
 * This is to setup the getter when your model requires attribute from other models
 * Example :
 *
 * `Absolute path :`
 * @Pointer(`ROOT.${target}`, `${target.attribute}`) : pointing to root's model's attribute regardless of where you current model is
 * @Pointer(`ROOT`, `${target}`) : pointing to root's model regardless of where you current model is
 *
 * `Relative path :`
 * @Pointer(`${target}`, `${target.attribute}`) : pointing to a model (target) that is on the same level as your current model
 * @Pointer(`${target}.${target.submodel}`, `${submodel.attribute}`) : pointing to a model (target)'s submodel that is on below level of your current model
 * @param pathToObj : `` or `ROOT` is used to define the path to target is absolute
 * @param propertyKey
 */
export function Pointer (pathToObj: string, propertyKey: string) {
  return Reflect.metadata(pointerMetadataKey, {
    path: pathToObj,
    key: propertyKey
  })
}

export function isPointer (target: any, propertyKey: string): {path: string; key: string} {
  return Reflect.getMetadata(pointerMetadataKey, target, propertyKey)
}

function getTarget (obj, chain) {
  if (isUndefined(chain)) {
    console.error(`Failed to get ${chain} in ${obj}!`)
    return
  }
  const chainArr = chain.split('.')
  let parent, target
  if (chainArr.length === 1) {
    parent = obj
    target = chain
  } else {
    parent = get(chainArr.slice(0, chainArr.length - 1), obj)
    target = chainArr.slice(-1)
  }
  return { parent, target }
}

export interface IPointer {
  path: string;
  key: string;
  pointerKey: string;
}

export function setupPointer (pointerArr: IPointer[], root: object, ancestor: object, parent: object, debug = false) {
  if (pointerArr.length) {
    pointerArr.forEach((point) => {
      let watchParent, watchTarget
      if (debug) console.log('setting up Pointer', ancestor, point)
      if (point.path === 'ROOT') {
        Object.defineProperty(parent, point.pointerKey, {
          get: () => {
            return root[point.key]
          }
        })
        return
      }
      const pathArr = point.path.split('.')
      if (pathArr[0] === 'ROOT') {
        const path = point.path.replace('ROOT.', '')
        const watch = getTarget(root, path)
        watchParent = watch.parent
        watchTarget = watch.target
        if (debug) console.log('locating target in root model : ', watchParent, watchTarget)
        if (isUndefined(watchParent) || isUndefined(watchTarget) || isUndefined(watchParent[watchTarget])) {
          console.error(`Unable to locate ${point.path} in`, root)
          return
        }
      } else {
        const watch = getTarget(ancestor, point.path)
        watchParent = watch.parent
        watchTarget = watch.target
        if (isUndefined(watchParent) || isUndefined(watchTarget) || isUndefined(watchParent[watchTarget])) {
          console.error(`Unable to locate ${point.path} in`, ancestor)
          return
        }
      }
      if (debug) console.log('getTarget', watchParent, watchTarget)
      Object.defineProperty(parent, point.pointerKey, {
        get: () => {
          return watchParent[watchTarget][point.key]
        }
      })
    })
  }
}
