import { riderProductTypes, insuranceProductTypes, insuranceProductCategories, investmentProductTypes, investmentProductCategories, ilpProductTypes } from '@master/model/common/ProductMeta'
import { NewProductDTO } from '../api'

export class NewProductModel implements NewProductDTO {
  issuer = ''
  issuer_oid = ''
  isBscClassify = undefined
  productFamilyName = ''
  productFamilyCode = ''
  productCode = ''
  name = ''
  type = ''
  category = ''
  needsCovered = [] as any
  recommendedRiskProfile = undefined
  riskRating = ''
  others = ''
  paymentType = [] as any
  premiumType = [] as any
  status = undefined
  launchDateMillis = undefined
  withdrawnDateMillis = undefined
  reasons = ''
  limitations = ''
  supplementaryBenefits = ''
  commReceivableFromDateMillis = undefined
  commReceivableToDateMillis = undefined
}

export class ProductModel implements NewProductDTO {
  pky: number;
  oid: string;
  issuer?: string;
  issuer_oid?: string;
  isBscClassify?: number;
  productFamilyName?: string;
  productFamilyCode?: string;
  productCode?: string;
  name?: string;
  type?: string;
  category?: string;
  needsCovered?: string;
  recommendedRiskProfile?: number;
  riskRating?: string;
  others?: string;
  paymentType?: string;
  premiumType?: string;
  status?: number;
  launchDateMillis?: number;
  withdrawnDateMillis?: number;
  reasons?: string;
  limitations?: string;
  supplementaryBenefits?: string;
  commReceivableFromDateMillis?: number;
  commReceivableToDateMillis?: number;

  isRider () {
    return this.isInclude(riderProductTypes, this.type) || this.isInclude(['rider'], this.category)
  }

  isInsurance () {
    return this.isInclude(insuranceProductTypes, this.type) || this.isInclude(insuranceProductCategories, this.category)
  }

  isInvestment () {
    return this.isInclude(investmentProductTypes, this.type) || this.isInclude(investmentProductCategories, this.category)
  }

  isILP () {
    return this.isInclude(ilpProductTypes, this.type) || this.isInclude([], this.category)
  }

  // Product filter is case insensitive
  // filter list make it to support both string & Regex to support more broader cases
  // Known issue in LINC is product category as 'hospital cash insurance' will show under investment
  // due to LINC include any category contain 'cash'
  isInclude (checkList: (string | RegExp)[], checkValue: string) {
    checkValue = checkValue.toLowerCase()
    return checkList.some(check => {
      if (check instanceof RegExp) {
        return check.test(checkValue)
      } else {
        return check === checkValue
      }
    })
  }
}
