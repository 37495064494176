import { RawKey } from '@master/annotation/RawKey'
import { Meta } from '@master/model/common/Meta'

export class PolicyClientMeta extends Meta {
  @RawKey('_oid')
  oid = ''

  @RawKey('accountType')
  accountType = ''

  title = ''
  citizenship = ''
  nationalityOthers = undefined
  nationalityOthers2 = undefined
  gender = undefined
  dob = undefined
  occupation = undefined
  identityType = undefined
  maritalStatus = undefined
  registrationDate = undefined
  residentialAddressOne = undefined
  companyAddressOne = undefined
  postalCodeOne = undefined
  countryOfResidenceOne = undefined
  residentialAddressTwo = undefined
  companyAddressTwo = undefined
  postalCodeTwo = undefined
  countryOfResidenceTwo = undefined
  mailingAddress = undefined
  mailingPostalCode = undefined
  mailingCountry = undefined
  companyAddressOnePostalCode = undefined
  companyAddressOneCountry = undefined
  companyAddressTwoPostalCode = undefined
  companyAddressTwoCountry = undefined
  mobileNoOne = undefined
  officeNoOne = undefined
  homeNoOne = undefined
  emailOne = undefined
  mobileNoTwo = undefined
  officeNoTwo = undefined
  homeNoTwo = undefined
  emailTwo = undefined
  exactDuties = undefined
  companyName = undefined
  annualIncomeRange = undefined
  highestEducationLevel = undefined
  spokenLanguage: string[] = []
  spokenLanguageOthers = undefined
  writtenLanguage: string[] = []
  writtenLanguageOthers = undefined
  isAccreditedInvestor: boolean = undefined
  isOverseasInvestor: boolean = undefined
  passedCka: boolean = undefined
  selectedClient: boolean = undefined
  hasBo: boolean = undefined
  hasPep: boolean = undefined
  pdpaMarketing: Array<string> = []
  pepNameList: Array<string> = []
  boName = ''
  payingPremium = ''
  nameOfPayor = ''
  occupationPayor = ''
  contactPayor = ''
  payorRelationship = ''
  nricppPayor = ''
  payorReasons = ''
  highRisk = ''
  complianceReview = ''
  complianceReviewDate: number = undefined
  complianceRemarks = ''
  ceoReview = ''
  ceoReviewDate: number = undefined
  ceoRemarks = ''
  highRiskReason = ''
  highRiskReasonOther = ''
  amlScreeningDoneBy = ''
  resolutionStatus = ''
  riskStatus = ''
  reason = ''
  notes = ''
  riskProfile = ''
  replacement = ''
  clientCallbackSelectedFc = ''
  premiumExceed = ''
  employmentStatus = ''
  sumAssured:any=0
}
