import { SharedVariable } from '@core/model/SharedVariable'
import { UserMeta } from '@core/model/common/UserMeta'
import { AdviserLicenceDto, AdviserLicenceService } from '@core/openapi/services/adviser-licence/api'
import { LicenceProductMappingDto, LicenceProductMappingService, ProductLicenceDto, ProductLicenceService } from '@core/openapi/services/product-licence/api'
import {AccountService, CountryDetailDTO } from '@core/openapi/services/user-account/api'
import { UIService } from '@core/services/UIService'
import { cid, container } from 'inversify-props'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import {Getter} from "vuex-facing-decorator";
import { BscService, BscReportSetupResponseDto } from '@core/pages/bsc/api'
import { BscReportSetup } from '../model/BscReportSetup'
const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()

@Module({ namespaced: true, name: 'BscSetupStore' })
export default class BscSetupStore extends VuexModule {

    variableListData = []
    variableListRecordsTotal = 0
    bscGradeListResponse = []
    bscGradeRecordsTotal = 0
    supervisorGradeListResponse = []
    supervisorGradeRecordsTotal = 0
    latestSupervisorRecord = {}
    supervisorGradeupdateresp = {}
    bscGradePage
    bscReportSetupData = []
    bscReportSetupRecordsTotal = 0
    bscReportSetupUpdateResponse: any = {}

    @Mutation
    setVariableResponse ({data, recordsTotal}) {
        data.map(item => {
            item.startingQuarter = `Q${item.startingQuarter}`
        })
        this.variableListData = data
        this.variableListRecordsTotal = recordsTotal
    }

    @Mutation
    setSupervisorGradeUpdateResponse (data) {
        this.supervisorGradeupdateresp = data
    }

    @Mutation
    setBscGradeResponse ({data, page, recordsTotal}) {
        data.map(item => {
            return item.yearQuarterData.map( item => {
                item.startingQuarter = `Q${item.startingQuarter}`
            })
        })
        this.bscGradeListResponse = data
        this.bscGradeRecordsTotal = recordsTotal
        this.bscGradePage = page
    }

    @Mutation
    setSupervisorGradeResponse({data, recordsTotal}) {
        this.supervisorGradeListResponse = data
        this.supervisorGradeRecordsTotal = recordsTotal
    }

    @Mutation
    setLatestSupervisorRecord(data) {
        this.latestSupervisorRecord = data
    }

    @Mutation
    setBscReportSetup(data) {
        this.bscReportSetupData = data.data
        this.bscReportSetupRecordsTotal = data.recordsTotal
    }

    @Mutation
    setBscReportSetupUpdateResponse (data) {
        this.bscReportSetupUpdateResponse = data
    }

    @Action({rawError: true})
    async fetchVariablePercentageList ({limit, page}) {
        const response = await BscService.retrieveListOfBscVariablePercentage(limit, page)
        console.log(response)


        this.context.commit('setVariableResponse', response)
    }

    @Action({rawError: true})
    async fetchSupervisorGradeList ({limit, page}) {
        const response = await BscService.retrieveListOfBscSupervisorGradeHistory(limit, page)
        console.log(response)

        this.context.commit('setSupervisorGradeResponse', response)
    }

    @Action({rawError: true})
    async addVariablePercentage (payload) {
        const response = await BscService.createBscVariablePercentage(payload)
        console.log(response)

        // this.context.commit('setVariableResponse', response)
    }

    @Action({rawError: true}) 
        async eligibleBscGrade ({limit, page}) {
            const response = await BscService.retrieveListOfBscAdviserGrade(limit, page)
            console.log(response)
        this.context.commit('setBscGradeResponse', response)
        }

    @Action({rawError: true}) 
    async createBscGrade (payload) {
        const response = await BscService.createBscAdviserGrade(payload)
        console.log(response)
    }

    @Action({rawError: true}) 
    async updateBscGrade ({oid, payload}) {
        const response = await BscService.updateBscAdviserGrade(oid, payload)
        console.log(response)
    }
        

    @Action({rawError: true})
    async updateVariablePercentage ({oid, payload }) {
        const response = await BscService.updateBscVariablePercentage(oid, payload)
        console.log(response)

        // this.context.commit('setVariableResponse', response)
    }

    @Action({rawError: true})
    async latestSupervisorGradeRecord() {
      const response = await BscService.retrieveLatestBscSupervisorGrade()
      this.context.commit('setLatestSupervisorRecord', response)
    }

    @Action({rawError: true})
    async deleteBscGrade (oid) {
        const response = await BscService.deleteBscAdviserGrade(oid)
        console.log(response)

    } 
    @Action({rawError: true})
    async deleteBscVariablePercentage (oid) {
        const response = await BscService.deleteBscVariablePercentage(oid)
    }

    @Action({rawError: true})
    async updateBscSupervisorGrade ({oid, payload }) {
        const response = await BscService.updateBscSupervisorGrade(oid, payload)
        console.log(response)

        this.context.commit('setSupervisorGradeUpdateResponse', response)
    }

    @Action({rawError: true})
    async addSupervisorGrade (payload) {
        const response = await BscService.createBscSupervisorGrade(payload)
        console.log(response)

        this.context.commit('setSupervisorGradeUpdateResponse', response)
    }

    @Action({rawError: true})
    async retrieveListOfBscReportSetup() {
      const response: BscReportSetupResponseDto = await BscService.retrieveListOfBscReportSetup()
      this.context.commit('setBscReportSetup', response)
    }

    @Action({rawError: true})
    async updateBscReportSetup (payload) {
        const response = await BscService.updateBscReportSetup(payload)
        this.context.commit('setBscReportSetupUpdateResponse', response)
    }
}
