import { PTC } from '@master/model/ptc/PTC'
import { ISimpleForm } from '@master/interface/ISimpleForm'

export class PTCBase<FORM extends ISimpleForm<CLIENT>, CLIENT> {
  preTransactionalList: { title: string; sections: PTC<FORM, CLIENT>[] }[]
  kycForm: FORM

  constructor (kycForm: FORM) {
    this.kycForm = kycForm
  }

  getById (id: string) {
    return this.preTransactionalList
      // map section and flat it to get the list of section
      .map(({ sections }) => sections)
      .flat()
      .find(section => section.id === id)
  }

  isClient2Include (): boolean {
    throw new Error('Override isClient2Include')
  }

  validate (validation) {
    const client2Included = this.isClient2Include()

    this.preTransactionalList.forEach(module => {
      module.sections.forEach(section => {
        section.validate(this.kycForm, validation, client2Included)
      })
    })

    return this.preTransactionalList
  }

  extractData (rawData) {
    const ptcData = rawData.ptc_fields
    this.preTransactionalList.forEach(module => {
      module.sections.forEach(section => {
        section.extractData(ptcData)
      })
    })
  }

  convertToRawData () {
    return this.preTransactionalList.reduce((result, module) => {
      const sectionsData = module.sections.reduce((result, section) => {
        return { ...result, ...section.convertToRawData() }
      }, {})
      return { ...result, ...sectionsData }
    }, {})
  }
}
