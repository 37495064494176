/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReportGeneratorDto = {
    report: number;
    option: any;
    from: number;
    to: number;
    status: ReportGeneratorDto.status;
}

export namespace ReportGeneratorDto {

    export enum status {
        PAID = 'paid',
        UNPAID = 'unpaid',
    }


}
