import CoreBudget from '@master/model/kyc-form/finances/Budget'
import { isUndefined, set, get } from 'lodash/fp'

/**
 *
 */
export default class Budget extends CoreBudget {
  budgetCashPerAnnum?: number = undefined
  budgetCashPerMonth?: number = undefined
  budgetCash?: number = undefined
  budgetCpfOrdinary?: number = undefined
  budgetCpfSpecial?: number = undefined
  budgetSrsAccount?: number = undefined
  budgetUtilizeCpfMedisave?: number = undefined
  budgetSourceOfFunds: string[] = []
  budgetSourceOfWealth: string[] = []
  smallBudgetSize = ''
  budgetSubstantialReasons = '' // was ma_budget_substantial_reasons
  budgetOthers1 = '' // was ma_budget_others_1
  budgetOthers2 = '' // was ma_budget_others_2
  budgetOthers3 = '' // was ma_budget_others_3

  afterExtractData (data: {}) {
    let defaultMap = {}
    if (isUndefined(get('budgetSourceOfFunds', data))) {
      defaultMap = set('budgetSourceOfFunds', [], defaultMap)
    }
    if (isUndefined(get('budgetSourceOfWealth', data))) {
      defaultMap = set('budgetSourceOfWealth', [], defaultMap)
    }
    Object.assign(data, defaultMap)
    return data
  }
}
