import { RawKYCPdf } from '@/views/kyc-pdf/model/rawKYCPdf'
import { FormattedKYCPdf } from '@/views/kyc-pdf/model/formattedKYCPdf'

export default class FormKeyConverter {
  mapRawToFormatted (kycPdf: RawKYCPdf) {
    const formattedKYCPdf: FormattedKYCPdf = {} as FormattedKYCPdf // eslint-disable-line @typescript-eslint/consistent-type-assertions
    let reconstructKey = ''
    for (const key of Object.keys(kycPdf)) {
      reconstructKey = key

      // there are existing fields with client_
      const regex = new RegExp(/^client_[a-zA-Z]/)
      if (regex.test(reconstructKey)) {
        reconstructKey = 'client_0' + reconstructKey.substr(6)
      } else if (key.startsWith('client_2')) {
        reconstructKey = 'client_1' + reconstructKey.substr(8)
      }

      switch (key) {
        case 'ppda_consent' :
          reconstructKey = 'pdpa_consent'
          break
      }

      // replace all key starts with ma/ja
      if (reconstructKey.startsWith('ma_')) {
        reconstructKey = 'client_0_' + reconstructKey.substr(3)
      } else if (key.startsWith('ja_')) {
        reconstructKey = 'client_1_' + reconstructKey.substr(3)
      }
      if (reconstructKey.startsWith('accompaniment_')) {
        reconstructKey = 'trusted_individual_' + reconstructKey.substr(14)
      }
      //  for pep
      reconstructKey = reconstructKey.replace('pep1', 'pep_1')
      reconstructKey = reconstructKey.replace('pep2', 'pep_2')
      reconstructKey = reconstructKey.replace('pep3', 'pep_3')
      //  end for pep
      if (reconstructKey === '') {
        reconstructKey = key
      }
      // go one level deeper if its ptc fields
      if (reconstructKey === 'ptc_fields') {
        formattedKYCPdf[reconstructKey] = {}
        let ptcReconstructKey = ''
        for (const ptcKey of Object.keys(kycPdf[key as keyof RawKYCPdf])) {
          ptcReconstructKey = ptcKey
          if (ptcKey.startsWith('ma_')) {
            ptcReconstructKey = 'client_0_' + ptcKey.substr(3)
          } else if (ptcKey.startsWith('ja_')) {
            ptcReconstructKey = 'client_1_' + ptcKey.substr(3)
          }
          formattedKYCPdf[reconstructKey][ptcReconstructKey] = kycPdf[key as keyof RawKYCPdf][ptcKey]
        }
        continue
      }
      // After all has been renamed, put the into the formatted form
      formattedKYCPdf[reconstructKey] = kycPdf[key as keyof RawKYCPdf]
    }

    return formattedKYCPdf
  }

  mapFormattedToRaw (snakeCaseKycPdf: {}) {
    const rawKycPdf = {
      _oid: ''
    }
    let reconstructKey = ''
    for (const key of Object.keys(snakeCaseKycPdf)) {
      reconstructKey = this.getRawKey(key)

      rawKycPdf[reconstructKey as keyof {}] = snakeCaseKycPdf[key as keyof {}]
    }

    return rawKycPdf
  }

  getRawKey (key: string) {
    let reconstructKey = key
    switch (key) {
      case 'pdpa_consent' :
        reconstructKey = 'pdpa_consent'
        break
    }

    // replace all key starts with ma/ja
    if (reconstructKey.startsWith('client_0_')) {
      reconstructKey = 'ma_' + reconstructKey.substr(9)
    } else if (key.startsWith('client_1_')) {
      reconstructKey = 'ja_' + reconstructKey.substr(9)
    }
    if (reconstructKey.startsWith('trusted_individual_')) {
      reconstructKey = 'accompaniment_' + reconstructKey.substr(19)
    }
    //  for pep
    reconstructKey = reconstructKey.replace('pep_1', 'pep1')
    reconstructKey = reconstructKey.replace('pep_2', 'pep2')
    reconstructKey = reconstructKey.replace('pep_3', 'pep3')
    //  end for pep

    if (reconstructKey === '') {
      reconstructKey = key
    }
    return reconstructKey
  }
}
