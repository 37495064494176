export const ExamTypeOptions = [
  { label: '', value: '' },
  { label: 'M5', value: 'M5' },
  { label: 'M8', value: 'M8' },
  { label: 'M9A', value: 'M9A' },
  { label: 'BCP', value: 'BCP' },
  { label: 'M6', value: 'M6' },
  { label: 'M8A', value: 'M8A' },
  { label: 'HI', value: 'HI' },
  { label: 'PGI', value: 'PGI' },
  { label: 'M6A', value: 'M6A' },
  { label: 'M9', value: 'M9' },
  { label: 'Certificate in GI', value: 'certGI' },
  { label: 'CommGI', value: 'commGI' }
]
// should add status: ['PASSED', 'EXEMPTED'] ?

// https://github.com/microsoft/TypeScript/issues/20965#issuecomment-510040954
// Via this link, we are able to allow ExamTypeForAttributes to see actual values instead
// of just 'string'
export const ExamTypeForAttributes = ExamTypeOptions.map(option => {
  if (option.value) return option.value
})

export const ReimbursableExamTypeOptions = [
  { label: '', value: '' },
  { label: 'M5', value: 'M5' },
  { label: 'M8', value: 'M8' },
  { label: 'M8A', value: 'M8A' },
  { label: 'M9', value: 'M9' },
  { label: 'M9A', value: 'M9A' },
  { label: 'HI', value: 'HI' }
]
