import { SimpleKYCFNA } from '@master/model/kyc-form/need-analysis/PrioritiesNeeds'
import { SimplePrioritiesList } from '@master/model/kyc-form/need-analysis/PrioritiesNeeds2'
import BasicProduct from '@master/model/kyc-form/product/BasicProduct'

function getRecommendationFlatMap (recommendations: BasicProduct[], clientIndex: 0|1) {
  return recommendations
    .filter(product => Number(product.clientIndex) === clientIndex || Number(product.jointClientIndex) === clientIndex)
    .flatMap(product => {
      const rider = product.riders
      const flatArray = [product]
      if (rider) { // v1 handler
        flatArray.push(...rider as any)
      }
      return flatArray
    })
}
export function noRecommendationForHighPriorityNeedsCheck<T extends SimpleKYCFNA> (clientIndex: 0 | 1, fna: T, recommendations: BasicProduct[], priorityList: SimplePrioritiesList<T>[]) {
  const highPriorityNeeds = Object.keys(fna)
    .map(key => fna[key])
    .filter(({ level, plan }) => level === 'h' && plan === true)

  const currentClientProducts = getRecommendationFlatMap(recommendations, clientIndex)

  // protection needs is check against product needs covered
  const protectionNeeds = highPriorityNeeds
    .filter(({ isProtection }) => isProtection)
    .map(({ needs }) => needs)

  const productNeeds = currentClientProducts
    .map(({ productNeedsCovered }) => productNeedsCovered)
    .flat()

  const distinctProductNeeds = [...new Set(productNeeds)]

  const isProductNeedsMatch = protectionNeeds.every(needs => distinctProductNeeds.includes(needs))

  // non protection needs is check against product priority
  const nonProtectionHighPriorityList = priorityList.map(item => {
    const needs = fna[item.key]
    return {
      ...needs,
      priority: item.id
    }
  })
    .filter(({ isProtection, level, plan }) => !isProtection && level === 'h' && plan === true)
    .map(({ priority }) => priority)

  const productPriority = currentClientProducts
    .map(({ priority }) => priority)

  const distinctProductPriority = [...new Set(productPriority)]

  const isNonProductNeedsMatch = nonProtectionHighPriorityList.every(priority => distinctProductPriority.includes(priority))
  return !isProductNeedsMatch || !isNonProductNeedsMatch
}

export function recommendationForUnprioritisedNeeds<T extends SimpleKYCFNA> (clientIndex: 0 | 1, fna: T, recommendations: BasicProduct[],
  priorityList: SimplePrioritiesList<T>[]) {
  const selectedPriorities = priorityList.map(item => {
    const needs = fna[item.key]
    return {
      ...needs,
      priority: item.id
    }
  })
    .filter(({ plan }) => plan)
    .map(({ priority }) => priority)

  const onlyContainPrioritiseNeeds = getRecommendationFlatMap(recommendations, clientIndex)
    .every(({ priority }) => selectedPriorities.includes(priority))

  return !onlyContainPrioritiseNeeds
}

export function recommendationNotWithinInsurerProducts<T extends SimpleKYCFNA> (clientIndex: 0 | 1, fna: T, recommendations: BasicProduct[], priorityList: SimplePrioritiesList<T>[]) {
  const priorityMapping = priorityList.reduce((mapping, { id: priority, key }) => {
    const { needs } = fna[key]
    return {
      ...mapping,
      [priority]: needs
    }
  }, {})

  return !getRecommendationFlatMap(recommendations, clientIndex)
    .every(product => {
      const needs = priorityMapping[product.priority]
      return product.productNeedsCovered && product.productNeedsCovered.includes(needs)
    })
}
