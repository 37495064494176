/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculateCommissionsDTO } from '../models/CalculateCommissionsDTO';
import type { GenericSuccessResponseDto } from '../models/GenericSuccessResponseDto';
import type { NewCommissionRecordsImportDTO } from '../models/NewCommissionRecordsImportDTO';
import { request as __request } from '@openapi/request';

export class CommissionRecordsImportService {

    /**
     * @param requestBody Transaction Oids
     * @returns any 
     * @throws ApiError
     */
    public static async validateNewCommissionData(
requestBody: Array<string>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission-records-import/validate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async importNewCommissionDataViaCompensationSetup(
requestBody: NewCommissionRecordsImportDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission-records-import/import`,
            formData: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async calculateCommissions(
requestBody: CalculateCommissionsDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/commission-records-import/calculate-commissions`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param batchOid 
     * @returns GenericSuccessResponseDto 
     * @throws ApiError
     */
    public static async batchRollback(
batchOid: string,
): Promise<GenericSuccessResponseDto> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/commission-records-import/batch/rollback/${batchOid}`,
        });
        return result.body;
    }

    /**
     * @param batchOid 
     * @returns GenericSuccessResponseDto 
     * @throws ApiError
     */
    public static async batchDelete(
batchOid: string,
): Promise<GenericSuccessResponseDto> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/commission-records-import/batch/${batchOid}`,
        });
        return result.body;
    }

}