
import { get, isEmpty, keys, snakeCase, cloneDeep } from 'lodash/fp'
import { Base } from '@master/model/Base'
import { ArrayBase } from '@master/model/ArrayBase'
import { Assumption } from '@master/model/common/Assumption'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { IDateService } from '@master/services/IDateService'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import ModelProxyService, { ModelProxyConfig } from '@master/services/ModelProxyService'
import KYCClient from '@master/model/kyc-form/KYCClient'
import AdviserDetails from '@master/model/kyc-form/AdviserDetails'
import AdviserDeclaration from '@master/model/kyc-form/AdviserDeclaration'
import ClientAssumptions from '@master/model/kyc-form/ClientAssumptions'
import ClientDeclaration from '@master/model/kyc-form/ClientDeclaration'
import { Dependant } from '@master/model/kyc-form/Dependant'
import ExistingPortfolioDeclaration from '@master/model/kyc-form/existing-plans/ExistingPortfolioDeclaration'
import ExistingPortfolioSummary from '@master/model/kyc-form/existing-plans/ExistingPortfolioSummary'
import ExistingInvestDeclaration from '@master/model/kyc-form/existing-plans/ExistingInvestDeclaration'
import ExistingInvestSummary from '@master/model/kyc-form/existing-plans/ExistingInvestSummary'
import ExistingPortfolio from '@master/model/kyc-form/existing-plans/ExistingPortfolioV2'
import ExistingInvestment from '@master/model/kyc-form/existing-plans/ExistingInvestment'
import ILPPortfolio from '@master/model/kyc-form/ILPPortfolio'
import RecommendationV2 from '@master/model/kyc-form/RecommendationV2'
import TrustedIndividual from '@master/model/kyc-form/TrustedIndividual'
import EducationNeedsDetail from '@master/model/kyc-form/need-analysis/savings/EducationNeedsDetail'
import FormKeyConverter from '@master/model/kyc-form/FormKeyConverter'
import { Ignore, isIgnore } from '@master/annotation/Ignore'
import { isPointer, setupPointer } from '@master/annotation/Pointer'
import DependantsProtectionClient from '@master/model/kyc-form/need-analysis/protection/DependantsProtectionClient'
import { IExistingInvestmentWrapper } from '@master/model/kyc-form/existing-plans/IExistingInvestmentWrapper'
import { IExistingPortfolioWrapper } from './existing-plans/IExistingPortfolioWrapper'
import ExistingPortfolioDTO from './existing-plans/v2/ExistingPortfolioDTO'
import ExistingInvestmentDTO from './existing-plans/v2/ExistingInvestmentDTO'
import PoliticalExposedPerson from '../kyc-form/PoliticalExposedPerson'
import InvestmentRiskProfile from './risk-profile/InvestmentRiskProfile'
import { InsuranceProductArray } from './InsuranceProduct'


// import { ECDDFormData} from '@master/model/kyc-form/ecddForm'
import ECDDForm from '@/views/kyc-form/component/ECDD/ECDDForm.vue'

import { ECDDFormModel} from '@master/model/kyc-form/ecddForm'
import { EcddDataList } from '@master/model/kyc-form/listECDD'

import SignPadSigners from '@master/model/kyc-form/SignPadSigners'


// export class ExistingPortfolioDTO implements IExistingPortfolioWrapper {
//   declaration = new ExistingPortfolioDeclaration()
//   summary = new ExistingPortfolioSummary(new ExistingPortfolio())
//   portfolios = Array(12).fill(null).map(() => new ExistingPortfolio())
// }
// export class ExistingInvestmentPortfolioDTO implements IExistingInvestmentWrapper {
//   declaration = new ExistingInvestDeclaration()
//   summary = new ExistingInvestSummary(new ExistingInvestment())
//   portfolios = Array(10).fill(null).map(() => new ExistingInvestment())
// }

export default class KYCForm {
  client1: KYCClient
  client2: KYCClient

  clientDeclaration = new ClientDeclaration()
  clientAssumptions = new ClientAssumptions()
  educationNeedsDetails = Array(5).fill(null).map(() => new EducationNeedsDetail())
  dependantsProtectionClient: DependantsProtectionClient[]
  recommendations: RecommendationV2
  dependants: Dependant[]
  ilpPortfolios = Array(3).fill(null).map(() => new ILPPortfolio())
  trustedIndividual = new TrustedIndividual()
  adviser = {
    details: new AdviserDetails(),
    declaration: new AdviserDeclaration()
  }

  is_client_one_my_info_linked: Boolean
  is_client_two_my_info_linked: Boolean
  maMyInfoExpired: Boolean
  jaMyInfoExpired: Boolean
  maMyinfoAuthRequired: Boolean
  jaMyinfoAuthRequired: Boolean
  cloningOid: string

  manual_reason_c_1: string
  manual_reason_c_2: string
  second_c_2_added: boolean

  existingPortfolio: IExistingPortfolioWrapper = new ExistingPortfolioDTO()

  existingInvestment: IExistingInvestmentWrapper = new ExistingInvestmentDTO()

  listIRP: InvestmentRiskProfile[];

  listInsuranceProduct: InsuranceProductArray;

  ecddForm: ECDDFormModel;

  generateSignPdfComplete: boolean;

  @Ignore()
  snakeCaseKycForm = {}

  @Ignore()
  inProduction = true

  pep = new PoliticalExposedPerson()
  signpad = new SignPadSigners()

  // Storing field name that has been edited
  isDirty = new Map();

  constructor (dateService: IDateService, projectSettings: IProjectSettings, riskRatingService: IRiskRatingService) {
    // super()
    this.client1 = new KYCClient('client_0', dateService, projectSettings)
    this.client2 = new KYCClient('client_1', dateService, projectSettings)
    this.dependants = Array(5).fill(null).map(() => new Dependant(dateService))
    this.dependantsProtectionClient = Array(5).fill(null).map(() => new DependantsProtectionClient(projectSettings))
    this.recommendations = new RecommendationV2(riskRatingService)
    this.listIRP = this.client2.personalDetails?.included === true 
      ? [new InvestmentRiskProfile('client_0', 'portfolio1', true), new InvestmentRiskProfile('client_1', 'portfolio2', true)]
      : [new InvestmentRiskProfile('client_0', 'portfolio1', true), new InvestmentRiskProfile('client_0', 'portfolio2', false)]
      this.listInsuranceProduct = new InsuranceProductArray()

      this.ecddForm = new ECDDFormModel()
  }

  get hasClient2 () {
    return this.client2.personalDetails?.included === true
  }

  get hasAccompaniment () {
    return this.trustedIndividual?.accompanied === 'yes'
  }

  get hasAnsweredAccompanimentQn () {
    return this.trustedIndividual?.accompanied === 'yes' || this.trustedIndividual?.accompanied === 'no'
  }

  get declaredBO1 () {
    return this.client1.beneficialOwner.declarationBeneficialOwner === 'no'
  }

  get hasValidBO1 () {
    const c1BOIncomplete = this.client1?.beneficialOwner?.beneficialOwnerParticulars?.isNotComplete()
    return this.declaredBO1 && !c1BOIncomplete
  }

  get declaredBO2 () {
    return this.hasClient2 && this.client2.beneficialOwner.declarationBeneficialOwner === 'no'
  }

  get hasValidBO2 () {
    const c2BOIncomplete = this.client2?.beneficialOwner?.beneficialOwnerParticulars?.isNotComplete()
    return this.declaredBO2 && !c2BOIncomplete
  }

  get declaredC1PEP () {
    return this.clientDeclaration.client0DeclarationPoliticallyExposed === 'yes'
  }

  get hasValidC1PEP () {
    const targetPEP = this.clientDeclaration.pepList.find(pep => pep.policyholderName === 'C1')
    const c1PEPIncomplete = targetPEP ? targetPEP.isNotComplete() : true
    return this.declaredC1PEP && !c1PEPIncomplete
  }

  get declaredC2PEP () {
    return this.hasClient2 && this.clientDeclaration.client1DeclarationPoliticallyExposed === 'yes'
  }

  get hasValidC2PEP () {
    const targetPEP = this.clientDeclaration.pepList.find(pep => pep.policyholderName === 'C2')
    const c2PEPIncomplete = targetPEP ? targetPEP.isNotComplete() : true
    return this.declaredC2PEP && !c2PEPIncomplete
  }

  get hasAI1 () {
    return this.clientDeclaration.client0AccreditedInvestor === 'yes'
  }

  get hasAI2 () {
    return this.hasClient2 && (this.clientDeclaration.client0AccreditedInvestor === 'yes')
  }

  get allRecommendations () {
    return this.recommendations.rcmd.filter(recommendation => {
      if (!this.hasClient2 && recommendation.clientIndex === 1) {
        return false
      }

      return true
    })
  }

  /**
   * this is the mapping of individual needs category and its corresponding default rate (assumptions)
   * @param assumptions
   */
  setupDefaultRate (assumptions: Assumption) {
    // saving
    this.client1.retirementNeeds.setupDefaultRates(assumptions.salaryIncrementRate, assumptions.inflationAdjustedReturn)
    this.client2.retirementNeeds.setupDefaultRates(assumptions.salaryIncrementRate, assumptions.inflationAdjustedReturn)
    this.client1.savingsNeeds.setupDefaultRate(assumptions.investmentReturn)
    this.client2.savingsNeeds.setupDefaultRate(assumptions.investmentReturn)
    this.client1.investmentNeeds.setupDefaultRate(assumptions.investmentReturn)
    this.client2.investmentNeeds.setupDefaultRate(assumptions.investmentReturn)
    this.client1.specificGoalsNeeds.setupDefaultRate(assumptions.investmentReturn)
    this.client2.specificGoalsNeeds.setupDefaultRate(assumptions.investmentReturn)

    // health & protection
    this.client1.criticalIllnessNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    this.client2.criticalIllnessNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    this.client1.deathProtectionNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    this.client2.deathProtectionNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    this.client1.disabilityIncomeNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    this.client2.disabilityIncomeNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    this.client1.disabilityProtectionNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    this.client2.disabilityProtectionNeeds.setupDefaultRate(assumptions.inflationAdjustedReturn)
    // education details
    for (let index = 0; index < this.educationNeedsDetails?.length; index++) {
      this.educationNeedsDetails[index].setupDefaultRate(assumptions.educationInflationRate)
    }
    // dependants protection
    for (let index = 0; index < this.dependantsProtectionClient?.length; index++) {
      this.dependantsProtectionClient[index].setupDefaultRate(assumptions.inflationAdjustedReturn)
    }
    // @TODO: This needs to be improved on, clientAssumptions are stored in the KYCForm
    // the values are taken from the default assumption endpoint. If this were to improve,
    // consider if we still need `assumption` object in the KYCFormStore or we just use
    // clientAssumptions directly.
    // When storing, 2.3% = 2.3 and not 0.023
    this.clientAssumptions.adjustmentsAssumptionsEducation = assumptions.educationInflationRate || 0
    this.clientAssumptions.adjustmentsAssumptionsInflation = assumptions.inflation || 0
    this.clientAssumptions.adjustmentsAssumptionsInvestment = assumptions.investmentReturn || 0
    this.clientAssumptions.adjustmentsAssumptionsRetirement = assumptions.retirementIncome || 0
    this.clientAssumptions.adjustmentsAssumptionsSalary = assumptions.salaryIncrementRate || 0
    // setup proxy for which value is required to be synced between models
    const mps = new ModelProxyService(this)
    const otherNeedsProxies = this.otherNeedsProxies()
    const allProxies = otherNeedsProxies.concat([
      {
        watch: 'clientDeclaration',
        watchKeys: ['reviewDate'],
        react: 'dependants',
        reactKey: 'reviewDate'
      },
      {
        watch: 'client1.cka',
        react: 'client2.cka',
        mutualKeys: ['ckaCompleted', 'otherInvestmentExp', 'otherProfessional']
      },
      {
        watch: 'client2.cka',
        react: 'client1.cka',
        mutualKeys: ['ckaCompleted', 'otherInvestmentExp', 'otherProfessional']
      },
      {
        watch: 'client1.budget',
        react: 'client2.budget',
        mutualKeys: ['budgetOthers1', 'budgetOthers2', 'budgetOthers3']
      },
      {
        watch: 'client2.budget',
        react: 'client1.budget',
        mutualKeys: ['budgetOthers1', 'budgetOthers2', 'budgetOthers3']
      }
    ])
    mps.setProxy(allProxies)
  }

  otherNeedsProxies (): ModelProxyConfig[] {
    const clientN = [1, 2]
    const needsN = [1, 2]
    const proxies: ModelProxyConfig[] = []
    clientN.forEach(cn => {
      needsN.forEach(nn => {
        const watchModel = `client${cn}.other${nn}Needs`
        proxies.push({
          watch: watchModel,
          watchKeys: ['planningFor'],
          readKey: 'planningFor',
          react: watchModel,
          reactKey: 'text'
        })
        proxies.push({
          watch: watchModel,
          watchKeys: ['text'],
          readKey: 'text',
          react: watchModel,
          reactKey: 'planningFor'
        })
      })
    })
    return proxies
  }

  setData (kycForm: any, obj: object = this, path = '', raw = true) {
    let formattedKYCForm = kycForm
    if (raw) {
      const converter = new FormKeyConverter()
      formattedKYCForm = converter.mapRawToFormatted(kycForm)
    }
    keys(obj).filter(key => !isIgnore(this, key)).forEach(key => {
      // Skipped key list
      if (["isDirty"].includes(key)) {
        return;
      }

      if (isPointer(this, key)) {
        console.error(`Pointer of ${key} at current level of ${this} is not recommended.
        Please use getter as entire model can be accessed in current level.`)
        return
      }
      const newObject = get(key, obj)
      
      if (newObject instanceof Base) {
        // setup pointer
        setupPointer(newObject.getPointers(), this, obj, newObject)
        Object.assign(newObject, newObject.extractData(formattedKYCForm))
      } else if (newObject instanceof Array) {
        if (key === 'listIRP') {
          // Convert data for Investment Risk Profile page
          newObject.forEach((object: ArrayBase, index) => {
            // setup pointer
            setupPointer(object.getPointers(), this, obj, object)
            Object.assign(object, formattedKYCForm?.['list_irp']?.[index])
          })
        } else {
          newObject.forEach((object: ArrayBase, index) => {
            // setup pointer
            setupPointer(object.getPointers(), this, obj, object)
            Object.assign(object, object.extractData(formattedKYCForm, index))
          })
        }
      } else if (newObject instanceof Object) {
        this.setData(formattedKYCForm, newObject, `${path}.${key}`, false)
      } else {
        obj[key] = formattedKYCForm[key]
      }
    })
  }

  deleteNestedObject(obj, path) {
    // Base case: reached the end of the path
    if (path?.length === 0) {
      return undefined; // Indicate deletion
    }
  
    const key = path[0]; // Get the first key in the path
  
    // Check if key exists and is an object (might be an array)
    if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
      const deleted = this.deleteNestedObject(obj[key], path.slice(1)); // Recursive call
      if (deleted === undefined) { // If nested object deleted
        delete obj[key]; // Delete the key itself
      }
    }
  
    return obj;
  }
  
  cloneAndDelete(obj, paths) {
    const clonedObj = cloneDeep(obj)
    for (const path of paths) {
      this.deleteNestedObject(clonedObj, path);
    }
    return clonedObj;
  }

  /*
    Add kyc form keys to below array to exclude from the meta object when there are separate apis to manage those data
  */
  convertToRawDataSimplified(obj: object = this) {
    const pathsToDelete = [
      ["client1", "cashFlow"], 
      ["client2", "cashFlow"], 
      ["client1", "netWorth"],
      ["client2", "netWorth"],
      ["client1", "existingInsurence"],
      ["client2", "existingInsurence"],
      // ["client1FinancialObjectives"],
      // ["client2FinancialObjectives"],
      ["client2", "netWorth"], 
      ["client1", "existingInvestment"],
      ["client2", "existingInvestment"],  
    ];

    const updatedObject = this.cloneAndDelete(obj, pathsToDelete);
    return this.convertToRawData(updatedObject);
  }

  convertToRawData (obj: object = this, path = '') {
    if (obj === this) this.snakeCaseKycForm = {} // reset if calling from highest level
    keys(obj).filter(key => !isIgnore(this, key)).forEach((key) => {
      const newObject = get(key, obj)
      if (newObject instanceof Base) {
        const rawData = newObject.convertToRawData()
        if (!this.inProduction) this.checkOverwrite(this.snakeCaseKycForm, rawData)
        Object.assign(this.snakeCaseKycForm, rawData)
      } else if (newObject instanceof Array) {
        if (key === 'listIRP') {
          // Convert data for Investment Risk Profile page
          Object.assign(this.snakeCaseKycForm, {
            list_irp: newObject
          })
        } else {
          newObject.forEach((object: ArrayBase, index) => {
            const rawData = object?.convertToRawData?.(index)
            if (!this.inProduction) this.checkOverwrite(this.snakeCaseKycForm, rawData)            
            Object.assign(this.snakeCaseKycForm, rawData)
          })
        }
      } else if (typeof newObject === 'string') {
        const snakeKey = snakeCase(key)
        const stringObj = {}
        stringObj[snakeKey] = newObject
        if (!this.inProduction) this.checkOverwrite(this.snakeCaseKycForm, stringObj)
        Object.assign(this.snakeCaseKycForm, stringObj)
      } else if (newObject instanceof Object) {
        this.convertToRawData(newObject, `${path}.${key}`)
      } else {
        const snakeKey = snakeCase(key)
        const stringObj = {}
        stringObj[snakeKey] = newObject
        if (!this.inProduction) this.checkOverwrite(this.snakeCaseKycForm, stringObj)
        Object.assign(this.snakeCaseKycForm, stringObj)
      }
    })
    
    const converter = new FormKeyConverter()
    const rawKycForm = converter.mapFormattedToRaw(this.snakeCaseKycForm)
    return rawKycForm
  }

  /**
   * This is to flag overlapping keys (if any) for debug
   * @param collected
   * @param toadd
   */
  checkOverwrite (collected: object, toadd: object) {
    Object.keys(toadd).forEach((key) => {
      if (Object.keys(collected).includes(key)) {
        console.warn(`[Overlapping Model Attribute Key] KycForm's convertToRawData attempted to overwrite value for : ${key}`)
      }
    })
  }

  getLifeAssured () {
    const list = [{ label: '', value: '' }]
    list.push({
      label: `${this.client1.personalDetails.name} (C1)`,
      value: this.client1.personalDetails.name
    })
    if (this.hasClient2) {
      list.push({
        label: `${this.client2.personalDetails.name} (C2)`,
        value: this.client2.personalDetails.name
      })
    }
    // TODO: using new client1DependantDetails / client2DependantDetails
    this.dependants.filter(dependant => !isEmpty(dependant.name)).forEach(dependant => {
      const sameClient1 = dependant.name === this.client1.personalDetails.name && dependant.nric === this.client1.personalDetails.nric
      const sameClient2 = dependant.name === this.client2.personalDetails.name && dependant.nric === this.client2.personalDetails.nric
      const label = `${dependant.name} (D${dependant.curIndex + 1})`
      if (sameClient1 || sameClient2) {
        const clientIndex = sameClient1 ? 'C1' : 'C2'
        const existingList = list.find(option => option.value === dependant.name)
        if (existingList) existingList.label = `${dependant.name} (${clientIndex}, D${dependant.curIndex + 1})`
        return
      }
      list.push({
        label,
        value: dependant.name
      })
    })
    return list
  }
}
