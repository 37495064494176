import { Ignore } from '@master/annotation/Ignore'
import { snakeCase } from 'lodash/fp'
import { Base } from './Base'

export class TypeBase extends Base {
  @Ignore()
  type = ''

  constructor (type: string) {
    super()
    this.type = type
  }

  snakeCaseKey (key: string) {
    return `${this.type}_${snakeCase(key)}`
  }
}
