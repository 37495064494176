import { CSVModelInterface } from '@core/services/csv/CSVModel.interface';
import { CISDTO } from './CISDTO';
import { Expose } from 'class-transformer';
import { IsNotEmpty, IsNotEmptyAndOneOfThese, IsOneOfThese } from '@core/services/csv/CSVCustomValidator';
import { IsOptional } from 'class-validator';

export class CisCSVModel extends CSVModelInterface implements CISDTO {
  @Expose()
  @IsOptional()
  oid=""

  @Expose()
  @IsNotEmpty()
  indicator ="CIS";
  
  @Expose()
  @IsNotEmpty()
  private 'product_name' = 'abrdn Select Portfolio All China Sustainable Equity Fund SGD';
  get productName() {
    return this['product_name'];
  }

  @Expose()
  @IsOptional()
  private 'product_code' = 'A004';
  get productCode() {
    return this['product_code'];
  }
  
  @Expose()
  @IsNotEmpty()
  private 'provider_name' = 'GROW';
  get providerName() {
    return this['provider_name'];
  }

  @Expose()
  @IsOptional()
  private 'product_type_name' = 'All(Inv)';
  get productTypeName() {
    return this['product_type_name'];
  }
  
  @Expose()
  @IsOptional()
  private 'product_type_code' = 'InvAll';
  get productTypeCode() {
    return this['product_type_code'];
  }

  @Expose()
  @IsNotEmpty()
  private 'mdrt_category' = 'Others';
  get mdrtCategory() {
    return this['mdrt_category'];
  }

  @Expose()
  @IsNotEmpty()
  private 'product_category' = 'UTF';
  get productCategory() {
    return this['product_category'];
  }

  @Expose()
  @IsNotEmptyAndOneOfThese(['Yes', 'No'])
  private 'training_mandatory' = 'No';
  get trainingMandatory() {
    return this['training_mandatory'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsNotEmptyAndOneOfThese(['Yes', 'No'])
  private 'bsc_classification' = 'Yes';
  get bscClassification() {
    return this['bsc_classification'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsOptional()
  private 'fund_house' = 'abrdn Asia Limited';
  get fundHouse() {
    return this['fund_house'];
  }

  @Expose()
  @IsOptional()
  private 'fund_type' = 'NonHCL';
  get fundType() {
    return this['fund_type'];
  }

  @Expose()
  @IsOptional()
  private 'fund_risk_classification' = 'High';
  get fundRiskClassification() {
    return this['fund_risk_classification'];
  }

  @Expose()
  @IsOptional()
  private 'fund_risk_rating' = '5';
  get fundRiskRating() {
    return this['fund_risk_rating'];
  }

  @Expose()
  @IsOptional()
  private 'fund_currency' = 'SGD';
  get fundCurrency() {
    return this['fund_currency'];
  }

  @Expose()
  @IsOptional()
  private 'fund_asset_class' = 'Equities';
  get fundAssetClass() {
    return this['fund_asset_class'];
  }

  @Expose()
  @IsOptional()
  private 'fund_geographical_focus' = 'China';
  get fundGeographicalFocus() {
    return this['fund_geographical_focus'];
  }
  
  @Expose()
  @IsOptional()
  private 'fund_sector' = 'General';
  get fundSector() {
    return this['fund_sector'];
  }

  @Expose()
  @IsOptional()
  @IsOneOfThese(['Yes', 'No', ''])
  private 'fund_income_payout' = 'No';
  get fundIncomePayout() {
    return this['fund_income_payout'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsOptional()
  @IsOneOfThese(['Yes', 'No', ''])
  private 'fund_cash' = 'Yes';
  get fundCash() {
    return this['fund_cash'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsOptional()
  @IsOneOfThese(['Yes', 'No', ''])
  private 'fund_cpf_oa' = 'Yes';
  get fundCpfOa() {
    return this['fund_cpf_oa'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsOptional()
  @IsOneOfThese(['Yes', 'No', ''])
  private 'fund_cpf_sa' = 'No';
  get fundCpfSa() {
    return this['fund_cpf_sa'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsOptional()
  @IsOneOfThese(['Yes', 'No', ''])
  private 'fund_cpf_srs' = 'No';
  get fundCpfSrs() {
    return this['fund_cpf_srs'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsOptional()
  private 'fund_isin' = 'SG9999000368';
  get fundIsin() {
    return this['fund_isin'];
  }
  
  @Expose()
  @IsNotEmpty()
  status ="ACTIVE";
}
