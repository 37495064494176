/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '@openapi/request';
import { ModuleConfigurationDTO } from '../models/ModuleConfigurationDTO';

export class UserAccessControlService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async seedData(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-access-control/seed-data`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async retirieveUserAccessControl(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-access-control/roles-permissions`,
        });
        return result.body;
    }

    /**
     * @returns ModuleConfigurationDTO
     * @throws ApiError
     */
    public static async retrieveActiveModules(): Promise<Array<ModuleConfigurationDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-access-control/modules`,
        });
        return result.body;
    }

    /**
     * @param policyNumber
     * @returns any
     * @throws ApiError
     */
    public static async retrieveRolesPermissionsByModule(
        moduleOid: string,
        searchField?: string,
        searchFieldValue?:string
    ): Promise<Array<any>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/user-access-control/roles-permissions/${moduleOid}`,
            query: {
                'searchField': searchField,
                'searchFieldValue': searchFieldValue
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async updateRolePermissionByModuleAccess(
        requestBody: Object,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/user-access-control/roles-permissions`,
            body: requestBody,
        });
        return result.body;
    }

}