import OccupationList from '@master/model/options/OccupationList.json'
import { RelationshipOptions } from '@master/model/options/RelationshipOptions'
import { VenueOptions } from '@master/model/options/VenueOptions'
import { AcquisitionOptions } from '@master/model/options/AcquisitionOptions'

// this type is used to match formstructure's options type else it will complain not match type
export type options = {
  label: string;
  value: string | boolean | number;
}
export class UIOptions {
  occupationList: string[] = OccupationList
  relationshipList: options[] = RelationshipOptions
  venueOptions: options[] = VenueOptions
  acquisitionOptions: options[] = AcquisitionOptions
}
