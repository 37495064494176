/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePayeeDTO } from '../models/CreatePayeeDTO';
import type { PayeeDTO } from '../models/PayeeDTO';
import { request as __request } from '@openapi/request';

export class PayeeService {

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async upsertPayee(
requestBody: CreatePayeeDTO,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/payee`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async massUploadPayee(
requestBody: Array<PayeeDTO>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/payee/import`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param headers 
     * @param limit 
     * @param page 
     * @returns any 
     * @throws ApiError
     */
    public static async fetchPayeeList(
headers: string,
limit: number,
page: number,
search?: string,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/payee/list`,
            query: {
                'headers': headers,
                'limit': limit,
                'page': page,
                'search': search,
            },
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param headers 
     * @param limit 
     * @param page 
     * @returns any 
     * @throws ApiError
     */
    public static async fetchPayee(
oid: string,
headers: string,
limit: number,
page: number,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v2/payee/${oid}`,
            query: {
                'headers': headers,
                'limit': limit,
                'page': page,
            },
        });
        return result.body;
    }

    /**
     * @param oid 
     * @param headers 
     * @param page 
     * @param limit 
     * @param search 
     * @param retrieveAll 
     * @returns any 
     * @throws ApiError
     */
    public static async getPayeeList(
oid?: string,
headers?: string,
page?: number,
limit?: number,
search?: string,
retrieveAll?: boolean,
): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/v3/payee/list`,
            query: {
                'oid': oid,
                'headers': headers,
                'page': page,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

}