/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CpdMasterListResponseDTO } from '../models/CpdMasterListResponseDTO';
import type { CpdMasterRecordDTO } from '../models/CpdMasterRecordDTO';
import type { CpdMasterRecordUpsertDTO } from '../models/CpdMasterRecordUpsertDTO';
import { request as __request } from '@openapi/request';

export class CpdMasterRecordService {

    /**
     * @param courseTypes
     * @param courseOids
     * @param pagination
     * @param limit
     * @param search
     * @param retrieveAll
     * @returns CpdMasterListResponseDTO Retrieve the list of CPD master records
     * @throws ApiError
     */
    public static async retrieveCpdMasterList(
        courseTypes: Array<string>,
        courseOids: Array<string>,
        pagination?: number,
        limit?: number,
        search?: string,
        retrieveAll?: boolean,
    ): Promise<CpdMasterListResponseDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd-master/list`,
            query: {
                'courseTypes': courseTypes,
                'courseOids': courseOids,
                'pagination': pagination,
                'limit': limit,
                'search': search,
                'retrieveAll': retrieveAll,
            },
        });
        return result.body;
    }

    /**
     * @param courseOid
     * @returns CpdMasterRecordDTO Retrieve a specific CPD master record
     * @throws ApiError
     */
    public static async retrieveCpdMasterRecord(
        courseOid: string,
    ): Promise<CpdMasterRecordDTO> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/cpd-master/${courseOid}`,
        });
        return result.body;
    }

    /**
     * @param courseOid
     * @param requestBody
     * @returns CpdMasterListResponseDTO Updates a CPD master record
     * @throws ApiError
     */
    public static async updateCpdMasterRecord(
        courseOid: string,
        requestBody: CpdMasterRecordUpsertDTO,
    ): Promise<CpdMasterListResponseDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/cpd-master/${courseOid}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param courseOid
     * @returns any Deletes a CPD master record (soft delete)
     * @throws ApiError
     */
    public static async deleteCpdMasterRecord(
        courseOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/cpd-master/${courseOid}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CpdMasterListResponseDTO Creates CPD master record(s)
     * @throws ApiError
     */
    public static async createCpdMasterRecords(
        requestBody: Array<CpdMasterRecordUpsertDTO>,
    ): Promise<CpdMasterListResponseDTO> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-master`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any Validate CPD master record(s) import
     * @throws ApiError
     */
    public static async validateCpdMasterRecordCsv(
        requestBody: Array<CpdMasterRecordUpsertDTO>,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-master/import/validate`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns CpdMasterRecordUpsertDTO Import CPD master record(s)
     * @throws ApiError
     */
    public static async importCpdMasterRecordCsv(
        requestBody: Array<CpdMasterRecordUpsertDTO>,
    ): Promise<Array<CpdMasterRecordUpsertDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/cpd-master/import/csv`,
            body: requestBody,
        });
        return result.body;
    }

}