export const AcquisitionOptions = [
  { value: undefined, label: '' },
  { value: 6001, label: 'Roadshow^*' },
  { value: 6002, label: 'Retailer tie-up^*' },
  { value: 6003, label: 'Door-to-Door Prospecting^*' },
  { value: 6004, label: 'Street Canvassing^*' },
  { value: 6005, label: 'Seminar at client Premise' },
  { value: 6006, label: 'Seminar at Branch/ Office Premise' },
  { value: 6007, label: 'Cold Calling/ Telemarketing' },
  { value: 6008, label: 'Approved Introducer' },
  { value: 6009, label: 'Existing Client' },
  { value: 6010, label: 'Referrals' },
  { value: 6011, label: 'Others (eg. Self-purchased, family, Online Marketing) Please specify' }
]
export const AcquisitionOptionLabel = AcquisitionOptions.map(item => item.label)
export const AcquisitionOptionValues = AcquisitionOptions.map(item => item.value)
export const getAcquisitionLabel= (arg: any) => AcquisitionOptions.find(item => item.label.toLowerCase() ==  arg.toLowerCase() || item.label.toLowerCase() === `${arg?.toLowerCase()}^*`)